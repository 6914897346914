import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AdditionalContactProto } from '../../authentication-constants';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { NgForm } from '@angular/forms';

@Component({
  templateUrl: './add-new-contact.component.html',
  styleUrls: ['./add-new-contact.component.scss']
})
export class AddNewContactComponent implements OnInit {

  @ViewChild('addContactForm') addContactForm: NgForm;
  public title: string;
  public addDialog = false;
  public additionalContact = new AdditionalContactProto();

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: any, public dialogRef: MatDialogRef<AddNewContactComponent>) {
  }

  ngOnInit() {
    this.title = this.dialogData.title;
    this.addDialog = this.dialogData.addDialog;
    if (!this.addDialog) {
      this.additionalContact = {...this.dialogData.contactData};
    }
  }

  addContact() {
    this.dialogRef.close(this.additionalContact);
  }


}
