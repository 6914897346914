import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AuthenticationConstants, ChangePasswordObjProto, StatusWrapper } from '../authentication-constants';
import { NgForm } from '@angular/forms';
import { UserProfilePojo, UserService } from '../../../core/user.service';
import { WebService } from 'web-service';
import { ToasterService } from '../../../core/toaster/toaster.service';
import { LoaderService } from '../../../core/loader/loader.service';
import { MatDialog, MatDialogRef } from '@angular/material';
import { AlertDialogComponent } from '../../../core/alert-dialog/alert-dialog.component';
import { AppUtilService } from '../../../core/app-util.service';
import { PasswordErrorStates } from '../../../constants/app-constants';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  /***
   * @author : Lekhika Inani (lekhika.inani@kahunasystems.com)
   * @description : object for change password
   */
  public changePasswordObjProto: ChangePasswordObjProto = new ChangePasswordObjProto();

  /***
   * @author : Lekhika Inani (lekhika.inani@kahunasystems.com)
   * @description : string to store confirm password
   */
  public cfm_password: string;

  /***
   * @author : Lekhika Inani (lekhika.inani@kahunasystems.com)
   * @description : ng form binding
   */
  @ViewChild('changePasswordForm') public changePasswordForm: NgForm;

  /***
   * @author : Lekhika Inani (lekhika.inani@kahunasystems.com)
   * @description : checks form is valid or not
   */
  public validForm: boolean = false;

  /***
   * @author : Lekhika Inani (lekhika.inani@kahunasystems.com)
   * @description : checks whether password matches or not
   */
  public pwdNotMatch: boolean = false;
  public samePassword: boolean = false;
  public passwordError: PasswordErrorStates = new PasswordErrorStates();
  public showMatch: boolean = false;
  public userDetail: UserProfilePojo = new UserProfilePojo();
  public oldPwd: boolean;
  public newPwd: boolean;
  public cfmPwd: boolean;
  public title: string;

  constructor(public userService: UserService,
              private dialogRef: MatDialogRef<ChangePasswordComponent>,
              public webService: WebService,
              public toasterService: ToasterService,
              public loader: LoaderService,
              private matDialog: MatDialog,
              private utilService: AppUtilService,
              @Inject(MAT_DIALOG_DATA) public dialogData: any) {
  }

  ngOnInit(): void {
    this.title = this.dialogData.title;
    this.changePasswordObjProto.userId = this.userService.getUserProperty('primaryEmailId');
    this.userDetail = this.userService.getUser();
  }

  /***
   * @author : Lekhika Inani (lekhika.inani@kahunasystems.com)
   * @description : to check whether the password is valid or not
   * @param : password{any}
   * @returns : {void}
   */
  validatePassword = (password: any): void => {
    if (this.cfm_password) {
      this.checkCfrmPassword();
    }
    if (this.changePasswordObjProto.newPassword !== this.changePasswordObjProto.oldPassword) {
      if (this.changePasswordObjProto.newPassword === this.cfm_password) {
        this.validForm = false;
      } else {
        this.validForm = true;
      }
      this.passwordError = this.utilService.checkPasswordError(this.changePasswordObjProto.newPassword, this.userDetail);
      this.samePassword = false;
    } else {
      this.samePassword = true;
      this.passwordError = new PasswordErrorStates();
    }
  };

  /***
   * @author : Lekhika Inani (lekhika.inani@kahunasystems.com)
   * @description : to confirm the password with the above written password
   * @param : {void}
   * @returns : {void}
   */
  checkCfrmPassword = (): void => {
    if (this.changePasswordObjProto.newPassword === this.cfm_password) {
      this.validForm = false;
      this.pwdNotMatch = false;
    } else {
      this.validForm = true;
      this.pwdNotMatch = true;
    }
  };

  /***
   * @author : Lekhika Inani (lekhika.inani@kahunasystems.com)
   * @description : to change password
   * @param : {void}
   * @returns : {void}
   */
  changePassword = (): void => {
    this.loader.show();
    this.webService.post(AuthenticationConstants.API.changePassword, this.changePasswordObjProto)
      .subscribe((response: StatusWrapper) => {
        this.loader.hide();
        if (response.status.code === 200) {
          // this.toasterService.showToast(response.status.message, 'success');
          const data = {
            title: '',
            message: 'AUTHENTICATION.CHANGE_PASSWORD.CHANGE_PASSWORD_MESSAGE'
          };
          this.matDialog.open(AlertDialogComponent, this.utilService.getDialogData(data)).afterClosed()
            .subscribe((result) => {
              this.dialogRef.close(true);
            });
        } else {
          this.toasterService.showToast(response.status.message, 'error');
        }
      }, () => {
        this.loader.hide();
      });
  };
}
