import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppSettingDetailsProto, AppSettingService } from '../../../core/app-setting.service';
import { Subscription } from 'rxjs';
import { CannedHomepageService } from '../../../feature-modules/homepage/canned-homepages/pipe/canned-homepage.service';
import { OAuth2Service } from 'o-auth2';
import { LogoutService } from '../../../feature-modules/authentication/logout/logout.service';
import { HomepageConfigParam } from '../../../feature-modules/homepage/canned-homepages/canned-homepages-list';
import { LoginDialogComponent } from '../../../feature-modules/authentication/login-dialog/login-dialog/login-dialog.component';
import { MatDialog } from '@angular/material';
import { AppUtilService } from '../../../core/app-util.service';
import { Router } from '@angular/router';
import { RegisterDialogComponent } from '../../../feature-modules/authentication/register-dialog/register-dialog/register-dialog.component';
import * as $ from 'jquery';
import { UserService } from '../../../core/user.service';
import { ServerUrls } from '../../../server-url-constants';
declare var fby: any;
@Component({
  selector: 'app-default-top-navbar',
  templateUrl: './default-top-navbar.component.html',
  styleUrls: ['./default-top-navbar.component.scss']
})
export class DefaultTopNavbarComponent implements OnInit, OnDestroy {

  public isHomepagePreview: boolean = false;
  public isLoggedIn: boolean = false;
  public appSetting: AppSettingDetailsProto;
  public isToolbarPrimary: boolean = true;
  public homepageConfigParam: typeof HomepageConfigParam = HomepageConfigParam;
  private appSettingSubscriber: Subscription;
  private isLoginDialog: boolean = false;
  private isRegisterDialog: boolean = false;
  public showGlobalSearch: boolean = true;
  public homepageLogoPaddingRemove: boolean = false;
  public isNOFA: boolean = ServerUrls.IS_NOFA;

  constructor(public cannedHomepageService: CannedHomepageService,
              private appSettingService: AppSettingService,
              private authService: OAuth2Service,
              private logoutService: LogoutService, private matDialog: MatDialog,
              private utilService: AppUtilService, private router: Router,
              private userService: UserService) {
  }

  ngOnInit(): void {

    if (ServerUrls.hideGlobalSearch) {
      this.showGlobalSearch = false;
    }

    $('.slideRightToLeft').css('margin-left', '100%');
    this.isLoggedIn = this.authService.isAuthenticated();
    this.appSettingSubscriber = this.appSettingService.getObserver().subscribe((result) => {
      this.appSetting = result;
    });
    const homepageConfig = this.cannedHomepageService.getHomepageConfig();
    this.isToolbarPrimary = homepageConfig[this.homepageConfigParam.SHOW_HOMEPAGE_TOOLBAR_PRIMARY];
    this.isLoginDialog = homepageConfig[this.homepageConfigParam.LOGIN_DIALOG];
    this.isRegisterDialog = homepageConfig[this.homepageConfigParam.REGISTER_DIALOG];
    this.isHomepagePreview = this.cannedHomepageService.getIspreview();
    this.homepageLogoPaddingRemove = ServerUrls.homepageLogoPaddingRemove;
  }

  ngOnDestroy(): void {
    $('.slideRightToLeft').css('margin-left', '100%');
    if (this.appSettingSubscriber) {
      this.appSettingSubscriber.unsubscribe();
    }
  }

  onLogoutClick() {
    this.logoutService.logout();
  }

  onLoginClick() {
    if (this.isLoginDialog) {
      this.matDialog.open(LoginDialogComponent, this.utilService.getDialogData(null));
    } else {
      this.router.navigate(['/login']);
    }
  }

  onRegisterClick() {
    if (this.isRegisterDialog) {
      this.matDialog.open(RegisterDialogComponent, this.utilService.getDialogData(null));
    } else {
      this.router.navigate(['/register']);
    }
  }

  openSearch() {
    setTimeout(() => {
      const interval = setInterval(() => {
        if ($('#searchBox').length !== 0) {
          $('.slideRightToLeft').css('margin-left', '0%');
          clearInterval(interval);
        }
      }, 100);

    }, 0);

  }

  closeSearch(event?) {
    event.stopPropagation();
    $('.slideRightToLeft').css('margin-left', '100%');
  }
  onFeedBackFormClick (){
    fby.push(['showForm', '15557']);return false;
  }
}
