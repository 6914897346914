export class AppConstants {
  public static API: any = {
    'get_dataOnRefresh': '/rest/uiengine/config/v1/get_dataonrefresh',
    'getMenuForSidebar': '/rest/group/menu/v1/list',
    'getCustomPageForUser': '/rest/page_builder/v1/get_pages_for_user',
    'imageUpload': '/rest/caprc/image/upload_image'
  };
}

export const secretPhrase = 'AXyTUvWZUI897YureW';

export const StateList = [
  {'value': 'AL', 'title': 'Alabama'},
  {'value': 'AK', 'title': 'Alaska'},
  {'value': 'AZ', 'title': 'Arizona'},
  {'value': 'AR', 'title': 'Arkansas'},
  {'value': 'CA', 'title': 'California'},
  {'value': 'CO', 'title': 'Colorado'},
  {'value': 'CT', 'title': 'Connecticut'},
  {'value': 'DE', 'title': 'Delaware'},
  {'value': 'FL', 'title': 'Florida'},
  {'value': 'GA', 'title': 'Georgia'},
  {'value': 'HI', 'title': 'Hawaii'},
  {'value': 'ID', 'title': 'Idaho'},
  {'value': 'IL', 'title': 'Illinois'},
  {'value': 'IN', 'title': 'Indiana'},
  {'value': 'IA', 'title': 'Iowa'},
  {'value': 'KS', 'title': 'Kansas'},
  {'value': 'KY', 'title': 'Kentucky'},
  {'value': 'LA', 'title': 'Louisiana'},
  {'value': 'ME', 'title': 'Maine'},
  {'value': 'MD', 'title': 'Maryland'},
  {'value': 'MA', 'title': 'Massachusetts'},
  {'value': 'MI', 'title': 'Michigan'},
  {'value': 'MN', 'title': 'Minnesota'},
  {'value': 'MS', 'title': 'Mississippi'},
  {'value': 'MO', 'title': 'Missouri'},
  {'value': 'MT', 'title': 'Montana'},
  {'value': 'NE', 'title': 'Nebraska'},
  {'value': 'NV', 'title': 'Nevada'},
  {'value': 'NH', 'title': 'New Hampshire'},
  {'value': 'NJ', 'title': 'New Jersey'},
  {'value': 'NM', 'title': 'New Mexico'},
  {'value': 'NY', 'title': 'New York'},
  {'value': 'NC', 'title': 'North Carolina'},
  {'value': 'ND', 'title': 'North Dakota'},
  {'value': 'OH', 'title': 'Ohio'},
  {'value': 'OK', 'title': 'Oklahoma'},
  {'value': 'OR', 'title': 'Oregon'},
  {'value': 'PA', 'title': 'Pennsylvania'},
  {'value': 'RI', 'title': 'Rhode Island'},
  {'value': 'SC', 'title': 'South Carolina'},
  {'value': 'SD', 'title': 'South Dakota'},
  {'value': 'TN', 'title': 'Tennessee'},
  {'value': 'TX', 'title': 'Texas'},
  {'value': 'UT', 'title': 'Utah'},
  {'value': 'VT', 'title': 'Vermont'},
  {'value': 'VA', 'title': 'Virginia'},
  {'value': 'WA', 'title': 'Washington'},
  {'value': 'WV', 'title': 'West Virginia'},
  {'value': 'WI', 'title': 'Wisconsin'},
  {'value': 'WY', 'title': 'Wyoming'}
];

export class KendoPagination {
  pageNumber: number = 1;
  pageSize: number = 10;
  skip: number = 0;
}

export class PaginationProto {
  pageNumber: number = 1;
  pageSize: number = 10;
}

export class PaginationRequestProto {
  pageNumber: number;
  pageSize: number;
  searchText: string;
}

export class PaginatorPaginationProto {
  pageNumber: number = 1;
  pageSize: number = 10;
  total: number;
}

export interface LanguageListProto {
  languageKey: string;
  languageName: string;
}

export interface DefaultResponse {
  status: ResponseStatusWrapper
}

export interface ResponseStatusWrapper {
  code: number;
  message: string;
  cause: string;
}

export class PaginationObject {
  skip: number = 0;
  take: number = 10;
}

export class KendoReportsPagination {
  pageNumber: number = 1;
  pageSize: number = 50;
  skip: number = 0;
}

export class PasswordErrorStates {
  pwdLength: boolean = false;
  pwdCap: boolean = false;
  pwdLetter: boolean = false;
  pwdDigit: boolean = false;
  formError: boolean = false;
  pwdLengthMax: boolean = false;
  pwdWhiteSpace: boolean = false;
  pwdContainsFirstName: boolean = false;
  pwdContainsLastName: boolean = false;
  pwdContainsDictionaryString: boolean = false;
  pwdSpecialCharacter: boolean = false;
}


export class ListRequestProto {
  pageNumber: number;
  pageSize: number;
  filters: any [];
  sort: any[];
}

export class FilterProto {
  key: string;
  value: string;
}
