import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { LayoutService } from '../layout.service';
import { MenuService } from '../components/menu.service';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { LanguageService } from '../../core/language/language.service';
import { MainMenuItem } from '../layout-constants';
import { Subscription } from 'rxjs';
import { AppSettingService } from '../../core/app-setting.service';

@Component({
  selector: 'app-layout-toolbar-tabbed',
  templateUrl: './layout-toolbar-tabbed.component.html',
  styleUrls: ['./layout-toolbar-tabbed.component.scss']
})
export class LayoutToolbarTabbedComponent implements OnInit, OnDestroy {

  public showTabs: boolean = false;
  public children: MainMenuItem[] = [];
  public currentLanguage: string;
  public showAccordion: boolean = false;
  public accordionsList: MainMenuItem[] = [];
  public selectedIndex: number = 0;
  public accordionIndex: number;
  public allMenus: MainMenuItem[] = [];
  public menuList: MainMenuItem[] = [];
  public subscription: Subscription;
  public appLogoPath: string;
  private appSettingSubscriber: Subscription;

  constructor(public layoutService: LayoutService, private menuService: MenuService, public router: Router,
              private languageService: LanguageService, private changeDetectorRef: ChangeDetectorRef,
              private appSettingService: AppSettingService) {
  }

  ngOnInit(): void {
    this.appSettingSubscriber = this.appSettingService.getObserver().subscribe((result) => {
      this.appLogoPath = result.appLogoPath;
    });

    this.subscription = this.menuService.getQuickMenus().subscribe((response) => {
      this.allMenus = this.menuService.menus;
      this.menuList = response;
      this.menuList.forEach((v) => {
        if (v.type === 'DROPDOWN') {
          v.children.forEach((child) => {
            if (child.type === 'LINK') {
              if (child['state'] === this.router.routerState.snapshot.url.toString()) {
                this.showChildren(v);
              }
            }
            if (child.type === 'DROPDOWN') {
              child.children.forEach((value) => {
                if (value.type === 'LINK') {
                  if (value['state'] === this.router.routerState.snapshot.url.toString()) {
                    this.showChildren(v);
                  }
                }
              });
            }
          });
        }
      });
    });
    this.currentLanguage = this.languageService.getCurrentLanguage();
    this.router.events.subscribe((e: RouterEvent) => {
      if (e instanceof NavigationEnd) {
        this.showTabs = false;
        this.allMenus.forEach((v) => {
          if (v.type === 'DROPDOWN') {
            v.children.forEach((child) => {
              if (child.type === 'LINK' && child.state === e.url) {
                if (child['state'] === this.router.routerState.snapshot.url.toString()) {
                  this.showChildren(v);
                }
              }
              if (child.type === 'DROPDOWN') {
                child.children.forEach((value) => {
                  if (value.type === 'LINK' && value.state === e.url) {
                    if (value['state'] === this.router.routerState.snapshot.url.toString()) {
                      this.showChildren(v);
                    }
                  }
                });
              }
            });
          }
        });
      }
    });
  }

  ngOnDestroy(): void {
    if (this.appSettingSubscriber) {
      this.appSettingSubscriber.unsubscribe();
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  showChildren(item: any) {
    this.selectedIndex = undefined;
    this.accordionIndex = undefined;
    this.showTabs = false;
    this.showAccordion = false;
    if (item.type !== 'LINK' && item.hasOwnProperty('children')) {
      if (item.children.length > 0) {
        this.showTabs = true;
        this.children = item.children;
        this.setState();
      } else {
        this.children = [];
      }
    }
  }

  setState() {
    let flag = false;
    this.children.forEach((v, k) => {
      if (v['state'] === this.router.routerState.snapshot.url.toString()) {
        flag = true;
        this.selectedIndex = k;
        this.selectedIndexChange(this.selectedIndex);
      }
      if (v.type === 'DROPDOWN') {
        v.children.forEach((child) => {
          if (child['state'] === this.router.routerState.snapshot.url.toString()) {
            flag = true;
            this.selectedIndex = k;
            this.selectedIndexChange(this.selectedIndex);
          }
        });
      }
    });
    if (flag === false) {
      if (this.children && this.children.length > 0) {
        if (this.children[0].type === 'DROPDOWN' && this.children[0].children.length > 0) {
          this.router.navigateByUrl(this.children[0].children[0].state);
        } else {
          for (const i in this.children) {
            if (this.children[i].children.length === 0) {
              this.router.navigateByUrl(this.children[i].state);
              break;
            } else {
              this.selectedIndexChange(JSON.parse(i));
            }
          }
        }
      }
    }
  }

  selectedIndexChange(index: number) {
    this.accordionIndex = undefined;
    this.accordionsList = [];
    this.selectedIndex = index;
    this.showAccordion = false;
    let flag = false;
    this.children.forEach((v, k) => {
      if (k === index) {
        if (v.type === 'LINK') {
          this.router.navigateByUrl(v.state);
        }
        if (v.type === 'DROPDOWN') {
          this.showAccordion = true;
          this.accordionsList = v.children.filter(object => object.showInMenu || object.showInMenu === undefined);
          this.accordionsList.forEach((accordion, accIndex) => {
            if (accordion['state'] === this.router.routerState.snapshot.url.toString()) {
              this.accordionIndex = accIndex;
              flag = true;
            }
          });
          if (!flag) {
            for (const i in this.accordionsList) {
              if (this.accordionsList[i].children.length === 0) {
                this.router.navigateByUrl(this.accordionsList[i].state);
                break;
              }
            }
          }
        }
      }
    });
  }

}
