import { Component, OnDestroy, OnInit } from '@angular/core';
import { CannedHomepageService } from '../../feature-modules/homepage/canned-homepages/pipe/canned-homepage.service';
import { Subscription } from 'rxjs';
import { HomepageConfigParam } from '../../feature-modules/homepage/canned-homepages/canned-homepages-list';
import { MenuService } from '../components/menu.service';
import {ServerUrls} from "../../server-url-constants";

@Component({
  selector: 'app-default-layout',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss']
})
export class DefaultLayoutComponent implements OnInit, OnDestroy {

  public homepageConfigParam: typeof HomepageConfigParam = HomepageConfigParam;
  public homepageConfig: Map<HomepageConfigParam, any> = new Map<HomepageConfigParam, any>();
  private subscriber: Subscription;
  public isNOFA: boolean = ServerUrls.IS_NOFA;

  constructor(private cannedHomepageService: CannedHomepageService,
              private menuService: MenuService) {
  }

  ngOnInit(): void {
    this.menuService.getMenus().subscribe();
    this.subscriber = this.cannedHomepageService.getHomepageConfigObservable().subscribe((result) => {
      this.homepageConfig = result;
    });
  }

  ngOnDestroy(): void {
    if (this.subscriber) {
      this.subscriber.unsubscribe();
    }
  }

}
