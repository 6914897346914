/****** Created by Ganeshram Kumhar(ganeshram.kumhar@kahunasystems.com) 9/5/18 1:31 PM*******/

export class SeriesObjModel {
  name: string;
  data: any[] = [];
}

export class PieSeriesObjModel {
  data: string;
  value: number;
}

export class FunnelSeriesObjModel {
  stat: string;
  count: number;
}

export class SelectedFilterFieldProto {
  fieldName: string;
  fieldDataType: string;
  basicfilter: any[] = [];
  filtertype: string;
  firstOperation: string;
  secOperation: string;
  firstValue: string | number;
  secValue: string | number;
  logicalOpe = 'AND';
}

export class AxisShowHideProto {
  xAxis: boolean = true;
  yAxis: boolean = true;
  toolTip: boolean = true;
  legend: boolean = true;
  labels: boolean = true;
  showYAxiskeyValue: boolean = true;
  showXAxiskeyvalue: boolean = true;
}

export const AggregationList = [
  {
    title: 'Sum',
    value: 'SUM',
    type: ['INT', 'FLOAT']
  },
  {
    title: 'Avg',
    value: 'AVG',
    type: ['INT']
  },
  {
    title: 'Count',
    value: 'COUNT',
    type: ['INT', 'FLOAT', 'TEXT', 'LOCATION']
  },
  {
    title: 'Dev',
    value: 'DEV',
    type: ['INT', 'FLOAT']
  },
  {
    title: 'Min',
    value: 'MIN',
    type: ['INT', 'FLOAT']
  },
  {
    title: 'Max',
    value: 'MAX',
    type: ['INT', 'FLOAT']
  },
  {
    title: 'Day',
    value: 'DAY',
    type: ['DATE']
  },
  {
    title: 'Week',
    value: 'WEEK',
    type: ['DATE']
  },
  {
    title: 'Month',
    value: 'MONTH',
    type: ['DATE']
  },
  {
    title: 'Year',
    value: 'YEAR',
    type: ['DATE']
  },
  {
    title: 'None',
    value: 'NONE',
    type: ['DATE', 'TEXT', 'INT', 'FLOAT', 'LOCATION']
  }
];

export const ChartEnum = {
  'axisChart': ['BAR', 'STACK', 'AREA', 'LINE'],
  'groupChart': ['PIE', 'DONUT'],
  'trendChart': ['SCATTER', 'BUBBLE'],
  'funnelChart': ['FUNNEL'],
  'waterfallChart': ['WATERFALL'],
  'numberChart': ['NUMBER'],
  'mapChart': ['MAP'],
  'gridChart': ['GRID'],
  'textChart': ['TEXT']
};

export const FilterTypeList = [
  {
    title: 'DASHBOARD.OPEN_WIDGET.FILTER_LIST.BASIC',
    value: 'BASIC',
    notAllow: ['DATE']
  },
  {
    title: 'DASHBOARD.OPEN_WIDGET.FILTER_LIST.ADVANCE',
    value: 'ADVANCE',
    notAllow: []
  },
  {
    title: 'DASHBOARD.OPEN_WIDGET.FILTER_LIST.PAST7DAYS',
    value: 'PAST7DAYS',
    notAllow: ['INT', 'FLOAT', 'LOCATION', 'TEXT']
  },
  {
    title: 'DASHBOARD.OPEN_WIDGET.FILTER_LIST.PASTMONTH',
    value: 'PASTMONTH',
    notAllow: ['INT', 'FLOAT', 'LOCATION', 'TEXT']
  },
  {
    title: 'DASHBOARD.OPEN_WIDGET.FILTER_LIST.PAST3MONTH',
    value: 'PAST3MONTH',
    notAllow: ['INT', 'FLOAT', 'LOCATION', 'TEXT']
  },
  {
    title: 'DASHBOARD.OPEN_WIDGET.FILTER_LIST.PAST6MONTH',
    value: 'PAST6MONTH',
    notAllow: ['INT', 'FLOAT', 'LOCATION', 'TEXT']
  },
  {
    title: 'DASHBOARD.OPEN_WIDGET.FILTER_LIST.PASTYEAR',
    value: 'PASTYEAR',
    notAllow: ['INT', 'FLOAT', 'LOCATION', 'TEXT']
  },
  {
    title: 'DASHBOARD.OPEN_WIDGET.FILTER_LIST.TODAY',
    value: 'TODAY',
    notAllow: ['INT', 'FLOAT', 'LOCATION', 'TEXT']
  },
  {
    title: 'DASHBOARD.OPEN_WIDGET.FILTER_LIST.YESTERDAY',
    value: 'YESTERDAY',
    notAllow: ['INT', 'FLOAT', 'LOCATION', 'TEXT']
  }
];

export const FilterOperationsList = [
  {
    title: 'DASHBOARD.OPEN_WIDGET.FILTER_OPERATIONS_LIST.LT',
    value: 'LT',
    notAllow: ['TEXT']
  },
  {
    title: 'DASHBOARD.OPEN_WIDGET.FILTER_OPERATIONS_LIST.LTE',
    value: 'LTE',
    notAllow: ['TEXT']
  },
  {
    title: 'DASHBOARD.OPEN_WIDGET.FILTER_OPERATIONS_LIST.GT',
    value: 'GT',
    notAllow: ['TEXT']
  },
  {
    title: 'DASHBOARD.OPEN_WIDGET.FILTER_OPERATIONS_LIST.GTE',
    value: 'GTE',
    notAllow: ['TEXT']
  },
  {
    title: 'DASHBOARD.OPEN_WIDGET.FILTER_OPERATIONS_LIST.IS',
    value: 'IS',
    notAllow: []
  },
  {
    title: 'DASHBOARD.OPEN_WIDGET.FILTER_OPERATIONS_LIST.IS_NOT',
    value: 'ISNOT',
    notAllow: []
  },
  {
    title: 'DASHBOARD.OPEN_WIDGET.FILTER_OPERATIONS_LIST.ISBLANK',
    value: 'ISBLANK',
    notAllow: []
  },
  {
    title: 'DASHBOARD.OPEN_WIDGET.FILTER_OPERATIONS_LIST.ISNOTBLANK',
    value: 'ISNOTBLANK',
    notAllow: []
  },
  {
    title: 'DASHBOARD.OPEN_WIDGET.FILTER_OPERATIONS_LIST.ISNULL',
    value: 'ISNULL',
    notAllow: []
  },
  {
    title: 'DASHBOARD.OPEN_WIDGET.FILTER_OPERATIONS_LIST.ISNOTNULL',
    value: 'ISNOTNULL',
    notAllow: []
  },
  {
    title: 'DASHBOARD.OPEN_WIDGET.FILTER_OPERATIONS_LIST.CONTAINS',
    value: 'CONTAINS',
    notAllow: ['INT', 'FLOAT', 'DATE']
  },
  {
    title: 'DASHBOARD.OPEN_WIDGET.FILTER_OPERATIONS_LIST.NOTCONTAINS',
    value: 'NOTCONTAINS',
    notAllow: ['INT', 'FLOAT', 'DATE']
  },
  {
    title: 'DASHBOARD.OPEN_WIDGET.FILTER_OPERATIONS_LIST.STARTWITH',
    value: 'STARTWITH',
    notAllow: ['INT', 'FLOAT', 'DATE']
  },
  {
    title: 'DASHBOARD.OPEN_WIDGET.FILTER_OPERATIONS_LIST.NOTSTARTWITH',
    value: 'NOTSTARTWITH',
    notAllow: ['INT', 'FLOAT', 'DATE']
  },
  {
    title: 'DASHBOARD.OPEN_WIDGET.FILTER_OPERATIONS_LIST.ENDWITH',
    value: 'ENDWITH',
    notAllow: ['INT', 'FLOAT', 'DATE']
  },
  {
    title: 'DASHBOARD.OPEN_WIDGET.FILTER_OPERATIONS_LIST.NOTENDWITH',
    value: 'NOTENDWITH',
    notAllow: ['INT', 'FLOAT', 'DATE']
  }
];

export const FilterLogicalOperationList = [
  {
    title: 'DASHBOARD.OPEN_WIDGET.FILTER_LOGICAL_LIST.AND',
    value: 'AND'
  }, {
    title: 'DASHBOARD.OPEN_WIDGET.FILTER_LOGICAL_LIST.OR',
    value: 'OR'
  }
];

export const OrderTypeList = [
  {
    title: 'DASHBOARD.OPEN_WIDGET.ORDER_LIST.ASC',
    value: 'ASC'
  },
  {
    title: 'DASHBOARD.OPEN_WIDGET.ORDER_LIST.DESC',
    value: 'DESC'
  }
];

export const OpenWidgetChartOptionsConfig = {
  chartRatio: {
    height: 150
  },
  chartDefault: {
    renderAs: 'canvas',
    transitions: false,
    zoomable: false
  },
  seriesDefaults: {
    gap: 0.25
  },
  categoryAxisItem: {
    majorGridLines: {visible: true},
    majorTicks: {visible: true},
    labels: {rotation: 'auto', visible: true}
  },
  valueAxisItem: {
    majorTicks: {visible: true}
  },
  chartLegend: {
    position: 'right',
    orientation: 'vertical'
  },
  labels: {
    visible: true,
    padding: 3,
    format: 'n'
  }
};


export class BubbleChartDataProto {
  x: number;
  y: number;
  size: number;
  category: string;
}

export class OpenWidgetGridChartProto {
  name: string;
  field: string;
  amount: number;
}

export class DataSourceLinksObject {
  host: string;
  service: string;
  paramDataService: string;
  chartDataService: string;
  dataService: string;
}


export class FieldProto {
  label: string;
  renameField: string;
  color: string;
  fieldName: string;
  fieldType: string;
  fieldDataType: string;
  orderType: string;
  aggType: string;
  allowGroup = false;
}

export class CustomReportProto {
  count: number;
  orderType: string;
  reportId: string;
  dimensionField: FieldProto[] = [];
  measureField: FieldProto[] = [];
  chartType: string;
  colorSaturation: FieldProto = new FieldProto();
  topRecord: boolean;
  totalRecord: number;
  columnSequence: any;
  lookAndFeelList: any;
  axisShowHide = new AxisShowHideProto();
  widgetDataUrl: string;
  content: string;
  analytics: ChartAnalyticsProto = new ChartAnalyticsProto();
}

export class ChartAnalyticsProto {
  plotband: PlotBandDetailsProto[] = [];
  highlightSegment = {};
}

export class PlotBandDetailsProto {
  text: string;
  width = 1;
  plotValue: number;
  color: string;
}
