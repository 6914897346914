// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: true,
  gdaxURL: 'wss://ws-feed.gdax.com',
  SERVER_URL: 'https://api.unofa.org',
  server_url: 'https://api.unofa.org',
  APPID: 'NOFA',
  USER_TYPE_LIST: [{
    value: 'Citizen',
    title: 'Internal'
  }],
  IS_NOFA: true,
  GOOGLE_LOGOUT: 'https://www.google.com/accounts/Logout?continue=https://appengine.google.com/_ah/logout?continue=http://192.168.0.151:4568/#/homepage',
  UI_URL: 'http://192.168.0.158/nofaUI2/dist/BrushA6',
  googleAnalyticsKey: 'UA-21232180-2',
  GOOGLE_API_KEY: 'AIzaSyBaftLC1wHx2YALwc7I-_ryxoQ1UkWlaCU'
};
