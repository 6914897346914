import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ServerUrls} from './server-url-constants';

@Injectable()
export class VersionCheckService {
  // this will be replaced by actual hash post-build.js
  private currentHash = '6648e5313336e48e3fdgb';

  constructor(private http: HttpClient) {
  }

  /**
   * Checks in every set frequency the version of frontend application
   * @param url
   * @param {number} frequency - in milliseconds, defaults to 30 minutes
   */
  public initVersionCheck(url, frequency = 60000) {
    setInterval(() => {
      this.checkVersion(url);
    }, frequency);
  }

  /**
   * Will do the call and check if the hash has changed or not
   * @param url
   */
  private checkVersion(url) {
    if (ServerUrls.IS_NOFA) {
      if (localStorage.getItem('nofaHash') !== undefined) {
        this.currentHash = localStorage.getItem('nofaHash');
      }
    } else {
      if (localStorage.getItem('procHash') !== undefined) {
        this.currentHash = localStorage.getItem('procHash');
      }
    }

    // timestamp these requests to invalidate caches
    this.http.get(url)
      .first()
      .subscribe(
        (response: any) => {
          const projectHash = response.hash;
          if (this.currentHash !== projectHash) {
            setTimeout(() => {
              if (ServerUrls.IS_NOFA) {
                localStorage.setItem('nofaHash', projectHash);
              } else {
                localStorage.setItem('procHash', projectHash);
              }
            }, 2000);

            // If new version, do something
            setTimeout(() => {
              location.reload();
            }, 3000);
          }
          // store the new hash so we wouldn't trigger versionChange again
          // only necessary in case you did not force refresh
        },
        (err) => {
          console.error(err, 'Could not get version');
        }
      );
  }
}
