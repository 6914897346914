/**
 * Created by Isha Dhimar (isha.dhimar@kahunasystems.com) on 18/4/18.
 **/
/****** Created by shivani gajjar(shivani.gajjar@kahunasystems.com) 28/12/17 2:00 PM*******/
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'filterArray'})
export class FilterArrayPipe implements PipeTransform {

  transform(list: any, ...args: any[]): any {
    let value, key;
    [value, key] = args;
    if (args[0]) {
      return list.filter((v) => {
        if (v[key] === value) {
          return true;
        }
        return false;
      });
    }
    return undefined;
  }

}

