import { Pipe, PipeTransform } from '@angular/core';

/***
 * @Author : Khushbu Poddar (khushbu.poddar@kahunasystems.com)
 * @description : Captitalise a word to TitleCase (ex: 'methodName' to 'Method Name').
 * @param string
 * @returns {string}
 */

@Pipe({name: 'capitalize'})
export class CapitalizePipe implements PipeTransform {
  transform(value): any {
    if (value) {
      return value.charAt(0).toUpperCase() + value.slice(1).replace(/([a-z])([A-Z])/g, '$1 $2');
    } else {
      return value;
    }
  }
}
