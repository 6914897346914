import { Component, Inject, OnInit } from '@angular/core';
import { PageContentModelProto } from 'kscommon-components';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { LoaderService } from '../../../core/loader/loader.service';

@Component({
    selector: 'app-view-details',
    templateUrl: './view-details-dialog.component.html',
    styleUrls: []
})
export class ViewDetailsDialogComponent implements OnInit {
    item = new PageContentModelProto();

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                public loaderService: LoaderService,
                public matDialogRef: MatDialogRef<ViewDetailsDialogComponent>) {
    }

    ngOnInit(): void {
      this.loaderService.show();
      if (this.data && this.data.moduleData) {
        this.item.moduleData = this.data.moduleData;
      }
      if (this.data && this.data.parameters) {
        this.item.parameters = this.data.parameters;
      }
      this.item.parameters.push({
        key: 'dialogRef',
        data: this.matDialogRef
      });
      this.loaderService.hide();
    }
}
