/***
 * @Author : Shefali Agarwal (saifali.agrawal@kahunasystems.com)
 * @description : Update user profile module
 */

import { ChangeDetectorRef, Component, ElementRef, OnInit, QueryList, ViewChild } from '@angular/core';
import {
  AdditionalContactProto,
  AdditionalFieldDetails,
  AddressCustomFields,
  AddressListProto,
  AddressType,
  ContactDetails,
  ContactType,
  DIRECTIONS_LIST,
  EmailIdList,
  FRACTIONS_LIST,
  InformationObject,
  LatestUserProfileProto,
  LocationAdditionalDetails,
  LocationValidationProto,
  PrefferredCommChannel
} from '../authentication-constants';
import { AppUtilService } from '../../../core/app-util.service';
import { MatDialog } from '@angular/material';
import { ConfirmDialogComponent } from '../../../core/confirm-dialog/confirm-dialog.component';
import { AddNewContactComponent } from './add-new-contact/add-new-contact.component';
import { DomSanitizer } from '@angular/platform-browser';
import { WebService } from 'web-service';
import { LoaderService } from '../../../core/loader/loader.service';
import { ToasterService } from '../../../core/toaster/toaster.service';
import { UserService } from '../../../core/user.service';
import { ServerUrls } from '../../../server-url-constants';
import { AuthenticationUrlConstants } from '../authentication-url.constants';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { LanguageService } from '../../../core/language/language.service';
import { AppSettingService } from '../../../core/app-setting.service';

@Component({
  selector: 'app-update-user-profile',
  templateUrl: './nofa-update-user-profile.component.html',
  styleUrls: ['./update-user-profile.component.scss']
})
export class UpdateUserProfileComponent implements OnInit {
  public contactType: any = ContactType.contactType;
  public addressType: any = AddressType.addressType;
  public preferredCommChannel: any = PrefferredCommChannel.preferredCommChannel;
  public languages: any[] = [];
  public countries: any[] = [];
  public socialTemplate: any[] = [];
  public latestUserProfile: LatestUserProfileProto = new LatestUserProfileProto();
  public imgPath: any;
  public addCustomFieldFlag: boolean;
  public verifyFlag: boolean;
  public addressPrimary: any = 0;
  public contactPrimary: any = 0;
  public emailPrimary: any = 0;
  public finalFlag: boolean = false;
  public aPrimary: boolean;
  public ePrimary: boolean;
  public isEmailPrimary: boolean;
  public cPrimary: boolean;
  public additionalContactArray: AdditionalContactProto[] = [];
  public additionalDetails: LocationAdditionalDetails = new LocationAdditionalDetails();
  public fractionsList: any[] = FRACTIONS_LIST;
  public directionsList: string[] = DIRECTIONS_LIST;
  public imgFile: any;
  public temp: string;
  public avtarImg: string = 'assets/img/user.png';
  public isRemove: boolean = false;
  @ViewChild('updateProfileForm') formObj: NgForm;
  public addressSearchElementRef: QueryList<ElementRef>;
  public isCitizen: boolean = false;
  public dialogTitle: string = 'AUTHENTICATION.CONTACT_FORM.DIALOG_TITLE';
  public languageSubcription: Subscription;
  public selectedLanguage: string;
  public timeZones: any[] = [];

  constructor(public webService: WebService, public loader: LoaderService, public toasterService: ToasterService,
              public userService: UserService, public detector: ChangeDetectorRef, public appUtilService: AppUtilService,
              public dialog: MatDialog, private sanitizer: DomSanitizer, private languageService: LanguageService,
              private appSettingService: AppSettingService) {
  }

  /*@ViewChildren('articleAddress')
  set content(address: QueryList<ElementRef>) {
    this.addressSearchElementRef = address;
    this.setAutoComplete();
  }*/

  ngOnInit(): void {
  //  this.timeZones = timezones;
    this.isCitizen = this.userService.getUserProperty('isCitizen');
    this.languages = this.languageService.getLanguages();
    this.languageSubcription = this.appSettingService.getObserver()
      .subscribe((result) => {
        if (result.language) {
          this.languages = result.language.map(item => item.languageKey);
        }
      });
    this.selectedLanguage = this.languageService.getDefaultLang();
    this.getLatestUserProfile();
    //this.getLanguage();
    this.getCountries();
    this.getSocialTemplate();
  }

  /***
   * @Author : Shefali Agarwal (saifali.agrawal@kahunasystems.com)
   * @description :  address auto complete from google
   * @return {void}
   */

  /* setAutoComplete(): void {
     const c = this.addressSearchElementRef.toArray();
     for (let i = 0; i < this.latestUserProfile.addressList.length; i++) {
       if (c) {
         const autoComplete = new google.maps.places.Autocomplete(c[i].nativeElement, {
           types: ['address']
         });
         autoComplete.addListener('place_changed', () => {
           const place: google.maps.places.PlaceResult = autoComplete.getPlace();
           if (place.geometry === undefined || place.geometry === null) {
             return;
           } else {
             this.latestUserProfile.addressList[i].addressLine = place.formatted_address;
             for (let index = 0; index < place.address_components.length; index++) {
               for (let j = 0; j < place.address_components[index].types.length; j++) {
                 if (place.address_components[index].types[j] === 'country') {
                   for (let k = 0; k < this.countries.length; k++) {
                     if (this.countries[k].countryName === place.address_components[index].long_name) {
                       this.latestUserProfile.addressList[i].country = this.countries[k].countryCode;
                     }
                   }
                 }
                 if (place.address_components[index].types[j] === 'administrative_area_level_1') {
                   this.latestUserProfile.addressList[i].state = place.address_components[index].long_name;
                 }
                 if (place.address_components[index].types[j] === 'locality') {
                   this.latestUserProfile.addressList[i].city = place.address_components[index].long_name;
                 }
                 if (place.address_components[index].types[j] === 'premise') {
                   this.latestUserProfile.addressList[i].unit = place.address_components[index].long_name;
                 }
               }
             }
           }
         });
       }
       this.detector.detectChanges();
     }
   }*/

  /***
   * @Author : Shefali Agarwal (saifali.agrawal@kahunasystems.com)
   * @description :  gets latest user profile from service
   * @return {void}
   */
  getLatestUserProfile(): void {
    this.additionalDetails = new LocationAdditionalDetails();
    this.loader.show();
    this.webService.post(AuthenticationUrlConstants.API.Authentication.updateProfile.getLatestUserProfile, {crmId: this.userService.getUserProperty('crmId')}).subscribe((res) => {
      this.loader.hide();
      if (res.status.code === 200) {
        Object.assign(this.latestUserProfile, res.userProfilePojo);
        if (this.latestUserProfile.addressList) {
          for (let i = 0; i < this.latestUserProfile.addressList.length; i++) {
            this.latestUserProfile.addressList[i].typeSame = true;
            this.latestUserProfile.addressList[i].country = this.latestUserProfile.addressList[i].country;
            this.latestUserProfile.addressList[i].addressLine = this.latestUserProfile.addressList[i].addressLine;
            /*(this.latestUserProfile.addressList[i].city ? this.latestUserProfile.addressList[i].city + ',' : '') +
            (this.latestUserProfile.addressList[i].state ? this.latestUserProfile.addressList[i].state + ' ' : '') +
            (this.latestUserProfile.addressList[i].zip ? this.latestUserProfile.addressList[i].zip + ',' : '') +
            (this.latestUserProfile.addressList[i].country ? this.latestUserProfile.addressList[i].country : '');*/
            /*if (this.latestUserProfile.addressList[i].addressCustomFields) {
              if (this.latestUserProfile.addressList[i].addressCustomFields.length > 0) {
                for (let j = 0; j < this.latestUserProfile.addressList[i].addressCustomFields.length; j++) {
                  this.latestUserProfile.addressList[i].addressCustomFields[j] = res.userProfilePojo[0].addressList[i].addressCustomFields[j];
                }
              }
            }*/
          }
        }
        if (!res.userProfilePojo.hasOwnProperty('userProfilePath')) {
          res.userProfilePojo.userProfilePath = 'assets/img/user.png';
        }
        this.imgPath = res.userProfilePojo.userProfilePath;
        this.isRemove = !(this.avtarImg === this.imgPath);
        if (res.userProfilePojo.additionalDetails) {
          const findObject = this.appUtilService.findObject(res.userProfilePojo.additionalDetails, 'appId', ServerUrls.APPID);
          if (findObject) {
            this.latestUserProfile.additionalDetails = res.userProfilePojo.additionalDetails;
            for (const key in findObject.information) {
              if (key) {
                const temp = new InformationObject();
                temp.key = key;
                temp.value = findObject.information[key];
                this.additionalDetails.information.push(temp);
              }
            }
          } else {
            const tempAdditionalDetails = new AdditionalFieldDetails();
            tempAdditionalDetails.appId = ServerUrls.APPID;
            this.latestUserProfile.additionalDetails.push(tempAdditionalDetails);
            this.additionalDetails.appId = ServerUrls.APPID;
          }
        }
        this.userService.setUser(res.userProfilePojo);
      }
    }, (error) => {
      this.loader.hide();
    });
  }

  /***
   * @Author : Shefali Agarwal (saifali.agrawal@kahunasystems.com)
   * @description :  get languages
   * @return {void}
   */

  /* getLanguage(): void {
     this.webService.get(AuthenticationUrlConstants.API.getAllLanguages).subscribe((res) => {
       if (res.status.code === 200) {
         this.languages = res.Response;
       }
     });
   }*/

  /***
   * @Author : Shefali Agarwal (saifali.agrawal@kahunasystems.com)
   * @description :  get countries to add address
   * @return {void}
   */
  getCountries(): void {
    this.webService.get(AuthenticationUrlConstants.API.Authentication.updateProfile.getListOfCountries).subscribe(
      (res) => {
        if (res.status.code === 200) {
          this.countries = res.countryList;
        }
      });
  }

  /***
   * @Author : Shefali Agarwal (saifali.agrawal@kahunasystems.com)
   * @description :  fetch social template config data
   * @return {void}
   */
  getSocialTemplate(): void {
    this.webService.get(AuthenticationUrlConstants.API.Authentication.SocialAuthConfig.getSocialAuthConfig).subscribe(
      (res) => {
        if (res.status.code === 200) {
          this.socialTemplate = res.list;
        }
      }
    );
  }

  /***
   * @Author : Shefali Agarwal (saifali.agrawal@kahunasystems.com)
   * @description :  update profile service is invoked
   * @return {void}
   */
  onUpdateProfile(): void {
    this.finalFlag = false;
    this.addressPrimary = 0;
    this.emailPrimary = 0;
    this.contactPrimary = 0;
    /*----------------checks address primary ----------------------*/
    for (let i = 0; i < this.latestUserProfile.addressList.length; i++) {
      if (this.latestUserProfile.addressList[i].primary !== true) {
        this.addressPrimary++;
      }
    }
    if ((this.latestUserProfile.addressList.length) && (this.latestUserProfile.addressList.length === this.addressPrimary)) {
      this.aPrimary = true;
    }

    /*----------------checks email primary ----------------------*/
    for (let i = 0; i < this.latestUserProfile.emailIdList.length; i++) {
      if (this.latestUserProfile.emailIdList[i].primary !== true) {
        this.emailPrimary++;
      }
    }
    if ((this.latestUserProfile.emailIdList.length) && (this.latestUserProfile.emailIdList.length === this.emailPrimary)) {
      this.ePrimary = true;
    }

    /*----------------checks contact primary ----------------------*/
    for (let i = 0; i < this.latestUserProfile.contactDetails.length; i++) {
      if (this.latestUserProfile.contactDetails[i].primary !== true) {
        this.contactPrimary++;
      }
    }
    if ((this.latestUserProfile.contactDetails.length) && (this.latestUserProfile.contactDetails.length === this.contactPrimary)) {
      this.cPrimary = true;
    }
    this.isRemove = !(this.avtarImg === this.imgPath);
    if (this.latestUserProfile.additionalDetails) {
      const findIndex = this.appUtilService.getIndex(this.latestUserProfile.additionalDetails, 'appId', ServerUrls.APPID);
      if (findIndex !== -1) {
        this.latestUserProfile.additionalDetails[findIndex].information = new Map<string, string>();
        this.additionalDetails.information.forEach((field) => {
          this.latestUserProfile.additionalDetails[findIndex].information[field.key] = field.value;
        });
      }
    }

    if (!((this.latestUserProfile.addressList.length) && (this.latestUserProfile.addressList.length === this.addressPrimary)) &&
      !((this.latestUserProfile.emailIdList.length) && (this.latestUserProfile.emailIdList.length === this.emailPrimary)) &&
      !((this.latestUserProfile.contactDetails.length) && (this.latestUserProfile.contactDetails.length ===
        this.contactPrimary))) {
      for (let i = 0; i < this.latestUserProfile.addressList.length; i++) {
        if (this.latestUserProfile.addressList[i].typeSame === false) {
          this.finalFlag = true;
          this.toasterService.showToast('Address is of same type', 'error');
        }
      }
      if (this.finalFlag !== true) {
        const dialogdata = {
          'title': 'CONSTANTS.CONFIRMATION.TITLE',
          'message': 'CONSTANTS.CONFIRMATION.MESSAGE'
        };
        this.dialog.open(ConfirmDialogComponent, this.appUtilService.getDialogData(dialogdata))
          .afterClosed().subscribe((result) => {
          if (result) {
            for (let i = 0; i < this.latestUserProfile.addressList.length; i++) {
              delete this.latestUserProfile.addressList[i].typeSame;
              if (this.latestUserProfile.addressList[i].verified) {
                delete this.latestUserProfile.addressList[i].verified;
              }
            }
            const body = {'data': this.latestUserProfile};
            const formData = new FormData();
            formData.append('appJson', JSON.stringify(body));
            if (this.isRemove && this.imgFile) {

              formData.append('file', this.imgFile);
            }
            this.loader.show();
            this.webService.multipartFileUpload(AuthenticationUrlConstants.API.Authentication.updateProfile.updateUserProfile, formData, {}).subscribe((res) => {
              this.loader.hide();
              if (res.status.code === 200) {
                this.formObj.resetForm();
                this.getLatestUserProfile();
                this.toasterService.showToast(res.status.message, 'success');
              } else {
                this.toasterService.showToast(res.status.message, 'error');
              }
            }, (error) => {
              this.loader.hide();
            });
          }
        });
      }
    }


  }

  /**
   * @author: Isha Dhimar (isha.dhimar@kahunasystems.com)
   * @param: event
   * @description: to update file path of selected image
   */

  fileUpdate(event: any): void {
    this.imgFile = event.target.files[0];
    if (this.imgFile) {
      this.imgPath = URL.createObjectURL(this.imgFile);
    }
    if (this.imgFile !== this.avtarImg) {
      this.isRemove = true;
    }
  }


  /***
   * @Author : Shefali Agarwal (saifali.agrawal@kahunasystems.com)
   * @description :  add new address in profile
   * @return {void}
   */
  onAddAddress(): void {
    const newAddress = new AddressListProto();
    newAddress.type = '';
    newAddress.addressLine = '';
    newAddress.unit = '';
    newAddress.city = '';
    newAddress.state = '';
    newAddress.country = 'US';
    newAddress.landmark = '';
    newAddress.buildingType = '';
    newAddress.zip = '';
    newAddress.primary = false;
    if (this.latestUserProfile.addressList === undefined) {
      this.latestUserProfile.addressList = [];
    }
    this.addCustomFieldFlag = false;
    // this.latestUserProfile.addressList = [newAddress,...this.latestUserProfile.addressList]
    this.latestUserProfile.addressList.push(newAddress);
  }

  /***
   * @Author : Shefali Agarwal (saifali.agrawal@kahunasystems.com)
   * @description :  delete address from profile
   * @param: rowAddress = index of address to be deleted
   * @return {void}
   */
  onDeleteAddress(rowAddress: number): void {
    const data = this.appUtilService.getDialogData(this.appUtilService.getDeleteMsg());
    data.data.message = 'AUTHENTICATION.UPDATE_PROFILE.VALIDATION.REMOVE_ADDRESS';
    this.dialog.open(ConfirmDialogComponent, data).afterClosed()
      .subscribe((result) => {
        if (result) {
          this.latestUserProfile.addressList.splice(rowAddress, 1);
          this.aPrimary = false;
        }
      });
  }

  /***
   * @Author : Shefali Agarwal (saifali.agrawal@kahunasystems.com)
   * @description :  set the primary value for address
   * @param: addressIndex = address index to set its primary to true and others primary false
   * @return {void}
   */
  onAddressPrimary(addressIndex: number): void {
    this.latestUserProfile.addressList.forEach((value: any, index: number) => {
      if (index !== addressIndex) {
        value.primary = false;
      }
    });
  }

  /***
   * @Author : Shefali Agarwal (saifali.agrawal@kahunasystems.com)
   * @description :  add new contact in profile
   * @return {void}
   */
  onAddContact(): void {
    const newContact = new ContactDetails();
    newContact.primary = false;
    newContact.type = 'mobile';
    newContact.phoneCountryPrefix = null;
    newContact.phoneNumberFull = null;
    newContact.phoneExtension = null;
    newContact.faxNumber = null;
    if (this.latestUserProfile.contactDetails === undefined) {
      this.latestUserProfile.contactDetails = [];
    }
    this.latestUserProfile.contactDetails.push(newContact);
  }

  /***
   * @Author : Shefali Agarwal (saifali.agrawal@kahunasystems.com)
   * @description :  delete contact from profile
   * @param: contactIndex = index of contact to be deleted
   * @return {void}
   */
  onDeleteContact(contactIndex: number): void {
    this.latestUserProfile.contactDetails.splice(contactIndex, 1);
    this.cPrimary = false;

  }

  onContactTypeChange(contact, index) {
    this.latestUserProfile.contactDetails[index] = {
      primary: null,
      type: contact.type,
      phoneCountryPrefix: null,
      phoneNumberFull: null,
      faxNumber: null,
      phoneExtension: null
    };
  }

  /***
   * @Author : Shefali Agarwal (saifali.agrawal@kahunasystems.com)
   * @description :  set the primary value for contact
   * @param: contactIndex = contact index to set its primary to true and others primary false
   * @return {void}
   */
  onContactPrimary(contactIndex: number): void {
    this.latestUserProfile.contactDetails.forEach((value: any, index: number) => {
      if (index !== contactIndex) {
        value.primary = false;
      }
    });
  }

  /***
   * @Author : Shefali Agarwal (saifali.agrawal@kahunasystems.com)
   * @description :  add new email in profile
   * @return {void}
   */
  onAddPreferences(): void {
    const newPreference = new EmailIdList();
    newPreference.emailId = '';
    newPreference.primary = false;
    newPreference.verified = false;
    if (this.latestUserProfile.emailIdList === undefined) {
      this.latestUserProfile.emailIdList = [];
    }
    this.latestUserProfile.emailIdList.push(newPreference);
  }

  /***
   * @Author : Shefali Agarwal (saifali.agrawal@kahunasystems.com)
   * @description :  delete preference from profile
   * @param: emailIndex = index of email to be deleted
   * @return {void}
   */
  onDeletePrefernce(emailIndex: number): void {
    this.latestUserProfile.emailIdList.splice(emailIndex, 1);
    this.ePrimary = false;

  }

  /***
   * @Author : Shefali Agarwal (saifali.agrawal@kahunasystems.com)
   * @description :  set the primary value for preference
   * @param: emailIdIndex = email index to set its primary to true and others primary false
   * @return {void}
   */
  onPreferencePrimary(emailIdIndex: number): void {
    this.latestUserProfile.emailIdList.forEach((value: any, index: number) => {
      if (index !== emailIdIndex) {
        value.primary = false;
      }
    });
  }

  /***
   * @Author : Shefali Agarwal (saifali.agrawal@kahunasystems.com)
   * @description :  add new custom field in profile
   * @return {void}
   */
  onAddCustomField(rowAddress: any): void {
    this.addCustomFieldFlag = true;
    const newCustomField = new AddressCustomFields();
    newCustomField.fieldName = '';
    newCustomField.fieldValue = '';
    for (let i = 0; i < this.latestUserProfile.addressList.length; i++) {
      if (this.latestUserProfile.addressList[i] === rowAddress) {
        if (this.latestUserProfile.addressList[i].addressCustomFields === undefined) {
          this.latestUserProfile.addressList[i].addressCustomFields = [];
        }
        this.latestUserProfile.addressList[i].addressCustomFields.push(newCustomField);
      }
    }

  }

  onAddAdditionalDetails() {
    this.additionalDetails.information.push(new InformationObject());
  }

  /***
   * @Author : Shefali Agarwal (saifali.agrawal@kahunasystems.com)
   * @description :  delete custom field from profile
   * @param: rowCustomField = custom field row to be deleted, rowAddress = address of which custom field to be deleted
   * @return {void}
   */
  onDeleteCustomField(addressIndex: number, customIndex: number): void {
    this.latestUserProfile.addressList[addressIndex].addressCustomFields.splice(customIndex, 1);
  }

  onDeleteAdditionalInfor(index: number): void {
    this.additionalDetails.information.splice(index, 1);
  }

  /***
   * @Author : Shefali Agarwal (saifali.agrawal@kahunasystems.com)
   * @description :  verify location entered in profile
   * @param: addressObj = address in which location added, location = location entered by user
   * @return {void}
   */
  onVerifyAddress(addressObj: any, location: any): void {
    this.verifyFlag = false;
    const reqLocationValidation = new LocationValidationProto();
    this.appUtilService.geoCode(location).subscribe((result) => {
      if (result) {
        reqLocationValidation.longitude = result[0].geometry.location.lng();
        reqLocationValidation.latitude = result[0].geometry.location.lat();
        this.loader.show();
        this.webService.post(AuthenticationUrlConstants.API.validateLocation, reqLocationValidation).subscribe((res) => {
          this.loader.hide();
          if (res.status.code === 200) {
            window.dispatchEvent(new Event('resize'));
            this.verifyFlag = true;
            this.toasterService.showToast(res.status.message, 'success');
          } else {
            window.dispatchEvent(new Event('resize'));
            this.verifyFlag = false;
            this.toasterService.showToast(res.status.message, 'error');
          }
        }, (error) => {
          this.loader.hide();
        });
      }
    });
  }

  /***
   * @Author : Shefali Agarwal (saifali.agrawal@kahunasystems.com)
   * @description :  check address type if address type is same or different
   * @param: addressRow = address in which address type to be checked, addressIndex = index of address
   * @return {void}
   */
  checkAddressType(addressRow: any, addressIndex: number): void {

    // this.checkTypeFlag = true;
    for (let index = 0; index < this.latestUserProfile.addressList.length; index++) {
      this.latestUserProfile.addressList[index].typeSame = true;

      if ((this.latestUserProfile.addressList[index].type === addressRow.type) && (addressIndex !== index)) {
        this.latestUserProfile.addressList[addressIndex].typeSame = false;
        // this.checkTypeFlag = false;
        break;
      }
    }
  }

  onCancelUpdate(): void {
    this.getLatestUserProfile();
    this.addressPrimary = 0;
    this.contactPrimary = 0;
    this.emailPrimary = 0;
  }

  /**
   * @author : Isha Dhimar (isha.dhimar@kahunasystems.com)
   * @description : Used to manage additional contacts
   */

  openAddContactDialog(): void {
    const data = {
      title: this.dialogTitle,
      addDialog: true
    };
    this.dialog.open(AddNewContactComponent, this.appUtilService.getDialogData(data)).afterClosed()
      .subscribe((result) => {
        if (result) {
          this.additionalContactArray.push(result);
        }
      });
  }

  editContact(index: number): void {
    const contactData = this.additionalContactArray[index];
    const data = {
      title: 'Edit Contact',
      addDialog: false,
      contactData: contactData
    };
    this.dialog.open(AddNewContactComponent, this.appUtilService.getDialogData(data)).afterClosed()
      .subscribe((result) => {
        if (result) {
          this.additionalContactArray[index] = result;
        }
      });
  }

  deleteContact(index: number): void {
    this.additionalContactArray.splice(index, 1);
  }

  openFileExplorer(): void {
    $('#updateProfileImage').trigger('click');
  }

  sanitizeImageUrl(url: any): any {
    if (url !== undefined && url !== null) {
      return this.sanitizer.bypassSecurityTrustUrl(url);
    }
  }

  onPlaceChange(place: any, index?: number): void {
    this.latestUserProfile.addressList[index].addressLine = place.formatted_address;
    this.latestUserProfile.addressList[index].state = '';
    this.latestUserProfile.addressList[index].city = '';
    this.latestUserProfile.addressList[index].zip = '';
    this.latestUserProfile.addressList[index].country = '';
    this.latestUserProfile.addressList[index].coordinates = [];
    if (place.address_components && place.address_components.length > 0) {
      place.address_components.forEach((value) => {
        if (value.types[0] === 'route') {
          this.latestUserProfile.addressList[index].streetName = value.short_name;
        }
        if (value.types[0] === 'locality') {
          this.latestUserProfile.addressList[index].city = value.short_name;
        }
        if (value.types[0] === 'administrative_area_level_1') {
          this.latestUserProfile.addressList[index].state = value.short_name;
        }
        if (value.types[0] === 'postal_code') {
          this.latestUserProfile.addressList[index].zip = value.short_name;
        }
        if (value.types[0] === 'country') {
          this.latestUserProfile.addressList[index].country = value.short_name;
        }
      });
    }
    this.latestUserProfile.addressList[index].coordinates.push(place.geometry.location.lat(), place.geometry.location.lng());
    this.appUtilService.resizeWindow();
  }

  removeProfileImage(): void {
    if (this.imgPath) {
      this.imgPath = this.avtarImg;
      this.isRemove = false;
    }
  }
}
