import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ToasterService } from '../../../../../../core/toaster/toaster.service';
import { WebService } from 'web-service';
import { ActivatedRoute } from '@angular/router';
import { CrmContactConstants, NoteProto } from '../../crm-contact-constants';
import { LoaderService } from '../../../../../../core/loader/loader.service';

@Component({
  selector: 'app-add-edit-notes-dialog',
  templateUrl: './add-edit-notes-dialog.component.html',
  styleUrls: ['./add-edit-notes-dialog.component.scss']
})
export class AddEditNotesDialogComponent implements OnInit {
  public requestPayload: NoteProto = new NoteProto();
  public title: string = 'CRM_CONTACT.NOTES.ADD_NOTE';
  public isUpdate: boolean = false;
  public noteId: string;

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: any, public webservice: WebService,
              private activatedRoute: ActivatedRoute,
              public dialogRef: MatDialogRef<AddEditNotesDialogComponent>,
              private loader: LoaderService, private toasterService: ToasterService) {
  }

  ngOnInit(): void {
    if (this.dialogData) {
      this.requestPayload.userCrmId = this.dialogData.userCrmId;
      this.isUpdate = this.dialogData.isUpdate;
      if (this.isUpdate) {
        this.noteId = this.dialogData.noteId;
      }
    }
    this.title = this.isUpdate ? 'CRM_CONTACT.NOTES.UPDATE_NOTE' : 'CRM_CONTACT.NOTES.ADD_NOTE';

  }

  /***
   * @Author : Sonal Afre (sonal.afre@kahunasystems.com)
   * @description :  add note to server
   * @return {void}
   */
  onAddNote(): void {
    this.loader.show();
    const formData: FormData = new FormData();
    formData.append('data', JSON.stringify(this.requestPayload));
    this.webservice.multipartFileUpload(CrmContactConstants.API.add, formData)
      .subscribe((response) => {
        this.loader.hide();
        if (response.status.code === 200) {
          this.toasterService.showToast(response.status.message, 'success');
          this.dialogRef.close(true);
        } else {
          this.toasterService.showToast(response.status.message, 'error');
        }
      }, () => {
        this.loader.hide();
      });
  }

  /***
   * @Author : Sonal Afre (sonal.afre@kahunasystems.com)
   * @description :  edit note to server
   * @return {void}
   */
  onEditNote(): void {
    this.loader.show();
    const formData: FormData = new FormData();
    formData.append('data', JSON.stringify(this.requestPayload));
    this.webservice.multipartFileUpload(CrmContactConstants.API.add, formData)
      .subscribe((response) => {
        this.loader.hide();
        if (response.status.code === 200) {
          this.toasterService.showToast(response.status.message, 'success');
          this.dialogRef.close(true);
        } else {
          this.toasterService.showToast(response.status.message, 'error');
        }
      }, () => {
        this.loader.hide();
      });
  }

}
