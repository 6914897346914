import { ResponseStatusWrapper } from '../../../constants/app-constants';
import { State } from '@progress/kendo-data-query';
import { GridDataResult } from '@progress/kendo-angular-grid';

export class MyOrganizationConstants {
  public static API = {
    'getMyActiveOrganization': '/rest/organization/v1/user/active',
    'getMyPendingOrganization': '/rest/organization/v1/user/pending?status=',
    'joinOrganization': '/rest/organization/v1/join/add',
    'joinOrgUserAction': '/rest/organization/v1/join/action',
    'removeUser': '/rest/crm/organization/v1/remove_user',
    'getInviteUserStatus': '/rest/organization/v1/invite_user/user/pending?status=',
    'getJoinUserStatus': '/rest/organization/v1/join/user/pending?status=',
    'getMyActiveOrganizationByCrmId': '/rest/organization/v1/user/active/',
  };
}

export class MyOrgViewReqProto {
  firstName: string;
  lastName: string;
  userName: string;
  role: any[];
  status: string[];
  assignApp: any[];
}


export interface MyOrganizationResponse {
  status: ResponseStatusWrapper;
  organizationList: OrganizationList[];
}

export interface OrganizationList {
  organization: Organization;
  userList: UserList[];
  admin: boolean;
  state?: State;
  gridData?: GridDataResult;
  fullName?: string;
  dunsId?: string;
  emailAddress?: string;
  phoneNumberFull?: any;
  address?: string;
}

export interface UserList {
  crmId: string;
  userId: string;
  firstName: string;
  lastName?: string;
  emailAddress: string;
  addressDetails: AddressDetail[];
  contactNumber?: string;
  status?: string;
}

interface AddressDetail {
  type?: string;
  coordinates: any[];
  primary: boolean;
  addressLine?: string;
  city?: string;
  state?: string;
  country?: string;
  zip?: string;
  addressCustomFields?: any[];
}

interface Organization {
  id: string;
  userId: string;
  crmId: string;
  fullName: string;
  firstName: string;
  activeProfile: boolean;
  emailAddress: string;
  emailVerified: boolean;
  receiveEmergencyNotifications: boolean;
  userType: string;
  updateVersion?: number;
  updatedDate?: string;
  userRole: string[];
  appId: string;
  businessInformation: BusinessInformation;
  pnEnabled: boolean;
  blocked: boolean;
  businessContact: boolean;
  emailIdList?: EmailIdList[];
  phoneNumberFull?: string;
  extNo?: string;
  contactDetails?: ContactDetail[];
  addressList?: AddressList[];
  contactFirstName: string;
  contactLastName: string;
  contactEmailAddress: string;

}

interface AddressList {
  type: string;
  addressLine: string;
  city: string;
  state: string;
  country: string;
  zip: string;
  coordinates: any[];
  isPrimary: boolean;
  landmark: string;
  unit: string;
  buildingType: string;
  addressLine1: string;
  addressLine2: string;
  phoneNumber: string;

  houseNumber: string;
  houseFracNumber: string;
  preDirCd: string;
  streetName: string;
  streetTypeCd: string;
  postDirCd: string;
  zipSuffix: string;
  addressCustomFields: any[];
  primary: boolean;
}

interface ContactDetail {
  type: string;
  phoneCountryPrefix: number;
  phoneNumberFull: string;
  primary: boolean;
}

interface EmailIdList {
  emailId: string;
  primary: boolean;
  verified: boolean;
}

interface BusinessInformation {
  primaryContacts: PrimaryContact[];
  dunsId: string;
  members?: Member[];
}

interface Member {
  crmId: string;
  position: string;
  status?: string;
}

interface PrimaryContact {
  crmId: string;
  status: string;
  position: string;
}


export class UserActionRequestProto {
  action: string;
  status: string;
  comment: string;
}

export interface ApproveRejectJoinRequest {
  userCrmId: string;
  organizationCrmId: string;
  comment: string;
  action: string;
}

export class AssignApplicationRequest {
  crmId: string;
  request: PermissionRequestProto[] = [];
}

export class PermissionRequestProto {
  appId: string;
  permissions: string[] = [];
}
