import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss']
})
export class ErrorDialogComponent implements OnInit {


  public title: string;
  public message: string;
  public isCancel = true;

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: any) {
  }

  ngOnInit() {
    this.title = this.dialogData.title;
    this.message = this.dialogData.message;
    if (this.dialogData.cancel) {
      this.isCancel = false;
    }
  }

}
