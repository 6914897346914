/**
 * Created by Ganeshram Kumhar (ganeshram.kumhar@kahunasystems.com)
 */
import { PageContentModelProto, WIDGET_TYPE_CONST } from 'kscommon-components';

/***
 * @Author : Ganeshram Kumhar (ganeshram.kumhar@kahunasystems.com)
 * @description : homepage configuration enu. developer can set it as per requirement.
 */
export enum HomepageConfigParam {
  SHOW_REGISTER = 'SHOW_REGISTER', /*to show or hide register button from homepage*/
  SHOW_LOGIN_CLICK_LINK = 'SHOW_LOGIN_CLICK_LINK', /*to show or hide register button from homepage*/
  SHOW_FAQ_CLICK_LINK = 'SHOW_FAQ_CLICK_LINK', /*to show or hide faq click here link login*/
  SHOW_FORGOT_PASSWORD = 'SHOW_FORGOT_PASSWORD', /*show hide forget password link*/
  SHOW_SOCIAL_LOGIN = 'SHOW_SOCIAL_LOGIN', /*to show hide social login from page*/
  SHOW_SOCIAL_LOGIN_ON_REGISTER = 'SHOW_SOCIAL_LOGIN_ON_REGISTER', /*to show hide social login from page*/
  SHOW_VERIFY_EMAIL_LINK = 'SHOW_VERIFY_EMAIL_LINK', /*to show hide verify email from login*/
  SHOW_SOCIAL_ICON_IN_FOOTER = 'SHOW_SOCIAL_ICON_IN_FOOTER', /*to show hide social icon from app-user-footer*/
  SHOW_TOP_NAV_BAR = 'SHOW_TOP_NAV_BAR', /*to show hide top default homepage toolbar*/
  LOGIN_DIALOG = 'LOGIN_DIALOG', /*to show login dialog on login button click*/
  REGISTER_DIALOG = 'REGISTER_DIALOG', /*to show register dialog on login button click*/
  SHOW_HOMEPAGE_TOOLBAR_PRIMARY = 'SHOW_HOMEPAGE_TOOLBAR_PRIMARY' /*to show hide top default homepage toolbar*/
}

/***
 * @Author : Ganeshram Kumhar (ganeshram.kumhar@kahunasystems.com)
 * @description : enum for number of type of  homepage layout available in application
 */
export enum CannedHomepagesEnum {
  DEFAULT = 'DEFAULT',
  LOGIN = 'LOGIN',
  LOGIN_SCROLL = 'LOGIN_SCREEN',
  NOFA = 'NOFA'
}

/***
 * @Author : Ganeshram Kumhar (ganeshram.kumhar@kahunasystems.com)
 * @description : proto for canned homepage details
 */
export interface CannedHomepagesDetailsProto {
  title: string;
  type: CannedHomepagesEnum;
  moduleData?: PageContentModelProto[];
}

/***
 * @Author : Ganeshram Kumhar (ganeshram.kumhar@kahunasystems.com)
 * @description : list of the home page layout available
 */
export const CannedHomepageList: CannedHomepagesDetailsProto[] = [
   {
    title: 'Login Screen',
    type: CannedHomepagesEnum.LOGIN,
    moduleData: [
      {
        moduleData: {
          modulePath: 'app/feature-modules/homepage/default-homepage/default-homepage.module#DefaultHomepageModule',
          componentName: 'DefaultHomepageToolbarComponent'
        },
        parameters: [{key: 'showLoginLink', data: false}],
        viewType: WIDGET_TYPE_CONST.COMPONENT
      },
      {
        moduleData: {
          modulePath: 'app/feature-modules/authentication/authentication.module#AuthenticationModule',
          componentName: 'LoginComponent'
        },
        parameters: [{key: 'isHomepage', data: true}],
        viewType: WIDGET_TYPE_CONST.COMPONENT
      },
      {
        moduleData: {
          modulePath: 'app/layouts/layouts.module#LayoutsModule',
          componentName: 'FooterComponent'
        },
        viewType: WIDGET_TYPE_CONST.COMPONENT
      }
    ]
  },
  {
    title: 'NOFA',
    type: CannedHomepagesEnum.NOFA,
    moduleData: [
      {
        moduleData: {
          modulePath: 'app/feature-modules/homepage/nofa-homepage/nofa-homepage.module#NofaHomepageModule',
          componentName: 'NofaHomepageToolbarComponent'
        },
        parameters: [{key: 'showLoginLink', data: false}],
        viewType: WIDGET_TYPE_CONST.COMPONENT
      },
      {
        moduleData: {
          modulePath: 'app/feature-modules/homepage/nofa-homepage/nofa-homepage.module#NofaHomepageModule',
          componentName: 'NofaHomepageViewComponent'
        },
        viewType: WIDGET_TYPE_CONST.COMPONENT
      }
    ]
  }
];

