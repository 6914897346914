import { Component, OnDestroy, OnInit } from '@angular/core';
import { OAuth2Service } from 'o-auth2';
import { UserService } from '../../../core/user.service';
import { MatDialog } from '@angular/material';
import { MenuService } from '../menu.service';
import { LogoutService } from '../../../feature-modules/authentication/logout/logout.service';
import { CommonCookieService } from 'kscookies';
import { AppSettingService } from '../../../core/app-setting.service';
import { LoginDialogComponent } from '../../../feature-modules/authentication/login-dialog/login-dialog/login-dialog.component';
import { HomepageConfigParam } from '../../../feature-modules/homepage/canned-homepages/canned-homepages-list';
import { Router } from '@angular/router';
import { AppUtilService } from '../../../core/app-util.service';
import { CannedHomepageService } from '../../../feature-modules/homepage/canned-homepages/pipe/canned-homepage.service';
import { ChangePasswordComponent } from "../../../feature-modules/authentication/change-password/change-password.component";
import { ServerUrls } from "../../../server-url-constants";

interface UserDetailsForMenu {
  firstName: string;
  lastName?: string;
  profilePic?: string;
}

@Component({
  selector: 'portal-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit, OnDestroy {

  public userDetails: UserDetailsForMenu = {
    firstName: 'Guest',
    profilePic: 'assets/img/user.png'
  };
  private subscriber: any;
  public isLoggedIn: boolean = false;
  public isSocialLoggedIn: boolean = false;
  public hidePassbook: boolean = false;
  private isLoginDialog: boolean = false;
  public isCitizen: boolean = false;
  public isNOFA: boolean = ServerUrls.IS_NOFA;

  constructor(private authService: OAuth2Service, private userService: UserService,
              private dialog: MatDialog, private cookieService: CommonCookieService,
              private menuService: MenuService,
              private logoutService: LogoutService,
              private appSettingService: AppSettingService,
              private router: Router, private utilService: AppUtilService,
              public cannedHomepageService: CannedHomepageService) {
  }

  ngOnInit(): void {
    this.isCitizen = this.userService.getUserProperty('isCitizen');
    this.isSocialLoggedIn = this.cookieService.getCookie('social_logged_in');
    this.isLoggedIn = this.authService.isAuthenticated();
    this.hidePassbook = this.appSettingService.getProperty('hidePassbook');
    this.getUserDetails();
    const homepageConfig = this.cannedHomepageService.getHomepageConfig();
    this.isLoginDialog = homepageConfig[HomepageConfigParam.LOGIN_DIALOG];
  }

  ngOnDestroy(): void {
    if (this.subscriber) {
      this.subscriber.unsubscribe();
    }
  }
  onLoginClick() {
    if (this.isLoginDialog) {
      this.dialog.open(LoginDialogComponent, this.utilService.getDialogData(null));
    } else {
      this.router.navigate(['/login']);
    }
  }

  logout(): void {
    this.logoutService.logout();
  }

  openChangePassword() {
    const data = {
      title: 'AUTHENTICATION.CHANGE_PASSWORD.TITLE',
    };

    this.dialog.open(ChangePasswordComponent, this.utilService.getDialogData(data)).afterClosed()
      .subscribe((result) => {
        if (result) {
          this.logout();
        }
      });
  }

  private getUserDetails(): void {
    this.subscriber = this.userService.getObserver().subscribe((result) => {
      if (result.firstName) {
        this.userDetails.firstName = result.firstName;
      }
      if (result.lastName) {
        this.userDetails.lastName = result.lastName;
      }
      if (result.userProfilePath) {
        this.userDetails.profilePic = result.userProfilePath;
      } else {
        this.userDetails.profilePic = 'assets/img/user.png';
      }
    });
  }
}
