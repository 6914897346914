import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { LayoutsModule } from './layouts/layouts.module';
import { AppComponent } from './app.component';
import 'hammerjs';
import { WebServiceModule } from 'web-service';
import { KSCookiesModule } from 'kscookies';
import { AppConfigService } from './app-config.service';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { environment } from '../environments/environment.prod';
import { CoreModule } from './core/core.module';
import { RouterSubscribeService } from './core/router-subscribe.service';
import { CannedHomepageService } from './feature-modules/homepage/canned-homepages/pipe/canned-homepage.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog, MatIconRegistry } from '@angular/material';
import { VersionCheckService } from './version-check.service';
import { AppUtilService } from './core/app-util.service';
import { LogoutService } from './feature-modules/authentication/logout/logout.service';
import { OAuth2Service } from 'o-auth2';
import { HttpApiInterceptor } from './core/http-api.interceptor';
import { ConfirmDialogComponent } from './core/confirm-dialog/confirm-dialog.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { FroalaEditorSettingService } from './core/froala-editor/froala-editor-setting.service';


export function createTranslateLoader(http: HttpClient): any {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

export function ConfigLoader(configService: any): any {
  return () => configService.load();
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    CoreModule,
    AppRoutingModule,
    LayoutsModule,
    WebServiceModule,
    KSCookiesModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    HttpClientModule,
    CommonModule,
    NgIdleKeepaliveModule.forRoot()
  ],
  entryComponents: [],
  bootstrap: [AppComponent],
  providers: [
    CannedHomepageService,
    VersionCheckService,
    AppConfigService,
    FroalaEditorSettingService,
    {provide: APP_INITIALIZER, useFactory: ConfigLoader, deps: [AppConfigService], multi: true}, HttpClient,
    {provide: HTTP_INTERCEPTORS, useClass: HttpApiInterceptor, multi: true}]
})
export class AppModule {

  public interval: any;
  private isRefresh: boolean = false;

  constructor(private matIconRegistry: MatIconRegistry, private sanitizer: DomSanitizer,
              public routerSubscribeService: RouterSubscribeService,
              public authService: OAuth2Service, private appUtilService: AppUtilService, private dialog: MatDialog,
              private logoutService: LogoutService, private lan: TranslateService) {

    window.addEventListener('storage', (event) => {
      if (event.key === 'logged_in') {
        this.isRefresh = true;
      }
    }, false);
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible' && this.isRefresh) {
        window.location.reload();
      }
    });
    // Register Font Awesome
    matIconRegistry.registerFontClassAlias('fontawesome', 'fa');

    // Register layout icon SVGs
    matIconRegistry.addSvgIcon('classic',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/classic.svg')
    );
    matIconRegistry.addSvgIcon('toolbar',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/toolbar.svg')
    );
    matIconRegistry.addSvgIcon('compact',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/compact.svg')
    );
    matIconRegistry.addSvgIcon('boxed',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/boxed.svg')
    );
    matIconRegistry.addSvgIcon('funky',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/funky.svg')
    );
    matIconRegistry.addSvgIcon('tabbed',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/tabbed.svg')
    );

    this.routerSubscribeService.initRouterSubscribeService();
    // this.interval = setInterval(() => {
    //   if (localStorage.getItem('loginTime')) {
    //     const loginTime = JSON.parse(localStorage.getItem('loginTime'));
    //     const difference = new Date().getTime() - new Date(loginTime).getTime();
    //     const seconds = Math.floor((difference % (1000 * 60)) / 1000);
    //     const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    //     const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    //     if (hours === 0 && minutes === 5 && seconds === 0) {
    //       if (this.authService.isAuthenticated()) {
    //         this.dialog.open(ConfirmDialogComponent, this.appUtilService.getDialogData({
    //           title: 'Session Timeout Warning',
    //           message: 'Your session is about to expire due to inactivity. You will be automatically logged out in 15 minutes. Press "Stay Logged In" if you wish to stay logged in.',
    //           okButtonText: 'Stay Logged In',
    //           cancelButtonText: 'Sign Out'
    //         })).afterClosed().subscribe((result) => {
    //           if (result) {
    //             localStorage.setItem('loginTime', JSON.stringify(new Date()));
    //           } else {
    //             localStorage.removeItem('loginTime');
    //             this.dialog.closeAll();
    //             this.logoutService.logout();
    //             clearInterval(this.interval);
    //           }
    //         });
    //       }
    //     }
    //     if (hours === 1 && minutes === 0 && seconds === 0) {
    //       localStorage.removeItem('loginTime');
    //       this.dialog.closeAll();
    //       this.logoutService.logout();
    //       clearInterval(this.interval);
    //     }
    //   }
    // }, 1000);
  }
}
