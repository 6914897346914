import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export interface KendoShowHideColumn {
  field: string;
  title: string;
}

@Component({
  selector: 'kendo-show-hide-column',
  template: `
    <mat-form-field>
      <mat-select [name]="name"
                  [placeholder]="placeholder | translate"
                  [multiple]="true"
                  [(ngModel)]="innerValue"
                  (valueChange)="valueChange($event)">
        <mat-option *ngFor="let option of _optionList" [value]="option.field"
                    [disabled]="isDisabled(option.field)">
          {{ option.title | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  `,
  styleUrls: ['./kendo-show-hide-column.component.scss'],
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: KendoShowHideColumnComponent, multi: true}
  ]
})
export class KendoShowHideColumnComponent implements ControlValueAccessor {


  @Input('name') public name: string = 'hide-column';
  @Input('placeholder') public placeholder: string = 'CONSTANTS.HIDE_COLUMNS';
  @Output('valueChangedEvent') public valueChangedEvent  = new EventEmitter();
  @Input('optionList')
  set optionList(list: any[]) {
    if (list) {
      this._optionList = list;
    }
  }

  public _optionList: KendoShowHideColumn[] = [];
  public innerValue: string[] = [];

  writeValue(value: string[]): void {
    if (value && this.innerValue !== value) {
      this.innerValue = value;
      this.propagateChange(value);
    }
  }

  propagateChange = (_: any) => {
  };

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  valueChange = (value: any): void => {
    this.writeValue(value);
    this.valueChangedEvent.emit(value);
  };

  public isDisabled(columnName: string): boolean {
    return this._optionList.length - this.innerValue.length === 1 && !this.isHidden(columnName);
  }

  private isHidden(columnName: string): boolean {
    return this.innerValue.indexOf(columnName) > -1;
  }
}
