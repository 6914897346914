/**
 * @author: Hiral Akbari(hiral.akbari@kahunasystems.com)
 *
 */
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  Pagination,
  PaginationObject,
} from '../../../../feature-modules/common-components/common-components.constant';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { ActivatedRoute, Router } from '@angular/router';
import { ToasterService } from '../../../../core/toaster/toaster.service';
import { WebService } from 'web-service';
import { UserService } from '../../../../core/user.service';
import { LoaderService } from '../../../../core/loader/loader.service';
import { OAuth2Service } from 'o-auth2';
import { GlobalSearchUrlConstants, SearchCountRequestProto, ServicesConstants } from '../global-search-url.constants';
import { CommonCookieService } from 'kscookies';
import { LanguageService } from '../../../../core/language/language.service';
import { ServerUrls } from '../../../../server-url-constants';
import { ViewCrmContactComponent } from '../../../../feature-modules/admin/crm/crm-contact/view-crm-contact/view-crm-contact.component';
import { MatDialog } from '@angular/material';
import { AppUtilService } from '../../../../core/app-util.service';

@Component({
  selector: 'app-search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.scss']
})
export class SearchResultComponent implements OnInit, OnDestroy {

  public data: any;
  public searchContentRequest: SearchCountRequestProto = new SearchCountRequestProto();
  public searchList: any[] = [];
  public paginationObj: Pagination = new Pagination();
  public googleKey: string = ServerUrls.GOOGLE_API_KEY;
  public subscription: any;
  public isUserLoggedin: boolean = true;
  public gridData: GridDataResult;
  public paginationObject: PaginationObject = new PaginationObject();
  public showMore: any = [false];
  public isIE: boolean;

  constructor(private activatedRoute: ActivatedRoute, public toasterService: ToasterService,
              private router: Router, private webService: WebService, private userService: UserService,
              private loader: LoaderService, public oAuthService: OAuth2Service,
              public cookieService: CommonCookieService, public languageService: LanguageService,
              private dialog: MatDialog, private utilService: AppUtilService) {
  }

  ngOnInit(): void {
    this.isUserLoggedin = this.oAuthService.isAuthenticated();
    this.subscription = this.activatedRoute.params.subscribe((res) => {
      this.paginationObj.total = 0;
      this.paginationObj.pageSize = 10;
      this.paginationObj.pageNumber = 0;
      this.data = res;
      this.searchContentRequest.cityService = [ServicesConstants.SERVICES[this.data.index]];
      this.searchContentRequest.cityDirectory = ['ALL'];
      this.searchContentRequest.pageSize = this.paginationObj.pageSize.toString();
      this.searchContentRequest.language = this.languageService.getCurrentLanguage();
      this.loader.show();
      this.fecthList();
    });
    this.isIE = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }


  /**
   * @author: Hiral Akbari(hiral.akbari@kahunasystems.com)
   * @description : To fetch search result
   */

  fecthList(fromGrid?: boolean): void {
    // this.loader.show();
    if (fromGrid) {
      this.searchContentRequest.pageNumber = (this.paginationObject.pageNumber / this.paginationObject.pageSize + 1).toString();
    } else {
      this.searchContentRequest.pageNumber = (this.paginationObj.pageNumber + 1).toString();
    }
    this.searchContentRequest.pageSize = this.paginationObj.pageSize.toString();
    this.searchContentRequest.keyword = this.data.keyword;
    this.webService.post(GlobalSearchUrlConstants.API.searchResult.getResultList, this.searchContentRequest
    )
      .subscribe((response) => {
        if (response.status.code === 200) {
          this.loader.hide();
          if (response.response) {
            response.response.forEach((val) => {
              val['showMore'] = false;
            });
            this.searchList = response.response;
            this.gridData = {
              data: response.response,
              total: response.totalRecord
            };
          }
          if (!fromGrid) {
            this.paginationObj.total = response.totalRecord;
            this.showMore.length = response.totalRecord;
            this.showMore.fill(false);
            if (this.paginationObj.pageSize > this.paginationObj.total) {
              this.paginationObj.pageSize = this.paginationObj.total;
            }
            if (this.data.type === 'Address' || this.data.type === 'APN') {
              this.searchList.forEach((asset) => {
                this.webService.getFromUrlWithOutHeader('https://maps.googleapis.com/maps/api/streetview/metadata' +
                  '?size=400x400&location=' + asset.parameters.additional.latitude + ',' + asset.parameters.additional.longitude + '&fov=90&heading=235&pitch=10' +
                  '&key=' + this.googleKey)
                  .subscribe((googleImageResponse) => {
                    if (googleImageResponse.status !== 'ZERO_RESULTS') {
                      asset.parameters.additional.streetView = 'https://maps.googleapis.com/maps/api/streetview?size=400x400&location='
                        + asset.parameters.additional.latitude + ',' + asset.parameters.additional.longitude + '&fov=90&heading=235&pitch=10&key='
                        + this.googleKey;
                    } else {
                      asset.parameters.additional.streetView = 'assets/images/property-default-image.png';
                    }
                  });
              });
            }
          }
        } else {
          this.loader.hide();
          this.toasterService.showToast(response.status.message, 'error');
        }
      }, (error) => {
        this.loader.hide();
      });
  }

  showMoreDetails(article: any): void {
    article.showMore = true;
  }

  showLessDetails(article: any): void {
    article.showMore = false;
  }

  redirectToPropertyView(id: string): void {
    this.loader.show();
    this.router.navigate(['/case-engine/property', id]);
  }

  onPageChange(event: any, fromGrid?: boolean): void {
    if (fromGrid) {
      this.paginationObject.pageSize = event.take;
      this.paginationObject.pageNumber = event.skip;
    } else {
      // this.paginationObject.pageSize = event.pageSize;
      // this.paginationObject.pageNumber = event.pageIndex;
      this.paginationObj.pageNumber = event.pageIndex;
      this.paginationObj.pageSize = event.pageSize;
    }

    this.fecthList(fromGrid);
  }

  onViewArticle(article: any): void {
    if (this.data.type === 'Articles') {
      if (this.isUserLoggedin) {
        this.router.navigate(['/rich-content/published/view/details', article.parameters.blogId]);
      } else {
        this.router.navigate(['/content/details', article.parameters.blogId]);
      }
    } else if (this.data.type === 'Case') {
      if (article.parameters.additional.apnNumber) {
        if (this.isUserLoggedin) {
          this.router.navigate(['/case-engine/property', article.parameters.additional.apnNumber, article.parameters.additional.caseId]);
        } else {
          this.router.navigate(['/property', article.parameters.additional.apnNumber, article.parameters.additional.caseId]);
        }
      } else {
        if (this.isUserLoggedin) {
          this.router.navigate(['/case-engine/case-management/case-details', article.parameters.additional.caseId]);
        } else {
          this.router.navigate(['/case/case-details', article.parameters.additional.caseId]);
        }

      }
    } else if (this.data.type === 'Contact') {
      this.loader.show();
      if (this.isUserLoggedin) {
        this.dialog.open(ViewCrmContactComponent, this.utilService.getDialogDataFullScreen(article.parameters)).afterClosed()
          .subscribe((result) => {
            this.loader.hide();
          });
      }
    }
  }

  onCreateService(list: any): void {
    this.cookieService.setCookie('srTy', list.parameters.caseTypeId);
    if (this.data.type === 'Contact') {
      this.cookieService.setCookie('cId', list.title);
    }
    if (this.isUserLoggedin) {
      this.router.navigate(['/case-engine/case-management/intake/create']);
    } else {
      this.router.navigate(['/intake/create']);
    }

  }

  tabChangeEvent() {
    this.paginationObj.total = 0;
    this.paginationObj.pageSize = 10;
    this.paginationObj.pageNumber = 0;
    this.fecthList();
  }
}


