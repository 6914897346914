import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-session-timeout-dialog',
  templateUrl: './session-timeout-dialog.component.html',
  styleUrls: ['./session-timeout-dialog.component.scss']
})
export class SessionTimeoutDialogComponent implements OnInit {

  timeoutCounter: number;
  idleInterval: any;
  remainingSessionTime: string = "";
  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: any) {
  }

  ngOnInit() {
    if (this.idleInterval)
      clearInterval(this.idleInterval);
    this.timeoutCounter = this.dialogData.timeoutCounter;
    this.convertToTime(this.timeoutCounter);
    this.idleInterval = setInterval(() => {
      this.timeoutCounter -= 1;
      if (this.timeoutCounter >= 0)
        this.convertToTime(this.timeoutCounter);
      else
        this.remainingSessionTime = "0:0";
    }, 1000);


  }

  convertToTime(time) {
    // Hours, minutes and seconds
    let hrs = Math.floor(time / 3600);
    let mins = Math.floor((time % 3600) / 60);
    let secs = Math.floor(time % 60);

    // Output like "1:01" or "4:03:59" or "123:03:59"
    let ret = "";
    if (hrs > 0) {
      ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
    }
    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;

    if (hrs > 0)
      ret += " hour";
    else if (mins > 0)
      ret += " munites";
    else
      ret += " seconds";

    this.remainingSessionTime = ret;
  }



}
