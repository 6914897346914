import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-promt-dialog',
  templateUrl: './promt-dialog.component.html',
  styleUrls: ['./promt-dialog.component.scss']
})
export class PromtDialogComponent implements OnInit {

  public noBtnLabel: string = 'CONSTANTS.BUTTONS.NO';
  public yesBtnLabel: string = 'CONSTANTS.BUTTONS.YES';
  public title: string;
  public message: string;
  public inputValue: string;
  public placeholder: string;
  public commentRequired: boolean = true;
  public email: boolean = false;
  public isViewOnly: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: any,
              private dialogRef: MatDialogRef<PromtDialogComponent>) {
  }

  ngOnInit(): void {
    this.title = this.dialogData.title;
    this.message = this.dialogData.message;
    if (this.dialogData.placeholder) {
      this.placeholder = this.dialogData.placeholder;
    }
    if (this.dialogData.noBtnLabel) {
      this.noBtnLabel = this.dialogData.noBtnLabel;
    }
    if (this.dialogData.yesBtnLabel) {
      this.yesBtnLabel = this.dialogData.yesBtnLabel;
    }
    if (this.dialogData.inputValue) {
      this.inputValue = this.dialogData.inputValue;
    }
    if (this.dialogData.commentRequired !== undefined) {
      this.commentRequired = this.dialogData.commentRequired;
    }
    if (this.dialogData.isEmail) {
      this.email = this.dialogData.isEmail;
    }

    if(this.dialogData.isViewOnly){
      this.isViewOnly = true;
    }    
  }

  onClose(value) {
    const data = {
      commentRequired: true,
      comment: value
    };
    this.dialogRef.close(data);
  }
}
