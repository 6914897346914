import { ModuleWithProviders, NgModule } from '@angular/core';
import { NotificationBarRoutingModule } from './notification-bar-routing.module';
import { NotificationBarComponent } from './notification-bar/notification-bar.component';
import { SharedModule } from '../../../shared/shared.module';
import { NotificationBarService } from './notification-bar.service';

@NgModule({
  imports: [SharedModule, NotificationBarRoutingModule],
  declarations: [NotificationBarComponent],
  entryComponents: [NotificationBarComponent]
})
export class NotificationBarModule {
  static dynamicComponentsMap​ = {
    NotificationBarComponent
  };

  static forRoot (): ModuleWithProviders {
    return {
      ngModule: NotificationBarModule,
      providers: [NotificationBarService]
    };
  }
}
