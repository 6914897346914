import { NgModule } from '@angular/core';
import { CreateUserFormFieldComponent } from './create-user-form-field.component';
import { RegisterInfoDialogComponent } from './register-info-dialog/register-info-dialog.component';
import { SharedModule } from '../../../shared/shared.module';

@NgModule({
  imports: [SharedModule],
  declarations: [CreateUserFormFieldComponent, RegisterInfoDialogComponent],
  exports: [CreateUserFormFieldComponent],
  entryComponents: [RegisterInfoDialogComponent]
})
export class CreateUserFormFieldModule {
}
