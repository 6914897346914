import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { AppSettingService } from 'app/core/app-setting.service';
import { AppUtilService } from 'app/core/app-util.service';
import { LanguageService } from 'app/core/language/language.service';
import { FetchWidgetDataRequestPayloadProto } from 'app/feature-modules/dashboard/constants/dashboard-constant';
import { ACTION_ENUMS, GridColumnProto } from 'app/feature-modules/dashboard/dashboard-constant';
import { DashboardService } from 'app/feature-modules/dashboard/dashboard.service';
import { WebService } from 'web-service';
import { ViewDetailsDialogComponent } from '../component/view-entity-details-dialog/view-details-dialog.component';
import { MarkerClusterer } from '@googlemaps/markerclusterer';

@Component({
  selector: 'app-googlemaps',
  templateUrl: './googlemaps.component.html',
  styleUrls: ['./googlemaps.component.scss']
})
export class GooglemapsComponent implements OnInit {
  @Input('data') data: any;
  @Input('index') index: any;
  @Input('pairIndex') pairIndex: any;
  @Input('gridsterCompItem') gridsterCompItem: any;
  @Input() selectedWidgetType
  public mapData: any[] = [];
  public isLoading: boolean = false;
  public visible: boolean = false;
  public showNoDataMessage: boolean = false;
  public filterConfig: any;
  public paramValue: Object = {};
  public requestPayload: FetchWidgetDataRequestPayloadProto = new FetchWidgetDataRequestPayloadProto();
  public resetRequestPayload: FetchWidgetDataRequestPayloadProto = new FetchWidgetDataRequestPayloadProto();
  public latitude: number;
  public longitude: number;
  public dynMarkers: any[] = [];
  public markerPositions: any[] = [];
  public appLocation: any = ['34.1425', '-118.2551']; // Glandle CA
  public markerClusterer: any;
  public value: any;
  public columnList: GridColumnProto[] = [];

  constructor(public webService: WebService, public dashboardService: DashboardService,
    private router: Router, private activatedRoute: ActivatedRoute,
    private appSettingService: AppSettingService, private languageService: LanguageService, private utilService: AppUtilService, private dialog: MatDialog, public datePipe: DatePipe) {
  }


  ngOnInit(): void {
    const location = this.appSettingService.getProperty('appLocation');
  console.log(location)
    if (location) {
      this.appLocation = location;
    }
    this.requestPayload.language = this.languageService.getCurrentLanguage();
    this.requestPayload.widgetType = this.data.widgetType;
    this.requestPayload.timeInterval = this.data.timeInterval;
    this.requestPayload.startDate = this.data.startDate;
    this.requestPayload.endDate = this.data.endDate;
    this.requestPayload.resolution = this.data.resolution;
    this.requestPayload.filterParam = this.data.filterParam;
    this.requestPayload.column = this.data.columns;
    this.resetRequestPayload = this.requestPayload;
    this.getMapData();
    // this.getFilterConfig();
  }

  getMapData(): void {
    this.isLoading = true;
    this.webService.postFromUrl(this.data.widgetDataUrl, this.requestPayload)
      .subscribe((response) => {
        this.isLoading = false;
        this.visible = false;
        this.showNoDataMessage = false;
        if (response.status.code === 200) {
          this.columnList = response.columnList;
          this.dynMarkers = [];
          this.markerPositions = [];
          this.markerPositions = response.data;
          const centerLatLong = this.appLocation;
          const center = new google.maps.LatLng(Number.parseFloat(centerLatLong[0]), Number.parseFloat(centerLatLong[1]));
          const map = new google.maps.Map(document.getElementById('googlemap' + this.index + this.pairIndex), {
            zoom: 12,
            center: center,
            scrollwheel: true
          });
          console.log(map)
          for (let i = 0; i < this.markerPositions.length; i++) {
            if (this.markerPositions[i].location) {
            const latLng = new google.maps.LatLng(this.markerPositions[i].location[0], this.markerPositions[i].location[1]);
            const mark = new google.maps.Marker({ position: latLng, map: map });
            this.dynMarkers.push(mark);

            (function (marker: any, data: any, _that: any) {
              google.maps.event.addListener(marker, 'click', function (e) {

                const ul = document.createElement('ul');
                ul.className = 'attributes-click';
                const x = document.createElement('LI');
               // x.setAttribute('value', data);
                let addr;
                Object.entries(data).forEach(([key, value]) => {
                  console.log(key, value);
                 addr = document.createElement('p');
                 if(key !== 'location' && key!== 'appId_hidden'){
                   addr.innerHTML = key + " : " + value;
                   x.appendChild(addr);
                   ul.appendChild(x);
                 }
                });
                const infowindow = new google.maps.InfoWindow({
                  content: ul
                });
                infowindow.open(map, marker);
              });
            })(mark, this.markerPositions[i], this);
          }
          }

          this.markerClusterer = new MarkerClusterer({ map, markers: this.dynMarkers });
          this.visible = true;
        } else {
          this.showNoDataMessage = true;
        }
      }, () => {
        this.isLoading = false;
      });
  }

  refreshData(event: any): void {
    this.requestPayload.widgetType = event.widgetType;
    this.requestPayload.timeInterval = event.timeInterval;
    this.requestPayload.startDate = event.startDate;
    this.requestPayload.endDate = event.endDate;
    this.requestPayload.resolution = event.resolution;
    this.requestPayload.filterParam = event.filterParam;
    this.requestPayload.column = event.columns;
    this.resetRequestPayload = this.requestPayload;
    this.getMapData();
  }

  redirectTo(event: any, columnData, detailObj): void {
    switch (columnData.eventDetails.action) {
      case ACTION_ENUMS.internalState:
        this.router.navigate([columnData.eventDetails.url, detailObj[columnData.eventDetails.params.paramKey]], { relativeTo: this.activatedRoute });
        break;

      case ACTION_ENUMS.popUp:
        const data = [];
        columnData.eventDetails.params.keys.forEach((item) => {
          data.push({ key: item.paramKey, data: detailObj[item.paramValue] });
        });
        const dialogData = {
          moduleData:
          {
            modulePath: columnData.eventDetails.params.modulePath,
            componentName: columnData.eventDetails.params.componentName
          },
          parameters: data
        };

        this.dialog.open(ViewDetailsDialogComponent, this.utilService.getDialogDataFullScreen(dialogData));
        break;

    }
  }
}
