export class DashboardConstant {
  public static API: any = {
    'getDashboardList': '/rest/uiengine/app/v1/get_dashboard_list',
    'getUserApplication': '/rest/uiengine/app/v1/getuserapplication',
    'getDashboardWidgets': '/rest/uiengine/app/v1/get_dashboard_widgets',
    'getDetailsForWidget': '/rest/uiengine/app/v1/getdetailsforwidget',
    'saveWidgets': '/rest/uiengine/app/v1/save_widgets',
    'updateWidgets': '/rest/uiengine/app/v1/update_widget',
    'saveWidgetTemplate': '/rest/uiengine/app/v1/save_widgettemplate',
    'deleteDashboardWidgets': '/rest/uiengine/app/v1/delete_dashboard_widgets',
    'updateWidgetPosition': '/rest/uiengine/app/v1/update_widget_position',
    'saveDashboard': '/rest/uiengine/app/v1/save_dashboard',
    'deleteDashboard': '/rest/uiengine/app/v1/delete_dashboard/',
    'updateDashboardSequence': '/rest/uiengine/app/v1/update_sequence',
    'getModuleWidgetMappingCriteria': '/rest/uiengine/app/v1/getmodulewidgetmappingcriteria',
    'exportAppGrid': '/rest/nofa/widget/v1/exportNofaGridData',
    'exportRoundGrid': '/rest/nofa/round/widget/v1/exportNofaRoundGridData',
    'UserPersonalization': {
      'WidgetLibrary': {
        'get': '/rest/uiengine/app/v1/get_library_widgets',
      },
      'DashboardTemplate': {
        'get': '/rest/uiengine/app/v1/get_widget_template',
      }
    },
    'DataSet': {
      'CreateDataSet': '/rest/reportbi/create_dataset',
      'RetrieveDataSet': '/rest/reportbi/retrive_dataset',
      'GetReportsList': '/rest/reportbi/user_dataset',
      'GetDataSetsList': '/rest/reportbi/user_dataset',
      'GetChartData': '/rest/reportbi/chart_data',
      'generateCustomReport': '/rest/reportbi/generateCustomReport',
      'getFieldValues': '/rest/reportbi/getFieldValues',
      'delete': '/rest/reportbi/delete_dataset',
    }
  };
}

/**
 * Created by ganeshram on 28/9/17.
 */
export const ColorListForWidget = [
  '#FA3D3C',
  '#EE1561',
  '#9B32AA',
  '#6343B1',
  '#3657AF',
  '#0099EE',
  '#00ABEF',
  '#00BDD1',
  '#009588',
  '#49AD5A',
  '#8AC059',
  '#FFBC3A',
  '#FF922E',
  '#FF512F',
  '#7A554B',
  '#5D7D89',
  '#9D9D9D'
];

export class UploadFile {
  name: string;
  documentType: string;
  documentTag: string;
  caseId: string;
}

export const APP_SUPPORT_ENUMS = {'APP_WIDGET': 'APPWIDGET', 'OPEN_WIDGET': 'OPENWIDGET'};
export const APPLICATION_SUPPORT_ENUMS = {'appWidget': 'APPWIDGET', 'advWidget': 'OPENWIDGET'};
export const APP_WIDGET_ENUMS = {'basic': 'BASIC', 'advance': 'ADVANCE'};


export const WIDGET_TYPES = {
  'grid': 'Grid',
  'line': 'Line',
  'pie': 'Pie',
  'number': 'Number',
  'map': 'Map',
  'bar': 'Bar'
};

export const BASE_DATE_UNIT = {
  'months': 'Monthly',
  'years': 'Yearly',
  'weeks': 'Weekly',
  'days': 'Daily',
};

export class AxisShowHideProto {
  xAxis: boolean = true;
  yAxis: boolean = true;
  toolTip: boolean = true;
  legend: boolean = true;
  labels: boolean = true;
  showYAxiskeyValue: boolean = true;
  showXAxiskeyvalue: boolean = true;
}

export class GridColumnProto {
  columnName: string;
  renameColumn?: string;
  sequence?: number;
  eventDetails?: EventProto;
}


export class EventProto {
  action: string;
  url: string;
  params: Object;
  componentName: string;
}

export class GridColumnProtoType {
  columnName: string | undefined;
  renameColumn?: string;
  displayName?:string;
  sequence: number | undefined;
  dataType: string | undefined;
  avoidDisplay?: boolean;
  textColor?: boolean;
  smallDevice?: boolean;
  minDevice?: boolean;
  largeDevice?: boolean;
  eventDetails?: EventProtoType;
}




export class NewSiteAddress {
  houseNumber: string;
  streetName: string;
  city: string;
  state: string;
  zip: number;
  houseFractionNumber: string;
  preDirCd: string;
  id: string;
  apnNumber: string;
  streetTypeCd: string;
}

export class EventProtoType {
  action: string | undefined;
  url: string | undefined;
  params: object | undefined;
  componentName: string | undefined;
}

export class MatMenuListItem {
  menuLinkText: string="";
  menuIcon: string="";
  isDisabled: boolean=false;
}


export const ACTION_ENUMS = {
  'internalState': 'INTERNAL_STATE',
  'redirect': 'REDIRECT',
  'popUp': 'POPUP',

};

export class SequenceUpdateRequestProto {
  sequences: {
    appId: string;
    dashboardId: string;
    sequence: number
  }[];
}
