import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ToasterService } from '../../../../../../core/toaster/toaster.service';
import { LoaderService } from '../../../../../../core/loader/loader.service';
import { WebService } from 'web-service';
import { CrmContactConstants, EmailTemplate, SendMail, TemplateListResponse } from '../../crm-contact-constants';
import { FroalaEditorSettingService } from '../../../../../../core/froala-editor/froala-editor-setting.service';


@Component({
  selector: 'send-mail-dialog',
  templateUrl: './sen-mail.component.html',
  styleUrls: ['./send-mail.component.scss'],
  providers: [FroalaEditorSettingService]
})
export class SendMailDialogComponent implements OnInit {

  public templateList: any[] = [];
  public sendMailObj = new SendMail();
  public froalaOptions: any;
  public emailTemplate = new EmailTemplate();
  public emailContent: string;

  constructor(public dialogRef: MatDialogRef<SendMailDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public dialogData: any,
              public webService: WebService, private toaster: ToasterService,
              private loaderService: LoaderService,
              private froalaEditorSettingService: FroalaEditorSettingService) {

  }

  ngOnInit(): void {
    this.froalaOptions = this.froalaEditorSettingService.getFroalaOptionsForEmail();
    this.froalaEditorSettingService.addDropDown();
    if (this.dialogData.crmList.length > 0) {
      this.sendMailObj.crmList = this.dialogData.crmList;
      if (this.dialogData.listId) {  /*for mailing list id*/
        this.sendMailObj.listId = this.dialogData.listId;
      }
    } else if (this.dialogData.view) {
      this.sendMailObj.listId = this.dialogData.view;
    }
    this.getTemplateList();
  }

  getTemplateList() {
    this.loaderService.show();
    this.webService.get(CrmContactConstants.API.contact.getTemplates)
      .subscribe(
        (response: TemplateListResponse) => {
          this.loaderService.hide();
          if (response.status.code === 200) {
            this.templateList = response.templateList;
            if (this.templateList.length > 0) {
              this.emailTemplate.templateName = this.templateList[0].templateName;
              this.getTemplateByID(this.emailTemplate.templateName);
            }
          }
        }, () => {
          this.loaderService.hide();
        });
  }

  getTemplateByID(templateName) {
    this.loaderService.show();
    this.webService.get(CrmContactConstants.API.contact.getTemplateByName + templateName)
      .subscribe(
        (response: any) => {
          this.loaderService.hide();
          if (response.status.code === 200) {
            this.emailTemplate = response.emailTemplate;
            this.emailContent = atob(response.emailTemplate.content);
          }
        }, () => {
          this.loaderService.hide();
        });
  }

  sendMail() {
    this.sendMailObj.emailBody = btoa(this.emailContent);
    this.sendMailObj.emailSubject = this.emailTemplate.subject;
    // this.sendMailObj.emailTemplateName = this.emailTemplate.templateName;
    this.webService.post(CrmContactConstants.API.contact.sendMail, this.sendMailObj)
      .subscribe(
        (response: any) => {
          this.loaderService.hide();
          if (response.status.code === 200) {
            this.toaster.showToast(response.status.message, 'success');
            this.dialogRef.close(true);
          }
        }, () => {
          this.loaderService.hide();
        });
  }

}
