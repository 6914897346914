import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MainMenuItem } from '../../../layout-constants';

@Component({
  selector: 'app-vertical-icon-menu-tree',
  templateUrl: './vertical-icon-menu-tree.component.html',
  styleUrls: ['./vertical-icon-menu-tree.component.scss']
})
export class VerticalIconMenuTreeComponent {
  @Input('menuItem') public menuItem: MainMenuItem;
  @Input('isFunky') public isFunky: boolean = false;
  @Output('openMenu') public openMenu: EventEmitter<MainMenuItem> = new EventEmitter<MainMenuItem>();
}
