import { Component, Inject, OnInit } from '@angular/core';
import { AddUserMailingListProto, CrmContactConstants, GetDataReqProto, KendoPaginationProto, MailingUserListProto, SearchContent, ViewListResponse } from '../../../crm-contact-constants';
import { WebService } from 'web-service';
import { UserService } from '../../../../../../../core/user.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { LoaderService } from '../../../../../../../core/loader/loader.service';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import * as _ from 'lodash';
import { ToasterService } from 'app/core/toaster/toaster.service';
import { SortDescriptor, State } from '@progress/kendo-data-query';
import { CreateUserConstants } from 'app/feature-modules/admin/crm/create-user/create-user-constants';
@Component({
  selector: 'app-add-user-mailing-list-dialog',
  templateUrl: './add-user-mailing-list-dialog.component.html',
  styleUrls: ['./add-user-mailing-list-dialog.component.scss']
})
export class AddUserMailingListDialogComponent implements OnInit {
  public addUserMailingListProto: AddUserMailingListProto = new AddUserMailingListProto();
  public userData: GridDataResult = { 'data': [], 'total': 0 };
  public selectedUserData = [];
  public crmIdListRequest = {crmId :[]};

  public state: State = {
    skip: 0,
    take: 10,
    filter: { filters: [], logic: 'and' }
  };

  public multipleUsersMailingList: any[] = [];
  public userRoleList: any[] = [];
  public selecteUser: any;
  public userType: string = "existing";
  public userRoleId: string;

  constructor(public webService: WebService, private userService: UserService,
    private loaderService: LoaderService,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    public dialogRef: MatDialogRef<AddUserMailingListDialogComponent>,
    public toasterService: ToasterService) {
  }

  ngOnInit() {
    this.changeUserType("existing");
  }

  adduser(exixistingUser: boolean) {
    this.loaderService.show();
    if (!exixistingUser) {
      this.addUserMailingListProto.mailingListId = this.dialogData;
      this.webService.post(CrmContactConstants.API.contact.addMailingUser, this.addUserMailingListProto)
      .subscribe((response) => {
        this.loaderService.hide();
        if (response) {
          if (response.status.code == 230) {
            this.toasterService.showToast('User is already added.', 'error');
          }
          else if (response.status.code == 200) {
            this.toasterService.showToast('User is added succesSfuly.', 'success');
            this.dialogRef.close(true);
          }
        }
      }, (error) => {
        this.loaderService.hide();
      })
    } else {
      this.webService.post(CrmContactConstants.API.contact.addMultipleMailingUser, this.addUserMailingListProto)
      .subscribe((response) => {
        this.loaderService.hide();
        if (response) {
          if (response.status.code == 200) {
            this.toasterService.showToast('User is added succesSfuly.', 'success');
            this.dialogRef.close(true);
          }
        }
      }, (error) => {
        this.loaderService.hide();
      })
    }
  }

  changeUserType(selecteValue) {
    this.addUserMailingListProto = new AddUserMailingListProto()
    if (selecteValue) {
      switch (selecteValue.toLowerCase()) {
        case 'new':
          this.selecteUser = null;
          break;
        case 'existing':
          this.fetchUserList();
          this.getUserRoleList();
          break;
        default:
          break;
      }
    }
  }

  fetchUserList(request?: {}): void {
    this.loaderService.show();

    if (!request)
      request = {};

    this.webService.post('/rest/crm/view/v1/query', request)
      .subscribe((response) => {
        this.loaderService.hide();
        if (response.status.code === 200) {
          this.userData.data = _.cloneDeep(response.userList);
          this.userData.data.forEach(val => {
            for (var key of Object.keys(val)) {
              if(key === 'userRole') {
                val[key] = val[key].toString();
              }
            }
          })
          this.userData.total = response.count;
        }
      }, () => this.loaderService.hide());
  }

  addExistingUser() {
    if (this.multipleUsersMailingList) {
      this.addUserMailingListProto.mailingListId = this.dialogData;
      let data = [];
      this.multipleUsersMailingList.forEach(ele => {
        const userList = new MailingUserListProto();
        userList.crmId = ele.crmId;
        userList.emailAddress = ele.emailAddress;
        userList.firstName = ele.firstName;
        userList.lastName = ele.lastName;
        data.push(userList);
      })
      this.addUserMailingListProto.multipleUsersMailingList = data;
      this.adduser(true);
    } else {
      this.toasterService.showToast('Please select user to add in mailing list.', 'error');
    }
  }

  selectionChange(event: any): void {
    // if (event.selectedRows && event.selectedRows.length > 0) {
    //   this.selecteUser = event.selectedRows[0].dataItem;
    // }
    // else {
    //   this.selecteUser = null;
    // }
  }

  selectedKeysChange(rows: number[]) {
    this.getSelectedUsersDetails(rows);
  }

  getSelectedUsersDetails(rows) {
    this.crmIdListRequest.crmId = rows;
    this.webService.post(CrmContactConstants.API.contact.getUserprofileByCrmIdList, this.crmIdListRequest)
      .subscribe((response) => {
        if (response.status.code == 200) {
          this.selectedUserData = response.userList
          this.getUserDetails(rows)
        }
      })
  }

  getUserRoleList () {
    this.webService.get(CreateUserConstants.API.getRoleList)
      .subscribe((response) => {
        if (response.status.code === 200) {
          this.userRoleList = response.userRoles;
        }
      });
  }

  getUserDetails(rows) {
    this.multipleUsersMailingList = [];
    rows.forEach(crmId => {
      this.multipleUsersMailingList.push(this.selectedUserData.find(x => x.crmId === crmId));
    });
  }

  dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    let req = { "pageNumber": 1, "pageSize":10, "fields": [], "sortFields": [] }
    req.pageNumber = (this.state.skip / this.state.take) + 1;
    req.pageSize = this.state.take;

    if (state.filter && state.filter.filters && state.filter.filters.length > 0) {
      state.filter.filters.forEach(element => {
        req.fields.push({"key": element["field"], "value": element["value"], "operation": "CONTAINS" });
      })
    } else if (this.userRoleId != '' && this.userRoleId != undefined) {
      req.pageNumber = (this.state.skip / this.state.take) + 1;
      req.pageSize = this.state.take;
      req.fields.push({"key": "userRole", "value": this.userRoleId.toString(), "operation": "CONTAINS" });
    }

    if (state.sort && state.sort.length > 0) {
      state.sort.forEach(element => {
        req.sortFields.push({"key" : element["field"] , "sortOrder" :  (element["dir"] ? element["dir"].toUpperCase() : 'ASC')});
      })
    }

    this.fetchUserList(req);
  }

  resetListFilter(){
    this.userRoleId = '';
    this.userData.data = [];
    this.userData.total = 0;
    this.state =  {
      skip: 0,
      take: 10,
      filter: { filters: [], logic: 'and' }
    }
    this.fetchUserList();
  }

  dropDownFilterChange() {
    if(this.userRoleId) {
      let req = { "pageNumber": 1, "pageSize":10, "fields": [], "sortFields": [] }
      req.pageNumber = (this.state.skip / this.state.take) + 1;
      req.pageSize = this.state.take;
      req.fields.push({"key": "userRole", "value": this.userRoleId.toString(), "operation": "EQ" });
      this.fetchUserList(req);
    }
  }

}
