/**
 * Created by Isha Dhimar (isha.dhimar@kahunasystems.com) on 9/8/18.
 **/

import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { CustomMaterialModule } from './custom-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedComponentsModule } from './shared-components.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgPipesModule } from 'ngx-pipes';
import { KSCommonComponentsModule } from 'kscommon-components';
import { CommonCookieService } from 'kscookies';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { DynamicDialogComponent } from './component/dynamic-dialog/dynamic-dialog.component';
import {NgxCurrencyModule} from 'ngx-currency';
import {NgxPrintModule} from 'ngx-print';
// import { HttpsInterceptor } from './interceptors/https.interceptor';
// import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LogCampService } from './logcamp/logcamp.service';

export let options: Partial<IConfig> | (() => Partial<IConfig>) = {};

@NgModule({
  imports: [CommonModule, TranslateModule, CustomMaterialModule, FormsModule, ReactiveFormsModule,
    SharedComponentsModule, FlexLayoutModule, NgPipesModule, KSCommonComponentsModule, NgxCurrencyModule,
    NgxMaskModule.forRoot(options)],
  declarations: [DynamicDialogComponent],
  exports: [CommonModule, TranslateModule, CustomMaterialModule, FormsModule, ReactiveFormsModule, NgxCurrencyModule,
    SharedComponentsModule, FlexLayoutModule, NgPipesModule, KSCommonComponentsModule,
    NgxMaskModule, DynamicDialogComponent, NgxPrintModule],
  providers: [CommonCookieService, LogCampService, 
    // { provide: HTTP_INTERCEPTORS, useClass: HttpsInterceptor, multi: true }
  ],
  entryComponents: [DynamicDialogComponent]
})

export class SharedModule {

}
