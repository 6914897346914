/**
 * Created by ganeshram on 28/9/17.
 */
import { DATA_TYPES } from '../app-widget-dialog/app-widget-dialog.constants';
import { SeriesType, LineStyle } from '@progress/kendo-angular-charts';


export class LineSeries {
  seriesName: string = '';
  filterParam: Object = {};
}

export class AddWidgetProto {
  isLibrary: boolean = false;
  applicationId: string;
  dashboardId: string;
  moduleId: string;
  widgetName: string;
  widgetType: string;
  resolution: string = 'DayWise';
  columns: any[];
  pieCriteria: string;
  filterParam: any[] = [];
  timeInterval: string = 'Today';
  isFullWidth: boolean = false;
}


export class WidgetPositionUpdateProto {
  id: string;
  rowNum: number;
  colNum: number;
  widgetName: string;
  color: string;
  widgetPosition: WidgetPositionGridsterProto;
}

export class WidgetPositionGridsterProto {
  width: number;
  height: number;
  widthSmall: number;
  heightSmall: number;
  widthMedium: number;
  heightMedium: number;
  widthLarge: number;
  heightLarge: number;
  widthExtraLarge: number;
  heightExtraLarge: number;
  xSmall: number;
  ySmall: number;
  xMedium: number;
  yMedium: number;
  xLarge: number;
  yLarge: number;
  xExtraLarge: number;
  yExtraLarge: number;
}

export const WidgetPositionGridsterArray = [
  'width',
  'height',
  'widthSmall',
  'heightSmall',
  'widthMedium',
  'heightMedium',
  'widthLarge',
  'heightLarge',
  'widthExtraLarge',
  'heightExtraLarge',
  'xSmall',
  'ySmall',
  'xMedium',
  'yMedium',
  'xLarge',
  'yLarge',
  'xExtraLarge',
  'yExtraLarge',
];

export class GridWidgetRequestPayloadProto {
  column: any;
  widgetType: string;
  timeInterval: string;
  pageNumber: number = 0;
  pageSize: number = 10;
  language: string;
  startDate: string;
  endDate: string;
  filterParam: GridFilterProto[] = [];
  gridFilter: GridFilterProto[] = [];
  resolution: string;
  widgetDataUrl: string;
  sortField: string;
  sortOrder: string;
}

export class GridFilterProto {
  fieldName: string;
  value: string;
  startDate: any;
  endDate: any;
}

export class FetchWidgetDataRequestPayloadProto {
  column: string;
  widgetType: string;
  timeInterval: string;
  language: string;
  startDate: string;
  endDate: string;
  filterParam: any;
  lineFilterParam: Object;
  pieCriteria: string;
  resolution: string;
  pivot: string;
  widgetDataUrl: string;
  aggType: string;
  pageNumber: number = 0;
  pageSize: number = 10;
}

export class GetDashboardWidgetsProto {
  dashboardId: string;
}

export class DeleteDashboardWidgetProto {
  dashboardId: string;
  widgetId: string;
}


export class GetDashboardListDataProto {
  appId: string;
}

export class AddDashboardProto {
  id: string;
  dashboardName: string;
  appId: string;
  sequence: number;
}

export class GetWidgetLibraryProto {
  applicationId: string;
  pageNumber: number = -1;
  pageSize: number = -1;
}

export class GetModuleWidgetMappingCriteriaProto {
  moduleId: string;
  widgetTypeId: string;
  applicationId: string;
}


export class UpdateWidgetNameArrayObject {
  show: boolean = false;
  widgetName: string;
}

export class ChartWidgetConfiguaration {
  legend = {
    position: 'bottom',
    labels: {
      rotation: 'auto'
    },
    item: {}
  };
  categoryAxis = {
    type: 'string',
    categories: [],
    majorGridLines: {
      visible: false
    },
    line: {
      visible: false
    },
    labels: {
      rotation: 'auto'
    }
  };
  tooltip = {
    visible: true
  };
}

export class ChartWidgetConfiguration {
  title = { text: '' };
  chartDefault = {
    renderAs: 'canvas',
    transitions: false,
    zoomable: false
  };
  seriesDefaults = {
    gap: 0.25
  };
  chartRatio = {
    height: 150
  };
  series = {
    visible: true,
    name: '',
    categoryField: 'name',
    field: 'data',
    data: []
  };
  categoryAxisItem = {
    majorGridLines: { visible: true },
    majorTicks: { visible: true },
    labels: { rotation: 'auto', visible: true }
  };
  valueAxisItem = {
    majorTicks: { visible: true }
  };
  chartLegend = {
    position: 'right',
    orientation: 'vertical'
  };
  labels = {
    visible: true,
    padding: 3,
    format: 'n'
  };
  tooltip = { visible: true };
  categoryAxis = {
    type: null,
    baseUnit: null
  };
}

export class PieChartWidgetConfiguration {
  title = { text: '' };
  legend = {
    visible: true,
    position: 'right',
    orientation: 'vertical'
  };
  seriesDefaults = {
    type: '',
    labels: {
      visible: true,
      background: 'transparent',
      template: '#= value#',
      rotation: 'auto'
    }
  };
  series = [{ data: '', name: '' }, { data: '', name: '' }];
  tooltip = {
    visible: true,
    template: '#= series.name #: #= value #'
  };
  categoryAxis = {
    baseUnit: '',
    categories: '',
    majorGridLines: {
      visible: false
    },
    labels: {
      rotation: 'auto'
    }
  };
}

export class WidgetChartConfiguration {

  title = {
    text: 'Pie Chart'
  };
  crosshair = { visible: false };
  labels = {
    rotation: 0,
    visible: true,
    padding: 3,
    format: 'n',
    background:'#ffffff'
  };
  line = {
    color: 'black',
    width: 1,
  };
  chartType: SeriesType = "column";
  chartStyle: LineStyle = "normal";
  chartDefault = {
    renderAs: 'canvas',
    transitions: false,
    zoomable: true,
    autofit: true
  };
  seriesDefaults = {
    gap: 0.25,
  };
  chartRatio = {
    height: 150,
  };
  series = {
    visible: true,
    name: '',
    categoryField: 'name',
    field: 'data',
    data: [{}]
  };

  "majorGridLines" = {
    color: 'lightgray',
    visible: true
  };

  "minorGridLines" = {
    visible: false
  };

  "majorTicks" = {
    color: 'lightgray',
    visible: true
  };

  "minorTicks" = {
    visible: false
  };

  chartLegend = {
    visible: true,
    position: 'right',
    orientation: 'vertical',
  };
  tooltip = {
    visible: true,
  };
  categoryAxis = {
    type: null,
    baseUnit: null,
  }
}



export const OPERATION_LIST = [
  {
    key: 'is',
    operation: 'Equal',
    dataTypes: [DATA_TYPES.NUMBER, DATA_TYPES.DATE, DATA_TYPES.TEXT, DATA_TYPES.LIST],
    defaultValForDataTypes: [DATA_TYPES.NUMBER, DATA_TYPES.TEXT, DATA_TYPES.DATE]
  },{
    key: 'lt',
    operation: 'Is Less Than',
    dataTypes: [DATA_TYPES.NUMBER, DATA_TYPES.DATE]
  },
  {
    key: 'lte',
    operation: 'Less Than or Equal To',
    dataTypes: [DATA_TYPES.NUMBER, DATA_TYPES.DATE]
  }, {
    key: 'gt',
    operation: 'Is Greater Than',
    dataTypes: [DATA_TYPES.NUMBER],
    defaultValForDataTypes: [DATA_TYPES.DATE]
  },
  {
    key: 'gte',
    operation: 'Greater Than or Equal To',
    dataTypes: [DATA_TYPES.NUMBER, DATA_TYPES.DATE]
  },
  {
    key: 'in',
    operation: 'In',
    dataTypes: [DATA_TYPES.LIST],
    defaultValForDataTypes: [DATA_TYPES.LIST]
  }, {
    key: 'notin',
    operation: 'Not In',
    dataTypes: [DATA_TYPES.LIST]
  },
  {
     key: 'pastnday',
     operation: 'Past N Day',
     dataTypes: [DATA_TYPES.DATE]
   }, {
     key: 'pastnweek',
     operation: 'Past N Week',
     dataTypes: [DATA_TYPES.DATE]
   }, {
     key: 'pastnmonth',
     operation: 'Past N Month',
     dataTypes: [DATA_TYPES.DATE]
   }, {
     key: 'pastnyear',
     operation: 'Past N Year',
     dataTypes: [DATA_TYPES.DATE]
   },
    {
     key: 'range',
     operation: 'Range',
     dataTypes: [DATA_TYPES.DATE]
   },
  {
    key: 'contains',
    operation: 'Contains',
    dataTypes: [DATA_TYPES.TEXT],
    defaultValForDataTypes: [DATA_TYPES.TEXT]
  }, {
    key: 'isblank',
    operation: 'Is Empty',
    dataTypes: [DATA_TYPES.TEXT]
  }, {
    key: 'isnotblank',
    operation: 'Is Not Empty',
    dataTypes: [DATA_TYPES.TEXT]
  }
];


export const OPERATION_LIST_WIDGET = {
  "number": {
    dataType: DATA_TYPES.NUMBER,
    operations: [
    {
      key: 'lte',
      label: 'Less Than or Equal To',
    },
    {
      key: 'gte',
      label: 'Greater Than or Equal To',
    }
    ],
    defaultValue:[{
      key: "is",
      label: "Equal"
    }]
  },
  "list": {
    dataType: DATA_TYPES.LIST,
    operations: [{
      key: "is",
      label: "Equal"
    },
    {
      key: 'notin',
      label: 'Not In',
    }
    ],
    defaultValue:[{
      key: 'in',
      label: 'In',
    }]
  },
  "text": {
    dataType: DATA_TYPES.TEXT,
    operations: [
    {
      key: 'contains',
      label: 'Contains',

    },
    {
      key: 'isblank',
      label: 'Is Empty',
    },
    {
      key: 'isnotblank',
      label: 'Is Not Empty',
    }
    ],
    defaultValue:[{
      key: "is",
      label: "Equal"
    }]
  },
  "date": {
    dataType: DATA_TYPES.DATE,
    operations: [
    {
      key: 'lte',
      label: 'Less Than or Equal To',

    },
    {
      key: 'gte',
      label: 'Greater Than or Equal To',
    },
    {
      key: 'isnotblank',
      label: 'Is Not Empty',
    },
    {
      key: 'pastnday',
      label: 'Past N Day',

    },
    {
      key: 'pastnweek',
      label: 'Past N Week',

    },
    {
      key: 'pastnmonth',
      label: 'Past N Month',

    },
    {
      key: 'pastnyear',
      label: 'Past N Year',

    }
    ],
    defaultValue:[{
      key: "is",
      label: "Equal"
    }]
  }
}

export const DATE_FORMAT_LIST = [
  'MM/dd/yyyy',
  'yyyy-MM-dd',
  'yyyy/MM/dd',
  'MM-dd-yyyy',
  'dd-MM-yyyy',
  'dd/M/yyyy',
  'dd/MM/yyyy',
  'yyyy-MM-dd HH:mm:ss',
  'EEEEE MMMMM yyyy HH:mm:ss.SSSZ',
  'dd/MM/yy',
  'dd-MM-yy'
];

export class ParamProto {
  fieldName: string;
  value: any;
  operation: string;
  show: boolean = true
}

export class GridWidgetRequestPdfProto {
  column: any;
  widgetType: string;
  pageNumber: number = -1;
  pageSize: number = -1;
  language: string;
  filterParam: GridFilterProto[] = [];
  gridFilter: GridFilterProto[] = [];
  widgetDataUrl: string;
}
