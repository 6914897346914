import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoaderService } from './loader.service';
import { Subscription } from 'rxjs';
import * as $ from 'jquery';

@Component({
  selector: 'inner-loader',
  template: `
    <div *ngIf="showLoader" class="loader-content inner-loader">
      <div class="loader-container animation-4">
        <div class="shape shape1"></div>
        <div class="shape shape2"></div>
        <div class="shape shape3"></div>
        <div class="shape shape4"></div>
      </div>
    </div>
  `
})
export class LoaderComponent implements OnInit, OnDestroy {

  loaderHtml = `<div id="dialog-inner-loader" class="loader-content inner-loader">
      <div class="loader-container animation-4">
        <div class="shape shape1"></div>
        <div class="shape shape2"></div>
        <div class="shape shape3"></div>
        <div class="shape shape4"></div>
      </div>
    </div>`;
  loaderSubscription: Subscription;
  showLoader: boolean = false;

  constructor(private loaderService: LoaderService) {
  }

  ngOnInit() {
    this.loaderSubscription = this.loaderService.loader.subscribe((value: boolean) => {
      if (value) {
        if ($('mat-dialog-container[role=\'dialog\']')[0]) {
          const dialogElement = $('mat-dialog-container[role=\'dialog\']')[$('mat-dialog-container[role=\'dialog\']').length - 1];
          dialogElement.insertAdjacentHTML('beforeend', this.loaderHtml);
        } else {
          this.showLoader = value;
        }
      } else {
        if ($('mat-dialog-container[role=\'dialog\']')[0]) {
          const dialogElement = $('mat-dialog-container[role=\'dialog\']')[$('mat-dialog-container[role=\'dialog\']').length - 1];
          const innerLoader = document.getElementById('dialog-inner-loader');
          if (innerLoader) {
            dialogElement.removeChild(innerLoader);
          }
        } else {
          this.showLoader = value;
        }
      }

    });
  }

  ngOnDestroy(): void {
    if (this.loaderSubscription) {
      this.loaderSubscription.unsubscribe();
    }
  }

}
