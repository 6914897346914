import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthenticationConstants, ResetPasswordObj, StatusWrapper } from '../authentication-constants';
import { LoaderService } from '../../../core/loader/loader.service';
import { WebService } from 'web-service';
import { ToasterService } from '../../../core/toaster/toaster.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { PasswordErrorStates } from '../../../constants/app-constants';
import { AppUtilService } from '../../../core/app-util.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  @ViewChild('resetPasswordForm') public resetPasswordForm: NgForm;

  public isTokenValid: boolean = false;
  public resetSuccess: boolean = false;
  public validForm: boolean = false;
  public pwdNotMatch: boolean = false;
  public cfm_password: string = '';
  public passwordError: PasswordErrorStates = new PasswordErrorStates();

  public resetPasswordObj: ResetPasswordObj = new ResetPasswordObj();
public newPwd: boolean;
public cfmPwd: boolean;

  constructor(public loader: LoaderService,
              public webService: WebService,
              public toasterService: ToasterService,
              public router: Router,
              public activatedRoute: ActivatedRoute,
              private appUtilService: AppUtilService) {
  }

  ngOnInit(): void {
    this.resetPasswordObj.token = this.activatedRoute.snapshot.queryParams.token;
    this.isTokenValid = true;
    this.validateToken();
  }

  validateToken = (): void => {
    this.loader.show();
    delete this.resetPasswordObj.newPassword;
    this.webService.post(AuthenticationConstants.API.validateToken, this.resetPasswordObj)
      .subscribe((response: StatusWrapper) => {

        if (response.status.code === 200) {
          this.isTokenValid = true;
          this.loader.hide();
        } else {
          this.loader.hide();
          if (!AuthenticationConstants.IS_EXTERNAL_IDM) {
            // this.router.navigate(['homepage']);
            this.isTokenValid = false;
          }
          this.toasterService.showToast(response.status.message, 'error');
        }
      }, () => {
        this.loader.hide();
      });
  };


  validatePassword = (password: any): void => {
    if (this.cfm_password) {
      this.checkCfrmPassword();
    }
    if (this.resetPasswordObj.newPassword === this.cfm_password) {
      this.validForm = false;
    } else {
      this.validForm = true;
    }
    this.passwordError = this.appUtilService.checkPasswordError(this.resetPasswordObj.newPassword);
  };

  /***
   * @author : Lekhika Inani (lekhika.inani@kahunasystems.com)
   * @description : redirect page to login page
   * @param : {void}
   * @returns : {void}
   */
  public goToLogin = (): void => {
    this.router.navigate(['/']);
  };

  checkCfrmPassword = (): void => {
    if (this.resetPasswordObj.newPassword === this.cfm_password) {
      this.validForm = false;
      this.pwdNotMatch = false;
    } else {
      this.validForm = true;
      this.pwdNotMatch = true;
    }
  };


  resetPassword = (): void => {
    if (this.resetPasswordObj.newPassword !== '' && this.cfm_password !== '') {
      if (this.resetPasswordObj.newPassword === this.cfm_password) {
        this.loader.show();
        this.webService.post(AuthenticationConstants.API.resetPassword, this.resetPasswordObj)
          .subscribe((response: StatusWrapper) => {
            this.loader.hide();
            if (response.status.code === 200) {
              this.resetPasswordForm.resetForm();
              // this.router.navigate(['/']);
              this.resetSuccess = true;
              this.toasterService.showToast(response.status.message, 'success');
            } else {
              this.toasterService.showToast(response.status.message, 'error');
            }
          }, () => {
            this.loader.hide();
          });
      }
    }
  };
}
