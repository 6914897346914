import { Component, OnInit } from '@angular/core';
import { AppSettingService } from '../../../../core/app-setting.service';

@Component({
  selector: 'app-register-info-dialog',
  templateUrl: './register-info-dialog.component.html',
  styleUrls: ['./register-info-dialog.component.scss']
})
export class RegisterInfoDialogComponent implements OnInit {

  appName: string;

  constructor(private appSetting: AppSettingService) {
  }

  ngOnInit(): void {
    this.appName = this.appSetting.getProperty('title');
  }

}
