/**
 * Created by ganeshram on 13/11/17.
 */

export class GetMyCaseTypeRequestProto {
  pageNumber: number;
  pageSize: number;
  searchText: string;
  srTypeId = [];
  currentStatus = [];
  caseCreatedDate = [];
  sort = [];
}

export class PaginationProto {
  pageSize: number = 3;
  skip: number = 0;
}

export class GetFollowedServiceListRequestProto {
  caseCreatedDate = [];
  currentStatus = [];
  hotSr = false;
  pageNumber: number;
  pageSize: number;
  searchText = '';
  sort = [];
  sourceId: string[] = [];
  srCategoryId: string[] = [];
  srTypeId: string[] = [];
}


export class SocialFeedDetails {

  pageSize = 5;
  length = 0;

}


export class Pagination {
  pageSize: number = 10;
  total: number;
  pageNumber: number = 1;
}

export class PaginationObject {
  pageNumber: number = 0;
  pageSize: number = 10;
}

export class GetGridDataProto {
  pageNumber: number;
  pageSize: number;
}

export class PaymentTypeList {
  public static TYPES = [
    'Water/Sewer',
    'Parking Tickets',
    'Building Permits',
    'Business License | Renewal',
    'Vacant Property Registration',
    'Parks & Recreation',
    'Excise Tax',
    'Outdoor/Special Event Permit',
    'Municipal Court Tickets',
  ];
}

export class CreateConversationProto {
  firstName: string;
  lastName: string;
  email: string;
}

export class GetMessageHistoryProto {
  conversationId: string;
  pageNumber: number = 1;
  pageSize: number = 10;
  citizenCrmId: string;
}

export class SendMessageProto {
  conversationId: string;
  messageText: string;
  messageType: string;
  mediaType: string;
  citizenCrmId: string;
}

export const MESSAGE_TYPE = {'TEXT': 'TEXT', 'ACTIVITY': 'ACTIVITY', 'MULTIMEDIA': 'MULTIMEDA'};
