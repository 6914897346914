import { NgModule } from '@angular/core';
import {
  BodyModule,
  ColumnResizingService,
  ExcelModule,
  FilterMenuModule,
  GridModule,
  HeaderModule,
  PagerModule,
  PDFModule,
  RowFilterModule
} from '@progress/kendo-angular-grid';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { KendoDateRangeFilterComponent } from './kendo-date-range-filter/kendo-date-range-filter.component';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgPipesModule } from 'ngx-pipes';
import { CustomMaterialModule } from '../custom-material.module';
import { TranslateModule } from '@ngx-translate/core';
import { KendoDropDownFilterComponent } from './kendo-drop-down-filter/kendo-drop-down-filter.component';
import { KendoResetFilterButtonComponent } from './kendo-reset-filter-button/kendo-reset-filter-button.component';
import { KendoStringFilterComponent } from './kendo-string-filter/kendo-string-filter.component';
import { KendoShowHideColumnComponent } from './kendo-show-hide-column/kendo-show-hide-column.component';
import { NgxMatDrpModule } from 'kscommon-components';
import { DragDropModule } from '@angular/cdk/drag-drop';

const componentList = [
  KendoDropDownFilterComponent,
  KendoShowHideColumnComponent,
  KendoDateRangeFilterComponent,
  KendoStringFilterComponent,
  KendoResetFilterButtonComponent
];

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    NgPipesModule,
    CustomMaterialModule,
    TranslateModule,
    GridModule,
    DragDropModule,
    DropDownsModule,
    NgxMatDrpModule,
    BodyModule,
    FilterMenuModule,
    RowFilterModule,
    PagerModule,
    HeaderModule
  ],
  declarations: [
    componentList
  ],
  exports: [
    GridModule,
    PDFModule,
    ExcelModule,
    DropDownsModule,
    componentList,
    BodyModule,
    RowFilterModule,
    PagerModule,
    HeaderModule
  ],
  providers: [ColumnResizingService]
})
export class CustomKendoModule {
}
