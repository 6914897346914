import { ServerUrls } from '../../server-url-constants';
import { ResponseStatusWrapper } from '../../constants/app-constants';

export class AuthenticationConstants {
  public static IS_EXTERNAL_IDM: boolean = false;
  static API: any = {
    'userProfileImage': '/rest/crm/contact/v1/get_user_profile_image',
    'getSocialAuthConfig': '/rest/idm/config/v1/get_social_template_config',
    'socialSign': '/rest/idm/account/v2/external_auth_signup',
    'recoverPassword': '/rest/idm/user/v1/recover_password',
    'resetPassword': '/rest/idm/user/v1/reset_password',
    'validateToken': '/rest/idm/user/v1/validate_forgot_password_token',
    'changePassword': '/rest/idm/user/v1/change_password',
    'resendMail': '/rest/idm/account/v1/resend_mail',
    'verifyContact': '/rest/idm/account/v1/verify_citizen_registration_token'
  };
}


export class ContactType {
  static contactType = [{'data': 'Mobile'}, {'data': 'Telephone'},
    {'data': 'Fax', 'value': 'fax'}];
}

export class AddressType {
  static addressType = [{'data': 'Home'}, {'data': 'Work'},
    {'data': 'Other'}];
}

export class PrefferredCommChannel {
  static preferredCommChannel = [{'data': 'Mobile', 'value': 'mobile'}, {'data': 'E-mail', 'value': 'e-mail'}];
}

export class Gender {
  static gender = [{'data': 'Male'}, {'data': 'Female'}];
}


export class AdditionalContactProto {
  firstName: string;
  lastName: string;
  mobileNo: number;
  emailId: string;
  title: string;
}

export class AdditionalFieldDetails {
  appId: string;
  information: Map<string, string> = new Map<string, string>();
}

export class LocationAdditionalDetails {
  appId: string;
  information: InformationObject[] = [];
}

export class InformationObject {
  key: string;
  value: string;
}

export const FRACTIONS_LIST: any[] = [{id: '1/4', value: '1/4'}, {id: '1/2', value: '1/2'},
  {id: '3/4', value: '3/4'}];

export const DIRECTIONS_LIST: string[] = ['N', 'S', 'E', 'W', 'NE', 'NW', 'SE', 'SW'];


export class AddressCustomFields {
  public fieldName: string;
  public fieldValue: string;
}

export class AddressListProto {
  type: string = 'FIND';
  addressLine: string;
  city: string;
  state: string;
  country: string;
  zip: string;
  coordinates: any[] = [];
  isPrimary: boolean;
  landmark: string;
  unit: string;
  buildingType: string;
  addressLine1: string;
  addressLine2: string;
  phoneNumber: string;

  houseNumber: string;
  houseFracNumber: string;
  preDirCd: string;
  streetName: string;
  streetTypeCd: string;
  postDirCd: string;
  zipSuffix: string;
  public addressCustomFields: AddressCustomFields[] = [];
  public primary = false;
  public typeSame = true;
  public verified = true;
}

export class LoginRequestPayload {
  email: string;
  password: string;
}

export class ForgotPasswordRequest {
  userId: string;
}

export class UserRegisterRequestProto {
  userName: string;
  userPassword: string;
  userType: string = 'Citizen';
  userGroup: string[] = [];
  citizenAuthType: string = 'SelfSignup';
  userContact: UserContactRegisterRequest = new UserContactRegisterRequest();
  appId: string = ServerUrls.APPID;
}


export class UserContactRegisterRequest {
  emailAddress: string = '';
  secondaryPhone: string;
  firstName: string = '';
  lastName: string = '';
  addressList: any[] = [];
}


export class ResetPasswordObj {
  newPassword: string = '';
  token: string;
}

export class StatusWrapper {
  status: ResponseStatusWrapper;
}


export class LocationValidationProto {
  latitude: number;
  longitude: number;
}

export class EmailIdList {
  public emailId: string;
  public primary: boolean;
  public verified: boolean;

  constructor() {
    this.emailId = '';
    this.primary = false;
    this.verified = false;
  }
}

export class ContactDetails {
  type: string;
  phoneNumberFull: number;
  phoneCountryPrefix: number;
  phoneExtension: number;
  faxNumber: number;
  primary: boolean;

  constructor() {
    this.type = '';
    this.phoneNumberFull = null;
    this.phoneCountryPrefix = null;
    this.phoneExtension = null;
    this.faxNumber = null;
    this.primary = false;
  }
}

export class LatestUserProfileProto {
  public crmId: string;
  public firstName: string;
  public lastName: string;
  public aliasName: string;
  public middleName: string;
  public profileImageRemoved: boolean;
  public displayGender = false;
  // public gender: string;
  public addressList: AddressListProto[] = [];
  public userId: string;
  public receiveEmergencyNotifications = false;
  public emailIdList: EmailIdList [] = [];
  public appId: string;
  // public id: string;
  public contactDetails: ContactDetails[] = [];
  public preferredCommunicationChannel: string;
  public primaryLanguage: string;
  public additionalDetails: AdditionalFieldDetails[];
  public timeZoneId: any;

  constructor() {
    this.crmId = '';
    this.firstName = '';
    this.lastName = '';
    this.aliasName = '';
    this.middleName = '';
    this.appId = '';
    this.userId = '';
    this.preferredCommunicationChannel = '';
    this.primaryLanguage = 'en';
    this.timeZoneId = '';
  }
}

export class ChangePasswordObjProto {
  oldPassword: string;
  newPassword: string;
  userId: string;
}
