
export class LogCampConstant {
    public static API: any = {
        'logCampBaseURL': 'https://api.logcamp.net',
        'createlogCampJson': '/rest/logJson/create'
    };

    public static logCampAppInfo: any = {
        'logCampAppId': 191,
        'logCampDateFormat': "yyyy-MM-dd'T'HH:mm:ss"
    }


    public static operatingSystemList: any = [
        { s: 'Windows 10', r: /(Windows 10.0|Windows NT 10.0)/ },
        { s: 'Windows 8.1', r: /(Windows 8.1|Windows NT 6.3)/ },
        { s: 'Windows 8', r: /(Windows 8|Windows NT 6.2)/ },
        { s: 'Windows 7', r: /(Windows 7|Windows NT 6.1)/ },
        { s: 'Windows Vista', r: /Windows NT 6.0/ },
        { s: 'Windows Server 2003', r: /Windows NT 5.2/ },
        { s: 'Windows XP', r: /(Windows NT 5.1|Windows XP)/ },
        { s: 'Windows 2000', r: /(Windows NT 5.0|Windows 2000)/ },
        { s: 'Windows ME', r: /(Win 9x 4.90|Windows ME)/ },
        { s: 'Windows 98', r: /(Windows 98|Win98)/ },
        { s: 'Windows 95', r: /(Windows 95|Win95|Windows_95)/ },
        { s: 'Windows NT 4.0', r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/ },
        { s: 'Windows CE', r: /Windows CE/ },
        { s: 'Windows 3.11', r: /Win16/ },
        { s: 'Android', r: /Android/ },
        { s: 'Open BSD', r: /OpenBSD/ },
        { s: 'Sun OS', r: /SunOS/ },
        { s: 'Chrome OS', r: /CrOS/ },
        { s: 'Linux', r: /(Linux|X11(?!.*CrOS))/ },
        { s: 'iOS', r: /(iPhone|iPad|iPod)/ },
        { s: 'Mac OS X', r: /Mac OS X/ },
        { s: 'Mac OS', r: /(Mac OS|MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
        { s: 'QNX', r: /QNX/ },
        { s: 'UNIX', r: /UNIX/ },
        { s: 'BeOS', r: /BeOS/ },
        { s: 'OS/2', r: /OS\/2/ },
        { s: 'Search Bot', r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/ }
    ];

}

export class ServiceLogsRequestModel {
    appId: number;
    appVersion: string;
    dateTime: string;
    deviceId: string;
    deviceInfo: string;
    errorCode: string;
    functionSource: string;
    request: string;
    response: string;
    requestStartTime: string;
    requestEndTime: string;
    status: string;
    source: string;
    ticketNumber: string;
    user: string;  
}

export class ServiceLogsResponseModel {
    status: Status;
    logId: string;
}

export class Status {
    code: number;
    message: string;
    cause: string;
}

export class DeviceInfo {
    osName: string;
    browserInfo: string;
    isMobileDevice: boolean;
    user: string;
    userEmailID: string;
    userRoles: string;
    requestStartTime : string;
    requestEndTime : string;


    constructor(reqStartTime?:string, reqEndTime?:string) {
        this.requestStartTime = reqStartTime;
        this.requestEndTime = reqEndTime;
        this.getDeviceInfo()
    }

    private getDeviceInfo() {
        let nAgt = navigator.userAgent;
        let nVer = navigator.appVersion;
        let browser = navigator.appName;
        let browserVersion = '' + parseFloat(navigator.appVersion);
        let browserMajorVersion = parseInt(navigator.appVersion, 10);
        let fullVersion = '' + parseFloat(navigator.appVersion);        
        let nameOffset, verOffset, ix;

        // In Opera, the true version is after "Opera" or after "Version"

        if ((verOffset = nAgt.indexOf("Opera")) != -1) {
            browser = "Opera";
            fullVersion = nAgt.substring(verOffset + 6);
            if ((verOffset = nAgt.indexOf("Version")) != -1)
                fullVersion = nAgt.substring(verOffset + 8);
        }
        // In MSIE, the true version is after "MSIE" in userAgent

        else if ((verOffset = nAgt.indexOf("MSIE")) != -1) {
            browser = "Microsoft Internet Explorer";
            fullVersion = nAgt.substring(verOffset + 5);
        }
        // In Chrome, the true version is after "Chrome"

        else if ((verOffset = nAgt.indexOf("Chrome")) != -1) {
            browser = "Chrome";
            fullVersion = nAgt.substring(verOffset + 7);
        }
        // In Safari, the true version is after "Safari" or after "Version"

        else if ((verOffset = nAgt.indexOf("Safari")) != -1) {
            browser = "Safari";
            fullVersion = nAgt.substring(verOffset + 7);
            if ((verOffset = nAgt.indexOf("Version")) != -1)
                fullVersion = nAgt.substring(verOffset + 8);
        }
        // In Firefox, the true version is after "Firefox"

        else if ((verOffset = nAgt.indexOf("Firefox")) != -1) {
            browser = "Firefox";
            fullVersion = nAgt.substring(verOffset + 8);
        }
        // In most other browsers, "name/version" is at the end of userAgent

        else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) <
            (verOffset = nAgt.lastIndexOf('/'))) {
            browser = nAgt.substring(nameOffset, verOffset);
            fullVersion = nAgt.substring(verOffset + 1);
            if (browser.toLowerCase() == browser.toUpperCase()) {
                browser = navigator.appName;
            }
        }

        // trim the fullVersion string at semicolon/space if present
        if ((ix = fullVersion.indexOf(";")) != -1)
            fullVersion = fullVersion.substring(0, ix);
        if ((ix = fullVersion.indexOf(" ")) != -1)
            fullVersion = fullVersion.substring(0, ix);

        browserMajorVersion = parseInt('' + fullVersion, 10);
        if (isNaN(browserMajorVersion)) {
            fullVersion = '' + parseFloat(navigator.appVersion);
            browserMajorVersion = parseInt(navigator.appVersion, 10);
        }

        // system
        let os = "unknown";

        for (let id in LogCampConstant.operatingSystemList) {
            let cs = LogCampConstant.operatingSystemList[id];
            if (cs.r.test(nAgt)) {
                os = cs.s;
                break;
            }
        }

        let osVersion = undefined;

        if (/Windows/.test(os)) {
            osVersion = /Windows (.*)/.exec(os)[1];
            os = 'Windows';
        }

        switch (os) {
            case 'Mac OS':
            case 'Mac OS X':
            case 'Android':
                osVersion = /(?:Android|Mac OS|Mac OS X|MacPPC|MacIntel|Mac_PowerPC|Macintosh) ([\.\_\d]+)/.exec(nAgt)[1];
                break;

            case 'iOS':
                osVersion = /OS (\d+)_(\d+)_?(\d+)?/.exec(nVer);
                osVersion = osVersion[1] + '.' + osVersion[2] + '.' + (osVersion[3] | 0);
                break;
        }
        this.osName = os + ' ' + osVersion;
        this.browserInfo = browser + ' ' + fullVersion + ' (' + browserMajorVersion + ')';
        this.isMobileDevice = /Mobile|mini|Fennec|Android|iP(ad|od|hone)/.test(nVer);
    }
}