import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {MenuService} from '../menu.service';
import {Subscription} from 'rxjs';
import {MatDrawer, ThemePalette} from '@angular/material';
import {StaticTabs} from '../../layout-constants';
import {LayoutService} from "../../layout.service";
import { NotificationService } from "../notification-sidenav/notification.service";

@Component({
  selector: 'app-top-static-tabs-view',
  templateUrl: './top-static-tabs-view.component.html',
  styleUrls: ['./top-static-tabs-view.component.scss']
})
export class TopStaticTabsViewComponent implements OnInit, OnDestroy {

  @Input('color') color: ThemePalette = 'warn';

  public staticTabs: StaticTabs[] = [];
  selectedIndex: number = 0;
  @Input('orgSidenav') orgSidenav: MatDrawer;
  private subscription: Subscription;
  private subscriptionStaticTabClick: Subscription;

  constructor(private menuService: MenuService, public layoutService: LayoutService,public notificationService: NotificationService,) {
  }

  ngOnInit(): void {
    this.subscription = this.menuService.getStaticTabs().subscribe((staticTabs) => {
      this.staticTabs = staticTabs;
    });

    this.notificationService.getOrganizationComments().subscribe(response => {
     this.notificationService.unreadCommentCount = response.unreadCount;
    });
    this.subscriptionStaticTabClick = this.menuService.staticTabClick.subscribe((id: string) => {
      const findIndex = this.staticTabs.findIndex(object => object.id === id);
      if (findIndex > -1) {
        this.selectedIndex = findIndex;
      } else {
        this.selectedIndex = 0;
      }
    });
  }

  onSelectedIndexChange(index: number) {
    this.menuService.onClickStaticTab(this.staticTabs[index].id);
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.subscriptionStaticTabClick) {
      this.subscriptionStaticTabClick.unsubscribe();
    }
  }

}
