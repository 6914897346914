/**
 * Created by hiral on 2/11/17.
 */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseFilterCellComponent, FilterService } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query/dist/npm/state';


@Component({
  selector: 'app-kendo-grid-my-reset-filter-cell',
  templateUrl: './kendo-reset-filter-button.component.html'
})
export class KendoResetFilterButtonComponent extends BaseFilterCellComponent implements OnInit {

  @Output('resetFilter') resetFilterEmitter = new EventEmitter();
  @Input('state') stateObject: State;
  @Input('placeholder') placeholder: string = 'CONSTANTS.BUTTONS.RESET_FILTER';

  constructor(filterService: FilterService) {
    super(filterService);
  }

  ngOnInit(): void {
  }

  resetFilter(): void {
    if (this.stateObject) {
      if (this.stateObject.filter) {
        this.stateObject.filter.filters.length = 0;
      }
      if (this.stateObject.sort) {
        this.stateObject.sort.length = 0;
      }
    }
    this.resetFilterEmitter.emit(null);
  }

}
