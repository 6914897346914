import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppSettingService } from '../../../core/app-setting.service';
import { LanguageListProto } from '../../../constants/app-constants';
import { Subscription } from 'rxjs/Subscription';
import { CommonCookieService } from 'kscookies';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'portal-language-menu',
  templateUrl: './language-menu.component.html',
  styleUrls: ['./language-menu.component.scss']
})
export class LanguageMenuComponent implements OnInit, OnDestroy {

  public languageList: LanguageListProto[] = [];
  public subscriber: Subscription;

  constructor(private appSettingService: AppSettingService, private cookieService: CommonCookieService,
              private translateService: TranslateService) {
  }

  ngOnInit(): void {
    this.subscriber = this.appSettingService.getObserver().subscribe((result) => {
      this.languageList = result.language;
    });
  }

  ngOnDestroy(): void {
    if (this.subscriber) {
      this.subscriber.unsubscribe();
    }
  }

  selectLanguage(languageKey: string): void {
    this.cookieService.setCookie('lang', languageKey);
    this.translateService.setDefaultLang(languageKey);
    this.translateService.use(languageKey);
    window.location.reload(true);
  }
}
