import { Component, Input, OnInit } from '@angular/core';
import { MAT_LABEL_GLOBAL_OPTIONS } from '@angular/material';
import { Subject } from 'rxjs-compat';
import { Router } from '@angular/router';
import { WebService } from 'web-service';
import { OAuth2Service } from 'o-auth2';
import {
  GlobalSearchSearchResult,
  GlobalSearchUrlConstants,
  SearchCountRequestProto,
  ServicesConstants
} from './global-search-url.constants';


@Component({
  selector: 'app-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.scss'],
  providers: [
    {provide: MAT_LABEL_GLOBAL_OPTIONS, useValue: {float: 'never'}}
  ]
})
export class GlobalSearchComponent implements OnInit {


  @Input('placeholder') placeholder: string = 'GLOBAL_SEARCH.PLACEHOLDER';

  @Input('map_search') map_search: boolean = false;
  public searchCountRequest: SearchCountRequestProto = new SearchCountRequestProto();
  public searchText: any;
  public searchResult: GlobalSearchSearchResult[] = [];
  public resultCount: number = 0;
  public selectedResult: string;
  public filteredOptions: Subject<any> = new Subject();
  public isLoggedIn: boolean = false;

  constructor(private webService: WebService,
              private router: Router,
              public oAuthService: OAuth2Service) {
  }

  ngOnInit(): void {
    this.isLoggedIn = this.oAuthService.isAuthenticated();
    if (this.map_search === true) {
      this.searchResult = [
        {'name': 'APN', 'count': 0, 'index': 6, type: 'APN'},
        {'name': 'Case', 'count': 0, 'index': 8, type: 'CASE'}
      ];
    } else {
      this.searchResult = [{'name': 'CEP-Articles', 'count': 0, 'index': 0, type: 'CSD'},
        {'name': 'SR Type', 'count': 0, 'index': 1, type: 'SRTYPE'},
        {'name': 'FAQs', 'count': 0, 'index': 2, type: 'FAQ'},
        {'name': 'Articles', 'count': 0, 'index': 3, type: 'BLOG'},
        {'name': 'Document', 'count': 0, 'index': 4, type: 'DMS'},
        {'name': 'APN', 'count': 0, 'index': 5, type: 'APN'},
        {'name': 'Address', 'count': 0, 'index': 6, type: 'ADDRESS'},
        {'name': 'Case', 'count': 0, 'index': 7, type: 'CASE'},
        {'name': 'Contact', 'count': 0, 'index': 8, type: 'CRM'},
      ];
    }
    this.searchCountRequest.cityService = ServicesConstants.SERVICES;
    this.searchCountRequest.cityDirectory = ['ALL'];
    this.searchCountRequest.pageSize = 5;
    this.searchCountRequest.pageNumber = 1;
    this.searchCountRequest.language = 'en';
  }

  filter(): void {
    let data = this.searchResult.filter(option => option.count > 0);
    this.resultCount = data.length;
    if (data.length > 0) {
      data = data.filter((v) => !(v.type === 'DMS'));
      this.filteredOptions.next(data);
    } else {
      this.filteredOptions.next([{
        'name': 'No match found',
        'count': -1,
        'index': -1,
      }]);
    }
  }

  onChange(): void {
    if (this.searchText) {
      if (this.searchText.length >= 2) {
        this.selectedResult = this.searchText;
        this.searchCountRequest.keyword = this.searchText;
        this.webService.post(GlobalSearchUrlConstants.API.searchContentCount, this.searchCountRequest)
          .subscribe((response) => {
            if (response.status.code === 200) {
              const responseCountArray = Object.keys(response.count).map(key => ({
                type: key,
                count: response.count[key]
              }));
              this.searchResult.forEach((val) => {
                responseCountArray.forEach((v) => {
                  if (val.type === v.type) {
                    val.count = v.count;
                  }
                });
              });
              this.filter();
            }
          });
      } else {
        this.filteredOptions.next();
      }
    } else {
      this.searchCountRequest.keyword = '';
      this.filteredOptions.next();
    }
  }

  displayFn(): any {
    return this.searchText;
  }

  onSelect(option: GlobalSearchSearchResult): void {
    if (this.resultCount > 0) {
      this.searchText = this.selectedResult;
      this.filteredOptions.next();
      if (this.isLoggedIn) {
        this.router.navigate(['/search-result', option.name, this.searchCountRequest.keyword, option.index]);
      } else {
        this.router.navigate(['/searchResult', option.name, this.searchCountRequest.keyword, option.index]);
      }

    } else {
      this.searchText = '';
    }
    this.filteredOptions.next();
  }
}
