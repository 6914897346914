/**
 * Created by Isha Dhimar (isha.dhimar@kahunasystems.com) on 10/8/18.
 **/

export const Modules = {
  owl: {
    scripts: ['owl'],
    styles: ['owl']
  },
  scrollIntoView: {
    scripts: ['scrollIntoView']
  },
  froala: {
    styles: ['froala']
  },
  dragula: {
    styles: ['dragula']
  }
};
