import { Component, Input } from '@angular/core';
import { MainMenuItem } from '../../../layout-constants';

@Component({
  selector: 'app-horizontal-menu-tree',
  templateUrl: './horizontal-menu-tree.component.html',
  styleUrls: ['./horizontal-menu-tree.component.scss']
})
export class HorizontalMenuTreeComponent {

  @Input('menuItem') menuItem: MainMenuItem;
  @Input('matDropDownMenuItem') matDropDownMenuItem: boolean = false;

}
