import { ServerUrls } from '../../../../server-url-constants';
import { ResponseStatusWrapper } from '../../../../constants/app-constants';


export class CreateUserConstants {
  public static API: any = {
    'resendMail': '/rest/idm/account/v1/resend_mail',
    'getRoleList': '/rest/idm/user/v1/get_role_list',
    'getUserProfileConfig': '/rest/idm/account/v1/get_user_profile_config',
    'adminRegistration': '/rest/idm/account/v1/signup',
    'citizenRegistration': '/rest/idm/account/v1/citizen_registration'
  };
}
export interface UserProfileConfigResponse {
  status: ResponseStatusWrapper;
  config: Config;
}

export interface Config {
  id: string;
  userProfilePath: string;
  displayGender: boolean;
  viewUserProfileLink: string;
  passwordPolicyRegex: string;
  passwordPolicyMessage?: any;
  checkPasswordParameter: CheckPasswordParameter;
  passwordPolicyMessageFlag: PasswordPolicyMessageFlag;
  firstNameRequired: boolean;
  lastNameRequired: boolean;
  emailIdRequired: boolean;
  addressRequired: boolean;
  autoPasswordGenerationEnable: boolean;
}

interface PasswordPolicyMessageFlag {
  isCapitalLetterRequired: boolean;
  isLengthCheck: boolean;
  isSpecialCharRequired: boolean;
  isNumberRequired: boolean;
}

interface CheckPasswordParameter {
  containUserId: boolean;
  containFirstName: boolean;
  containLastName: boolean;
  containDictionary: boolean;
}
export class GetUserProfileConfig {
  status: ResponseStatusWrapper;
  userProfileConfig: UserProfileConfig[];
}

export class UserProfileConfig {
  id: string;
  userProfilePath: any = null;
  displayGender: boolean = false;
  viewUserProfileLink: any = null;
  emailIdRequired: boolean = true;
  firstNameRequired: boolean = true;
  lastNameRequired: boolean = true;
  addressRequired: boolean = false;
}

export class RegisterRequestProto {
  userName: string;
  userPassword: string;
  userType: string = 'Citizen';
  userGroup: string[] = [];
  citizenAuthType: string = 'SelfSignup';
  userContact: UserContactRegisterRequest = new UserContactRegisterRequest();
  appId: string = ServerUrls.APPID;
}

export class GetUserRoles {
  status: ResponseStatusWrapper;
  userRoles: UserRoles[];
}

export class UserRoles {
  id: string;
  roleName: string;
}

export class UserContactRegisterRequest {
  emailAddress: string = '';
  secondaryPhone: string;
  firstName: string = '';
  lastName: string = '';
  addressList: any[] = [];
}

export class AddressObj {
  addressLine: string;
  primary: boolean = true;
  city: string;
  zip: string;
  type: string = 'Home';
  coordinates: any[] = [];
  state: string;
}

export class ResendEmailRequestProto {
  userId: string;
}
