import { Component, OnInit, ViewChild } from '@angular/core';
import { CrmContactConstants, GetByViewObj } from '../../../crm-contact-constants';
import { ActivatedRoute } from '@angular/router';
import { WebService } from 'web-service';
import { ToasterService } from '../../../../../../../core/toaster/toaster.service';
import { LoaderService } from '../../../../../../../core/loader/loader.service';
import { DataStateChangeEvent, DetailExpandEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { DataResult, process, State } from '@progress/kendo-data-query';
import { Subject } from 'rxjs';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material';
import { AppUtilService } from '../../../../../../../core/app-util.service';
import { ViewMailNotificationDialogComponent } from './view-mail-notification-dialog/view-mail-notification-dialog.component';

@Component({
  selector: 'app-show-mail-history',
  templateUrl: './show-mail-history.component.html',
  styleUrls: ['./show-mail-history.component.scss']
})
export class ShowMailHistoryComponent implements OnInit {

  public id: any;
  public getByViewObj: GetByViewObj = new GetByViewObj();
  public listDetails: any;
  public listResponseData: any[] = [];

  public logsData: GridDataResult = {'data': [], 'total': 0};
  public state: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: []
    },
    sort: [{field: 'createdOn', dir: 'desc'}]
  };
  public clearInputFilter: Subject<any> = new Subject();
  @ViewChild('showDetailGrid') showDetailGrid: any;

  constructor(private route: ActivatedRoute,
              private webService: WebService,
              private toasterService: ToasterService,
              private loaderService: LoaderService,
              private datePipe: DatePipe,
              private dialog: MatDialog,
              private appUtilService: AppUtilService) {
  }

  ngOnInit() {
    this.id = this.route.snapshot.params['id'];
    this.getDataById();
  }

  getDataById() {
    this.getByViewObj.id = this.id;
    this.getByViewObj.pageNumber = this.state.skip / this.state.take + 1;
    this.getByViewObj.pageSize = this.state.take;
    this.webService.post(CrmContactConstants.API.contact.getByIdList, this.getByViewObj)
      .subscribe((response) => {
        this.loaderService.hide();
        if (response.status.code === 200) {
          if (response.logs) {
            this.listDetails = response.list;
            response.logs = response.logs.map((object) => {
              //object.createdOn = new Date(object.createdOn);
              //object.createdn = moment().format('YYYY-MM-DDTHH:mm:ss.SZ')
              return object;
            });
            this.listResponseData = response.logs;
            this.logsData = this.setGridData(this.listResponseData, this.state);
          } else {
            this.logsData.data = [];
            this.logsData.total = 0;
          }
        }
      });
  }

  getStatusById(event: DetailExpandEvent) {
    this.webService.get(CrmContactConstants.API.contact.getStatusById + event.dataItem.bunchId)
      .subscribe((response) => {
        this.loaderService.hide();
        if (response.status.code === 200) {
          this.logsData.data.forEach((row) => {
            if (row.bunchId === event.dataItem.bunchId) {
              event.dataItem.dataList = response.notificationStatus;
              event.dataItem.dataList.forEach((object) => {
                object.email = object.emailAttributes.to[0];
              });
              event.dataItem.state = {
                skip: 0,
                take: 10,
                filter: {
                  logic: 'and',
                  filters: []
                },
                sort: []
              };
              event.dataItem.gridData = this.setGridData(event.dataItem.dataList, event.dataItem.state);
              event.dataItem.showGrid = true;
            }
          });
        }
      });
  }

  onDataStateChange(event: any) {
    this.state = event;
    this.logsData = this.setGridData(this.listResponseData, this.state);
  }

  onInnerDataStateChange(event: DataStateChangeEvent, dataItem) {
    dataItem.state = event;
    dataItem.gridData = this.setGridData(dataItem.dataList, dataItem.state);
  }

  setGridData(list: any[], state: State): DataResult {
    return process(list, state);
  }

  refreshGrid() {
    this.clearInputFilter.next();
    this.getByViewObj = new GetByViewObj();
    this.logsData.data.forEach((data, index) => {
      this.showDetailGrid.collapseRow(index);
    });
    this.getDataById();
  }

  onViewStatus(data) {
    this.dialog.open(ViewMailNotificationDialogComponent, this.appUtilService.getDialogData(data));
  }
}
