/**
 * Created by hiral on 2/11/17.
 */

 import { Component, Input, OnInit } from '@angular/core';
 import { BaseFilterCellComponent, FilterService } from '@progress/kendo-angular-grid';
 import { Subject } from 'rxjs/Subject';
 import * as $ from 'jquery';

 @Component({
   selector: 'app-kendo-string-filter',
   template: `
       <div class="k-filtercell-wrapper">
           <input type="text" class="k-textbox" name="name{{field}}" [(ngModel)]="value"
                  [maxLength]="maxChar ? maxChar : 100"
                  oninput="(value=value.replace(/^[\\s\uFEFF\xA0]+/g, ''))"
                  id="stringFilter{{field}}" (keyup.enter)="callFilter($event)"/>
       </div>
   `
 })
 export class KendoStringFilterComponent extends BaseFilterCellComponent implements OnInit {
   //  [placeholder]="placeholder"

   // @Input() public placeholder: string;
   @Input() public filter: any;
   @Input() public maxChar: number;
   @Input() public sort: any;
   @Input() public data: any[];
   @Input() public field: string;
   @Input() clearInputValue: Subject<any>;
   @Input() public name: any;
   @Input() public value: any = '';
   constructor(filterService: FilterService) {
     super(filterService);
   }

   ngOnInit(): void {
     if (this.clearInputValue) {
       this.clearInputValue.subscribe(event => {
         this.resetFilter();
       });
     }
   }

   public callFilter(event: any): void {
     const givenString = event.target.value;
     this.applyFilter(
       (givenString === null) ? // value of the default item
         this.removeFilter(this.field) : // remove the filter
         this.updateFilter({ // add a filter for the field with the value
           field: this.field,
           operator: 'contains',
           value: givenString
         })
     ); // update the root filter
   }

   public resetFilter(): any {
     $('#stringFilter' + this.field).val('');
     /*this.applyFilter(
       this.removeFilter(this.field)
     );*/
   }
 }
