import {Component, Inject, OnInit} from '@angular/core';
import {PageContentModelProto} from 'kscommon-components';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material';
import {WebService} from 'web-service';
import {ActivatedRoute, Router} from '@angular/router';
import {LoaderService} from '../../../core/loader/loader.service';
import {LanguageService} from '../../../core/language/language.service';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-dynamic-dialog',
  templateUrl: './dynamic-dialog.component.html',
  styleUrls: ['./dynamic-dialog.component.scss']
})
export class DynamicDialogComponent implements OnInit {
  item = new PageContentModelProto();

  constructor(@Inject(MAT_DIALOG_DATA) public data: PageContentModelProto,
              public webService: WebService, public languageService: LanguageService,
              public router: Router, public activatedRoute: ActivatedRoute,
              private dialog: MatDialog, private loaderService: LoaderService,
              private dialogRef: MatDialogRef<DynamicDialogComponent>) {
  }

  ngOnInit(): void {
    this.loaderService.show();
    if (this.data) {
      this.item.moduleData = this.data.moduleData;
      this.item.parameters = this.data.parameters;
      this.item.parameters.push({key: 'dialogRef', data: this.dialogRef});
    }
    this.loaderService.hide();
  }
}
