/**
 * Created by Isha Dhimar (isha.dhimar@kahunasystems.com) on 6/8/18.
 **/
import { ElementRef, Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Modules } from './lazy-load.constants';

@Injectable()

export class LazyLoadService {
  modules = Modules;

  constructor(@Inject(DOCUMENT) private document: any) {
  }

  public loadStylesUrl = (urls: string[]): void => {
    urls.forEach((url) => {
      this.createElement(url);
    });
  };

  public loadScriptsUrl = (urls: string[], charset?: string): void => {
    urls.forEach((url) => {
      const link: ElementRef = this.document.querySelector(`script[src*="${url}"]`);
      if (!link) {
        const script = this.document.createElement('script');
        script.type = 'text/javascript';
        script.src = url;
        if (charset) {
          script.charset = charset;
        }
        this.document.body.appendChild(script);
      }
    });
  };

  public loadExternalScript = (urls: string[]): void => {
    urls.forEach((url) => {
      this.createScriptElement(url);
    });
  };

  public resolveFor(bundleName: string[]) {
    bundleName.forEach((v) => {
      if (this.modules[v] !== undefined) {
        if (this.modules[v].hasOwnProperty('scripts')) {
          this.loadScriptsUrl(this.modules[v].scripts);
        }
        if (this.modules[v].hasOwnProperty('styles')) {
          this.loadStylesUrl(this.modules[v].styles);
        }
      }
    });
  }

  private createElement(url: string): void {
    const link: ElementRef = this.document.querySelector(`link[href*="${url}"]`);
    if (!link) {
      const script = document.createElement('link');
      script.rel = 'stylesheet';
      script.type = 'text/css';
      script.href = 'css/' + url + '.css';
      document.getElementsByTagName('head')[0].appendChild(script);
    }
  };

  private createScriptElement(url: string): void {
    const link: ElementRef = this.document.querySelector(`script[src*="${url}"]`);
    if (!link) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = 'js/' + url + '.bundle.js';
      document.body.appendChild(script);
    }
  };
}
