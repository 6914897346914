import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatCheckboxChange, MatDialog, MatDrawer } from '@angular/material';
import * as _ from 'lodash';
import {
  AddEditListDialogData,
  ConfigObj,
  CrmContactConstants,
  GetByViewObj,
  GetDataReqProto,
  KendoPaginationProto,
  ResponseViewFields,
  SearchContent,
  secretPhrase,
  ViewListResponse,
  ViewRequestProto
} from '../../crm-contact-constants';
import { WebService } from 'web-service';
import { ToasterService } from '../../../../../../core/toaster/toaster.service';
import { LoaderService } from '../../../../../../core/loader/loader.service';
import { AppUtilService } from '../../../../../../core/app-util.service';
import { MenuService } from '../../../../../../layouts/components/menu.service';
import { CrmContactService } from '../../crm-contact.service';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor, SortDescriptor, State } from '@progress/kendo-data-query';
import { RightMaskHandler } from '../../../../../../shared/classes/right-mask-handler/right-mask-handler';
import { ViewCrmContactComponent } from '../../view-crm-contact/view-crm-contact.component';
import { EditCrmContactDialogComponent } from '../../edit-crm-contact-dialog/edit-crm-contact-dialog.component';
import { saveAs } from 'file-saver';
// tslint:disable-next-line:rxjs-no-wholesale
import { Subject, Subscription } from 'rxjs';
import { ViewListByIdResponse } from '../types/view-list-by-id-response';
import { CrmListResponse } from '../types/crm-list-response';
import { ResponseField, ResponseFieldResponse } from '../types/response-field';
import { AddEditListDialogComponent } from '../add-edit-list-dialog/add-edit-list-dialog.component';
import { PromtDialogComponent } from '../../../../../../core/promt-dialog/promt-dialog.component';

@Component({
  selector: 'app-crm-contact-view',
  templateUrl: './crm-contact-view.component.html',
  styleUrls: ['./crm-contact-view.component.scss']
})
export class CrmContactViewComponent extends RightMaskHandler implements OnInit, OnDestroy {

  public searchFilter: string = '';
  public enableFilter: boolean = false;
  public selectedViewListId: string = '';
  public viewList: any[] = [];
  public getUserDataByView: ViewRequestProto = new ViewRequestProto();
  public kendoPagination: KendoPaginationProto = new KendoPaginationProto();
  public getByViewObj: GetByViewObj = new GetByViewObj();
  public getDataReq: GetDataReqProto = new GetDataReqProto();
  public userData: GridDataResult = {'data': [], 'total': 0};
  public responseViewFields: any[] = JSON.parse(JSON.stringify(ResponseViewFields));
  public userTypes: any[] = [];
  public sortRoleList: any[] = [];
  public customFieldList: any[] = [];
  public fieldList: ResponseField[] = [];
  public crmFields: ResponseField[] = [];
  public viewListResponse: ViewListByIdResponse | any;
  public addContacts = true;
  public addressType: any;
  public emailVerified: any;
  public userRole: any;
  public userType: any;
  public updateAccessFlag: boolean = false;
  public searchContent: SearchContent = new SearchContent();
  public innerWidth: number = 280;
  public outerWidth: number;
  public selectedCrmList: any[] = [];
  public data: any[] = [];
  public searchState: State = {
    filter: {
      logic: 'and',
      filters: []
    },
    sort: []
  };
  clearInputFilter: Subject<any> = new Subject();
  @ViewChild('openWidgetSidenav') openWidgetSidenav: MatDrawer;
  public contactList: any[] = [];
  private staticFields: ResponseField[] = [];
  private selectedFiltersSubscription: Subscription;

  constructor(private webService: WebService,
              private loaderService: LoaderService,
              private appUtilService: AppUtilService,
              private dialog: MatDialog, public menuService: MenuService,
              public toaster: ToasterService,
              public crmContactService: CrmContactService) {
    super(menuService);
  }


  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setGridColumnSize();
  }


  ngOnInit() {
    this.getRightMask('app-crm-contact');
    this.getDefaultColumns();
    this.getViewList();
    this.getUserRoles();
    this.getDataFromSearchFields();
    this.selectedFiltersSubscription = this.crmContactService.selectedFilters$.asObservable()
      .subscribe((list) => {
        this.customFieldList = list;
        this.data = [];
        this.customFieldList.filter(object => object.isChecked).map((object) => {
          this.searchFilter = '';
          if (object.isChecked) {
            this.data.push({
              key: object.key,
              value: object.value,
              operation: object.operation
            });
          }
        });
        this.getSelectedFilter();
      });
    this.getContactList();
  }


  ngOnDestroy(): void {
    if (this.selectedFiltersSubscription) {
      this.selectedFiltersSubscription.unsubscribe();
    }
  }

  setGridColumnSize() {
    this.innerWidth = window.innerWidth - 100;
    this.innerWidth = (this.innerWidth / (this.responseViewFields.length - 2));
  }

  getDefaultColumns() {
    this.webService.get(CrmContactConstants.API.contact.getDefaultColumns)
      .subscribe((response: ResponseFieldResponse) => {
        if (response.status.code === 200) {
          // response.responseFields = [];
          /* if (response.crmFields) {
             response.crmFields.forEach(object => object.additionalInfo = {crmFields: true});
             response.responseFields = response.responseFields.concat(response.crmFields);*/
          const userTypesObject = response.responseFields.find(object => object.key === 'userType');
          if (userTypesObject) {
            this.userTypes = userTypesObject.lovList;
          }
          this.crmFields = response.responseFields;
          this.setDefaultColumns();
          // }
          /* if (response.apnFields) {
             response.apnFields.forEach(object => object.additionalInfo = {apnFields: true});
             response.responseFields = response.responseFields.concat(response.apnFields);
           }
           if (response.caseFields) {
             response.caseFields.forEach(object => object.additionalInfo = {caseFields: true});
             response.responseFields = response.responseFields.concat(response.caseFields);
           }*/
          this.fieldList = Object.assign([], response.responseFields);
          this.staticFields = Object.assign([], response.responseFields);
          this.crmContactService.fieldList$.next(this.fieldList);
          this.setGridColumnSize();
        }
      }, () => {
      });

  }

  setDefaultColumns() {
    const defaultColumn = this.crmFields.filter(object => object.default);
    this.responseViewFields = JSON.parse(JSON.stringify(ResponseViewFields));
    defaultColumn.forEach((object) => {
      const findIndex = this.responseViewFields.findIndex(field => field.key === object.key);
      if (findIndex > -1) {
        this.responseViewFields[findIndex] = object;
      } else {
        this.responseViewFields.push(object);
      }
    });
  }

  getViewList(): void {

    this.loaderService.show();
    this.webService.get(CrmContactConstants.API.contact.getList)
      .subscribe((response: ViewListResponse) => {
        this.loaderService.hide();
        if (response.status.code === 200) {
          this.viewList = response.views;
        }
      }, () => this.loaderService.hide());
  }

  onAddSelectedContacts() {
    const dialogData: AddEditListDialogData = {
      title: 'CRM_CONTACT.ADD_VIEW_LIST_DIALOG.SAVE_LIST',
      for: 'list',
      config: new ConfigObj(),
      crmList: [...this.selectedCrmList],
      fields: [],
      isUpdate: false,
      viewList: this.contactList
    };
    this.dialog.open(AddEditListDialogComponent, this.appUtilService.getDialogData(dialogData))
      .afterClosed().subscribe((res) => {
      if (res) {

      }
    });
  }

  onAddAllContacts() {
    const dialogData: AddEditListDialogData = {
      title: 'CRM_CONTACT.ADD_VIEW_LIST_DIALOG.SAVE_LIST',
      for: 'list',
      config: new ConfigObj(),
      crmList: [...this.selectedCrmList],
      fields: [],
      isUpdate: false,
      viewList: this.contactList
    };
    dialogData.fromAllContacts = true;
    dialogData.selectedCrmList = [];
    dialogData.fields = this.customFieldList.filter(object => object.isChecked).map((object) => {
      return {
        key: object.key,
        value: object.value,
        operation: object.operation
      };
    });
    this.dialog.open(AddEditListDialogComponent, this.appUtilService.getDialogData(dialogData))
      .afterClosed().subscribe((res) => {
      if (res) {

      }
    });
  }

  getSelectedFilter() {
    this.addContacts = true;
    for (let i = 0; i < this.customFieldList.length; i++) {
      if (this.customFieldList[i].isChecked) {
        this.addContacts = false;
        break;
      }
    }
  }

  getSearchedData() {
    if (this.searchContent.searchText === '') {
      this.kendoPagination.skip = 0;
      this.kendoPagination.pageSize = 10;
      this.getDataFromSearchFields();
    } else {
      this.searchContent.id = this.selectedViewListId;
      this.searchContent.pageNumber = this.kendoPagination.pageNumber;
      this.searchContent.pageSize = this.kendoPagination.pageSize;
      this.searchContent.responseFields = this.responseViewFields;
      this.webService.post(CrmContactConstants.API.contact.searchContent, this.searchContent)
        .subscribe((response) => {
          if (response.status.code === 200) {
            this.userData.data = _.cloneDeep(response.userList);
            this.userData.total = response.count;
            this.getDefaultColumns();
          }
        }, () => this.loaderService.hide());
    }
  }

  getUserRoles(): void {
    this.webService.get(CrmContactConstants.API.contact.getAllRole)
      .subscribe((response) => {
        if (response.status.code === 200) {
          this.sortRoleList = response.userRoles;
          this.sortRoleList.push({id: 'Non_registered_Citizen', roleName: 'Non_registered_Citizen'});
          this.crmContactService.userRoles$.next(this.sortRoleList);
        }
      }, () => {
      });
  }

  updateView(flag: boolean): void {
    this.openWidgetSidenav.toggle();
    this.enableFilter = !(!flag && flag !== undefined);
  }

  resetFilter(): void {
    this.crmContactService.eventEmit$.next('reset');
    this.openWidgetSidenav.close();
    this.enableFilter = false;
    if (this.searchState.filter) {
      this.searchState.filter.filters.length = 0;
    }
    if (this.searchState.sort) {
      this.searchState.sort.length = 0;
    }
    this.addContacts = true;
    this.selectedViewListId = '';
    this.selectedCrmList = [];
    this.getDefaultColumns();
    this.getDataReq = new GetDataReqProto();
    this.kendoPagination = new KendoPaginationProto();
    this.responseViewFields = [...ResponseViewFields];
    this.clearInputFilter.next(true);
    this.searchContent.searchText = '';
    this.viewListResponse = {};
    this.clearInputFilter.next(true);
    this.addressType = '';
    this.emailVerified = '';
    this.userRole = '';
    this.userType = '';
    this.getDataFromSearchFields();
    this.getViewList();
    this.viewListResponse.updateAccess = false;
  }

  public exportToExcel(id?: string): void {
    let d;
    if (id) {
      d = JSON.stringify({id: this.selectedViewListId});
    } else {
      d = JSON.stringify({fields: this.data});
    }
    const data = {
      moduleName: 'crm',
      additionalInfo: {
        isList: false,
        request: d
      },
      fileType: 'EXCEL'
    };

    if (!id) {
      delete data.additionalInfo.isList;
    }

    this.webService.downloadCertificatePost(CrmContactConstants.API.contact.export,
      data, 'application/json')
      .subscribe((response) => {
        const blob = new Blob([response], {type: 'application/vnd.ms-excel'});
        saveAs(blob, 'crm-view.xlsx');
      });
  }

  getDataFromSearchFields(event?: any): void {
    let flag = false;
    let url = CrmContactConstants.API.contact.search;

    this.openWidgetSidenav.close();
    if (event) {
      this.kendoPagination.skip = 0;
      this.kendoPagination.pageSize = 10;
      this.enableFilter = true;
      this.getDataReq = event;
    }

    if (this.getDataReq.fields && this.getDataReq.fields.length > 0) {
      const findObject = this.getDataReq.fields.find(object => object['additionalInfo'] && (object['additionalInfo'].apnFields || object['additionalInfo'].caseFields));
      if (findObject) {
        flag = true;
      }
      this.getDataReq.fields.forEach(object => delete object['additionalInfo']);
    }
    if (flag) {
      url = CrmContactConstants.API.contact.advanced_search_crm_case;
    }
    this.getDataReq.pageNumber = (this.kendoPagination.skip / this.kendoPagination.pageSize) + 1;
    this.getDataReq.pageSize = this.kendoPagination.pageSize;
    this.webService.post(url, this.getDataReq)
      .subscribe((response) => {
        this.loaderService.hide();
        if (response.status.code === 200) {
          this.userData.data = _.cloneDeep(response.userList);
          this.userData.total = response.count;
          this.updateAccessFlag = false;
          this.getFields();
          if (this.getDataReq.fields.length > 0) {
            for (let i = 0; i < this.getDataReq.fields.length; i++) {
              const index = this.customFieldList.map(function (e) {
                return e.key;
              }).indexOf(this.getDataReq.fields[i].key);
              if (index >= 0) {
                this.customFieldList[index].key = this.getDataReq.fields[i].key;
                this.customFieldList[index].value = this.getDataReq.fields[i].value;
                this.customFieldList[index].operation = this.getDataReq.fields[i].operation;
                this.customFieldList[index].isChecked = true;
              }
            }
          }
        }
      }, () => this.loaderService.hide());
  }

  getFields() {
    this.customFieldList = [];
    this.fieldList.forEach((object) => {
      this.customFieldList.push({
        key: object.key,
        title: object.value,
        type: object.type,
        responseKey: object.responseKey,
        responseType: object.responseType,
        isChecked: false,
        excludeFromSearch: false,
      });
    });
  }

  filterchange1(event: any, grid: any) {
    if (event.column.title === undefined) {
      event.preventDefault();
      return;
    }
    const reorderedColumn2 = this.responseViewFields.splice(event.oldIndex, 1);
    this.responseViewFields.splice(event.newIndex, 0, ...reorderedColumn2);
    if (this.selectedViewListId) {
      if (this.viewListResponse.updateAccess) {
        this.updateColumns(this.viewListResponse.view);
      }
    }
  }

  updateColumns(obj?: any) {
    obj.responseFields = [];
    obj.responseFields = this.responseViewFields;
    this.webService.put(CrmContactConstants.API.contact.update, obj)
      .subscribe((response) => {
        this.loaderService.hide();
        if (response.status.code === 200) {

        } else {
          this.toaster.showToast(response.status.message, 'error');
        }
      }, () => this.loaderService.hide());
  }

  onSortChange(event: SortDescriptor[]) {
    /*clear filter and sorting if they are not selected for response field*/
    event.forEach((object, index) => {
      if (object.dir !== undefined) {
        const findObject = this.appUtilService.findObject(this.responseViewFields, 'key', object.field);
        if (!findObject) {
          event.splice(index, 1);
        }
      }
    });

    this.getDataReq.sortFields = [];
    event.forEach((object: any) => {
      if (object.dir !== undefined) {
        this.getDataReq.sortFields.push({
          'key': object.field, 'sortOrder': object.dir.toUpperCase()
        });
      }
    });

    this.searchState.sort = event;
    this.kendoPagination.skip = 0;
    if (this.searchContent.searchText) {
      this.getSearchedData();
    } else {
      this.getDataFromSearchFields();
    }
  }

  onFilterChange(event: CompositeFilterDescriptor) {
    /*clear filter and sorting if they are not selected for response field*/
    event.filters.forEach((object: any, index) => {
      const findObject = this.appUtilService.findObject(this.responseViewFields, 'key', object.field);
      if (!findObject) {
        event.filters.splice(index, 1);
      }
    });

    this.getDataReq.fields = [];
    if (event.filters.length > 0) {
      if (event && event.filters && event.filters.length >= 1) {
        event.filters.forEach((object: any) => {
          this.getDataReq.fields.push({
            key: object.field,
            value: object.value,
            operation: this.getOperatorForApi(object.operator)
          });
        });
      }
    }
    this.searchState.filter = event;
    this.kendoPagination.skip = 0;
    if (this.searchContent.searchText) {
      this.getSearchedData();
    } else {
      this.getDataFromSearchFields();
    }
  }

  onPageChange(event: PageChangeEvent) {
    this.kendoPagination.pageSize = event.take;
    this.kendoPagination.skip = event.skip;
    if (this.searchContent.searchText) {
      this.getSearchedData();
    } else {
      this.getDataFromSearchFields();
    }
  }

  selectionChange(event: any): void {
    event.selectedRows.forEach((v, i) => {
      this.selectedCrmList.push(v.dataItem.crmId);
    });
    event.deselectedRows.forEach((v) => {
      this.selectedCrmList.forEach((id, k) => {
        if (id === v.dataItem.crmId) {
          this.selectedCrmList.splice(k, 1);
        }
      });
    });
  }

  onSelectedViewListIdChange(): void {
    this.openWidgetSidenav.close();

    this.kendoPagination.skip = 0;
    this.kendoPagination.pageSize = 10;
    this.getByViewObj.pageNumber = this.kendoPagination.skip / this.kendoPagination.pageSize + 1;
    this.getByViewObj.pageSize = this.kendoPagination.pageSize;
    this.getByViewObj.id = this.selectedViewListId;

    this.webService.post(CrmContactConstants.API.contact.getById, this.getByViewObj)
      .subscribe((response: ViewListByIdResponse) => {
        this.loaderService.hide();
        if (response.status.code === 200) {
          this.viewListResponse = response;
          this.updateAccessFlag = this.viewListResponse.updateAccess;

          if (this.viewListResponse.view && this.viewListResponse.view.responseFields) {
            this.responseViewFields = [];
          }
          if (this.viewListResponse.view.responseFields.length) {
            this.addContacts = false;
          }
          for (let i = 0; i < this.viewListResponse.view.responseFields.length; i++) {
            this.responseViewFields.push(this.viewListResponse.view.responseFields[i]);
          }
          this.setGridColumnSize();
          this.fieldList = Object.assign([], this.staticFields);
          if (this.viewListResponse.view.responseFields) {
            for (let i = 0; i < this.fieldList.length; i++) {
              const index1 = this.viewListResponse.view.responseFields.map(function (e) {
                return e.key;
              }).indexOf(this.fieldList[i].key);
              if (index1 >= 0) {
                this.fieldList[i].default = true;
              } else {
                this.fieldList[i].default = false;
              }
            }
          }
          this.getFields();
          for (let i = 0; i < response.view.fields.length; i++) {
            const index = this.customFieldList.map(function (e) {
              return e.key;
            }).indexOf(response.view.fields[i].key);
            this.customFieldList[index].key = response.view.fields[i].key;
            this.customFieldList[index].value = response.view.fields[i].value;
            this.customFieldList[index].operation = response.view.fields[i].operation;
            this.customFieldList[index].isChecked = true;
          }

          this.crmContactService.selectedFilters$.next(this.customFieldList);
          this.crmContactService.eventEmit$.next('view-change');

          if (response.userList) {
            if (response.userList.length > 0) {
              this.userData.data = _.cloneDeep(response.userList);
              this.userData.total = response.count;
            }
          } else {
            this.userData.data = [];
            this.userData.total = 0;
          }
          this.enableFilter = false;
        }
      });
  }

  viewContact(data: any): void {
    this.dialog.open(ViewCrmContactComponent, this.appUtilService.getDialogDataFullScreen(data)).afterClosed()
      .subscribe((result) => {
        if (result) {
        }
      });
  }

  addEditContact(data?: any): void {
    if (data) {
      const crmId = CryptoJS.AES.encrypt(data.crmId, secretPhrase).toString();
      this.dialog.open(EditCrmContactDialogComponent, this.appUtilService.getDialogData(crmId)).afterClosed()
        .subscribe((result) => {
          if (result) {
            this.getDataFromSearchFields();
          }
        });
    } else {
      this.dialog.open(EditCrmContactDialogComponent, this.appUtilService.getDialogData(data)).afterClosed()
        .subscribe((result) => {
          if (result) {
            this.getDataFromSearchFields();
          }
        });
    }
  }

  getDataById(id, isView?: boolean, isDefaultChange?: boolean) {
    this.selectedCrmList = [];
    this.getByViewObj.id = id;
    if (isView) {
      this.getByViewObj.pageNumber = this.kendoPagination.skip / this.kendoPagination.pageSize + 1;
      this.getByViewObj.pageSize = this.kendoPagination.pageSize;
      this.webService.post(CrmContactConstants.API.contact.getById, this.getByViewObj)
        .subscribe((response) => {
          this.loaderService.hide();
          if (response.status.code === 200) {
            this.viewListResponse = response;
            if (isView) {
              if (response.userList) {
                if (response.userList.length > 0) {
                  this.userData.data = _.cloneDeep(response.userList);
                  this.userData.total = response.count;
                }
              } else {
                this.userData.data = [];
                this.userData.total = 0;
              }
            }
          } else {
            this.viewListResponse = null;
          }
        });
    }
  }

  updateContactView(): void {
    const dialogData: AddEditListDialogData = _.cloneDeep(this.viewListResponse.view);
    dialogData.title = 'CRM_CONTACT.ADD_VIEW_LIST_DIALOG.UPDATE_VIEW';
    dialogData.for = 'view';
    dialogData.isUpdate = true;
    dialogData.responseFields = this.responseViewFields;
    this.dialog.open(AddEditListDialogComponent, this.appUtilService.getDialogData(dialogData))
      .afterClosed().subscribe((res) => {
      if (res) {
        this.onSelectedViewListIdChange();
        this.getDataById(dialogData.id);
        this.getViewList();  // to get all the contacts list after adding the selected contact to list
      }
    });
  }

  deleteView(id: string): void {
    const data = this.appUtilService.getDeleteMsg();
    data.message = 'CRM_CONTACT.VIEW.DELETE_CONFIRM';
    this.dialog.open(PromtDialogComponent, this.appUtilService.getDialogData(data))
      .afterClosed().subscribe((result) => {
      if (result) {
        if (result.comment === this.viewListResponse.view.name) {
          this.loaderService.show();
          this.webService.delete(CrmContactConstants.API.contact.delete + id)
            .subscribe((response) => {
              this.loaderService.hide();
              if (response.status.code === 200) {
                this.enableFilter = false;
                this.toaster.showToast(response.status.message, 'success');
                this.getViewList();
                this.getDataFromSearchFields();
                this.viewListResponse = {};
                this.resetFilter();
                this.selectedViewListId = '';
              } else {
                this.toaster.showToast(response.status.message, 'error');
              }
            }, (() => {
              this.loaderService.hide();
            }));
        } else {
          this.toaster.showToast('View name not matched', 'error');
        }
      }
    });
  }

  public menuChange(event: MatCheckboxChange, menu: ResponseField, index: number) {
    if (event.checked) {
      menu.default = true;
      this.responseViewFields.push(this.fieldList[index]);
    } else {
      const obj = this.responseViewFields.find(x => x.key === this.fieldList[index].key);
      const resIndex = this.responseViewFields.indexOf(obj);
      this.responseViewFields.splice(resIndex, 1);
      menu.default = false;
    }
    this.setGridColumnSize();
    if (this.getUserDataByView.id) {
      if (this.viewListResponse.updateAccess) {
        this.updateColumns(this.viewListResponse.view);
      }
    }
  }

  getContactList(): void {
    this.loaderService.show();
    this.webService.get(CrmContactConstants.API.contact.getContactList)
      .subscribe((response: CrmListResponse) => {
        this.loaderService.hide();
        if (response.status.code === 200) {
          this.contactList = response.lists;
        }
      }, () => this.loaderService.hide());
  }

  getDescription() {
    return this.appUtilService.findObject(this.viewList, 'id', this.selectedViewListId).description;
  }

  getOperatorForApi(operation: string): string {
    let newOperator = operation;
    switch (operation) {
      case 'eq': // is  match
        newOperator = 'EQ';
        break;
      case 'neq': // is not match
        newOperator = 'NE';
        break;
      case 'contains':  // contains
        newOperator = 'CONTAINS';
        break;
      case 'doesnotcontain': // does not contain
        newOperator = 'NOTCONTAINS';
        break;
      case 'isempty': // is empty
        newOperator = 'EMPTY';
        break;
      case 'isnull': // is empty
        newOperator = 'NULL';
        break;
      case 'isnotempty': // is not empty
        newOperator = 'NOTEMPTY';
        break;
      case 'isnotnull': // is not empty
        newOperator = 'NOTNULL';
        break;
      case 'startswith':
        newOperator = 'STARTSWITH';
        break;
      case 'endswith':
        newOperator = 'ENDSWITH';
        break;
    }
    return newOperator;
  }
}
