export class ServerUrls {
  public static serverUrl: string;
  public static UI_URL: string = '';
  public static IS_NOFA: boolean = true;
  public static APPID: string;
  public static CEP_CONFIG: boolean = false;
  public static googleAnalyticsKey: string = '';
  public static TRANSLATE: string;
  public static USER_TYPE_LIST: UserTypeList[] = [];
  public static ADMIN_ROLES: string[] = [];
  public static STAFF_ROLES: string[] = [];
  public static VENDOR_ROLES: string[] = [];
  public static CITIZEN_ROLES: string[] = [];
  public static hideGlobalSearch: boolean = false;
  public static homepageLogoPaddingRemove: boolean = false;
  public static appSLAHour: number = 24;
  public static phoneNumberMask = '(000) 000-0000';
  public static contactNumberMask  = '000-000-0000'
  public static homepageButtonLink = '/view-all-services';
  public static GOOGLE_API_KEY: string = '';
  public static getCart: string = '/rest/payments/v1/cart';
}

interface UserTypeList {
  value: string;
  title: string;
}

/*same field need to change in src/app/core/app-setting.service.ts too. required changes*/
export const APP_SETTING: any = {
  COPY_RIGHT: ' <a target="_blank" href="http://www.3disystems.com/" class="mat-blue-theme ">3Di Systems</a> '
    + '© ' + (new Date()).getFullYear(),
  VERSION: '0.0.1',
  bannerLogoPath: '',
  appLogoPath: '',
  banner: '',
  title: '',
  language: [],
  appLocation: [33.9883785, -117.9359484],
  addressParamConfig: {
    addressSuggestion: {
      externalGIS: false,
      google: true
    }
  },
  hideAskAQuestion: false,
  basicProfile: true,
  enableNotification: true,
  notificationInterval: 300000
};

export class PaginationObject {
  skip: number = 0;
  take: number = 10;
}

export const URL_PATTERN = 'https?:\\/\\/[-a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,4}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)';
export const EMAIL_PATTERN = '[^@\\s]+@[^@\\s]+\\.[^@\\s]+';

export class AppParameterConfigProto {
  id: string;
  paramName: string;
  paramValue: string;
  paramType: string;
  language: string;
}
