import { DatePipe } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { UserService } from "app/core/user.service";
import { DeviceInfo, LogCampConstant, ServiceLogsRequestModel } from "./logcamp-constant";

// get application version from package.json
const appVersion = require('../../../../package.json').version;

@Injectable({
    providedIn: "root"
})

export class LogCampService {

    constructor(public httpClient: HttpClient,
        public datePipe: DatePipe, public userService: UserService) { }

    UploadServiceLog(url: string, requestString: string, responseString: string, httpCode: number, requestStartTime: string, responseRecievedTime: string) {

        const requestObj = this.generateLogCampRequestModel(url, requestString, responseString, httpCode, requestStartTime, responseRecievedTime);
        this.httpClient.post(LogCampConstant.API.logCampBaseURL + LogCampConstant.API.createlogCampJson, requestObj)
            .subscribe((response) => {

                if (response && response["status"]["code"] == 200 && httpCode !== 200) {
                    if (!url.endsWith(".json")) {
                        console.log("%cError log id: " + response["logId"] + ", http code : " + httpCode + ", Request URL: " + url, "font-size:20px;font-weigth:bold;")
                    }
                }
                else if (response && response["status"]["code"] != 200) {
                    console.log("%cError occured while sending the logs to LogCamp", "font-size:20px;font-weigth:bold;");
                }
            });
    }

    private generateLogCampRequestModel(url: string, requestString: string, responseString: string,
        httpCode: number, requestStartTime: string, responseRecievedTime: string): ServiceLogsRequestModel {

        let requestModel = new ServiceLogsRequestModel();

        let userInfo = this.userService.getUser();

        let deviceInfo = new DeviceInfo(requestStartTime, responseRecievedTime);
        deviceInfo.user = (userInfo ? (userInfo.firstName ? userInfo.firstName : "")  + (userInfo.lastName ?  " " + userInfo.lastName : "") : "");
        deviceInfo.userEmailID = (userInfo && userInfo.primaryEmailId ? userInfo.primaryEmailId : "");
        deviceInfo.userRoles = (userInfo && userInfo.userRole ? JSON.stringify(userInfo.userRole) : "");

        requestModel.appId = LogCampConstant.logCampAppInfo.logCampAppId;
        requestModel.appVersion = (appVersion ? appVersion : "");
        requestModel.dateTime = this.datePipe.transform(new Date(), LogCampConstant.logCampAppInfo.logCampDateFormat);
        requestModel.deviceId = (deviceInfo && deviceInfo.isMobileDevice ? "Mobile Device" : "Desktop Device") + (deviceInfo && deviceInfo.browserInfo ? ": " + deviceInfo.browserInfo : "" );
        requestModel.deviceInfo = JSON.stringify(deviceInfo);
        requestModel.errorCode = httpCode.toString();
        requestModel.functionSource = url;
        requestModel.request = requestString;
        requestModel.response = responseString;
        requestModel.requestStartTime = requestStartTime;
        requestModel.requestEndTime = responseRecievedTime;
        requestModel.status = (httpCode == 200 ? "Success" : "Failed");
        requestModel.source = "iOS";
        requestModel.user = (userInfo ? (userInfo.firstName ? userInfo.firstName : "")  + (userInfo.lastName ?  " " + userInfo.lastName : "") : "");

        return requestModel;
    }
}