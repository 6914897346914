import { Component, OnInit, Inject } from '@angular/core';
import { WIDGET_TYPES } from 'app/feature-modules/dashboard/dashboard-constant';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DrillDownWidgetDialogComponent } from 'app/feature-modules/dashboard/drill-down-widget-dialog/drill-down-widget-dialog.component';
import { WebService } from 'web-service';
import { DashboardService } from 'app/feature-modules/dashboard/dashboard.service';
import { ToasterService } from 'app/core/toaster/toaster.service';
import { LanguageService } from 'app/core/language/language.service';
import { LoaderService } from 'app/core/loader/loader.service';
import { AppUtilService } from 'app/core/app-util.service';

@Component({
  selector: 'app-drilldown-dialog',
  templateUrl: './drilldown-dialog.component.html',
  styleUrls: ['./drilldown-dialog.component.scss'],
})
export class DrilldownDialogComponent implements OnInit {
  public widgetConfig: any;
  public widgetName: string;
  public defaultWidgetData: string;
  public widgetTypeList = WIDGET_TYPES;
  public defaultColor = '#D3D3D3';

  constructor(public dialogRef: MatDialogRef<DrillDownWidgetDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public dialogData: any, public webService: WebService,
              public dashboardService: DashboardService,
              public toasterService: ToasterService, public languageService: LanguageService,
              public loader: LoaderService, public utilService: AppUtilService) {
  }

  ngOnInit() {
    this.widgetConfig = this.dialogData.widgetData;
    this.defaultWidgetData = this.widgetConfig.widgetType;
    this.widgetConfig.widgetType = this.dialogData.openWidgetType;
    this.widgetName = this.dialogData.widgetName;
  }

  getIcon(widgetType) {
    switch (widgetType) {
      case  this.widgetTypeList.bar:
        return 'icon-bar-chart';
      case  this.widgetTypeList.line:
        return 'icon-line-chart';
      case  this.widgetTypeList.pie:
        return 'icon-pie-chart';
      case  this.widgetTypeList.number:
        return 'icon-number-chart';
      case  this.widgetTypeList.grid:
        return 'icon-grid';
      case  this.widgetTypeList.map:
        return 'icon-map';

    }
  }

}
