import { Component, OnDestroy, OnInit } from '@angular/core';
import { MenuService } from '../menu.service';
import { MainMenuItem } from '../../layout-constants';
import { Subscription } from 'rxjs';
import {ServerUrls} from "../../../server-url-constants";

@Component({
  selector: 'portal-horizontal-menu',
  templateUrl: './horizontal-menu.component.html',
  styleUrls: ['./horizontal-menu.component.scss']
})
export class HorizontalMenuComponent implements OnInit, OnDestroy {

  public menuList: MainMenuItem[] = [];
  public subscription: Subscription;
  public isNOFA: boolean = ServerUrls.IS_NOFA;

  constructor(private menuService: MenuService) {
  }

  ngOnInit(): void {
    this.subscription = this.menuService.getQuickMenus().subscribe((response) => {
      this.menuList = response.filter(object => object.showInMenu);
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
