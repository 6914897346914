import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs';
import 'rxjs/add/operator/do';
import { OAuth2Service } from 'o-auth2';

@Injectable()
export class HttpApiInterceptor implements HttpInterceptor {

  private authService: OAuth2Service;

  constructor(private injector: Injector) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    setTimeout(() => {
      this.authService = this.injector.get(OAuth2Service);
      if (this.authService.isAuthenticated()) {
        localStorage.setItem('loginTime', JSON.stringify(new Date()));
      }
    });
    return next.handle(req);
  }
}
