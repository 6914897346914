import { Subscription } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { LayoutService } from '../layout.service';
import {ServerUrls} from "../../server-url-constants";

@Component({
    selector: 'portal-layout-classic',
    templateUrl: './layout-classic.component.html',
    styleUrls: ['./layout-classic.component.scss']
})
export class LayoutClassicComponent implements OnInit, OnDestroy {
    /**
     * Stores if left sidenav is open.
     */
    leftSidenavOpen: boolean;

    /**
     * Stores left sidenav display mode.
     */
    leftSidenavMode: string;

    /**
     * Stores media observable subscription.
     */
    mediaSubscription: Subscription;
    public sideNavPosition: string = 'start';
  public isNOFA: boolean = ServerUrls.IS_NOFA;


    constructor(private media: MediaObserver,
                public layoutService: LayoutService) {
    }

    ngOnInit(): void {
        // Get initial state of the sidenav.
        this.calculateSidenavStatus();

        // Subscribe to changes in screen size to change sidenav behavior.
      this.mediaSubscription = this.media.asObservable()
        .subscribe((change: MediaChange[]) => this.calculateSidenavStatus());
    }

    ngOnDestroy(): void {
        this.mediaSubscription.unsubscribe();
    }

    calculateSidenavStatus(): void {
        const isMobile = this.media.isActive('lt-md');
        // Close sidenav on mobile.
        this.leftSidenavOpen = !isMobile;
        // Make sidenav open over content on mobile.
        this.leftSidenavMode = isMobile ? 'over' : 'side';
    }
}
