import { Component, Input, OnChanges } from '@angular/core';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { LoaderService } from '../../../../../core/loader/loader.service';
import { WebService } from 'web-service';
import { CrmContactConstants } from '../crm-contact-constants';
import { ToasterService } from '../../../../../core/toaster/toaster.service';
import { AppUtilService } from '../../../../../core/app-util.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-crm-property-list',
  templateUrl: './crm-property-list.component.html',
  styleUrls: ['./crm-property-list.component.scss']
})
export class CrmPropertyListComponent implements OnChanges {
  @Input('crmId') crmId: any;
  public propertyImages: any[] = [];
  public properties: any[] = [];
  public propertyList: GridDataResult;
  public state: State = {
    skip: 0,
    take: 10
  };

  constructor(private loader: LoaderService,
              private appUtilService: AppUtilService,
              private toasterService: ToasterService,
              private webService: WebService,
              private router: Router) {
  }

  ngOnChanges(): void {
    if (this.crmId) {
      this.getPropertyList();
    }
  }

  /***
   * @Author : Sonal Afre (sonal.afre@kahunasystems.com)
   * @description :  gets property list from server
   * @return {void}
   */
  getPropertyList(): void {
    this.loader.show();
    this.propertyImages = [];
    this.webService.getWithParams(CrmContactConstants.API.getPropertyList, {ownerId: this.crmId})
      .subscribe((response) => {
        this.loader.hide();
        if (response.status.code === 200) {
          if (response.addressDetails) {
            this.properties = response.addressDetails;
            if (this.properties.length > 0) {
              this.propertyList = {
                data: this.properties.slice(this.state.skip, this.state.skip + this.state.take),
                total: this.properties.length
              };
            }
            this.properties.forEach((asset, index) => {
              this.propertyImages.push({image: []});
              if (asset.siteAddresses) {
                if (asset.siteAddresses.length > 0) {
                  for (let j = 0; j < asset.siteAddresses.length; j++) {
                    if (asset.siteAddresses[j].fullAddress) {
                      if (asset.siteAddresses[j].images) {
                        for (let i = 0; i < asset.siteAddresses[j].images.length; i++) {
                          if (asset.siteAddresses[j].images[i].imageUrl) {
                            this.propertyImages[index].image.push(asset.siteAddresses[j].images[i].imageUrl);
                          }
                        }
                      } else {
                        // this.propertyImages[index].image.push(this.appUtilService.getStreetViewImageFromAddress(asset.siteAddresses[j].fullAddress, '400x400'));
                      }
                    } else {
                      this.propertyImages[index].image = [];
                    }
                  }
                }
              } else {
                this.propertyImages[index].image = [];
              }
            });
          }
        } else {
          this.loader.hide();
          this.toasterService.showToast(response.status.message, 'error');
        }
      }, () => {
        this.loader.hide();
      });
  }

  /**
   * @author: Ganeshram Kumhar (ganeshram.kumhar@kahunasystems.com)
   * @description : to edit or view perticular property
   * @param :
   * @return {void}
   */
  redirectToPropertyView(id) {
    this.loader.show();
    this.router.navigate(['/case-engine/property', id]);
  }
}
