/**
 * Created by Isha Dhimar (isha.dhimar@kahunasystems.com) on 27/11/18.
 **/
export class GlobalSearchUrlConstants {
  public static API = {
    'searchContentCount': '/rest/uiengine/app/v1/searchcontentcount',
    'searchContentCountAll': '/rest/uiengine/app/v1/searchcontentcount',
    'searchResult': {
      'getResultList': '/rest/uiengine/app/v1/searchcontent',
      'getResultListAll': '/rest/globalsearch/v1/content_all'
    }
  };
}


export class ServicesConstants {
  public static SERVICES: string[] = [
    'CSD',
    'SRTYPE',
    'FAQ',
    'BLOG',
    'DMS',
    'APN',
    'ADDRESS',
    'CASE',
    'CRM'
  ];
}

export class SearchCountRequestProto {
  cityDirectory: string[];
  cityService: string[];
  keyword: string;
  language: string;
  searchText: string;
  searchField: string;
  pageNumber: number | string = 1;
  pageSize: number | string = 20;
}

export class GlobalSearchSearchResult {
  name: string;
  type: string;
  count: number;
  index: number;
}

export class GlobalSearchPagination {
  pageSize: number = 10;
  total: number;
  pageNumber: number = 1;
}



