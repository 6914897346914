import { NgModule } from '@angular/core';
import { CrmContactRoutingModule } from './crm-contact-routing.module';
import { CrmContactComponent } from './crm-contact/crm-contact.component';
import { SharedModule } from '../../../../shared/shared.module';
import { CustomKendoModule } from '../../../../shared/custom-kendo/custom-kendo.module';
import { CrmPropertyListComponent } from './crm-property-list/crm-property-list.component';
import { CrmCaseListComponent } from './crm-case-list/crm-case-list.component';
import { CrmNotesListComponent } from './crm-notes-list/crm-notes-list.component';
import { EditCrmContactDialogComponent } from './edit-crm-contact-dialog/edit-crm-contact-dialog.component';
import { AddAdditionalFieldDialogComponent } from './edit-crm-contact-dialog/add-additional-field-dialog/add-additional-field-dialog.component';
import { AddEditNotesDialogComponent } from './crm-notes-list/add-edit-notes-dialog/add-edit-notes-dialog.component';
import { OwlModule } from 'ngx-owl-carousel';
import { SaveViewDialogComponent } from './crm-contact/save-view-dialog/save-view-dialog.component';
import { AddCustomViewsComponent } from './crm-contact/add-custom-views/add-custom-views.component';
import { ViewCrmContactComponent } from './view-crm-contact/view-crm-contact.component';
import { PopoverModule } from 'ngx-popover';
import { CrmContactService } from './crm-contact.service';
import { CrmContactViewComponent } from './crm-contact/crm-contact-view/crm-contact-view.component';
import { CrmContactListComponent } from './crm-contact/crm-contact-list/crm-contact-list.component';
import { SaveListDialogComponent } from './crm-contact/save-list-dialog/save-list-dialog.component';
import { SendMailDialogComponent } from './crm-contact/send-mail-dialog/send-mail.component';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { CrmMailingListComponent } from './crm-contact/crm-mailing-list/crm-mailing-list.component';
import { SaveMailingListDialogComponent } from './crm-contact/save-mailing-list-dialog/save-mailing-list-dialog.component';
import { AddEditListDialogComponent } from './crm-contact/add-edit-list-dialog/add-edit-list-dialog.component';
import { PromtDialogModule } from '../../../../core/promt-dialog/promt-dialog.module';
import { AddUserMailingListDialogComponent } from './crm-contact/crm-mailing-list/add-user-mailing-list-dialog/add-user-mailing-list-dialog.component';
import { ShowMailHistoryComponent } from './crm-contact/crm-mailing-list/show-mail-history/show-mail-history.component';
import { ViewMailNotificationDialogComponent } from './crm-contact/crm-mailing-list/show-mail-history/view-mail-notification-dialog/view-mail-notification-dialog.component';

@NgModule({
  imports: [
    SharedModule,
    CustomKendoModule,
    PopoverModule,
    OwlModule,
    FroalaViewModule,
    PromtDialogModule,
    FroalaEditorModule,
    CrmContactRoutingModule
  ],
  declarations: [
    CrmContactComponent,
    CrmCaseListComponent,
    CrmNotesListComponent,
    AddCustomViewsComponent,
    SaveViewDialogComponent,
    ViewCrmContactComponent,
    CrmContactViewComponent,
    CrmContactListComponent,
    SaveListDialogComponent,
    SendMailDialogComponent,
    CrmMailingListComponent,
    ShowMailHistoryComponent,
    CrmPropertyListComponent,
    AddEditListDialogComponent,
    AddEditNotesDialogComponent,
    EditCrmContactDialogComponent,
    SaveMailingListDialogComponent,
    AddAdditionalFieldDialogComponent,
    AddUserMailingListDialogComponent,
    ViewMailNotificationDialogComponent
  ],
  entryComponents: [
    EditCrmContactDialogComponent,
    CrmMailingListComponent,
    AddAdditionalFieldDialogComponent,
    SendMailDialogComponent,
    AddEditNotesDialogComponent,
    SaveViewDialogComponent,
    ViewCrmContactComponent,
    SaveListDialogComponent,
    SaveMailingListDialogComponent,
    AddEditListDialogComponent,
    AddUserMailingListDialogComponent,
    ViewMailNotificationDialogComponent
  ],
  providers: [CrmContactService]
})
export class CrmContactModule {
  static dynamicComponentsMap = {
    ViewCrmContactComponent,
    CrmMailingListComponent
  };
}
