import { filter } from 'rxjs/operators';
import { NavigationStart, Router, RouterEvent } from '@angular/router';
import { Subscription } from 'rxjs';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatSidenav } from '@angular/material';
import { AppSettingDetailsProto, AppSettingService } from '../../../core/app-setting.service';
import { MainMenuItem } from '../../layout-constants';
import { MenuService } from '../menu.service';
import {ServerUrls} from "../../../server-url-constants";
import { LayoutService } from '../../layout.service';

@Component({
  selector: 'portal-vertical-icon-menu',
  templateUrl: './vertical-icon-menu.component.html',
  styleUrls: ['./vertical-icon-menu.component.scss']
})
export class VerticalIconMenuComponent implements OnInit, OnDestroy {
  /**
   * Import material sidenav so we can access open close functions.
   */
  @Input() sidenav: MatSidenav;
  routerSubscription: Subscription;
  public appSetting: AppSettingDetailsProto;
  private menuSubscriber: Subscription;
  private appSettingSubscriber: Subscription;
  public mainMenuList: MainMenuItem[] = [];
  public isNOFA: boolean = ServerUrls.IS_NOFA;

  /**
   * for funky menu
   * */
  @Input() isFunky: boolean = false;
  @Output('openMenu') public openMenu: EventEmitter<MainMenuItem> = new EventEmitter<MainMenuItem>();

  constructor(private router: Router, private appSettingService: AppSettingService,
              private menuService: MenuService, public layoutService: LayoutService) {
    this.routerSubscription = this.router.events
      .pipe(
        filter(event => event instanceof NavigationStart),
      )
      .subscribe((event: RouterEvent) => {
        if (this.sidenav && this.sidenav.mode === 'over' && this.sidenav.opened) {
          this.sidenav.close();
        }
      });
  }

  ngOnInit(): void {
    this.appSettingSubscriber = this.appSettingService.getObserver().subscribe((result) => {
      this.appSetting = result;
    });
    this.menuSubscriber = this.menuService.getMenus().subscribe((response: MainMenuItem[]) => {
      this.mainMenuList = this.menuService.menus;
    });
  }

  ngOnDestroy(): void {
    if (this.menuSubscriber) {
      this.menuSubscriber.unsubscribe();
    }
    if (this.appSettingSubscriber) {
      this.appSettingSubscriber.unsubscribe();
    }
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }
}
