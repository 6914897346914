import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { saveAs } from '@progress/kendo-file-saver';
import { geometry, fit, exportPDF, Group } from '@progress/kendo-drawing';
import {
  ChartComponent,
  LegendLabelsContentArgs,
} from '@progress/kendo-angular-charts';
import { WidgetChartConfiguration, FetchWidgetDataRequestPayloadProto, ParamProto } from 'app/feature-modules/dashboard/constants/dashboard-constant';
import { WebService } from 'web-service';
import { LanguageService } from 'app/core/language/language.service';
import { AppUtilService } from 'app/core/app-util.service';
import { MatDialog } from '@angular/material';
import { DatePipe } from '@angular/common';
import { AxisShowHideProto, BASE_DATE_UNIT, WIDGET_TYPES } from 'app/feature-modules/dashboard/dashboard-constant';
import { DrilldownDialogComponent } from 'app/feature-modules/bi-engine/drilldown-dialog/drilldown-dialog.component';

@Component({
  selector: 'app-charts',
  templateUrl: './charts.component.html',
  styleUrls: ['./charts.component.scss'],
})
export class ChartsComponent implements OnInit, OnChanges {

  @Input('gridsterCompItem') gridsterCompItem: any;
  @Input('data') data;
  @Input('axisShowHide') axisShowHide1;

  public isLoading: boolean = false;
  public showNoDataMessage: boolean = false;

  public widgetType = 'column';
  public chartConfig: WidgetChartConfiguration = new WidgetChartConfiguration();
  public requestPayload: FetchWidgetDataRequestPayloadProto = new FetchWidgetDataRequestPayloadProto();
  public axisShowHide = true;
  public style = 'step';
  public baseUnitType:string="months";
  public baseDateUnitConst = BASE_DATE_UNIT;
  public pivotDataType: string;
  public widgetTypeList = WIDGET_TYPES;



  // FOR PIE CHART MAKE AXIS FALSE
  public axis = {
    xAxis: true,
    yAxis: true,
    toolTip: true,
    legend: true,
    labels: true,
    showYAxiskeyValue: true,
    showXAxiskeyvalue: true,
  };

  constructor(public webService: WebService, public languageService: LanguageService,
    private utilService: AppUtilService, private dialog: MatDialog, private datePipe: DatePipe) { }
    ngOnChanges() {
      this.chartConfig.chartType=this.data.widgetType.toLowerCase();

      if(this.chartConfig.chartType=="bar"){
        this.chartConfig.chartType="column"
      }
      if (this.data.widgetType === 'Line') {
        delete this.requestPayload.filterParam
        if (this.data.lineFilterParam) {
          this.requestPayload.lineFilterParam = this.data.lineFilterParam;
        } else {
          const req = [{
            filterParam: [[]]
          }];
          this.requestPayload['lineFilterParam'] = req;
        }

      } else {
        this.requestPayload.timeInterval = this.data.timeInterval;
        this.requestPayload.startDate = this.data.startDate;
        this.requestPayload.endDate = this.data.endDate;
        this.requestPayload.filterParam = this.data.filterParam;
        this.requestPayload.widgetDataUrl = this.data.widgetDataUrl;
      }
      this.chartConfig.title.text= this.data.widgetName
      this.requestPayload.language = this.languageService.getDefaultLang();
      this.requestPayload.widgetType = this.data.widgetType;
      this.requestPayload.pivot = this.data.pivot;
      if (this.data.aggType) {
        this.requestPayload.aggType = this.data.aggType;
      }
      this.chartConfig.categoryAxis.baseUnit = 'months';
      this.axisShowHide = this.data.openWigdetsQuery && this.data.openWigdetsQuery.axisShowHide ? this.data.openWigdetsQuery.axisShowHide : new AxisShowHideProto();
      this.getChartData();
    }
    ngOnInit(): void {
      this.chartConfig.chartType=this.data.widgetType.toLowerCase();

      if(this.chartConfig.chartType=="bar"){
        this.chartConfig.chartType="column"
      }

      if (this.data.widgetType === 'Line') {
        delete this.requestPayload.filterParam
        if (this.data.lineFilterParam) {
          this.requestPayload.lineFilterParam = this.data.lineFilterParam;
        } else {
          const req = [{
            filterParam: [[]]
          }];
          this.requestPayload['lineFilterParam'] = req;
        }
      } else {
        this.requestPayload.timeInterval = this.data.timeInterval;
        this.requestPayload.startDate = this.data.startDate;
        this.requestPayload.endDate = this.data.endDate;
        this.requestPayload.filterParam = this.data.filterParam;
        this.requestPayload.widgetDataUrl = this.data.widgetDataUrl;
      }
      this.chartConfig.title.text= this.data.widgetName
      this.requestPayload.language = this.languageService.getDefaultLang();
      this.requestPayload.widgetType = this.data.widgetType;
      this.requestPayload.pivot = this.data.pivot;
      if (this.data.aggType) {
        this.requestPayload.aggType = this.data.aggType;
      }
      this.chartConfig.categoryAxis.baseUnit = 'months';
      this.axisShowHide = this.data.openWigdetsQuery && this.data.openWigdetsQuery.axisShowHide ? this.data.openWigdetsQuery.axisShowHide : new AxisShowHideProto();
      // this.resetRequestPayload = JSON.parse(JSON.stringify((this.requestPayload)));
    }
  calculatePageSize = (val: number): number => {
    return val * 2.8347;
  }

  PAGE_RECT = new geometry.Rect([0, 0], [this.calculatePageSize(210 - 20), this.calculatePageSize(297 - 20)]);

  public labelContent(args: LegendLabelsContentArgs): string {
    return `${args.dataItem.name} :  ${args.dataItem.data}`;
  }

  exportScaledChart = (chart: ChartComponent): void => {
    const visual = chart.exportVisual();
    const content = new Group();
    content.append(visual);
    fit(content, this.PAGE_RECT);
    this.exportElement(content);
  }

  exportElement = (element: any): void => {
    exportPDF(element, {
      paperSize: 'A4',
      margin: '1cm',
    }).then((dataURI) => {
      saveAs(dataURI, 'chart.pdf');
    });
  }
  getChartData(): void {

    this.isLoading = true;
    if(this.requestPayload.filterParam && this.requestPayload.filterParam.length==0){
      if (this.data.widgetType !== 'Line') {
      this.requestPayload.filterParam=[[]];
      }
    }
    this.webService.postFromUrl(this.data.widgetDataUrl, this.requestPayload)
      .subscribe((response) => {
        this.isLoading = false;
        this.showNoDataMessage = false;

        if (response.status.code === 200) {
          if(response.data.length > 0)
          {
            this.chartConfig.series.data=response.data
            this.pivotDataType = response.pivotDataType;
            if (response.pivotDataType !== 'Date') {
              this.chartConfig.categoryAxis.type = null;
            } else {
              this.chartConfig.categoryAxis.type = 'date';
              this.chartConfig.series.name = this.baseDateUnitConst[this.chartConfig.categoryAxis.baseUnit];
              this.chartConfig.series.data.map(p => {
                p["name"] = new Date(p["name"]);
                return p;
              });
            }
          }
          if (response.data.length === 0) {
            this.showNoDataMessage = true;
          }
        } else {
          this.showNoDataMessage = true;
        }
      }, () => {
        this.isLoading = false;
      });
  }



  onSeriesClick(event) {
    const newRequestPayload = JSON.parse(JSON.stringify(this.requestPayload));
    const selectedIdxs = [];
    for (let idx = 0; idx < newRequestPayload.filterParam[0].length; idx++) {
      if (newRequestPayload.filterParam[0][idx].fieldName === newRequestPayload.pivot) {
        selectedIdxs.push(idx);
        newRequestPayload.filterParam[0].splice(idx, 1);
        idx--;
      }
    }
    const newParamFilter = new ParamProto();
    let newParamFilter1: any;
    newParamFilter.fieldName = newRequestPayload.pivot;
    if (this.pivotDataType === 'Date') {
      newParamFilter1 = new ParamProto();
      const dateValue = this.datePipe.transform(event.dataItem.key, 'yyyy-MM-dd');
      newParamFilter.operation = 'gte';
      newParamFilter.value = dateValue;
      newParamFilter1.operation = 'lte';
      newParamFilter1.fieldName = newRequestPayload.pivot;
      newParamFilter1.value = dateValue;
    } else {
      newParamFilter.operation = 'is';
      newParamFilter.value = event.dataItem.key;
    }
    if (!newRequestPayload.filterParam.length) {
      newRequestPayload.filterParam = [];
    }
    if (!newRequestPayload.filterParam[0].length) {
      newRequestPayload.filterParam[0] = [];
    }
    newRequestPayload.filterParam[0].push(newParamFilter);
    if (this.pivotDataType === 'Date') {
      newRequestPayload.filterParam[0].push(newParamFilter1);
      newRequestPayload.aggType = this.chartConfig.categoryAxis.baseUnit.toUpperCase();
    }
    this.onShowChartDialog(newRequestPayload);
  }


  onShowChartDialog(widgetData) {

    const dialogData = {
      widgetData: JSON.parse(JSON.stringify(widgetData)),
      widgetName: widgetData.widgetName,
      openWidgetType: widgetData.widgetType === this.widgetTypeList.grid ? this.widgetTypeList.pie : this.widgetTypeList.grid

    };
    this.dialog.open(DrilldownDialogComponent, this.utilService.getDialogData(dialogData))
      .afterClosed()
      .subscribe((result) => {

      });
  }

  onChartTypeChange(type){
    // let yaxis=this.axisShowHide1['yAxis']
    // let xais=this.axisShowHide1['xAxis']
    // this.axisShowHide1['xAxis']=yaxis
    // this.axisShowHide1['yAxis']=xais
    this.chartConfig.chartType=type
  }

  // getHeight() {
  //   let height = this.gridsterCompItem ? (this.gridsterCompItem.elementRef.nativeElement.clientHeight - 160) : 500;
  //   if (this.pivotDataType === 'Date') {
  //     height = height - $('#base-unit').outerHeight(true);
  //   }
  //   return height;
  // }
}



