import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { BaseFilterCellComponent, FilterService } from '@progress/kendo-angular-grid';
import { Subject } from 'rxjs';
import { DateBaseConstants, NgxDrpOptions, PresetItem, Range } from 'kscommon-components';

@Component({
  selector: 'kendo-date-range-filter',
  templateUrl: './kendo-date-range-filter.component.html',
  styleUrls: ['./kendo-date-range-filter.component.scss']
})
export class KendoDateRangeFilterComponent extends BaseFilterCellComponent implements OnInit {


  @ViewChild('dateRangePicker') dateRangePicker: any;
  @Input() public displayFormat: any;
  @Input() public format: any;
  @Input() public filter: any;
  @Input() public valueField: string;
  @Input() public field: string;
  @Input() public defaultDays: number;
  public date: string = '';
  @Input() clearInputValue: Subject<any>;
  options: NgxDrpOptions;
  presets: Array<PresetItem> = [];

  constructor(filterService: FilterService, private changeDetectorRef: ChangeDetectorRef) {
    super(filterService);
  }

  public get selectedValue(): any {
    const filter = this.filterByField(this.valueField);
    return filter ? filter.value : null;
  }

  ngOnInit(): void {
    this.setupPresets();
    this.options = {
      presets: this.presets,
      displayFormat: this.displayFormat || DateBaseConstants.dateFormat || 'mediumDate',
      placeholder: 'Select to Search',
      format: this.format || 'mediumDate',
      applyLabel: 'Apply',
      calendarOverlayConfig: {
        shouldCloseOnBackdropClick: true,
      },
    };
    this.changeDetectorRef.detectChanges();
    if (this.defaultDays) {
      const defaultDays = this.setDefaultDays(this.defaultDays);
      this.updateDefaultDate({fromDate: defaultDays[0], toDate: defaultDays[1]});
    }

    if (this.filter && this.filter.filters && this.filter.filters.length > 0) {
      for (let i = 0; i < this.filter.filters.length; i++) {
        if (this.filter.filters[i].field === this.field) {
          this.options.range = {fromDate: new Date(), toDate: new Date()};
          this.updateDefaultDate({fromDate: this.filter.filters[i].value[0], toDate: this.filter.filters[i].value[1]});
          break;
        }
      }
    }
    if (this.clearInputValue) {
      this.clearInputValue.asObservable().subscribe(() => {
        this.resetFilter();
      });
    }
  }

  public updateRange(data: any): void {
    this.applyFilter(
      (data.fromDate === null && data.toDate === null) ?
        this.removeFilter(this.field) :
        this.updateFilter({
          field: this.field,
          operator: 'eq',
          value: [data.fromDate, data.toDate]
        })
    );
  }

  public updateDefaultDate(range: Range): void {
    if (range) {
      this.dateRangePicker.setDefaultDate(range);
      this.changeDetectorRef.detectChanges();
    }
  }

  public resetFilter(): void {
    const resetRange = {fromDate: null, toDate: null};
    if (this.defaultDays) {
      const range = this.setDefaultDays(this.defaultDays);
      resetRange.fromDate = range[0];
      resetRange.toDate = range[1];
    }
    this.updateDefaultDate(resetRange);
  }

  private backDate = (numOfDays: number): Date => {
    const customDate = new Date();
    return new Date(customDate.setDate(customDate.getDate() - numOfDays));
  };

  private setupPresets(): void {
    const today = new Date();
    const yesterday = this.backDate(1);
    const minus7 = this.backDate(7);
    const minus30 = this.backDate(30);
    const currMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
    const currMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    const lastMonthStart = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    const lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0);

    this.presets = [
      {presetLabel: 'Yesterday', range: {fromDate: yesterday, toDate: today}},
      {presetLabel: 'Last 7 Days', range: {fromDate: minus7, toDate: today}},
      {presetLabel: 'Last 30 Days', range: {fromDate: minus30, toDate: today}},
      {presetLabel: 'This Month', range: {fromDate: currMonthStart, toDate: currMonthEnd}},
      {presetLabel: 'Last Month', range: {fromDate: lastMonthStart, toDate: lastMonthEnd}}
    ];
  }

  private setDefaultDays(days: number = 90): any[] {
    const end = new Date();
    const start = this.backDate(days);
    return [start, end];
  }

  private updateDate(event: Date[]): void {
    if (!this.options.range) {
      this.options.range = new class DateRange implements Range {
        fromDate: Date;
        toDate: Date;
      };
    }
    this.options.range.fromDate = event[0];
    this.options.range.toDate = event[1];
    this.date = event[0] + '-' + event[1];
  }
}
