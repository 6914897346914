import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { WebService } from 'web-service';
import { MatDialog } from '@angular/material';
import { RegisterInfoDialogComponent } from './register-info-dialog/register-info-dialog.component';
import {
  AddressObj,
  CreateUserConstants,
  GetUserProfileConfig,
  GetUserRoles,
  PasswordCheckObject,
  PasswordPolicyMessageFlagProto,
  PasswordPolicyProto,
  RegisterRequestProto,
  ResendEmailRequestProto,
  UserContactRegisterRequest,
  UserProfileConfig
} from './create-user-constants';
import { PasswordErrorStates } from '../../../constants/app-constants';
import { LoaderService } from '../../../core/loader/loader.service';
import { AppUtilService } from '../../../core/app-util.service';
import { ToasterService } from '../../../core/toaster/toaster.service';


@Component({
  selector: 'app-create-user-form-field',
  templateUrl: './create-user-form-field.component.html',
  styleUrls: ['./create-user-form-field.component.scss']
})
export class CreateUserFormFieldComponent implements OnInit {

  @Output('registerSuccess') registerSuccess: EventEmitter<any> = new EventEmitter();
  public passwordError: PasswordErrorStates = new PasswordErrorStates();
  public cfm_password: string;
  public registerRequest: RegisterRequestProto = new RegisterRequestProto();
  public userContact: UserContactRegisterRequest = new UserContactRegisterRequest();
  public resendEmailObj: ResendEmailRequestProto = new ResendEmailRequestProto();
  public addressObj: AddressObj = new AddressObj();
  @ViewChild('createUserForm') public createUserForm: NgForm;

  /*if from Admin*/
  @Input('admin') public isAdmin: boolean = false;
  public userRoleList: any[] = [];
  public userSortRoleList: any[];

  /*check Validation*/
  public validForm: boolean = false;
  public pwdNotMatch: boolean = false;
  /*Show Resend button*/
  public showResendButton: boolean = false;
  public userProfileConfigList: UserProfileConfig = new UserProfileConfig();
  public passwordPolicy = new PasswordPolicyProto();
  public passwordPolicyMessageFlag = new PasswordPolicyMessageFlagProto();
  public fieldTextType: boolean;
  public fieldConfirmTextType: boolean;


  constructor(public webService: WebService, public toasterService: ToasterService,
              public loader: LoaderService, public appUtilService: AppUtilService,
              private matDialog: MatDialog) {
  }

  ngOnInit(): void {
    if (this.isAdmin) {
      this.getRoleList();
    }
    this.userContact.addressList.push(this.addressObj);
    this.getUserProfileConfig();
  }

  /**
   * @author: Ganeshram Kumhar (ganeshram.kumhar@kahunasystems.com)
   * @description : get all user role list if it is admin then only
   * @param :
   * @return {void}
   */
  getRoleList(): void {
    this.webService.get(CreateUserConstants.API.getRoleList).subscribe((response: GetUserRoles) => {
      if (response.status.code === 200) {
        this.userRoleList = response.userRoles;
        this.userSortRoleList = this.appUtilService.sortList(response.userRoles, 'roleName');
      }
    });
  }

  /**
   * @author: Hiral Akbari (hiral.akbari@kahunasystems.com)
   * @description : get user profile config
   * @param :
   * @return {void}
   */
  getUserProfileConfig(): void {
    this.loader.show();
    this.webService.get(CreateUserConstants.API.getUserProfileConfig)
      .subscribe((response: GetUserProfileConfig) => {
        if (response.status.code === 200) {
          this.loader.hide();
          if (response.config) {
            this.userProfileConfigList = response.config;
            this.passwordPolicy = response.config.checkPasswordParameter;
            this.passwordPolicyMessageFlag = response.config.passwordPolicyMessageFlag;
          }
        }
      }, () => {
        this.loader.hide();
      });
  }


  /**
   * @author: Ganeshram Kumhar (ganeshram.kumhar@kahunasystems.com)
   * @description : validating the password
   * @return {void}
   * @param password
   */
  validatePassword(password: any): void {
    if (this.cfm_password) {
      this.checkCfrmPassword();
    }
    this.validForm = this.registerRequest.userPassword !== this.cfm_password;
    const checkData: PasswordCheckObject = {
      passwordPolicy: this.passwordPolicy,
      userContact: this.userContact,
      passwordPolicyMessageFlag: this.passwordPolicyMessageFlag
    };
    this.passwordError = this.appUtilService.checkPasswordErrors(this.registerRequest.userPassword, checkData);
  }

  /**
   * @author: Ganeshram Kumhar (ganeshram.kumhar@kahunasystems.com)
   * @description : for check confirm password or password matched or not
   * @param :
   * @return {void}
   */
  checkCfrmPassword(): void {
    // this.registerRequest.userGroup.push(this.userGroup)
    if (this.registerRequest.userPassword === this.cfm_password) {
      this.validForm = false;
      this.pwdNotMatch = false;
    } else {
      this.validForm = true;
      this.pwdNotMatch = true;
    }
  }

  /***
   * @Author : Hiral Akbari (hiral.akbari@kahunasystems.com)
   * @description :  address auto complete from google
   * @return {void}
   */

  setAutoComplete(place: any): void {
    this.loader.show();
    this.userContact.addressList[0].adressLine = place.formatted_address;
    this.userContact.addressList[0].state = '';
    this.userContact.addressList[0].city = '';
    this.userContact.addressList[0].zip = '';
    this.userContact.addressList[0].coordinates = [];
    place.address_components.forEach((val) => {
      val.types.forEach((type) => {
        if (type === 'administrative_area_level_1') {
          this.userContact.addressList[0].state = val.long_name;
        }
        if (type === 'locality' || type === 'sublocality_level_1') {
          this.userContact.addressList[0].city = val.long_name;
        }
        if (type === 'postal_code') {
          if (val.long_name.match(/^[0-9]+$/) != null) {
            this.userContact.addressList[0].zip = val.long_name;
          }
        }
      });
      this.appUtilService.resizeWindow();
    });
    this.userContact.addressList[0].coordinates.push(place.geometry.location.lat(), place.geometry.location.lng());
    setTimeout(() => {
      this.loader.hide();
    }, 300);
  }


  /**
   * @author: Ganeshram Kumhar (ganeshram.kumhar@kahunasystems.com)
   * @description : create or regiter user
   * @param :
   * @return {void}
   */
  createUser(): void {
    this.registerRequest.userContact = this.userContact;
    this.registerRequest.userName = this.registerRequest.userContact.emailAddress;
    this.loader.show();
    let apiUrl = CreateUserConstants.API.citizenRegistration;
    if (this.isAdmin) {
      apiUrl = CreateUserConstants.API.adminRegistration;
    } else {
      this.registerRequest.userGroup.push('Citizen');
    }
    this.webService.post(apiUrl, this.registerRequest).subscribe((response) => {
      this.loader.hide();
      if (response.status.code === 200) {
        const data = this.appUtilService.getDialogData();
        data.disableClose = true;
        this.matDialog.open(RegisterInfoDialogComponent, data);
        this.resetForm();
        this.toasterService.showToast(response.status.message, 'success');
        this.registerSuccess.emit(true);
      } else {
        this.registerRequest.userGroup = [];
        if (!this.isAdmin) {
          if (response.status.code === 229) {
            this.resendEmailObj.userId = this.registerRequest.userContact.emailAddress;
            this.showResendButton = true;
          }
          if (response.status.code !== 233) {
            this.resetForm();
          }
          this.toasterService.showToast(response.status.message, 'error');
        } else {
          this.toasterService.showToast(response.status.message, 'error');
        }
      }
    }, () => {
      this.loader.hide();
    });
  }

  resetForm(): void {
    this.createUserForm.resetForm();
    this.registerRequest = new RegisterRequestProto();
    this.userContact = new UserContactRegisterRequest();
  }

  /**
   * @author: Ganeshram Kumhar (ganeshram.kumhar@kahunasystems.com)
   * @description : resend email
   * @param :
   * @return {void}
   */
  resendEmail(): void {
    this.loader.show();
    this.webService.post(CreateUserConstants.API.resendMail, this.resendEmailObj).subscribe((response) => {
      this.loader.hide();
      if (response.status.code === 200) {
        this.toasterService.showToast(response.status.message, 'success');
      } else {
        this.toasterService.showToast(response.status.message, 'error');
      }
    }, () => {
      this.loader.hide();
    });
  }
}
