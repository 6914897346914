import { NgModule } from '@angular/core';
import { Router, RouterModule, Routes } from '@angular/router';
import { LayoutCompactComponent } from './layouts/layout-compact/layout-compact.component';
import { LayoutToolbarComponent } from './layouts/layout-toolbar/layout-toolbar.component';
import { CommonCookieService } from 'kscookies';
import { LayoutTabbedComponent } from './layouts/layout-tabbed/layout-tabbed.component';
import { LayoutService } from './layouts/layout.service';
import { DefaultLayoutComponent } from './layouts/default-layout/default-layout.component';
import { LayoutCitizenComponent } from './layouts/layout-citizen/layout-citizen.component';
import { LayoutToolbarTabbedComponent } from './layouts/layout-toolbar-tabbed/layout-toolbar-tabbed.component';
import { LoginLayoutComponent } from './layouts/login-layout/login-layout.component';
import { PreloadingStrategyService } from './preloading-strategy.service';
import { LayoutClassicComponent } from './layouts/layout-classic/layout-classic.component';

const routes: Routes =
  [
    {
      path: '',
      redirectTo: 'homepage',
      pathMatch: 'full'
    },
    {
      path: 'homepage',
      loadChildren: 'app/feature-modules/homepage/homepage.module#HomepageModule'
    },
    {
      path: 'layout-module-lazy',
      loadChildren: 'app/layouts/layouts.module#LayoutsModule'
    },
    {
      path: 'common-components-lazy',
      loadChildren: 'app/feature-modules/common-components/common-components.module#CommonComponentModule'
    },
    {
      path: '',
      loadChildren: 'app/feature-modules/verify-user/verify-user.module#VerifyUserModule'
    },
    {
      path: '',
      loadChildren: 'app/feature-modules/verify-mailing-subscription/verify-mailing-subscription.module#VerifyMailingSubscriptionModule'
    },
    {
      path: '',
      component: LoginLayoutComponent,
      children: [
        {
          path: '',
          loadChildren: 'app/feature-modules/authentication/authentication.module#AuthenticationModule'
        },
        {
          path: '',
          loadChildren: 'app/feature-modules/nofa/mail-unsubscribe/mail-unsubscribe.module#MailUnsubscribeModule'
        }
      ]
    },
    {
      path: '',
      component: DefaultLayoutComponent,
      children: [
        {
          path: 'app-faq-list',
          loadChildren: 'app/feature-modules/common-components/faq-list/faq-list.module#FaqListModule'
        },
        {
          path: 'searchResult/:type/:keyword/:index',
          loadChildren: 'app/layouts/components/global-search/global-search.module#GlobalSearchModule'
        },
        {
          path: 'content',
          loadChildren: 'app/feature-modules/rich-content/published-rich-content/rich-content-details/rich-content-details.module#RichContentDetailsModule'
        },
        {
          path: 'round',
          loadChildren: 'app/feature-modules/nofa/nofa-rounds/nofa-rounds.module#NofaRoundsModule'
        }
      ]
    },
    {
      path: '',
      data: {
        base: true
      },
      component: LayoutCitizenComponent,
      children: [
        {
          path: 'faqs',
          loadChildren: 'app/feature-modules/common-components/faq-list/faq-list.module#FaqListModule'
        },
        {
          path: 'page-content/:id',
          loadChildren: 'app/feature-modules/dynamic-page/dynamic-page.module#DynamicPageModule'
        },
        {
          path: 'canned-homepages',
          loadChildren: 'app/feature-modules/homepage/canned-homepages/canned-homepages.module#CannedHomepagesModule'
        },
        {
          path: 'profile',
          loadChildren: 'app/feature-modules/authentication/authentication.module#AuthenticationModule'
        },
        {
          path: '', loadChildren: 'app/feature-modules/admin/admin.module#AdminModule'
        },
        {
          path: 'rich-content',
          loadChildren: 'app/feature-modules/rich-content/rich-content.module#RichContentModule'
        },
        {
          path: 'calendar',
          loadChildren: 'app/feature-modules/calendar/calendar.module#AppCalendarModule'
        },
        {
          path: 'search-result/:type/:keyword/:index',
          loadChildren: 'app/layouts/components/global-search/global-search.module#GlobalSearchModule'
        },
        {
          path: 'manage-archives',
          loadChildren: 'app/feature-modules/archives/archives.module#ArchivesModule'
        },
        {
          path: 'query',
          loadChildren: 'app/feature-modules/faq-queries/faq-queries.module#FaqQueriesModule'
        },
        {
          path: 'app',
          loadChildren: 'app/feature-modules/nofa/nofa.module#NofaModule'
        },
        {
          path: 'dashboard',
          loadChildren: 'app/feature-modules/dashboard/dashboard.module#DashboardModule'
        },
        {
          path: 'analytics',
          loadChildren: 'app/feature-modules/bi-engine/bi-engine/bi-engine.module#BiEngineModule'
        }
      ],
    },
    {
      path: 'error',
      loadChildren: 'app/feature-modules/error-pages/error-pages.module#ErrorPagesModule'
    },
    {
      path: '**',
      redirectTo: '/error/404'
    }
  ];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true, preloadingStrategy: PreloadingStrategyService})],
  exports: [RouterModule],
  entryComponents: [
    LayoutToolbarComponent,
    LayoutCompactComponent,
    LayoutTabbedComponent,
    LayoutClassicComponent,
    LayoutCitizenComponent,
    LayoutToolbarTabbedComponent
  ]
})
export class AppRoutingModule {
  constructor (private router: Router, private cookieService: CommonCookieService,
               private layoutService: LayoutService) {
    this.layoutService.getLayoutObservable().subscribe((layoutName) => {
      /**
       * This allows us to switch layouts using the layout switcher.
       */
      const baseRoute = this.router.config.find(route => route.data !== undefined && route.data.base === true);
      switch (this.cookieService.getCookie('portal-layout')) {
        case 'toolbar':
          baseRoute.component = LayoutToolbarComponent;
          break;
        case 'compact':
          baseRoute.component = LayoutCompactComponent;
          break;
        case 'tabbed':
          baseRoute.component = LayoutTabbedComponent;
          break;
        case 'classic':
          baseRoute.component = LayoutClassicComponent;
          break;
          case 'citizen':
          baseRoute.component = LayoutCitizenComponent;
          break;
        case 'toolbar-tabbed':
          baseRoute.component = LayoutToolbarTabbedComponent;
          break;
        default:
          // Do nothing.
          break;
      }
    });
  }
}
