import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { WebService } from 'web-service';
import { OpenWidgetService } from 'app/feature-modules/dashboard/open-widgets/open-widget.service';
import { AppUtilService } from 'app/core/app-util.service';

@Component({
  selector: 'app-number-chart',
  templateUrl: './number-chart.component.html',
  styleUrls: ['./number-chart.component.scss']
})
export class NumberChartComponent implements OnInit {

  //   public defaultColor = 'red';
  //   public color="red"
  //   public widgetDataObject = {
  //     color: 'blue',
  //     widgetName: 'Test',
  //     paletteWidgetData: 10000

  //   };
  //   public toggle = true;
  //   constructor() { }

  //   ngOnInit(): void {
  //   }
  //   toggleButton(): void{
  //     this.toggle = !this.toggle;
  //   }
  // }


  @Input()
  set onRefresh(value: any) {
    if (value) {
      this.getChartDetails(true);
    }
  }

  @Output('refreshSuccess') refreshSuccess: EventEmitter<any> = new EventEmitter();

  @Input('gridsterCompItem') gridsterCompItem: any;
  @Input('requestPayload') requestPayload: any;
  @Input('hideFilter') hideFilter: boolean = false;
  @Input('widgetCreate') widgetCreate: boolean = false;
  @Output('savePreference') savePreference: EventEmitter<any> = new EventEmitter();
  public data: any;
  public isLoading: boolean = false;
  public colors: string[] = ['green', 'yellow'];
  public chartSeries: any[] = [];
  public localRequestPayload: any = {};

  constructor(private webService: WebService, private openWidgetService: OpenWidgetService,
    public utilService: AppUtilService) {
  }

  ngOnInit(): void {
    // if (this.requestPayload.openWigdetsQuery) {
    //   this.localRequestPayload = JSON.parse(JSON.stringify(this.requestPayload));
    //   this.requestPayload = JSON.parse(JSON.stringify(this.localRequestPayload.openWigdetsQuery));
    //   if (this.localRequestPayload.widgetDataUrl) {
    //     this.requestPayload['widgetDataUrl'] = this.localRequestPayload.widgetDataUrl;
    //   }
    //   if (this.localRequestPayload.openWigdetParamUrl) {
    //     this.requestPayload['openWigdetParamUrl'] = this.localRequestPayload.openWigdetParamUrl;
    //   }
    // }
    if (this.requestPayload.widgetDataUrl) {
      this.getChartDetails();
    }
  }


  generateChartData(): void {
    if (this.data.chartResponse) {
      this.data.chartResponse.forEach((chartResponse) => {
        chartResponse.data.forEach((chartD, chartDIndex) => {
          if (chartDIndex === 0) {
            for (const key in chartD) {
              if (key !== 'name') {
                const temp = {
                  name: '',
                  data: 0
                };
                if (this.requestPayload.lookAndFeelList) {
                  const findObj = this.utilService.findObject(this.requestPayload.lookAndFeelList, 'data', key);
                  if (findObj) {
                    temp['color'] = findObj.color;
                  }
                }
                temp.name = key;
                temp['key'] = key;
                this.chartSeries.push(temp);
              }
            }
          }
          for (const key in chartD) {
            if (key !== 'name') {
              const findObject = this.utilService.findObject(this.chartSeries, 'key', key);
              if (findObject) {
                if (typeof chartD[key] === 'string') {
                  findObject.data += parseInt(chartD[key], 0);
                } else {
                  findObject.data += chartD[key];
                }
              }
            }
          }
        });
      });
    }
  }

  

  getChartDetails(refresh: boolean = false): void {
    this.data = undefined;
    this.isLoading = true;
    this.chartSeries = [];
    let request = JSON.parse(JSON.stringify(this.requestPayload));
    request['filterParam']=this.requestPayload.filterParam
    request['widgetType']=this.requestPayload.widgetType
    if (!this.openWidgetService.checkFilter(request.filter)) {
      delete request['filter'];
    }
    this.webService.postFromUrl(this.requestPayload.widgetDataUrl, request).subscribe((response) => {
      this.isLoading = false;
      if (response.status.code === 200) {
        this.data = response;
        this.chartSeries.push({name:request.widgetName,data:this.data.totalRecords})
        //this.generateChartData();
        if (refresh) {
          this.refreshSuccess.emit(false);
        }
      }
    }, () => {
      this.isLoading = false;
    });
  }

  // refreshData(event: any): void {
  //   Object.assign(this.requestPayload, event);
  //   if (this.requestPayload.widgetDataUrl) {
  //     this.getChartDetails();
  //   }
  // }

  
  // onSavePreference(event: any): void {
  //   this.savePreference.emit(event);
  // }
}

