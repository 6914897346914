import { Directive, ElementRef, Input, OnInit } from '@angular/core';

export enum FileContentTypes {
  pdf = 'application/pdf',
  png = 'image/png',
  jpeg = 'image/jpeg',
  xls = 'application/vnd.ms-excel',
  xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  doc = 'application/msword',
  docx = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
}

@Directive({
  selector: '[appFileIcon]'
})

export class FileIconDirective implements OnInit {

  @Input('appFileIcon') appFileIcon: FileContentTypes;
  @Input('height') height: number = 50;
  @Input('width') width: number = 70;

  constructor(private ele: ElementRef) {
  }

  ngOnInit(): void {
    let iconUrl: string;
    switch (this.appFileIcon) {
      case FileContentTypes.pdf:
        iconUrl = 'assets/img/file-thumbs/pdf.png';
        break;
      case FileContentTypes.png:
        iconUrl = 'assets/img/file-thumbs/image.png';
        break;
      case FileContentTypes.jpeg:
        iconUrl = 'assets/img/file-thumbs/image.png';
        break;
      case FileContentTypes.xls:
        iconUrl = 'assets/img/file-thumbs/xlsx.png';
        break;
      case FileContentTypes.xlsx:
        iconUrl = 'assets/img/file-thumbs/xlsx.png';
        break;
      case FileContentTypes.doc:
        iconUrl = 'assets/img/file-thumbs/docs.png';
        break;
      case FileContentTypes.docx:
        iconUrl = 'assets/img/file-thumbs/docs.png';
        break;
      default:
        iconUrl = 'assets/img/file-thumbs/file.png';
        break;
    }
    const imageEle = document.createElement('img');
    imageEle.src = iconUrl;
    imageEle.height = this.height;
    imageEle.width = this.width;
    this.ele.nativeElement.append(imageEle);
  }
}
