/**
 * Created by Ganeshram Kumhar (ganeshram.kumhar@kahunasystems.com)
 */
import { Pipe, PipeTransform } from '@angular/core';
import { CannedHomepageService } from './canned-homepage.service';

@Pipe({
  name: 'homePageTranslate'
})
export class HomePageTranslatePipe implements PipeTransform {
  constructor(private cannedHomepageService: CannedHomepageService) {
  }

  /***
   * @Author : Ganeshram Kumhar (ganeshram.kumhar@kahunasystems.com)
   * @description : used to get value for the translate key received from server.
   * @param: value {string} translate key
   * @returns: {string}
   */
  transform(value: string): string {
    const newValue = this.cannedHomepageService.getValue(value);
    return newValue || value;
  }

}
