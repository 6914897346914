import { NgModule } from '@angular/core';
import { AlertDialogComponent } from './alert-dialog.component';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
  imports: [SharedModule],
  declarations: [AlertDialogComponent],
  entryComponents: [AlertDialogComponent]
})
export class AlertDialogModule {
}
