/****** Created by shivani gajjar(shivani.gajjar@kahunasystems.com) 8/11/17 5:32 PM*******/
import {Directive, ElementRef, Input, OnDestroy, OnInit} from '@angular/core';
import {NgForm, NgModel} from '@angular/forms';

@Directive({
  selector: '[appRegisterModelToForm]'
})
export class RegisterModelToFormDirective implements OnInit, OnDestroy {
  public el: HTMLInputElement;

  @Input('formRef') public form: NgForm;
  @Input('modelRef') public model: NgModel;

  constructor(el: ElementRef) {
    this.el = el.nativeElement;
  }

  ngOnInit() {
    if (this.form && this.model) {
      setTimeout(() => {
        this.form.form.addControl(this.model.name, this.model.control);
      }, 500);
    }
  }

  public ngOnDestroy() {
    if (this.form && this.model) {
      this.form.removeControl(this.model);
    }
  }

  /*remove() {
   console.log(this.model.name)
   this.form.form.removeControl('otherField');
   }*/
}
