import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { NotificationService } from './notification.service';
import { Subscription } from 'rxjs';
import { ActionType, CrmInbox, NotificationDialogDataProto } from './notification.constants';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { AppUtilService } from '../../../core/app-util.service';
import { DynamicDialogComponent } from '../../../shared/component/dynamic-dialog/dynamic-dialog.component';
import { LayoutService } from '../../layout.service';
import { WebService } from 'web-service';
import { ToasterService } from 'app/core/toaster/toaster.service';
import { LoaderService } from 'app/core/loader/loader.service';

@Component({
  selector: 'portal-notification-sidenav',
  templateUrl: './notification-sidenav.component.html',
  styleUrls: ['./notification-sidenav.component.scss'],
})
export class NotificationSidenavComponent implements OnInit, OnDestroy {
  /**
   * Import material sidenav so we can access open close functions.
   */
  @Input() sidenav: MatSidenav;
  public componentClicked: string;
  public commentsList: any[] = [];
  public searchCommentText: any;
  private sidenavSubscription: Subscription;
  private subscriptionUnreadCount: Subscription;
  private badgeCount: number = 0;
  public searchKeyword: string;
  public sDate = new Date();
  public eDate = new Date();
  public notificationData;
  constructor (public notificationService: NotificationService, private router: Router,
               private webService: WebService, private loader: LoaderService, private toasterService: ToasterService, private matDialog: MatDialog, private utilService: AppUtilService, private layoutService: LayoutService) {
  }

  ngOnInit (): void {
    this.sDate.setFullYear(this.sDate.getFullYear() - 1);
    const today = new Date();
    this.eDate.setDate(today.getDate() + 1)

    this.notificationData = {
    startDate: this.sDate,
    endDate: this.eDate
    }
    this.sidenavSubscription = this.sidenav.openedChange.subscribe((response) => {
      this.getOrgComments();
      if (response) {
        this.notificationService.getUnreadNotificationsCount(this.notificationData);
        if (this.layoutService.getComponentClicked()) {
          this.componentClicked = this.layoutService.getComponentClicked();
        }
      } else {
        this.componentClicked = undefined;
      }
    });
     this.subscriptionUnreadCount = this.notificationService.getUnreadCount().subscribe((response) => {
      if (this.badgeCount < response) {
        this.badgeCount = response;
      }
    });


  }

  getOrgComments () {
    this.notificationService.getOrganizationComments().subscribe(response => {
      this.commentsList = response.comments;
      this.notificationService.unreadCommentCount = response.unreadCount;
    });
  }

  ngOnDestroy (): void {
    if (this.sidenavSubscription) {
      this.sidenavSubscription.unsubscribe();
    }
    if (this.subscriptionUnreadCount) {
      this.subscriptionUnreadCount.unsubscribe();
    }
    if (this.notificationService.crmInboxes) {
      this.notificationService.crmInboxes = [];
    }
  }

  onPageChange (pageNumber): void {
    this.notificationService.getNotifications(pageNumber, this.searchKeyword);
  }

  onNotificationClick (item: CrmInbox) {
    if (item.viewed) {
      this.performActions(item);
    } else {
      this.markRead(item);
    }
  }

  markRead (item: CrmInbox) {
    this.notificationService.markRead(item).subscribe((response) => {
      item.viewed = true;
      this.notificationService.getUnreadNotificationsCount(this.notificationData);
      this.performActions(item);
    });
  }

  onMarkAsRead (comment, event?) {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    if (!comment.read) {
      this.webService.get('/rest/round/comment/read/v1/mark_as_read/' + comment.commentId).subscribe((response) => {
        if (response.status.code === 200) {
          comment.read = true;
          //this.createNofaRoundService.getApplicationSummaryComments(this.applicationId);
          //this.notificationService.getOrganizationComments();
          this.getOrgComments();
        }
      });
    }
  }

  onMarkAsUnRead (comment, event?) {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    this.webService.get('/rest/round/comment/read/v1/mark_as_unread/' + comment.commentId).subscribe((response) => {
      if (response.status.code === 200) {
        comment.read = false;
        this.getOrgComments();
      }
    });
  }


  markAllRead (item: CrmInbox) {
    this.notificationService.markAllRead().subscribe();
  }

  performActions (item: CrmInbox) {
    switch (item.eventDetails.action) {
      case ActionType.DIALOG:
        const dialogData = <NotificationDialogDataProto>{
          notificationData: {
            module: item.module
          },
          moduleData:
            {
              modulePath: item.eventDetails.url,
              componentName: item.eventDetails.componentName
            },
          parameters: []
        };

        for (const handlerObjectKey in item.handlerObject) {
          if (item.handlerObject.hasOwnProperty(handlerObjectKey)) {
            dialogData.notificationData[handlerObjectKey] = item.handlerObject[handlerObjectKey];
            dialogData.parameters.push({key: handlerObjectKey, data: item.handlerObject[handlerObjectKey]});
          }
        }

        const params = [];
        for (const handlerObjectKey in item.handlerObject) {
          if (item.handlerObject.hasOwnProperty(handlerObjectKey)) {
            dialogData.notificationData[handlerObjectKey] = item.handlerObject[handlerObjectKey];
            params.push({
              key: handlerObjectKey,
              data: item.handlerObject[handlerObjectKey]
            });
          }
        }
        dialogData.parameters.push({
          key: 'notificationData',
          data: dialogData.notificationData
        }, {
          key: 'parameters',
          data: params
        });
        if (item.eventDetails.isFullScreenDialog) {
          this.matDialog.open(DynamicDialogComponent, this.utilService.getDialogDataFullScreen(dialogData));
        } else {
          this.matDialog.open(DynamicDialogComponent, this.utilService.getDialogData(dialogData));
        }
        break;
      case ActionType.EXTERNAL_REDIRECT:
        window.open(item.eventDetails.url, '_blank');
        break;
      case ActionType.INTERNAL_REDIRECT:
        let url;
        if(item.handlerObject.hasOwnProperty('roundId')) {
          url = item.eventDetails.url + '/' + item.handlerObject['roundId'];
        } else if(item.handlerObject.hasOwnProperty('appId')) {
          url = item.eventDetails.url + '/' + item.handlerObject['appId'];
        }
        this.router.navigate([url]);
        break;
      default:
        const queryParams = {};
        for (const handlerObjectKey in item.handlerObject) {
          if (item.handlerObject.hasOwnProperty(handlerObjectKey)) {
            queryParams[handlerObjectKey] = item.handlerObject[handlerObjectKey];
          }
        }
        this.router.navigate([item.eventDetails.url, queryParams]);
        break;
    }
    this.sidenav.close();
  }

  searchCommentList () {
    const result = this.commentsList.filter(list => list.comment === this.searchCommentText);
    this.commentsList = result;
  }

  searchComment () {
    setTimeout(() => {
      const interval = setInterval(() => {
        if ($('#commentsearchBox2').length !== 0) {
          $('.slideRightToLeft').css('margin-top', '0%');
          clearInterval(interval);
        }
      }, 100);

    }, 0);
  }

  closeSearch ($event: MouseEvent) {
    this.getOrgComments();
    this.searchCommentText = '';
    event.stopPropagation();
    $('.slideRightToLeft').css('margin-top', '-22%');
  }
  onSearch() {
    this.notificationService.getNotifications(null, this.searchKeyword);
  }

  clearFilter() {
    this.searchKeyword = '';
    this.notificationService.getNotifications();
  }

  // deleteNotification(id) {
  //   this.loader.show();
  //   this.webService.delete('/rest/crm/inbox/v1/delete_inboxes/' + id).subscribe((response) => {
  //     this.loader.hide();
  //     if (response.status.code === 200) {
  //       this.toasterService.showToast('Notification Deleted Successfully', 'success');
  //       this.notificationService.getUnreadNotificationsCount(this.notificationData);
  //       this.notificationService.removeItem(id);
  //     } else {
  //       this.toasterService.showToast(response.status.message, 'error');
  //     }
  //   }, () => {
  //     this.loader.hide();
  //   });
  // }

  deleteNotification(item) {
    this.loader.show()
    this.notificationService.deleteNotification(item).subscribe(response => {
      // console.log('response is : ',response);
      if (response) {
        this.toasterService.showToast('Notification Deleted Successfully', 'success');
        this.notificationService.getUnreadNotificationsCount(this.notificationData);
        this.loader.hide();
      } else {
        // this.toasterService.showToast(response.status.message, 'error');
      }
    });
  }
}
