import { ResponseStatusWrapper } from '../../../constants/app-constants';
import { ModuleDataModelProto, ParameterModelProto } from 'kscommon-components';

export class NotificationConstants {
  public static API: any = {
    notification: {
      getUnreadCount: '/rest/crm/inbox/v1/unread_count',
      getUnreadCountForTime:'/rest/crm/inbox/v1/unread_count_for_time',
      get_inboxes: '/rest/crm/inbox/v1/get_inboxes',
      delete: '/rest/crm/inbox/v1/delete_inboxes/',
      get_org_comments: '/rest/round/comment/v1/org/get/by_entityIds'
    },
    notificationStatus: {
      mark_as_unread: '/rest/crm/inbox/v1/mark_as_unread/',
      mark_as_read: '/rest/crm/inbox/v1/mark_as_read/',
      mark_all_read: '/rest/crm/inbox/v1/mark_all_read',
      delete_inbox_status: '/rest/crm/inbox/v1/delete_inbox_status/'
    }
  };
}

export enum ActionType {
  'DIALOG' = 'POPUP',
  'INTERNAL_REDIRECT' = 'INTERNAL_STATE',
  'EXTERNAL_REDIRECT' = 'REDIRECT'
}

export interface GetUnreadNotificationsCountResponse {
  status: ResponseStatusWrapper;
  totalRecord: number;
}

export interface NotificationResponseProto {
  status: ResponseStatusWrapper;
  crmInboxes: CrmInbox[];
  totalRecord: number;
}

export interface CrmInbox {
  id: string;
  content: string;
  sourceId: string;
  toId: string;
  subject: string;
  module: string;
  handlerObject: Object;
  eventDetails: EventDetails;
  dateTime: string;
  firstName: string;
  lastName: string;
  viewed: boolean;
  deleted: boolean;
}

interface EventDetails {
  action: ActionType;
  isFullScreenDialog?: boolean;
  keys?: ParamValues[];
  componentName?: string;
  url?: string;
  params: Object;
}

interface ParamValues {
  paramKey: string;
  paramValue: string;
}

export interface DeleteNotificationResponse {
  deleted: boolean;
  status: ResponseStatusWrapper;
}

export interface NotificationStatusResponse {
  status: ResponseStatusWrapper;
}


export interface NotificationDialogDataProto {
  notificationData: NotificationDataProto;
  moduleData: ModuleDataModelProto;
  parameters: ParameterModelProto[];
}

interface NotificationDataProto {
  module: string;
  organizationCrmId: string;
  requestorCrmId: string;
  invitedUserCrmId: string;
  requestId: string;
  inviteUserRequestId: string;
  joinOrganizationRequestId: string;
}
