import { OnDestroy } from '@angular/core';
import { MenuService } from '../../../layouts/components/menu.service';

export class RightMaskHandler implements OnDestroy {

  public rightMask: string[] = [];
  // public rightMask: string[] = ['CR', 'ED', 'DL', 'APR', 'VW'];
  public RIGHT_MASK_LIST = {
    create: 'CR',
    edit: 'ED',
    delete: 'DL',
    approve: 'APR',
    submit: 'SUB',
    transition: 'TRANS',
    view: 'VW'
  };
  private subscribe: any;

  constructor(public menuService: MenuService) {
  }

  public getRightMask(rightKey: string): void {
    if (this.menuService.getRightMaskForKey(rightKey)) {
      this.rightMask = this.menuService.getRightMaskForKey(rightKey);
    } else {
      this.subscribe = this.menuService.getRightMask().subscribe(() => {
        if (this.menuService.getRightMaskForKey(rightKey)) {
          this.rightMask = this.menuService.getRightMaskForKey(rightKey);
          this.unsubscribeObservable();
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.unsubscribeObservable();
  }

  private unsubscribeObservable(): void {
    if (this.subscribe) {
      this.subscribe.unsubscribe();
    }
  }
}
