import { Component, HostListener, Input, OnInit } from '@angular/core';
import { RightMaskHandler } from '../../../../../../shared/classes/right-mask-handler/right-mask-handler';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import {
  ConfigObj,
  ContactListResponse,
  CrmContactConstants,
  excelExport,
  GetByViewObj,
  GetDataReqProto,
  KendoPaginationProto,
  SearchContent,
  ViewSaveRequest
} from '../../crm-contact-constants';
import { WebService } from 'web-service';
import { ToasterService } from '../../../../../../core/toaster/toaster.service';
import { LoaderService } from '../../../../../../core/loader/loader.service';
import { AppUtilService } from '../../../../../../core/app-util.service';
import { MatDialog, MatDialogRef } from '@angular/material';
import { MenuService } from '../../../../../../layouts/components/menu.service';
import { SendMailDialogComponent } from '../send-mail-dialog/send-mail.component';
import { saveAs } from 'file-saver';
import { ConfirmDialogComponent } from '../../../../../../core/confirm-dialog/confirm-dialog.component';
import { SaveMailingListDialogComponent } from '../save-mailing-list-dialog/save-mailing-list-dialog.component';
import { PromtDialogComponent } from '../../../../../../core/promt-dialog/promt-dialog.component';
import { State } from '@progress/kendo-data-query';
import * as _ from 'lodash';
import { AddUserMailingListDialogComponent } from './add-user-mailing-list-dialog/add-user-mailing-list-dialog.component';
import { Router } from '@angular/router';
import { ResponseFieldResponse } from '../types/response-field';
import { Subject } from "rxjs";

@Component({
  selector: 'app-crm-mailing-list',
  templateUrl: './crm-mailing-list.component.html',
  styleUrls: ['./crm-mailing-list.component.scss']
})
export class CrmMailingListComponent extends RightMaskHandler implements OnInit {

  @Input('pageTitle') pageTitle: string;
  public viewListItems: any[] = [];
  public listUserData: GridDataResult = { 'data': [], 'total': 0 };
  public getByViewObj: GetByViewObj = new GetByViewObj();
  public kendoPagination: KendoPaginationProto = new KendoPaginationProto();
  public searchListDataPagObj: GetDataReqProto = new GetDataReqProto();
  public listResponseData;
  public viewSaveRequest = new ViewSaveRequest();
  public fieldSortList: any[];
  public userListField: any[] = [];
  public staticFields: any[];
  public removeCrmList: any[] = [];
  public listId: string = '';
  public searchContent: SearchContent = new SearchContent();
  public searchState: State = {
    filter: {
      logic: 'and',
      filters: []
    },
    sort: []
  };
  public excelObj = new excelExport();
  private dialogRef: MatDialogRef<SendMailDialogComponent>;
  public innerWidth: number = 280;


  constructor(private webService: WebService,
    private toasterService: ToasterService,
    private loaderService: LoaderService,
    private appUtilService: AppUtilService,
    private router: Router,
    private dialog: MatDialog, public menuService: MenuService,
    public toaster: ToasterService) {
    super(menuService);
  }

  @HostListener('window:keyup.esc') onKeyUp() {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  ngOnInit() {
    this.getRightMask('manage-mailing-list');
    this.getDefaultColumns();
    this.getMailingList();
  }

  sendMail(viewListId) {
    const data = {
      view: viewListId,
      crmList: this.removeCrmList,
      listId: viewListId
    };
    const dialogData = this.appUtilService.getDialogData(data);
    dialogData.disableClose = true;
    this.dialogRef = this.dialog.open(SendMailDialogComponent, dialogData);
  }

  showHistory(id) {
    this.router.navigate(['/crm/contact/history/', id]);
  }

  addList() {
    // const dialogdata = {};
    const dialogData: any = {};
    dialogData.fromAllContacts = true;
    dialogData.isUpdate = false;
    dialogData.config = new ConfigObj();
    dialogData.crmList = [];
    this.dialog.open(SaveMailingListDialogComponent, this.appUtilService.getDialogData(dialogData))
      .afterClosed().subscribe((res) => {
        if (res) {
          this.getMailingList();
        }
      });
  }


  public exportToExcel(id: any): void {
    const d = JSON.stringify({ id: this.listId });
    const data = {
      moduleName: 'userOrgDetails',
      additionalInfo: {
        isList: true,
        request: d
      },
      fileType: 'EXCEL'
    };
    this.webService.downloadCertificatePost(CrmContactConstants.API.contact.export,
      data, 'application/json')
      .subscribe((response) => {
        const blob = new Blob([response], { type: 'application/vnd.ms-excel' });
        saveAs(blob, `${this.listResponseData.list.name}.xlsx`);
      });
  }

  onDataStateChange(event: DataStateChangeEvent | any) {
    this.searchState = event;

    this.kendoPagination.pageSize = event.take;
    this.kendoPagination.pageNumber = event.skip;

    this.searchListDataPagObj.fields = [];
    this.searchListDataPagObj.sortFields = [];

    if (event.filter && event.filter.filters && event.filter.filters.length >= 1) {
      event.filter.filters.forEach((object) => {
        this.searchListDataPagObj.fields.push({
          key: object.field,
          value: object.value,
          operation: this.getOperatorForApi(object.operator)
        });
      });
    }
    event.sort.forEach((object: any) => {
      if (object.dir !== undefined) {
        this.searchListDataPagObj.sortFields.push({
          'key': object.field, 'sortOrder': object.dir.toUpperCase()
        });
      }
    });
    if (this.searchContent.searchText) {
      this.getSearchedData();
    } else {
      this.getQueryData();
    }
  }

  getSearchedData() {
    if (this.searchContent.searchText === '') {
      this.kendoPagination.pageSize = 10;
      this.kendoPagination.pageNumber = 0;
      if (this.listId) {
        this.getDataById(this.listId);
      }
    } else {
      this.searchContent.id = this.listId;
      this.searchContent.pageNumber = this.kendoPagination.pageNumber / this.kendoPagination.pageSize + 1;
      this.searchContent.pageSize = this.kendoPagination.pageSize;
      this.searchContent.responseFields = this.userListField;
      this.webService.post(CrmContactConstants.API.contact.list.search, this.searchContent)
        .subscribe((response) => {
          if (response.status.code === 200) {
            this.listUserData.data = _.cloneDeep(response.userList);
            this.listUserData.total = response.count;
            // this.getDefaultColumns();
          }
        }, () => this.loaderService.hide());
    }
  }

  getQueryData() {
    this.searchListDataPagObj['id'] = this.listId;
    this.searchListDataPagObj.pageNumber = this.kendoPagination.pageNumber / this.kendoPagination.pageSize + 1;
    this.searchListDataPagObj.pageSize = this.kendoPagination.pageSize;
    this.webService.post(CrmContactConstants.API.contact.list.query, this.searchListDataPagObj)
      .subscribe((response) => {
        if (response.status.code === 200) {
          this.listUserData.data = _.cloneDeep(response.userList);
          this.listUserData.total = response.count;
          // this.getDefaultColumns();
        }
      }, () => this.loaderService.hide());
  }

  getDefaultColumns() {
    this.webService.get(CrmContactConstants.API.contact.getDefaultColumns)
      .subscribe((response: ResponseFieldResponse) => {
        if (response.status.code === 200) {
          /* response.responseFields = [];
           if (response.crmFields) {
             response.responseFields = response.responseFields.concat(response.crmFields);
           }*/
          this.staticFields = [...response.responseFields];
          this.fieldSortList = [...response.responseFields];
          this.userListField = this.fieldSortList.filter(object => object.default);
        }
      }, (err) => {
      });
  }

  deleteView(id: string): void {
    const data = this.appUtilService.getDeleteMsg();
    data.message = 'CRM_CONTACT.LIST.DELETE_CONFIRM';
    this.dialog.open(PromtDialogComponent, this.appUtilService.getDialogData(data))
      .afterClosed().subscribe((result) => {
        if (result) {
          if (result.comment === this.listResponseData.list.name) {
            this.loaderService.show();
            this.webService.delete(CrmContactConstants.API.contact.deleteList + id)
              .subscribe((response) => {
                this.loaderService.hide();
                if (response.status.code === 200) {
                  this.toaster.showToast(response.status.message, 'success');
                  this.getMailingList();
                  this.listUserData.data = [];
                  this.listUserData.total = 0;
                  this.listId = '';
                } else {
                  this.toaster.showToast(response.status.message, 'error');
                }
              }, (() => {
                this.loaderService.hide();
              }));
          } else {
            this.toaster.showToast('List name not matched', 'error');
          }
        }
      });
  }


  public menuChange(menu: any, event: any, i: number) {
    if (event.checked) {
      menu.default = true;
      this.userListField.push(this.fieldSortList[i]);

    } else {
      // const index1 = this.userListField.indexOf(this.fieldList[i].key);
      const index1 = this.userListField.map(function (e) {
        return e.key;
      }).indexOf(menu.key);
      if (index1 >= 0) {
        this.userListField.splice(index1, 1);
      }
      menu.default = false;
    }
  }

  onAddContactsToList(isUpdate: boolean): void {
    let dialogData: any = {};
    dialogData = this.listResponseData.list;
    if (isUpdate) {
      dialogData = { ...this.listResponseData.list };
      dialogData.isUpdate = isUpdate;
    }
    if (this.removeCrmList.length > 0) {
      dialogData.config.crmIds = this.removeCrmList;
    } else {
      dialogData.config.crmIds = [];
    }
    this.dialog.open(SaveMailingListDialogComponent, this.appUtilService.getDialogData(dialogData))
      .afterClosed().subscribe((res) => {
        if (res) {
          if (isUpdate) {
            this.getDataById(dialogData.id);
          }
          this.getMailingList();
        }
      });
  }


  getMailingList(): void {
    this.loaderService.show();
    this.webService.get(CrmContactConstants.API.contact.getMailinglist)
      .subscribe((response: ContactListResponse) => {
        this.loaderService.hide();
        if (response.status.code === 200) {
          this.viewListItems = response.lists.filter(object => object.updateAccess);
          if (this.viewListItems && this.viewListItems.length > 0) {
            const defaultList = this.viewListItems.find((list) => {
              return list.mailingListConfig && list.mailingListConfig.defaultList;
            });
            if (defaultList) {
              this.listId = defaultList.id;
            } else {
              this.listId = this.viewListItems[0].id;
            }
            this.getDataById(this.listId);
          }
        }
      }, () => this.loaderService.hide());
  }

  removeContactChangeEvent(event: any): void {
    event.selectedRows.forEach((v, i) => {
      this.removeCrmList.push(v.dataItem.crmId);
    });
    event.deselectedRows.forEach((v, i) => {
      this.removeCrmList.forEach((id, k) => {
        if (id === v.dataItem.crmId) {
          this.removeCrmList.splice(k, 1);
        }
      });
    });
  }


  getDataById(id?: any) {
    if (id) {
      this.getByViewObj.id = id;
    }
    this.getByViewObj.pageNumber = this.kendoPagination.pageNumber / this.kendoPagination.pageSize + 1;
    this.getByViewObj.pageSize = this.kendoPagination.pageSize;
    this.webService.post(CrmContactConstants.API.contact.getByIdList, this.getByViewObj)
      .subscribe((response) => {
        this.loaderService.hide();
        if (response.status.code === 200) {
          this.listResponseData = response;
          if (response.list.mailingListConfig) {
            this.viewSaveRequest.mailingListConfig = response.list.mailingListConfig;
          } else {
            delete this.viewSaveRequest.mailingListConfig;
          }
          if (response.userList) {
            if (response.userList.length > 0) {
              this.listUserData.data = [...response.userList];
              this.listUserData.total = response.count;
            }
          } else {
            this.listUserData.data = [];
            this.listUserData.total = 0;
          }
        }
      });
  }


  removeContactsToList(): void {
    const dialogData = this.appUtilService.getDialogData(this.appUtilService.getDeleteMsg());
    dialogData.data = {
      'title': 'CRM_CONTACT.LIST.REMOVE_USER_CONFIRMATION.TITLE',
      'message': 'CRM_CONTACT.LIST.REMOVE_USER_CONFIRMATION.MESSAGE'
    };
    this.dialog.open(
      ConfirmDialogComponent,
      dialogData).afterClosed().subscribe((result) => {
        if (result) {
          this.loaderService.show();
          this.viewSaveRequest = new ViewSaveRequest();
          this.viewSaveRequest.name = this.listResponseData.list.name;
          this.viewSaveRequest.config = this.listResponseData.list.config;
          if (this.listResponseData.list.mailingListConfig) {
            this.viewSaveRequest.mailingListConfig = this.listResponseData.list.mailingListConfig;
          } else {
            delete this.viewSaveRequest.mailingListConfig;
          }
          delete this.viewSaveRequest.description;
          delete this.viewSaveRequest.fields;
          delete this.viewSaveRequest.removeFromList;
          this.viewSaveRequest.crmList = this.removeCrmList;
          this.viewSaveRequest.id = this.listResponseData.list.id;
          this.viewSaveRequest.removeFromList = true;
          this.webService.put(CrmContactConstants.API.contact.updateList, this.viewSaveRequest)
            .subscribe((response) => {
              this.loaderService.hide();
              if (response.status.code === 200) {
                this.toaster.showToast(response.status.message, 'success');
                this.getDataById(this.listId);
                this.removeCrmList = [];
              } else {
                this.toaster.showToast(response.status.message, 'error');
              }
            }, () => this.loaderService.hide());
        }
      });
  }

  resetListFilter(): void {
    this.listUserData.data = [];
    this.listUserData.total = 0;
    this.listResponseData = {};
    this.listId = '';
    this.userListField = [];
    this.removeCrmList = [];
    this.getDefaultColumns();
    this.searchListDataPagObj = new GetDataReqProto();
    this.searchContent = new SearchContent();
    this.kendoPagination = new KendoPaginationProto();
    if (this.viewListItems.length > 0) {
      this.listId = this.viewListItems[0].id;
      this.getDataById(this.listId);
    }
  }

  getOperatorForApi(operation: string): string {
    let newOperator = operation;
    switch (operation) {
      case 'eq': // is  match
        newOperator = 'EQ';
        break;
      case 'neq': // is not match
        newOperator = 'NE';
        break;
      case 'contains':  // contains
        newOperator = 'CONTAINS';
        break;
      case 'doesnotcontain': // does not contain
        newOperator = 'NOTCONTAINS';
        break;
      case 'isempty': // is empty
        newOperator = 'EMPTY';
        break;
      case 'isnull': // is empty
        newOperator = 'NULL';
        break;
      case 'isnotempty': // is not empty
        newOperator = 'NOTEMPTY';
        break;
      case 'isnotnull': // is not empty
        newOperator = 'NOTNULL';
        break;
      case 'startswith':
        newOperator = 'STARTSWITH';
        break;
      case 'endswith':
        newOperator = 'ENDSWITH';
        break;
    }
    return newOperator;
  }

  addUserMailingList() {
    this.dialog.open(AddUserMailingListDialogComponent, this.appUtilService.getDialogDataHalfScreen(this.listId))
      .afterClosed().subscribe((response) => {
        if (response) {
          this.getDataById(this.listId);
        }
      });
  }
}
