import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { OAuth2Service } from 'o-auth2';
import { LayoutService } from '../../layouts/layout.service';
import { CommonCookieService } from 'kscookies';

@Injectable()
export class LoginGuardGuard implements CanActivate {
  constructor(private authService: OAuth2Service, private router: Router,
              private layoutService: LayoutService, private cookieService: CommonCookieService) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.authService.isAuthenticated()) {
      const firstPage = this.cookieService.getCookie('first-page');
      if (firstPage) {
        this.router.navigate([firstPage]);
      } else {
        this.router.navigate(['/citizen/dashboard']);
      }
      this.layoutService.setLoader(false);
      return false;
    }
    return true;
  }
}
