import { OAuth2RequestProto, OAuth2Service } from 'o-auth2';
import { Router } from '@angular/router';
import { AuthenticationConstants, LoginRequestPayload, UserRegisterRequestProto } from '../authentication-constants';
import { HttpErrorResponse } from '@angular/common/http';
import { WebService } from 'web-service';
import { UserService } from '../../../core/user.service';
import { LoaderService } from '../../../core/loader/loader.service';
import { LayoutService } from '../../../layouts/layout.service';
import { FacebookAppGlobals, GoogleAppGlobals } from './social-login/app-globals';
import { ServerUrls } from '../../../server-url-constants';
import { FacebookAuthService } from './social-login/facebook-auth.service';
import { SocialLoginDataDialogComponent } from './social-login-data-dialog/social-login-data-dialog.component';
import { CommonCookieService } from 'kscookies';
import { AppUtilService } from '../../../core/app-util.service';
import { MatDialog, MatDialogRef } from '@angular/material';
import { Location } from '@angular/common';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';
import { VerifyOtpDialogComponent } from '../verify-otp-dialog/verify-otp-dialog.component';
import { HomepageConfigParam } from '../../homepage/canned-homepages/canned-homepages-list';
import { CannedHomepageService } from '../../homepage/canned-homepages/pipe/canned-homepage.service';
import { LogoutService } from '../logout/logout.service';
import { ChangePasswordComponent } from "../change-password/change-password.component";
import { MyOrganizationConstants } from "../../nofa/my-organization/my-organization.constants";

export class LoginBaseClass {

  public requestPayload: LoginRequestPayload = new LoginRequestPayload();
  public errorMsg: string;
  public fbAppId: string;
  public googleAppId: string;
  public matDialogRef: MatDialogRef<any>;
  public isResendMail: boolean = false;
  public isLoading: boolean = false;
  public checkLoading: boolean = false;
  public isLoginDialog: boolean = false;
  public isRegisterDialog: boolean = false;
  public homepageConfigParam: typeof HomepageConfigParam = HomepageConfigParam;
  public homepageConfig: Map<HomepageConfigParam, any> = new Map<HomepageConfigParam, any>();
  public redirectPage: boolean = true;
  public isAdmin: boolean;

  constructor (public router: Router,
               public authService: OAuth2Service,
               public webService: WebService,
               public userService: UserService,
               public loader: LoaderService,
               public layoutService: LayoutService,
               public _facebookAuth: FacebookAuthService,
               public cookieService: CommonCookieService,
               public utilService: AppUtilService,
               public matDialog: MatDialog,
               public location: Location,
               public logoutService: LogoutService,
               public cannedHomepageService: CannedHomepageService) {
  }

  public setHomepageConfig (): void {
    this.homepageConfig = this.cannedHomepageService.getHomepageConfig();
    this.isLoginDialog = this.homepageConfig[this.homepageConfigParam.LOGIN_DIALOG];
    this.isRegisterDialog = this.homepageConfig[this.homepageConfigParam.REGISTER_DIALOG];
  }

  /**
   * @author: Ganeshram Kumhar (ganeshram.kumhar@kahunasystems.com)
   * @description : get social login template details for facebook and google
   * @param : response
   * @return {void}
   */
  public getSocialTemplateDetails (): void {
    // this.loader.show();
    this.webService.get(AuthenticationConstants.API.getSocialAuthConfig)
      .subscribe((response) => {
        this.isLoading = false;
        this.loader.hide();
        if (response.status.code === 200) {
          if (response.list.length > 0) {
            for (let i = 0; i < response.list.length; i++) {
              if (response.list[i].socialNetName === 'facebook' || response.list[i].socialNetName === 'gfh') {
                FacebookAppGlobals.FACEBOOK_CLIENT_ID = response.list[i].appId;
                this.fbAppId = response.list[i].appId;
              }
              if (response.list[i].socialNetName === 'google' || response.list[i].socialNetName === 'google+') {
                GoogleAppGlobals.GOOGLE_CLIENT_ID = response.list[i].appId;
                this.googleAppId = response.list[i].appId;
              }
            }
          }
        }
      }, () => {
        this.isLoading = false;
        this.loader.hide();
      });
  }

  public login (): void {
    this.isResendMail = false;
    this.loader.show();
    this.isLoading = true;
    this.checkLoading = true;
    const loginObject = new OAuth2RequestProto();
    loginObject.username = this.requestPayload.email;
    loginObject.password = encodeURIComponent(this.requestPayload.password);
    loginObject.authType = 'selfSignup';
    loginObject.realm = 'Citizen';
    this.authService.login(loginObject).subscribe((response) => {
      this.authService.storeAccessTokenResponse(response.access_token, response.expires_in, response.refresh_token);
      if (response.isOtpRequire) {
        this.matDialog.open(VerifyOtpDialogComponent).afterClosed().subscribe((result) => {
          if (result) {
            this.getUserProfileImage(response);
          }
        });
       } else {
        this.isLoading = false;
        this.checkLoading = false;
        this.loader.hide();
        if (response.forcePwdUpdate) {
          this.openChangePassword();
        } else {
          this.getUserProfileImage(response);
        }
      }
    }, (error: HttpErrorResponse) => {
      this.isLoading = false;
      this.checkLoading = false;
      this.loader.hide();
      this.handleLoginError(error);
    });
  }

  public onSocialLoginGoogle (userDetails: any): void {
    this.resetErrorMsg();
    const profile = userDetails.getBasicProfile();
    this.userService.setUserProperty('userProfilePath', profile.getImageUrl());
    this.socialSignIn('gplus', userDetails.getAuthResponse()['access_token'], 'google');
  }

  public onSocialLoginFacebook (): void {
    this.resetErrorMsg();
    this._facebookAuth.authenticateUser().subscribe((response) => {
      if (response.status === 'connected') {
        this._facebookAuth.getUserInfo().then((fbProfileResponse) => {
          this.userService.setUserProperty('firstName', fbProfileResponse.name.split()[0]);
          this.userService.setUserProperty('lastName', fbProfileResponse.name.split()[1]);
          this.userService.setUserProperty('lastName', fbProfileResponse.email);
        });
        this._facebookAuth.getUserProfileImage().then((imageUrl) => {
          this.userService.setUserProperty('userProfilePath', imageUrl);
        });
        this.socialSignIn('fb', response.authResponse.accessToken, 'facebook');
      }
    }, (error) => {
      console.log('error', error);
    });
  }

  public resetErrorMsg (): void {
    this.errorMsg = null;
  }

  public goToForgotPassword (): void {
    this.matDialog.open(
      ForgotPasswordComponent,
      this.utilService.getDialogData({type: 'forgetPassword', userId: this.requestPayload.email})
    );
  }

  public onResentEmail (): void {
    this.matDialog.open(
      ForgotPasswordComponent,
      this.utilService.getDialogData({type: 'resendEmail', userId: this.requestPayload.email})
    ).afterClosed().subscribe((result) => {
      if (result) {
        this.isResendMail = false;
      }
    });
  }

  private getUserProfileImage (loginResponse: any): void {
    this.userService.setUserProperty('token', loginResponse.access_token);
    this.userService.setUserProperty('crmId', loginResponse.crmId);
    this.webService.post(AuthenticationConstants.API.userProfileImage, {'crmId': loginResponse.crmId})
      .subscribe((profileImageResponse) => {
        if (profileImageResponse.status.code === 200) {
          this.completeSocialLoginProcess(profileImageResponse);
        } else {
          this.isLoading = false;
          this.loader.hide();
          this.errorMsg = profileImageResponse.status.message;
        }
      }, () => {
        this.isLoading = false;
        this.loader.hide();
      });
  }

  private socialSignIn (socialMedia: string, accessToken: string, loginFrom: string, request?: any): void {
    this.loader.show();
    let requestPayload;
    if (request) {
      const newRequest = new UserRegisterRequestProto();
      newRequest.userName = request.email;
      newRequest.userType = 'Citizen';
      newRequest.citizenAuthType = 'SocialAuth';
      newRequest.userGroup.push('Citizen');
      newRequest.userContact.emailAddress = request.email;
      newRequest.userContact.firstName = request.firstName;
      newRequest.userContact.lastName = request.lastName;
      requestPayload = JSON.parse(JSON.stringify(newRequest));
      requestPayload['source'] = socialMedia;
      requestPayload['accessToken'] = accessToken;
    } else {
      requestPayload = {
        'source': socialMedia,
        'accessToken': accessToken,
        'citizenAuthType': 'SocialAuth'
      };
    }
    this.webService.post(AuthenticationConstants.API.socialSign, requestPayload).subscribe((response) => {
      if (response.status.code === 200) {
        this.cookieService.setCookie('social_logged_in', 'true');
        this.cookieService.setCookie(socialMedia, 'true');
        this.authService.storeAccessTokenResponse(response.tokenRes.access_token, response.tokenRes.expires_in, response.tokenRes.refresh_token);
        this.userService.setUserProperty('token', response.tokenRes.access_token);
        this.userService.setUserProperty('crmId', response.tokenRes.crmId);
        this.completeSocialLoginProcess(response);
      } else if (response.status.code === 548) {
        this.isLoading = false;
        this.loader.hide();
        const dialogData = this.utilService.getDialogData(response);
        dialogData.closeOnNavigation = false;
        dialogData.disableClose = true;
        /*dialogData.data = {
          firstName: requestPayload.firstName,
          lastName: requestPayload.lastName
        };*/
        this.matDialog.open(SocialLoginDataDialogComponent, dialogData).afterClosed()
          .subscribe((result) => {
            if (result) {
              this.socialSignIn(socialMedia, accessToken, loginFrom, result);
            }
          });
      } else if (response.status.code === 229) {
        this.isLoading = false;
        this.loader.hide();
        this.errorMsg = response.status.message;
      } else {
        this.isLoading = false;
        this.loader.hide();
      }
    }, (error: HttpErrorResponse) => {
      this.handleLoginError(error);
      this.isLoading = false;
      this.loader.hide();
    });
  }

  private completeSocialLoginProcess (response: any): void {
    this.authService.setUserLoggedIn();
    window.localStorage.setItem('logged_in', 'true');
    this.userService.setUser(response.userProfilePojo);
    const userRoles = this.userService.getUserProperty('userRole');
    this.userService.setUserProperty('isCitizen', true);
    userRoles.forEach((v) => {
      if (ServerUrls.ADMIN_ROLES.indexOf(v) > -1) {
        this.userService.setUserProperty('isAdmin', true);
        this.userService.setUserProperty('isStaff', false);
        this.userService.setUserProperty('isVendor', false);
        this.userService.setUserProperty('isCitizen', false);
      }
      if (ServerUrls.STAFF_ROLES.indexOf(v) > -1) {
        this.userService.setUserProperty('isStaff', true);
        this.userService.setUserProperty('isAdmin', false);
        this.userService.setUserProperty('isVendor', false);
        this.userService.setUserProperty('isCitizen', false);
      }
      if (ServerUrls.VENDOR_ROLES.indexOf(v) > -1) {
        this.userService.setUserProperty('isVendor', true);
        this.userService.setUserProperty('isStaff', false);
        this.userService.setUserProperty('isAdmin', false);
        this.userService.setUserProperty('isCitizen', false);
      }
      if (ServerUrls.CITIZEN_ROLES.indexOf(v) > -1) {
        this.userService.setUserProperty('isVendor', false);
        this.userService.setUserProperty('isStaff', false);
        this.userService.setUserProperty('isAdmin', false);
        this.userService.setUserProperty('isCitizen', true);
      }
    });
    if (response.layout) {
      this.layoutService.switchLayout(response.layout || '');
      this.layoutService.setMenuPosition(response.menuPosition);
    } else {
      this.layoutService.switchLayout('citizen');
      this.layoutService.setMenuPosition('start');
    }
    if (response.redirectUrl) {
      this.isAdmin = this.userService.getUserProperty('isAdmin');
      this.cookieService.setCookie('first-page', response.redirectUrl);
      if (response.redirectUrl === '/app/dashboard-applicant' && this.redirectPage) {
        this.router.navigate(['/app/applications/list']);
        /*this.webService.post(NofaRoundsConstants.API.getAllUserApplication, {
          pageNumber: 1,
          pageSize: 10
        }).subscribe((userApplicationResponse: UserApplicationResponse) => {
          if (userApplicationResponse.status.code === 200 && userApplicationResponse.userApplications.length > 0) {
            this.router.navigate(['/app/applications/list']);
          } else {
            this.router.navigateByUrl(response.redirectUrl);
          }
        }, (error) => {
          this.router.navigateByUrl(response.redirectUrl);
        });*/
        this.getActiveOrganizations();
      } else if (this.redirectPage) {
        if (this.isAdmin) {
          this.router.navigateByUrl('/crm/contact');
        } else {
          this.router.navigateByUrl(response.redirectUrl);
        }
      }
    } else if (this.redirectPage) {
      this.router.navigateByUrl('/citizen/dashboard');
    }
    if (this.matDialogRef) {
      this.matDialogRef.close(true);
    }
    this.isLoading = false;
    this.loader.hide();
  }

  private handleLoginError (error: HttpErrorResponse) {
    if (error.status === 401) {
      this.errorMsg = error.error.error_description ? error.error.error_description : 'AUTHENTICATION.LOGIN.UN_AUTHENTICATE_ERROR';
      /* if (error.error.error === '331') {
         this.errorMsg = error.error.error_description;  //'AUTHENTICATION.LOGIN.USER_NOT_REGISTERED';
       } else if (error.error.error === '229') {
         this.errorMsg = 'AUTHENTICATION.LOGIN.EMAIL_VERIFICATION_PENDING';
         this.isResendMail = true;
       } else {
         this.errorMsg = 'AUTHENTICATION.LOGIN.UN_AUTHENTICATE_ERROR';
       }*/
    } else {
      this.errorMsg = 'AUTHENTICATION.LOGIN.UN_AUTHENTICATE_ERROR';
    }
  }
  getActiveOrganizations () {
    this.webService.get(MyOrganizationConstants.API.getMyActiveOrganization)
      .subscribe((response) => {
        if (response.status.code === 200) {
          if (response.organizationList && response.organizationList.length === 0) {
            const msg = `Please associate yourself with an organization prior to applying for a funding opportunity by </span>`;
            this.utilService.showNotificationBar(msg, 'warn', '/app/my-organizations');
          }
        }
      });
  }

  openChangePassword() {
    const data = {
      title: 'AUTHENTICATION.CHANGE_PASSWORD.TITLE'
    };
    this.matDialog
      .open(ChangePasswordComponent, this.utilService.getDialogData(data))
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.isLoading = false;
          this.checkLoading = false;
          this.loader.hide();
          this.logoutService.logout();
        }
      });
  }
}
