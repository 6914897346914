import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CustomFieldObj } from './crm-contact-constants';
import { ResponseField } from './crm-contact/types/response-field';


@Injectable()
export class CrmContactService {


  public eventEmit$: BehaviorSubject<string> = new BehaviorSubject('');

  public userRoles$: BehaviorSubject<ResponseField[]> = new BehaviorSubject([]);
  public fieldList$: BehaviorSubject<ResponseField[]> = new BehaviorSubject([]);
  public selectedFilters$: BehaviorSubject<CustomFieldObj[]> = new BehaviorSubject([]);

  public userListField$: BehaviorSubject<ResponseField[]> = new BehaviorSubject([]);

}
