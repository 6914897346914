/****** Created by shivani gajjar(shivani.gajjar@kahunasystems.com) 5/10/17 2:36 PM*******/
import { MatDialogConfig } from '@angular/material';
import { Observable } from 'rxjs/Observable';
import {
  Compiler,
  ComponentFactoryResolver,
  Injectable,
  ModuleWithComponentFactories,
  NgModuleFactoryLoader
} from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { PasswordErrorStates } from '../constants/app-constants';
import { } from 'googlemaps';
import GeocoderStatus = google.maps.GeocoderStatus;
import { NotificationBarService } from '../feature-modules/common-components/notification-bar/notification-bar.service';
import { PasswordCheckObject } from '../feature-modules/authentication/create-user-form-field/create-user-constants';


@Injectable()
export class AppUtilService {

  public geoCoder = new google.maps.Geocoder();

  public appRightMask: any;
  public appRightMaskObservable = new ReplaySubject();
//  public processTransaction: ProcessTransactionProto;
  public amount: number;
  public _RoutingLoadingIcon: boolean;
  public _ApiLoadingIcon: boolean;


  constructor(private resolver: ComponentFactoryResolver,
              private compiler: Compiler, private loader: NgModuleFactoryLoader,
              private notificationBarService: NotificationBarService) {


  }

  /**
   * @author : Shivani Gajjar
   * @description used to find perticular object from list
   * @param list : given list
   * @param attr : match for given attr
   * @param matchedValue : match for given value
   * @return object containing matching values from list
   */
  public findObject(list, attr, matchedValue): any {
    return list.find(result => result[attr] === matchedValue.toString());
  }

  findArrayList(list, attr, matchedList) {
    const array = [];
    matchedList.map((val) => {
      if (this.findObject(list, attr, val[attr]) !== undefined) {
        if (this.findObject(list, attr, val[attr])[attr] === val[attr]) {
          array.push(val);
        }
      }
    });
    return array;
  }

  findArrayForTwoIds(list: any[], attr1: string, matchedList: any[], attr2: string) {
    const array = [];
    matchedList.map((val) => {
      if (this.findObject(list, attr1, val[attr2]) !== undefined) {
        if (this.findObject(list, attr1, val[attr2])[attr1] === val[attr2]) {
          array.push(val);
        }
      }
    });
    return array;
  }

  /**
   * @author : Ganeshram Kumhar
   * @description used to sort array
   * @param list : given list
   * @param attr : match for given attr
   * @return sorted array list
   */
  public sortList(list: any[], attr?: string): any[] {
    return list.sort((a, b) => {
      let objectOne = a, objectTwo = b;
      if (attr) {
        objectOne = a[attr];
        objectTwo = b[attr];
      }
      if (typeof (objectOne) === 'string') {
        objectOne = objectOne.toLowerCase();
      }
      if (typeof (objectTwo) === 'string') {
        objectTwo = objectTwo.toLowerCase();
      }
      if (objectOne < objectTwo) {
        return -1;
      } else if (objectOne > objectTwo) {
        return 1;
      }

      return 0;
    });
  }

  get RoutingLoadingIcon (): boolean {
    return this._RoutingLoadingIcon;
  }

  set RoutingLoadingIcon (value: boolean) {
    this._RoutingLoadingIcon = value;
  }


  get ApiLoadingIcon (): boolean {
    return this._ApiLoadingIcon;
  }

  set ApiLoadingIcon (value: boolean) {
    this._ApiLoadingIcon = value;
  }


  public getExistsList(list: any[], key: string) {
    return list.map(e => e[key])
      .map((e, i, final) => final.indexOf(e) === i && i)
      .filter(e => list[e]).map(e => list[e]);
  }

  public getExistsIds(list: any[], key: string) {
    return list.map(e => e[key])
      .map((e, i, final) => final.indexOf(e) === i && i)
      .filter(e => list[e]).map(e => list[e]);
  }

  /**
   * @author : Ganeshram Kumhar
   * @description used to scroll to element
   * @param attr : it can be id or class if id then need to send as #id or if class then need to send .class
   * @return void
   */
  public scrollTo(attr: string): void {
    // setTimeout(() => {
      const elementList = document.querySelectorAll(attr);
      const element = elementList[0] as HTMLElement;
      if (element) {
        element.scrollIntoView();
      }
    // }, 0);
  }

  /**
   * @author : Shivani Gajjar
   * @description used to get the list of <key> from the json object array
   * @param list : given list
   * @param attr : match for given attr
   * @return list of <attr>
   */
  public toArrayOf(list: string[], attr: string): any {
    return list.map((val) => {
      return val[attr];
    });
  }

  /**
   * @author : Shivani Gajjar
   * @description used to get the elements of an array that meet the condition specified in a callback function.
   * @param list : given list
   * @param checkFn : callback function
   * @return list of element meet the condition in callback function
   */
  public filterArray(list: any[], checkFn: any): any {
    return list.filter(checkFn);
  }

  /**
   * @author : Shivani Gajjar
   * @description used to get the index of json object from json array
   * @param list : given list
   * @param key : key to compare
   * @param data : value to compare
   * @return index of json object
   */
  public getIndex(list: any[], key: string, data: any): any {
    return list.map((d: any) => {
      return d[key];
    }).indexOf(data);
  }

  /**
   * @author : Ganeshram Kumhar
   * @description used to get same setting for dialogs
   * @param data : given list
   * @return index of json object
   */
  public getDialogData(data?: any, disableClose = false): MatDialogConfig {
    const config = new MatDialogConfig();
    config.panelClass = 'mat-dialog-mini-content';
    config.closeOnNavigation = true;
    config.hasBackdrop = true;
    config.disableClose = disableClose;
    if (data) {
      config.data = data;
    }
    return config;
  }

  /**
   * @author : Ganeshram Kumhar
   * @description used to get same setting for dialogs full screen
   * @param data : given list
   * @return index of json object
   */
  public getDialogDataFullScreen(data?: any, disableClose = false): MatDialogConfig {
    const config = new MatDialogConfig();
    config.panelClass = 'my-full-screen-dialog';
    config.maxWidth = 'calc(100vw - 35px)';
    config.maxHeight = 'calc(100vh - 35px)';
    config.closeOnNavigation = true;
    config.disableClose = disableClose;
    config.hasBackdrop = true;
    config.disableClose = false;
    if (data) {
      config.data = data;
    }
    return config;
  }

  public getDialogDataHalfScreen(data?: any, disableClose = false): MatDialogConfig {
    const config = new MatDialogConfig();
    config.panelClass = 'my-half-screen-dialog';
    config.maxWidth = 'calc(100vw - 300px)';
    config.maxHeight = 'calc(100vh - 35px)';
    config.closeOnNavigation = true;
    config.disableClose = disableClose;
    config.hasBackdrop = true;
    config.disableClose = false;
    if (data) {
      config.data = data;
    }
    return config;
  }

  /**
   * @author : Shivani Gajjar
   * @description used to get confirmation dialog for delete's title and message.
   * @return delete message
   */
  public getDeleteMsg(): any {
    return {
      'title': 'CONSTANTS.DELETE.TITLE',
      'message': 'CONSTANTS.DELETE.MESSAGE'
    };
  }

  public getArchiveMsg(): any {
    return {
      'title': 'CONSTANTS.ARCHIVE.TITLE',
      'message': 'CONSTANTS.ARCHIVE.MESSAGE'
    };
  }

  /**
   * @author : Ganeshram Kumhar (ganeshram.kumhar@kahunasystems.com)
   * @description used to call resize window
   */
  public resizeWindow(): void {
    // window.dispatchEvent(new Event('resize'));
    const resizeEvent = window.document.createEvent('UIEvents');
    resizeEvent.initUIEvent('resize', true, false, window, 0);
    window.dispatchEvent(resizeEvent);
  }

  /**
   * @author : Ganeshram Kumhar (ganeshram.kumhar@kahunasystems.com)
   * @description generation rgb color code for given hexa
   */
  public generateTextColor(color: string): string {
    const r = parseInt(color.substring(1, 3), 16);
    const g = parseInt(color.substring(3, 5), 16);
    const b = parseInt(color.substring(5, 7), 16);
    return (r * 0.299 + g * 0.587 + b * 0.114) > 186 ? '#000000' : '#ffffff';
  }

  /**
   * @author : Shivani Gajjar
   * @description used to initialize map.
   * @param mapId (id of div to which map need to initialize)
   * @param lat (latitude)
   * @param lng (longitude)
   * @param zoomLevel
   * @return google map reference
   */
  getMapRef(mapId, lat, lng, zoomLevel?) {
    if (!zoomLevel) {
      zoomLevel = 10;
    }
    return new google.maps.Map(document.getElementById(mapId), {
      center: {lat: lat, lng: lng},
      zoom: zoomLevel,
      scrollwheel: false
    });
  }

  /**
   * @author : Shivani Gajjar
   * @description used to get lat lng from address from google api.
   * @param lat (latitude)
   * @param lng (longitude)
   * @return address
   */
  reverseGeoCode(lat, lng): Observable<any> {
    return new Observable((observe) => {
      const location = {lat: lat, lng: lng};
      this.geoCoder.geocode({location: location}, (results, status) => {
        if (status === GeocoderStatus.OK) {
          observe.next(results);
        } else {
          observe.error(status);
        }
        observe.complete();
      });
    });
  }

  /**
   * @author : Shivani Gajjar
   * @description used to get address from lat lng from google api.
   * @param address
   * @param
   * @return lat (latitude),
   */
  geoCode(address): Observable<any> {
    return new Observable((observe) => {
      const addressObj = {'address': address};
      this.geoCoder.geocode(addressObj, (results, status) => {
        if (status === GeocoderStatus.OK) {
          observe.next(JSON.parse(JSON.stringify(results)));
        } else {
          observe.error(status);
        }
        observe.complete();
      });
    });
  }

  /**
   * @author : Shivani Gajjar
   * @description used to initialize info window.
   * @return info window reference
   */
  getInfoWindowRef() {
    return new google.maps.InfoWindow();
  }

  getAppRightMask(moduleName: string) {
    const rightMaskList = {
      'create': false,
      'edit': false,
      'delete': false,
      'view': false,
      'approve': false,
      'reject': false
    };
    if (this.appRightMask) {
      const permissions = this.appRightMask[moduleName].split('');
      let i = 0;
      for (let k in rightMaskList) {
        if (permissions[i++] === '1') {
          rightMaskList[k] = true;
        }
      }
    }
    return rightMaskList;
  }

  setAppRightMask(rightMask: any) {
    this.appRightMask = rightMask;
    this.appRightMaskObservable.next(true);
  }

  /**
   * @author : Hiral Akbari
   * @description used to get right mask value.
   * @return boolean value
   */

  checkActionEnable(rightMask: any) {
    const perList = Object.keys(rightMask);
    let AllFalse = false;
    for (const key of perList) {
      if (rightMask[key]) {
        AllFalse = true;
        break;
      }
    }
    return AllFalse;
  }

  /**
   * @author : Shivani Gajjar
   * @description used to load component with module lazyloading
   * @param modulePath : string
   * @param componentName : string
   * @param viewRef : viewreference
   * @param parameters : object to pass in newly created component
   */
  loadComponentWithLazyLoading(modulePath, componentName, viewRef, parameters?) {
    this.loader.load(modulePath).then((module) => {
      this.compiler.compileModuleAndAllComponentsAsync<any>(module.moduleType)
        .then((factory: ModuleWithComponentFactories<any>) => {
          return factory.componentFactories.find((component) => component.componentType.name === componentName);
        }).then((factory) => {
        const modRef = module.create(viewRef.parentInjector);
        const componentRef = viewRef.createComponent(factory, 0, modRef.injector);
        if (parameters) {
          parameters.forEach((v, k) => {
            componentRef.instance[v.key] = v.data;
          });
        }
      });
    });
  }

  formatContactNumber(number) {
    if (number) {
      let newString = '(';
      for (let i = 0; i < number.length; i++) {
        newString = newString + number[i];
        if (i === 2) {
          newString = newString + ')';
        }
        if (i === 2) {
          newString = newString + ' ';
        }
        if (i === 5) {
          newString = newString + '-';
        }
      }
      return newString;
    }
    return number;
  }

  getRedirectState(url) {
    if (url.includes('admin')) {
      return 'admin';
    } else if (url.includes('staff')) {
      return 'staff';
    } else if (url.includes('citizen')) {
      return 'citizen';
    } else if (url.includes('mobile')) {
      return 'mobileUser';
    } else {
      return 'none';
    }
  }

  getHashCode(hashCode) {
    let hash = 0;
    const length = hashCode.length;
    if (length === 0) {
      return hash;
    }
    for (let i = 0; i < length; i++) {
      const charC = hashCode.charCodeAt(i);
      hash = ((hash << 5) - hash) + charC;
      hash = hash & hash;
    }
    return hash;
  }

  checkPasswordErrors(password: string, passwordChecks?: PasswordCheckObject): PasswordErrorStates {
    const passwordError: PasswordErrorStates = new PasswordErrorStates();
    const passwordPolicy = passwordChecks.passwordPolicy;
    const userContact = passwordChecks.userContact;
    const passwordPolicyMessageFlag = passwordChecks.passwordPolicyMessageFlag;
    if (password) {
      if (passwordPolicy && (passwordPolicy.containFirstName || passwordPolicy.containLastName
        || passwordPolicy.containDictionary)) {
        if (passwordPolicy.containFirstName && userContact && userContact.firstName) {
          if (password.toLowerCase().includes(userContact.firstName.toLowerCase())) {
            passwordError.pwdContainsFirstName = true;
          }
        }
        if (passwordPolicy.containLastName && userContact && userContact.lastName) {
          if (password.toLowerCase().includes(userContact.lastName.toLowerCase())) {
            passwordError.pwdContainsLastName = true;
          }
        }
        if (passwordPolicy.containDictionary) {
          if (password.toLowerCase().includes('dictionary')) {
            passwordError.pwdContainsDictionaryString = true;
          }
        }

      }
      if (password.length > 0 && passwordPolicyMessageFlag) {
        if (passwordPolicyMessageFlag.isLengthCheck) {
          if (password.length < 8) {
            passwordError.pwdLength = true;
          }
          if (password.length > 16) {
            passwordError.pwdLengthMax = true;
          }
        }
        if (password.search(/[a-z]/) < 0) {
          passwordError.pwdLetter = true;
        }
        if (passwordPolicyMessageFlag.isCapitalLetterRequired && password.search(/[A-Z]/) < 0) {
          passwordError.pwdCap = true;
        }
        if (passwordPolicyMessageFlag.isNumberRequired && password.search(/[0-9]/) < 0) {
          passwordError.pwdDigit = true;
        }
        if (passwordPolicyMessageFlag.isSpecialCharRequired && password.search(/[*.!@$%^&(){}\[\]:;<>,?/~_+\-=|]/) < 0) {
          passwordError.pwdSpecialCharacter = true;
        }
        if (/\s/.test(password)) {
          passwordError.pwdWhiteSpace = true;
        }
      }
    }
    if (passwordError.pwdLength || passwordError.pwdSpecialCharacter || passwordError.pwdCap ||
      passwordError.pwdLetter || passwordError.pwdDigit || passwordError.pwdWhiteSpace || passwordError.pwdLengthMax) {
      passwordError.formError = true;
    }
    return passwordError;
  }

  checkPasswordError(password: string, userContact?: any): PasswordErrorStates {
    const passwordError: PasswordErrorStates = new PasswordErrorStates();
    if (password) {
      if (password.length < 8) {
        passwordError.pwdLength = true;
      }
      if (password.length > 16) {
        passwordError.pwdLengthMax = true;
      }
      if (password.search(/[a-z]/) < 0) {
        passwordError.pwdLetter = true;
      }
      if (password.search(/[A-Z]/) < 0) {
        passwordError.pwdCap = true;
      }
      if (password.search(/[0-9]/) < 0) {
        passwordError.pwdDigit = true;
      }
      if (password.search(/[*.!@$%^&(){}\[\]:;<>,?/~_+\-=|]/) < 0) {
        passwordError.pwdSpecialCharacter = true;
      }
      if (/\s/.test(password)) {
        passwordError.pwdWhiteSpace = true;
      }
      if (userContact && userContact && userContact.firstName) {
        passwordError.pwdContainsFirstName = password.toLowerCase().includes(userContact.firstName.toLocaleLowerCase());
      }
      if (userContact && userContact && userContact.lastName) {
        passwordError.pwdContainsLastName = password.toLowerCase().includes(userContact.lastName.toLocaleLowerCase());
      }
    }
    if (passwordError.pwdLength || passwordError.pwdCap || passwordError.pwdLetter || passwordError.pwdDigit
      || passwordError.pwdWhiteSpace || passwordError.pwdLengthMax || passwordError.pwdContainsFirstName || passwordError.pwdContainsLastName) {
      passwordError.formError = true;
    }
    return passwordError;
  }

  /*Payment Gateway Services*/
  setProcessTransactionRequest(processTransaction: any) {
    // this.processTransaction = processTransaction;
  }

  getProcessTransactionRequest() {
    // return this.processTransaction;
  }

  setAmount(amount: number) {
    this.amount = amount;
  }

  getAmount() {
    return this.amount;
  }


  showNotificationBar(message, color, url?: string) {
    this.notificationBarService.notificationColor = color;
    this.notificationBarService.notificationContent = message;
    this.notificationBarService.url = url;
    this.notificationBarService.show();
  }

  hideNotificationBar() {
    this.notificationBarService.notificationContent = null;
    this.notificationBarService.hide();
  }
}

