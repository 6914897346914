import { Pipe, PipeTransform } from '@angular/core';

/***
 * @Author : Khushbu Poddar (khushbu.poddar@kahunasystems.com)
 * @description : Fetch All keys from Object.
 * @param Object
 * @returns {string[]}
 */
@Pipe({name: 'keys'})
export class KeysPipe implements PipeTransform {
  transform(value, args?: string[]): any {
    if (value instanceof Object) {
      return Object.keys(value);
    } else {
      return [];
    }
  }
}
