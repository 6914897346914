import { Component, Inject, OnInit } from '@angular/core';
import { AuthenticationConstants, ForgotPasswordRequest, StatusWrapper } from '../authentication-constants';
import { AppSettingDetailsProto, AppSettingService } from '../../../core/app-setting.service';
import { ToasterService } from '../../../core/toaster/toaster.service';
import { WebService } from 'web-service';
import { LoaderService } from '../../../core/loader/loader.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { AlertDialogComponent } from '../../../core/alert-dialog/alert-dialog.component';
import { AppUtilService } from '../../../core/app-util.service';

@Component({
  selector: 'portal-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  /***
   * @author : Lekhika Inani (lekhika.inani@kahunasystems.com)
   * @description : object for forget password
   */
  public requestPayload: ForgotPasswordRequest = new ForgotPasswordRequest();

  /***
   * @author : Lekhika Inani (lekhika.inani@kahunasystems.com)
   * @description : set the details like banner, logo etc for the dialog
   */
  public appSetting: AppSettingDetailsProto;

  /***
   * @author : Lekhika Inani (lekhika.inani@kahunasystems.com)
   * @description : checks whther dialog is resend or not
   */
  public resendDialog: boolean = false;

  /***
   * @author : Lekhika Inani (lekhika.inani@kahunasystems.com)
   * @description : title of the dialog
   */
  public title: string = 'AUTHENTICATION.FORGOT_PASSWORD.FORGET_PASSWORD';

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: any,
              private appSettingService: AppSettingService,
              public toasterService: ToasterService,
              public webService: WebService,
              public loader: LoaderService,
              public dialogRef: MatDialogRef<ForgotPasswordComponent>,
              private matDialog: MatDialog,
              private utilService: AppUtilService) {
  }

  ngOnInit(): void {
    this.appSettingService.getObserver().subscribe((result) => {
      this.appSetting = result;
    });
    if (this.dialogData) {
      this.requestPayload.userId = this.dialogData.userId;
      if (this.dialogData.type === 'resendEmail') {
        this.resendDialog = true;
        this.title = 'AUTHENTICATION.FORGOT_PASSWORD.SEND_RESET_LINK';
      }
    }
  }

  /***
   * @author : Lekhika Inani (lekhika.inani@kahunasystems.com)
   * @description : redirect page to login page
   * @param : {void}
   * @returns : {void}
   */
  public goToLogin = (): void => {
    this.dialogRef.close();
  };

  /***
   * @author : Lekhika Inani (lekhika.inani@kahunasystems.com)
   * @description : sends reset link to the email id given
   * @param : {void}
   * @returns : {void}
   */
  public onFormSubmit = () => {
    this.loader.show();
    let apiUrl = AuthenticationConstants.API.recoverPassword;
    if (this.resendDialog) {
      apiUrl = AuthenticationConstants.API.resendMail;
    }

    this.webService.post(apiUrl, this.requestPayload)
      .subscribe((response: StatusWrapper) => {
        this.loader.hide();
        if (response.status.code === 200) {
          // this.toasterService.showToast(response.status.message, 'success');
          this.dialogRef.close(true);
          const data = {
            title: 'AUTHENTICATION.FORGOT_PASSWORD.RESET_LINK',
            message: 'AUTHENTICATION.FORGOT_PASSWORD.RESET_LINK_MESSAGE'
          };
          this.matDialog.open(AlertDialogComponent, this.utilService.getDialogData(data));
        } else {
          this.toasterService.showToast(response.status.message, 'error');
        }
      }, () => {
        this.loader.hide();
      });
  };
}
