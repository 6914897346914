import { NgModule } from '@angular/core';
import { LanguageMenuComponent } from './language-menu.component';
import { SharedModule } from '../../../shared/shared.module';

@NgModule({
  imports: [SharedModule],
  declarations: [LanguageMenuComponent],
  exports: [LanguageMenuComponent]
})
export class LanguageMenuModule {
}
