import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { LoginBaseClass } from '../../base-component/loginBaseClass';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { Subscription } from 'rxjs';
import { AppSettingDetailsProto, AppSettingService } from '../../../../core/app-setting.service';
import { Router } from '@angular/router';
import { OAuth2Service } from 'o-auth2';
import { WebService } from 'web-service';
import { UserService } from '../../../../core/user.service';
import { LoaderService } from '../../../../core/loader/loader.service';
import { LayoutService } from '../../../../layouts/layout.service';
import { FacebookAuthService } from '../../base-component/social-login/facebook-auth.service';
import { CommonCookieService } from 'kscookies';
import { AppUtilService } from '../../../../core/app-util.service';
import { CannedHomepageService } from '../../../homepage/canned-homepages/pipe/canned-homepage.service';
import { Location } from '@angular/common';
import { RegisterInfoDialogComponent } from '../../create-user-form-field/register-info-dialog/register-info-dialog.component';
import { LogoutService } from "../../logout/logout.service";

@Component({
  selector: 'app-register-dialog',
  templateUrl: './register-dialog.component.html',
  styleUrls: ['./register-dialog.component.scss']
})
export class RegisterDialogComponent extends LoginBaseClass implements OnInit, OnDestroy {

  private subscription: Subscription;
  public appSetting: AppSettingDetailsProto = new AppSettingDetailsProto();

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: any,
              public router: Router,
              public authService: OAuth2Service,
              public webService: WebService,
              public userService: UserService,
              public loader: LoaderService,
              public layoutService: LayoutService,
              public facebookAuth: FacebookAuthService,
              public cookieService: CommonCookieService,
              public utilService: AppUtilService,
              public matDialog: MatDialog,
              private appSettingService: AppSettingService,
              public cannedHomepageService: CannedHomepageService,
              public logoutService: LogoutService,
              public location: Location,
              private dialogRef: MatDialogRef<RegisterDialogComponent>) {
    super(router, authService, webService, userService, loader, layoutService, facebookAuth, cookieService, utilService, matDialog, location, logoutService, cannedHomepageService);
  }

  ngOnInit() {
    this.setHomepageConfig();
    if (this.homepageConfig[this.homepageConfigParam.SHOW_SOCIAL_LOGIN_ON_REGISTER]) {
      this.getSocialTemplateDetails();
    }

    this.matDialogRef = this.dialogRef;

    this.subscription = this.appSettingService.getObserver().subscribe((result) => {
      this.appSetting = result;
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  onRegisterSuccess(event) {
    this.dialogRef.close();
  }

  openDialog() {
    const data = this.utilService.getDialogData();
    data.disableClose = true;
    this.matDialog.open(RegisterInfoDialogComponent, data);
  }
}
