import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { WebService } from 'web-service';
import {
  CrmInbox,
  DeleteNotificationResponse,
  GetUnreadNotificationsCountResponse,
  NotificationConstants,
  NotificationResponseProto,
  NotificationStatusResponse
} from './notification.constants';
import { LoaderService } from '../../../core/loader/loader.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  public crmInboxes: any[] = [];
  private notificationUnreadCount: BehaviorSubject<number> = new BehaviorSubject(0);
  private notificationList: BehaviorSubject<CrmInbox[]> = new BehaviorSubject([]);
  private unreadCount: number = 0;

  constructor (private webService: WebService, public loaderService: LoaderService) {
  }

  private _unreadCommentCount: any;

  get unreadCommentCount (): any {
    return this._unreadCommentCount;
  }

  set unreadCommentCount (count: any) {
    this._unreadCommentCount = count;
  }

  /***
   * @Author : Ganeshram Kumhar (ganeshram.kumhar@kahunasystems.com)
   * @description : used to get un read count of notification observable
   * @returns: {void}
   */
   getUnreadCount (): Observable<number> {
    return this.notificationUnreadCount.asObservable();
  }


  /***
   * @Author : Ganeshram Kumhar (ganeshram.kumhar@kahunasystems.com)
   * @description : used to update notificationUnreadCount observable
   * @param count:number
   * @returns: {void}
   */
  setUnreadCount (count: number) {
    this.notificationUnreadCount.next(count);
  }

  /***
   * @Author : Ganeshram Kumhar (ganeshram.kumhar@kahunasystems.com)
   * @description : used to get notification list as observable
   * @returns: {void}
   */
  getList (): Observable<CrmInbox[]> {
    return this.notificationList.asObservable();
  }

  /***
   * @Author : Ganeshram Kumhar (ganeshram.kumhar@kahunasystems.com)
   * @description :
   * @param list:CrmInbox[], object of notification list which we want to replace.
   * @returns: {void}
   */
  setList (list: CrmInbox[]) {
    this.notificationList.next(list);
  }

  /***
   * @Author : Ganeshram Kumhar (ganeshram.kumhar@kahunasystems.com)
   * @description : used to set / replace existing notification object with new one in notification observable list based on id
   * @param id:string, id of notification object
   * @param item:CrmInbox, object of notification list which we want to replace.
   * @returns: {void}
   */
  setItem (id: string, item: CrmInbox) {
    const list = this.notificationList.getValue();
    const findIndex = list.findIndex(object => object.id === id);
    if (findIndex > -1) {
      list[findIndex] = item;
    } else {
      list.push(item);
    }
    this.notificationList.next(list);
  }

  /***
   * @Author : Ganeshram Kumhar (ganeshram.kumhar@kahunasystems.com)
   * @description : used to return notification object from notification observable list based on id
   * @param id:string, id of notification object
   * @returns: {CrmInbox}
   */
  getItem (id: string): CrmInbox {
    const list = this.notificationList.getValue();
    return list.find(object => object.id === id);
  }

  /***
   * @Author : Ganeshram Kumhar (ganeshram.kumhar@kahunasystems.com)
   * @description : used to remove notifications from notification observable list
   * @param id:string, id of notification object
   * @returns: {void}
   */
  removeItem (id: string) {
    const list = this.notificationList.getValue();
    const findIndex = list.findIndex(object => object.id === id);
    if (findIndex > -1) {
      list.splice(findIndex, 1);
      this.notificationList.next(list);
    }
  }

  /***
   * @Author : Ganeshram Kumhar (ganeshram.kumhar@kahunasystems.com)
   * @description : used to get un read count of notifications from server
   * @returns: {void}
   */
  getUnreadNotificationsCount (data) {
    /* this.webService.get(NotificationConstants.API.notification.getUnreadCount)
      .subscribe((response: GetUnreadNotificationsCountResponse) => {
        if (response.status.code === 200) {
          this.unreadCount = response.totalRecord;
          this.setUnreadCount(response.totalRecord);
        }
      }); */
        this.webService.post(NotificationConstants.API.notification.getUnreadCountForTime, data)
          .subscribe((response) => {
            if (response.status.code === 200) {
              this.unreadCount = response.totalRecord;
              this.setUnreadCount(response.totalRecord);
            }
          }, );
  }

  getOrganizationComments (): Observable<any> {
    return new Observable((resolved) => {
      this.loaderService.show();
      this.webService.get(NotificationConstants.API.notification.get_org_comments).subscribe((response) => {
        this.loaderService.hide();
        if (response.status.code === 200) {
          resolved.next(response);
          resolved.complete();
        } else {
          resolved.error(response);
        }
      }, (error) => {
        this.loaderService.hide();
        resolved.error(error);
      });
    });
  }

  /***
   * @Author : Ganeshram Kumhar (ganeshram.kumhar@kahunasystems.com)
   * @description : used to get notifications from server
   * @returns: {void}
   */
  getNotifications (pageNumber?: number, searchText?: string) {
    this.webService.post(NotificationConstants.API.notification.get_inboxes, {
      pageNumber: pageNumber ? pageNumber : 1,
      searchKeyword: searchText,
      pageSize: 20,
      sort: {
        sortBy: 'dateTime',
        direction: 'DESC'
      },
    }).subscribe((response: NotificationResponseProto) => {
      if (response.status.code === 200) {
        if (searchText) {
          this.crmInboxes = response.crmInboxes;
        } else {
          if (!pageNumber) {
            response.crmInboxes.reverse().forEach((notification) => {
              if (!(this.crmInboxes.find(object => object.id === notification.id))) {
                this.crmInboxes.splice(0, 0, notification);
              }
            });
          } else {
            this.crmInboxes = this.crmInboxes.concat(response.crmInboxes);
          }
        }
        this.setList(this.crmInboxes);
      }
    });
  }

  /***
   * @Author : Ganeshram Kumhar (ganeshram.kumhar@kahunasystems.com)
   * @description : used to delete notification. after delete from server it will remove item from location notification list using removeItem.
   * @param item: CrmInbox, item is notification object received from server.
   * @returns: {void}
   */
  deleteNotification (item: CrmInbox): Observable<boolean> {
    return new Observable((resolve) => {
      this.webService.delete(NotificationConstants.API.notification.delete + item.id)
        .subscribe((response: DeleteNotificationResponse) => {
          if (response.status.code === 200) {
            this.removeItem(item.id);
            resolve.next(response.deleted);
            resolve.complete();
          } else {
            resolve.error(response);
          }
        }, error => resolve.error(error));
    });
  }


  /***
   * @Author : Ganeshram Kumhar (ganeshram.kumhar@kahunasystems.com)
   * @description : used to mark notification as unread
   * @param item: CrmInbox, item is notification object received from server.
   * @returns: {void}
   */
  markUnread (item: CrmInbox): Observable<NotificationStatusResponse> {
    return new Observable((resolve) => {
      this.webService.get(NotificationConstants.API.notificationStatus.mark_as_unread + item.id)
        .subscribe((response: NotificationStatusResponse) => {
          if (response.status.code === 200) {
            resolve.next(response);
            resolve.complete();
          } else {
            resolve.error(response);
          }
        }, error => resolve.error(error));
    });
  }

  /***
   * @Author : Ganeshram Kumhar (ganeshram.kumhar@kahunasystems.com)
   * @description : used to mark notification as read
   * @param item: CrmInbox, item is notification object received from server.
   * @returns: {void}
   */
  markRead (item: CrmInbox): Observable<NotificationStatusResponse> {
    return new Observable((resolve) => {
      this.webService.get(NotificationConstants.API.notificationStatus.mark_as_read + item.id)
        .subscribe((response: NotificationStatusResponse) => {
          if (response.status.code === 200) {
            resolve.next(response);
            resolve.complete();
          } else {
            resolve.error(response);
          }
        }, error => resolve.error(error));
    });
  }

  /***
   * @Author : Ganeshram Kumhar (ganeshram.kumhar@kahunasystems.com)
   * @description : used to mark all notification as read
   * @param item: CrmInbox, item is notification object received from server.
   * @returns: {void}
   */
  markAllRead (): Observable<NotificationStatusResponse> {
    return new Observable((resolve) => {
      this.webService.get(NotificationConstants.API.notificationStatus.mark_all_read)
        .subscribe((response: NotificationStatusResponse) => {
          if (response.status.code === 200) {
            resolve.next(response);
            resolve.complete();
          } else {
            resolve.error(response);
          }
        }, error => resolve.error(error));
    });
  }

  /***
   * @Author : Ganeshram Kumhar (ganeshram.kumhar@kahunasystems.com)
   * @description : used to delete notification status
   * @param item: CrmInbox, item is notification object received from server.
   * @returns: {void}
   */
  deleteInboxStatus (item: CrmInbox): Observable<boolean> {
    return new Observable((resolve) => {
      this.webService.delete(NotificationConstants.API.notificationStatus.delete_inbox_status + item.id)
        .subscribe((response: DeleteNotificationResponse) => {
          if (response.status.code === 200) {
            this.removeItem(item.id);
            resolve.next(response.deleted);
            resolve.complete();
          } else {
            resolve.error(response);
          }
        }, error => resolve.error(error));
    });
  }

}
