import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberSuffix'
})
export class NumberSuffixPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (args === 'number') {
    return '#' + value;
    }
  }

}
