import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OAuth2Service } from 'o-auth2';
import { LoginBaseClass } from '../base-component/loginBaseClass';
import { WebService } from 'web-service';
import { UserService } from '../../../core/user.service';
import { LoaderService } from '../../../core/loader/loader.service';
import { LayoutService } from '../../../layouts/layout.service';
import { AppSettingDetailsProto, AppSettingService } from '../../../core/app-setting.service';
import { Subscription } from 'rxjs';
import { CannedHomepageService } from '../../homepage/canned-homepages/pipe/canned-homepage.service';
import { FacebookAuthService } from '../base-component/social-login/facebook-auth.service';
import { CommonCookieService } from 'kscookies';
import { AppUtilService } from '../../../core/app-util.service';
import { MatDialog } from '@angular/material';
import { Location } from '@angular/common';
import { RegisterDialogComponent } from '../register-dialog/register-dialog/register-dialog.component';
import { LoginDialogComponent } from '../login-dialog/login-dialog/login-dialog.component';
import { LogoutService } from "../logout/logout.service";

@Component({
  selector: 'portal-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends LoginBaseClass implements OnInit, OnDestroy {

  @Input('isHomepage') isHomepage: boolean = false;
  private subscription: Subscription;
  public appSetting: AppSettingDetailsProto = new AppSettingDetailsProto();

  constructor(public router: Router,
              public authService: OAuth2Service,
              public webService: WebService,
              public userService: UserService,
              public loader: LoaderService,
              public layoutService: LayoutService,
              public facebookAuth: FacebookAuthService,
              public cookieService: CommonCookieService,
              public utilService: AppUtilService,
              public matDialog: MatDialog,
              private appSettingService: AppSettingService,
              public cannedHomepageService: CannedHomepageService,
              public logoutService: LogoutService,
              public location: Location) {
    super(router, authService, webService, userService, loader, layoutService, facebookAuth, cookieService, utilService, matDialog, location,  logoutService, cannedHomepageService);

  }

  ngOnInit(): void {
    this.setHomepageConfig();
    if (this.homepageConfig[this.homepageConfigParam.SHOW_SOCIAL_LOGIN]) {
      this.getSocialTemplateDetails();
    }
    this.subscription = this.appSettingService.getObserver().subscribe((result) => {
      this.appSetting = result;
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  goToRegister(): void {
    if (this.isRegisterDialog) {
      this.matDialog.open(RegisterDialogComponent, this.utilService.getDialogData(null));
    } else {
      this.router.navigate(['/register']);
    }
  }

  goToLogin(): void {
    if (this.isLoginDialog) {
      this.matDialog.open(LoginDialogComponent, this.utilService.getDialogData(null));
    } else {
      this.router.navigate(['/login']);
    }
  }
}
