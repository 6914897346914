import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { LoginGuardGuard } from '../../core/guards/login-guard.guard';
import { RegisterComponent } from './register/register.component';
import { UpdateUserProfileComponent } from './update-user-profile/update-user-profile.component';
import { IsAuthenticatedGuard } from '../../core/guards/is-authenticated.guard';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { APP_SETTING } from '../../server-url-constants';
import { UpdateUserProfileBasicComponent } from './update-user-profile-basic/update-user-profile-basic.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login'
    },
    canActivate: [LoginGuardGuard]
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Register'
    },
    canActivate: [LoginGuardGuard]
  },
  {
    path: 'update-profile',
    component: APP_SETTING.basicProfile ? UpdateUserProfileBasicComponent : UpdateUserProfileComponent,
    canActivate: [IsAuthenticatedGuard]
  },
  {
    path: 'resetPassword',
    component: ResetPasswordComponent,
    data: {
      title: 'Reset Password'
    },
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthenticationRoutingModule {
}
