import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MenuService } from '../menu.service';

@Component({
  selector: 'app-custom-horizontal-menu',
  templateUrl: './custom-horizontal-menu.component.html',
  styleUrls: ['./custom-horizontal-menu.component.scss']
})
export class CustomHorizontalMenuComponent implements OnInit, AfterViewInit {

  @Output('showChildren') showTabs = new EventEmitter();
  public menus: any[] = [];
  public showFilter: boolean = false;
  public search: string = '';

  constructor(public menuService: MenuService) {
  }

  get menuList(): any[] {
    return this.menus;
  }

  @Input()
  set menuList(menuList: any[]) {
    this.menus = Object.assign(menuList, []);
  }

  ngOnInit() {

  }

  ngAfterViewInit(): void {


  }

  showHideFilerPopover(): void {
    this.showFilter = !(this.showFilter);
  }

  showChildren(item: any) {
    this.showTabs.emit(item);
  }

}
