import { Component, Input } from '@angular/core';

@Component({
  selector: 'portal-card',
  templateUrl: 'portal-card.component.html',
  styleUrls: ['portal-card.component.scss']
})
export class PortalCardComponent {

  /*classes*/
  /***
   * @Author : Ganeshram Kumhar (ganeshram.kumhar@kahunasystems.com)
   * @description : apply provided class to .portal-card applied element (div)
   */
  @Input('portal-card-class') portalCardClass: string = '';

  /***
   * @Author : Ganeshram Kumhar (ganeshram.kumhar@kahunasystems.com)
   * @description : apply provided class to mat card element having .mat-card class (mat-card)
   */
  @Input('mat-card-class') matCardClass: string = '';

  /*extra item*/
  /***
   * @Author : Ganeshram Kumhar (ganeshram.kumhar@kahunasystems.com)
   * @description : to apply background image in card
   */
  @Input('bg-image') bgImage: string = '';

  /***
   * @Author : Ganeshram Kumhar (ganeshram.kumhar@kahunasystems.com)
   * @description : if you want to show side line on card layout
   */
  @Input('ticker-item') tickerItem: string = '';

  /***
   * @Author : Ganeshram Kumhar (ganeshram.kumhar@kahunasystems.com)
   * @description : if you want to show and hide box shadow of portal card
   */
  @Input('box-shadow') boxShadow: boolean = true;

  /*color*/
  /***
   * @Author : Ganeshram Kumhar (ganeshram.kumhar@kahunasystems.com)
   * @description : used to apply color of the fonts inside portal card. only material color can be provided. i.e. 'primary' | 'accent' | 'warn'
   */
  @Input('color') color: 'primary' | 'accent' | 'warn';

  /***
   * @Author : Ganeshram Kumhar (ganeshram.kumhar@kahunasystems.com)
   * @description : used to apply portal card background color. only material color can be provided. i.e. 'primary' | 'accent' | 'warn'
   */
  @Input('content-color') contentColor: 'primary' | 'accent' | 'warn' | 'transparent' | 'card';

  /***
   * @Author : Ganeshram Kumhar (ganeshram.kumhar@kahunasystems.com)
   * @description : if you want to apply custom bg color rather than material color then pass custom color
   */
  @Input('custom-bg-color') customBGColor: string = '';

  /***
   * @Author : Ganeshram Kumhar (ganeshram.kumhar@kahunasystems.com)
   * @description : if you want to apply custom font color rather than material color then pass custom color
   */
  @Input('custom-font-color') customFontColor: string = '';

  /*title*/
  /***
   * @Author : Ganeshram Kumhar (ganeshram.kumhar@kahunasystems.com)
   * @description : title of the card. if you apply title then and only the header of the card is visible.
   */
  @Input('title') title: string = '';
  @Input('html-title') htmlTitle: string = '';

  /*Content*/
  /***
   * @Author : Ganeshram Kumhar (ganeshram.kumhar@kahunasystems.com)
   * @description : used to apply padding in card. default is 16
   */
  @Input('card-margin-top') matCardMarginTop: string;
  @Input('content-padding') contentPadding: string;

  /***
   * @Author : Ganeshram Kumhar (ganeshram.kumhar@kahunasystems.com)
   * @description : use to defined card-height. i.e. card-height="100%", card-height="100px"
   */
  @Input('card-height') cardHeight: string;
  @Input('card-content-height') cardContentHeight: string;

  /*divider*/

  /***
   * @Author : Ganeshram Kumhar (ganeshram.kumhar@kahunasystems.com)
   * @description : to show divider between header and content
   */
  @Input('divider') public divider: boolean = false;


  /*Border*/

  /***
   * @Author : Ganeshram Kumhar (ganeshram.kumhar@kahunasystems.com)
   * @description : to show card border
   */
  @Input('card-border') public cardBorder: boolean = false;

  /***
   * @Author : Ganeshram Kumhar (ganeshram.kumhar@kahunasystems.com)
   * @description : if you want to show border to only.
   */
  @Input('content-border') public contentBorder: boolean = false;

  /***
   * @Author : Ganeshram Kumhar (ganeshram.kumhar@kahunasystems.com)
   * @description : to apply custom margin bottom to portal card
   */
  @Input('margin-bottom') public marginBottom: string;
}
