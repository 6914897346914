import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { LanguageListProto } from '../constants/app-constants';
import { APP_SETTING } from '../server-url-constants';

/*same field need to change in APP_SETTING too. required changes*/
export class AppSettingDetailsProto {
  COPY_RIGHT: string = '© ' + (new Date()).getFullYear() +
    ' <a target="_blank" href="http://www.3disystems.com/">3Di Systems</a>. All Rights Reserved.';
  VERSION: string = '2.5.1';
  bannerLogoPath: string = 'assets/img/bannerLogoPath.png';
  appLogoPath: string = 'assets/images/hcidlogo.jpeg';
  bannerPath: string = 'https://s3-us-west-2.amazonaws.com/3dicapv3mediapub/NofaUAT/media/2019/4/3/3_4_2019_4_18_44_8165328414946950062602.jpg';
  favicon: string = 'assets/img/city-seal-los-angeles.png';
  language: LanguageListProto[] = [];
  appLocation: number[] = [34.142509, -118.255074];
  showGlobalSearch: boolean = true;
  title: string = 'Contracting Opportunities';
  portalName: string = 'Community Portal';
  homePageLayoutId: string;
  theme: any;
  toolbarHeader: 'Weed Abatement Inspection Portal';
  openCaseViewInNewTab: false;
  hideAskAQuestion: false;
  hideCart: boolean = true;
  hidePassbook: boolean = true;
  paymentGateway: string = 'brainTree';
  basicProfile: boolean = false;
}

@Injectable({
  providedIn: 'root'
})
export class AppSettingService {

  public Observable: ReplaySubject<AppSettingDetailsProto> = new ReplaySubject();
  private settingData: AppSettingDetailsProto = new AppSettingDetailsProto();

  constructor() {
  }

  setSetting(data: any): void {
    const keys = Object.keys(data);
    keys.forEach((key) => {
      if (data[key]) {
        APP_SETTING[key] = data[key];
        this.settingData[key] = data[key];
      }
    });
  }

  getObserver(): Observable<AppSettingDetailsProto> {
    return this.Observable;
  }

  nextObserver(): void {
    this.Observable.next(this.settingData);
  }

  getSetting(): AppSettingDetailsProto {
    return this.settingData;
  }

  setProperty(key: string, value: any): void {
    APP_SETTING[key] = value;
    this.settingData[key] = value;
  }

  getProperty(key: string): any {
    return this.settingData[key];
  }
}
