/****** Created by Ganeshram Kumhar(ganeshram.kumhar@kahunasystems.com) 15/5/18 1:44 PM*******/

import { Injectable } from '@angular/core';
import { ChartEnum } from './open-widget-constant';

@Injectable()
export class OpenWidgetService {
  public checkFilter(filterObject: any): any {
    if (filterObject) {
      if (Array.isArray(filterObject)) {
        if (filterObject.length === 0) {
          return false;
        } else {
          for (const field of filterObject) {
            if (field.filtertype === 'BASIC') {
              if (field.basicfilter.length === 0) {
                filterObject.splice(filterObject.indexOf(field), 1);
              }
            }
          }
        }
      } else {
        for (const key in filterObject) {
          if (filterObject[key]) {
            if (filterObject[key].length === 0) {
              delete filterObject[key];
            }
          } else {
            delete filterObject[key];
          }
        }
        if (JSON.stringify(filterObject) === JSON.stringify({})) {
          return false;
        }
      }
    }
    return true;
  }

  public generateFieldList(requestPayload: any, allowGroup?: any): any[] {
    let fieldList = [];
    requestPayload.dimensionField.forEach((v) => {
      if (allowGroup) {
        if (v.allowGroup) {
          fieldList.push(v);
        }
      } else {
        fieldList.push(v);
      }
    });
    requestPayload.measureField.forEach((v) => {
      if (allowGroup) {
        if (v.allowGroup) {
          fieldList.push(v);
        }
      } else {
        fieldList.push(v);
      }
    });
    fieldList = fieldList.sort((a, b) => {
      return (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0);
    });
    return fieldList;
  }

  getChartType(type: string): string {
    for (const key in ChartEnum) {
      if (ChartEnum[key].indexOf(type) !== -1) {
        return key;
      }
    }
    return 'NONE';
  }

  getFieldTypeMap(requestPayload: any): FieldTypeMapProto {
    const returnObject = new FieldTypeMapProto();

    let list = [];
    for (const field of requestPayload.dimensionField) {
      let fieldN = field.fieldName;
      let label = field.label;
      if (field.renameField) {
        fieldN = field.renameField;
        label = fieldN;
      }
      returnObject.fieldTypeMap[label] = 'yAxis';
      returnObject.dataTypeMap[fieldN] = field.fieldDataType;
      if (field.fieldDataType === 'COORDINATE') {
        list.push(field);
      }
    }
    for (const field of requestPayload.measureField) {
      let fieldN = field.fieldName;
      let label = field.label;
      if (field.renameField) {
        fieldN = field.renameField;
        label = fieldN;
      }
      returnObject.fieldTypeMap[label] = 'xAxis';
      returnObject.dataTypeMap[fieldN] = field.fieldDataType;
      if (field.fieldDataType === 'COORDINATE') {
        list.push(field);
      }
    }
    list = list.sort((a, b) => {
      return (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0);
    });
    list.forEach((item, index) => {
      let fieldN = item.fieldName;
      if (item.renameField) {
        fieldN = item.renameField;
      }
      returnObject.coordinateMap[index] = fieldN;
    });

    return returnObject;
  }

  getHashCode(hashCode: any): any {
    let hash = 0;
    const length = hashCode.length;
    if (length === 0) {
      return hash;
    }
    for (let i = 0; i < length; i++) {
      const charC = hashCode.charCodeAt(i);
      hash = ((hash << 5) - hash) + charC;
      hash = hash & hash;
    }
    return hash;
  }
}

export class FieldTypeMapProto {
  fieldTypeMap: Map<any, any> = new Map();
  dataTypeMap: Map<any, any> = new Map();
  coordinateMap: Map<any, any> = new Map();
}
