import { Component, OnDestroy, OnInit } from '@angular/core';
import { NotificationBarService } from '../notification-bar.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-notification-bar',
  templateUrl: './notification-bar.component.html',
  styleUrls: ['./notification-bar.component.scss']
})
export class NotificationBarComponent implements OnInit, OnDestroy {

  showBar: boolean = false;
  refresh: boolean = false;
  private subscription: Subscription;
  public url: string;
  public notificationContent: string;
  public notificationColor: string;

  constructor(private notificationBarService: NotificationBarService) {
  }

  ngOnInit() {
    this.subscription = this.notificationBarService.showBar.asObservable().subscribe((value) => {
      this.notificationColor = this.notificationBarService.notificationColor;
      this.notificationContent = this.notificationBarService.notificationContent;
      this.url = this.notificationBarService.url;
      this.refresh = this.notificationBarService.refresh;
      this.showBar = value;
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  onUrlClick() {
    this.notificationBarService.hide();
  }

  onRefreshPage() {
    window.location.reload(true);
  }
}
