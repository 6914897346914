import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {

  public title: string;
  public message: string;
  public cancelButton: boolean;

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: any) {
  }

  ngOnInit(): void {
    this.title = this.dialogData.title;
    this.message = this.dialogData.message;
    if (this.dialogData.cancelButton === false) {
      this.cancelButton = this.dialogData.cancelButton;
    } else {
      this.cancelButton = true;
    }
  }
}
