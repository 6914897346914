import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { GetModuleWidgetMappingCriteriaProto, WidgetPositionUpdateProto } from './constants/dashboard-constant';
import { WebService } from 'web-service';
import { saveAs } from '@progress/kendo-file-saver';
import { exportPDF, geometry } from '@progress/kendo-drawing';
import { ServerUrls } from '../../server-url-constants';
import { DashboardConstant } from './dashboard-constant';

function mm(val: number): number {
  return val * 2.8347;
}

const PAGE_RECT = new geometry.Rect([0, 0], [mm(210 - 20), mm(297 - 20)]);

@Injectable()
export class DashboardService {

  public updateWidgetObservable: Subject<any> = new Subject<any>();
  public dashboardListObservable: Subject<any> = new Subject<any>();
  public _widgetList: any[] = [];
  public _dashboardList: any[] = [];
  public _TotalWidget: number = 0;
  public _selectedDashboard: any;
  public _ApplicationId: string;
  public dataSource = new Subject();
  currentData = this.dataSource.asObservable();

  constructor(public webService: WebService) {
  }
  changeData(data: string) {
    this.dataSource.next(data)
  }
  updateWidgetListObservable(data: any): void {
    this.updateWidgetObservable.next(data);
  }

  updateChartWidgetPositions(item: any, fromDelete?: boolean): void {
    const positionList: WidgetPositionUpdateProto[] = [];
    if (item.widgetName) {
      const widgetPos = new WidgetPositionUpdateProto();
      widgetPos.id = item.id;
      if (item.widgetPosition) {
        widgetPos.colNum = item.widgetPosition.xExtraLarge;
        widgetPos.rowNum = item.widgetPosition.yExtraLarge;
      }
      widgetPos.widgetName = item.widgetName;
      widgetPos.color = item.color;
      widgetPos.widgetPosition = item.widgetPosition;
      positionList.push(widgetPos);
    }
    this.updateWidgetPosition(positionList);
  }

  updateWidgetPosition(list: any[]): void {
    if (list.length > 0) {
      this.webService.post(DashboardConstant.API.updateWidgetPosition, {widgets: list}).subscribe((response) => {
      });
    }
  }

  getWidgetList(): any[] {
    return JSON.parse(JSON.stringify(this._widgetList));
  }

  setWidgetList(value: any[]): void {
    this._widgetList = JSON.parse(JSON.stringify(value));
    this.setTotalWidget(this._widgetList.length - 1);
  }

  setTotalWidget(count: number): void {
    this._TotalWidget = count;
  }

  getTotalWidget(): number {
    return this._TotalWidget;
  }

  setSelectedDashboard(dashboard: any): void {
    this._selectedDashboard = dashboard;
  }

  getSelectedDashboard(): any {
    return this._selectedDashboard;
  }

  setApplicationId(id: any): void {
    this._ApplicationId = id;
  }

  getApplicationId(): string {
    return this._ApplicationId;
  }


  /******************************************************
   * Dashboard List Getter setter and observable
   ******************************************************/
  getDashboardListFromServer(): Promise<any> {
    return new Promise((resolve) => {
      const request = {appId: this._ApplicationId};
      this.webService.post(DashboardConstant.API.getDashboardList, request).subscribe((response) => {
        this._dashboardList = response.details;
        resolve(response);
      }, (error) => {
      });
    });
  }

  getDashboardList(): any {
    return this._dashboardList;
  }

  updateDashboardListObservable(data: any): void {
    this._dashboardList = data;
    this.dashboardListObservable.next(data);
  }


  /******************************************************
   * to Get Filter Configuration data of every widget
   ******************************************************/
  /*getModuleWidgetMappingCriteria(request: GetModuleWidgetMappingCriteriaProto): Promise<any> {
    return new Promise((resolve) => {
      this.webService.post(DashboardConstant.API.getModuleWidgetMappingCriteria, request).subscribe((response) => {
        if (response.status.code === 200) {
          resolve(response.criteriaMapping);
        }
      }, (error) => {
      });
    });
  }*/


  /**
   * To export chart will use this function chart export portion
   * */
  exportChartImage(chart: any): void {
    chart.exportImage().then((dataURI) => {
      saveAs(dataURI, 'chart.png');
    });
  }

  exportChartSVG(chart: any): void {
    chart.exportSVG().then((dataURI) => {
      saveAs(dataURI, 'chart.svg');
    });
  }

  exportChartPDF(chart: any): void {
    const visual = chart.exportVisual({
      width: PAGE_RECT.size.width
    });
    exportPDF(visual, {
      paperSize: 'A4',
      landscape: false,
      margin: 5
    }).then((dataURI) => {
      saveAs(dataURI, 'chart.pdf');
    });
  }
}
