/**
 * Created by Isha Dhimar (isha.dhimar@kahunasystems.com) on 21/9/18.
 **/
export class AuthenticationUrlConstants {
  public static API = {
    'getAllLanguages': '/rest/v2/srapp/get_all_languages',
    'validateLocation': '/rest/gis_address/v1/validate',

    'Authentication': {
      'loginToken': '/rest/oauth/authenticate',
      'verifyOTP': '/rest/idm/user/otp/v1/verify',
      'updateProfile': {
        'getListOfCountries': '/rest/crm/contact/v1/get_list_of_countries',
        'getLatestUserProfile': '/rest/crm/contact/v1/get_latest_user_profile',
        'updateUserProfile': '/rest/crm/contact/v1/update_profile',
      },
      'SocialAuthConfig': {
        'getSocialAuthConfig': '/rest/idm/config/v1/get_social_template_config',
      }
    },
  };
}
