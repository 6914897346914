import { Inject, Injectable, Injector } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { CommonCookieService } from 'kscookies';
import { WebServiceConstants } from 'web-service';
import { OAuth2Constant, OAuth2Service } from 'o-auth2';
import { UserService } from './core/user.service';
import { AppConstants } from './constants/app-constants';
import { AppUtilService } from './core/app-util.service';
import { AppSettingService } from './core/app-setting.service';
import { DOCUMENT } from '@angular/common';
import { AppParameterConfigProto, ServerUrls } from './server-url-constants';
import { Observable } from 'rxjs';
import { CannedHomepageService } from './feature-modules/homepage/canned-homepages/pipe/canned-homepage.service';
import { LanguageService } from './core/language/language.service';
import { DateBaseConstants } from 'kscommon-components';
import { coerceBooleanProperty, coerceNumberProperty } from '@angular/cdk/coercion';
import { environment } from '../environments/environment';


@Injectable()
export class AppConfigService {

  public data = {
    ADMIN: [],
    STAFF: [],
    OWNER: [],
    VENDOR: [],
    CITIZEN: []
  };
  private oAuthService: OAuth2Service;

  constructor (private http: HttpClient, private _titleService: Title,
               private cookieService: CommonCookieService,
               private languageService: LanguageService, private userService: UserService,
               private utilService: AppUtilService,
               private appSettingService: AppSettingService,
               private injector: Injector,
               @Inject(DOCUMENT) private document: any,
               private cannedHomepageService: CannedHomepageService) {
  }

  /**
   * @author: Ganeshram Kumhar (ganeshram.kumhar@kahunasystems.com)
   * @description : loading base url constant
   * @param :
   * @return {void}
   */
  load (): any {
    const env = environment;
    /*************************
     * Return promise
     *************************/
    return new Promise((resolve) => {
      this.oAuthService = this.injector.get(OAuth2Service);
      /*************************
       * Fetching the url constant from baseUrl.json
       *************************/
      /**
       * Setting default language for application
       * */
      if (!this.cookieService.getCookie('lang')) {
        this.languageService.addLanguages(['en']);
        this.languageService.setDefaultLanguage('en');
      }
      /**
       * Setting default server url for webservice module
       * */
      WebServiceConstants.serverUrl = env.SERVER_URL;
      //  WebServiceConstants.CHAT_URL = config.CHAT_URL;
      WebServiceConstants.appId = env.APPID;

      /**
       * Setting homepage username and password, and cap url for auth
       * */
      OAuth2Constant.CAP_URL = env.SERVER_URL;
      OAuth2Constant.homePageLoginUserName = 'VAP';
      OAuth2Constant.homePageLoginPassword = 'VAPAPP';
      OAuth2Constant.authenticate = '/rest/oauth/authenticate';
      OAuth2Constant.logout = '/rest/idm/auth/v1/logout';

      DateBaseConstants.dateFormat = 'MM/dd/yyyy';

      /**
       * Setting server url for application
       * */
      ServerUrls.APPID = env.APPID;
      ServerUrls.serverUrl = env.SERVER_URL;
      ServerUrls.UI_URL = env.UI_URL;
      ServerUrls.IS_NOFA = env.IS_NOFA;

      if (env.googleAnalyticsKey) {
        ServerUrls.googleAnalyticsKey = env.googleAnalyticsKey;
      }
      if (env.GOOGLE_API_KEY) {
        ServerUrls.GOOGLE_API_KEY = env.GOOGLE_API_KEY;
      }
      /*
      * Setting default homepage config
      * */
      this.cannedHomepageService.setDefaultHomepageConfig();

      /**
       * checking user cookie if exist then save in user service
       * */

      if (this.cookieService.getCookieObject('sjgnei')) {
        this.userService.setUser(this.cookieService.getCookieObject('sjgnei'));
      }


      if (env.USER_TYPE_LIST) {
        ServerUrls.USER_TYPE_LIST = env.USER_TYPE_LIST;
      }

      /**
       * check if access token is not there and user is not logged in.(both false then)
       * */
      if (!this.oAuthService.getAccessToken() && !this.oAuthService.isAuthenticated()) {
        this.loginForHome().subscribe(() => resolve());
        /**
         * check if access token is not there but user not logged in.
         * */
      } else if (!this.oAuthService.getAccessToken() && this.oAuthService.isAuthenticated()) {
        if (this.oAuthService.getRefreshToken()) {
          this.oAuthService.refreshToken().subscribe((response) => {
            this.oAuthService.storeAccessTokenResponse(response.access_token, response.expires_in, response.refresh_token);
            this.getDataOnRefresh().subscribe(() => resolve());
            // this.cannedPageLayout();
          }, () => {
            this.cookieService.removeAllCookie();
            this.loginForHome().subscribe(() => {
              // this.cannedPageLayout();
              resolve();
            });
          });
        } else {
          this.cookieService.removeAllCookie();
          this.loginForHome().subscribe(() => {
            // this.cannedPageLayout();
            resolve();
          }, () => {
            // this.cannedPageLayout();
          });
        }
        /**
         * check if access token is there but user is not logged in.
         * */
      } else if (this.oAuthService.getAccessToken() && !this.oAuthService.isAuthenticated()) {
        // this.cannedPageLayout();
        this.getDataOnRefresh().subscribe(() => resolve());
      } else {
        // this.cannedPageLayout();
        this.getDataOnRefresh().subscribe(() => resolve());
      }

    });
  }

  /**
   * @author: Ganeshram Kumhar (ganeshram.kumhar@kahunasystems.com)
   * @description : Fetching data on refresh and refresh token in expired and fetch
   * @param :
   * @return {Observable}
   */
  private getDataOnRefresh (): Observable<any> {
    let count = 0;
    return new Observable<any>((resolve) => {
      /******************************************************
       * Fetching refresh data and returning resolving promise
       ******************************************************/

      const takeCallback = (): void => {
        this.http.get(
          ServerUrls.serverUrl + AppConstants.API.get_dataOnRefresh,
          this.getRequestOptions()
        ).subscribe((response: any) => {
          if (response.status.code === 200) {
            if (response.cannedHomepage) {
              const cannedPageLayoutDetails = response.cannedHomepage;
              this.cannedHomepageService.setLayoutName(cannedPageLayoutDetails.name);
              this.cannedHomepageService.setHomepageConfig(cannedPageLayoutDetails.config);
              this.cannedHomepageService.setFooterConfig(cannedPageLayoutDetails.footerProperties);
              cannedPageLayoutDetails.properties.forEach((object) => {
                this.cannedHomepageService.setValue(object.key, object.value);
              });
              cannedPageLayoutDetails.footerProperties.forEach((footerObject) => {
                footerObject.properties.forEach((object) => {
                  this.cannedHomepageService.setValue(object.key, object.value);
                });
              });
            }
            this.appSettingService.setSetting(response.appLogo);
            this.appSettingService.setProperty('language', response.language);
            if (response.appLogo.theme) {
              this.appSettingService.setProperty('theme', response.appLogo.theme);
              this.appSettingService.nextObserver();
            }

            const langList = [];
            if (response.language) {
              response.language.forEach((value) => {
                langList.push(value.languageKey);
              });
            }
            this.languageService.addLanguages(langList);
            if (this.cookieService.getCookie('lang')) {
              this.languageService.setDefaultLanguage(this.cookieService.getCookie('lang'));
            } else {
              this.cookieService.setCookie('lang', this.languageService.getDefaultLang());
              this.languageService.setDefaultLang(this.languageService.getDefaultLang());
            }


            /**
             * for custom translate change from json file from any location
             * */
            if (ServerUrls.TRANSLATE && typeof ServerUrls.TRANSLATE === 'string') {
              this.http.get(ServerUrls.TRANSLATE).subscribe((translateResponse) => {
                const translateObject = translateResponse[this.languageService.getCurrentLanguage()];
                if (translateObject) {
                  this.cannedHomepageService.setTranslateKeyJson(translateObject);
                }
              });
            } else if (ServerUrls.TRANSLATE && Array.isArray(ServerUrls.TRANSLATE)) {
              ServerUrls.TRANSLATE.forEach((url) => {
                this.http.get(url).subscribe((translateResponse) => {
                  const translateObject = translateResponse[this.languageService.getCurrentLanguage()];
                  if (translateObject) {
                    this.cannedHomepageService.setTranslateKeyJson(translateObject);
                  }
                });
              });
            }

            this._titleService.setTitle(response.appLogo.title + (response.appLogo.portalName ? ' | ' + response.appLogo.portalName : ''));

            if (response.homePageLayoutId) {
              this.appSettingService.setProperty('homePageLayoutId', response.homePageLayoutId);
            }

            /*Converting configParam Array to Map*/
            const configParamMap = new Map<string, AppParameterConfigProto>();
            response.configParam.forEach((data) => {
              configParamMap.set(data.paramName, data);
            });

            if (configParamMap.has('ADMIN_ROLES')) {
              ServerUrls.ADMIN_ROLES = JSON.parse(configParamMap.get('ADMIN_ROLES').paramValue);
            }
            if (configParamMap.has('USER_TYPE_LIST')) {
              ServerUrls.USER_TYPE_LIST = JSON.parse(configParamMap.get('USER_TYPE_LIST').paramValue);
            }
            if (configParamMap.has('STAFF_ROLES')) {
              ServerUrls.STAFF_ROLES = JSON.parse(configParamMap.get('STAFF_ROLES').paramValue);
            }
            if (configParamMap.has('VENDOR_ROLES')) {
              ServerUrls.VENDOR_ROLES = JSON.parse(configParamMap.get('VENDOR_ROLES').paramValue);
            }
            if (configParamMap.has('CITIZEN_ROLES')) {
              ServerUrls.CITIZEN_ROLES = JSON.parse(configParamMap.get('CITIZEN_ROLES').paramValue);
            }
            if (configParamMap.has('hideGlobalSearch')) {
              ServerUrls.hideGlobalSearch = JSON.parse(configParamMap.get('hideGlobalSearch').paramValue);
            }
            if (configParamMap.has('appLocation')) {
              this.appSettingService.setProperty('appLocation', configParamMap.get('appLocation').paramValue.split(','));
            }
            if (configParamMap.has('appSLAHour')) {
              ServerUrls.appSLAHour = JSON.parse(configParamMap.get('appSLAHour').paramValue);
            }

            if (configParamMap.has('APPID')) {
              ServerUrls.APPID = configParamMap.get('APPID').paramValue;
              WebServiceConstants.appId = ServerUrls.APPID;
            }
            if (configParamMap.has('GOOGLE_API_KEY')) {
              ServerUrls.GOOGLE_API_KEY = configParamMap.get('GOOGLE_API_KEY').paramValue;
            }

            if (configParamMap.has('homepageLogoPaddingRemove')) {
              ServerUrls.homepageLogoPaddingRemove = Boolean(configParamMap.get('homepageLogoPaddingRemove').paramValue);
            }

            if (configParamMap.has('homepageButtonLink')) {
              ServerUrls.homepageButtonLink = configParamMap.get('homepageButtonLink').paramValue;
            }

            if (configParamMap.has('hideAskAQuestion')) {
              this.appSettingService.setProperty('hideAskAQuestion', coerceBooleanProperty(configParamMap.get('hideAskAQuestion').paramValue));
            }

            if (configParamMap.has('hideCart')) {
              this.appSettingService.setProperty('hideCart', coerceBooleanProperty(configParamMap.get('hideCart').paramValue));
            }

            if (configParamMap.has('hidePassbook')) {
              this.appSettingService.setProperty('hidePassbook', coerceBooleanProperty(configParamMap.get('hidePassbook').paramValue));
            }


            /* Set JSON.parse values in APP_SETTINGS from configParam */
            const params = ['showGlobalSearch', 'openCaseViewInNewTab', 'addressParamConfig', 'addressParamConfig', 'citizenDashboardParams', 'caseDetail', 'propertyDetail', 'paymentGateway'];

            params.forEach((param) => {
              if (configParamMap.has(param)) {
                this.appSettingService.setProperty(param, JSON.parse(configParamMap.get(param).paramValue));
              }
            });

            const notificationSetting = configParamMap.get('notificationSetting');
            if (notificationSetting) {
              const setting = JSON.parse(configParamMap.get('notificationSetting').paramValue);
              this.appSettingService.setProperty('enableNotification', coerceBooleanProperty(setting.enable));
              this.appSettingService.setProperty('notificationInterval', coerceNumberProperty(setting.interval));
            }
            this.appSettingService.nextObserver();
            resolve.next(response);
          } else {
            resolve.next(response);
          }
        }, (httpError: HttpErrorResponse) => {
          count++;
          this.handleError(httpError).subscribe((refreshTokenResponse) => {
            if (refreshTokenResponse === 'success') {
              if (count >= 3) {
                resolve.next(httpError);
              } else {
                takeCallback();
              }
            } else {
              resolve.next(refreshTokenResponse);
            }
          });
        });
      };

      takeCallback();
    });
  }

  /**
   * @author: Ganeshram Kumhar (ganeshram.kumhar@kahunasystems.com)
   * @description : get header
   * @param :
   * @return {httpoption}
   */
  private getRequestOptions (): any {
    const httpOptions: any = {};
    /*set header section*/
    const httpHeader = {};
    httpHeader['Authorization'] = 'Bearer ' + this.oAuthService.getAccessToken();
    httpOptions['headers'] = new HttpHeaders(httpHeader);
    return httpOptions;
  }

  /**
   * @author: Ganeshram Kumhar (ganeshram.kumhar@kahunasystems.com)
   * @description : error handling, refresh token
   * @param :
   * @return {Observable}
   */
  private handleError (error: HttpErrorResponse): Observable<string> {
    return new Observable((resolve) => {
      if (error.status === 401) {
        this.oAuthService.refreshToken()
          .subscribe((response) => {
            this.oAuthService.storeAccessTokenResponse(response.access_token, response.expires_in, response.refresh_token);
            resolve.next('success');
          }, () => {
            this.cookieService.removeAllCookie();
            setTimeout(() => {
              location.reload();
              resolve.next('refreshTokenError');
            }, 0);
          });
      } else {
        resolve.next('error');
      }
    });
  }

  /**
   * @author: Ganeshram Kumhar (ganeshram.kumhar@kahunasystems.com)
   * @description : error handling, refresh token
   * @param :
   * @return {Observable}
   */
  private loginForHome (): Observable<string> {
    return new Observable((resolve) => {
      this.oAuthService.loginForHome().subscribe((response) => {
        this.oAuthService.storeAccessTokenResponse(response.access_token, response.expires_in, response.refresh_token);
        this.getDataOnRefresh().subscribe(() => resolve.next());
      }, () => {
        resolve.next();
      });
    });
  }

  getAdminFlags(): any {
    return this.data.ADMIN || [];
  }

  getStaffFlags(): any {
    return this.data.STAFF || [];
  }

  getOwnerFlags(): any {
    return this.data.OWNER || [];
  }

  getCitizenFlags(): any {
    return this.data.CITIZEN || [];
  }
}
