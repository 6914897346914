/**
 * Created by Ganeshram Kumhar (ganeshram.kumhar@kahunasystems.com)
 */
import { ModuleWithProviders, NgModule } from '@angular/core';
import { LoaderService } from './loader.service';
import { LoaderDirective } from './loader.directive';
import { LoaderComponent } from './loader.component';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [CommonModule],
  declarations: [LoaderDirective, LoaderComponent],
  exports: [LoaderDirective, LoaderComponent],
})
export class LoaderModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: LoaderModule,
      providers: [LoaderService]
    };
  }
}
