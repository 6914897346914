import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { MainMenuItem } from '../../layout-constants';
import { MenuService } from '../menu.service';

@Component({
  selector: 'portal-top-toolbar-menus',
  templateUrl: './top-toolbar-menus.component.html',
  styleUrls: ['./top-toolbar-menus.component.scss']
})
export class TopToolbarMenusComponent implements OnInit, OnDestroy {

  private toolbarMenuSubscription: Subscription;
  public toolbarMenus: MainMenuItem[] = [];

  constructor(private menuService: MenuService) {
  }

  ngOnInit() {
    this.toolbarMenuSubscription = this.menuService.getToolbarMenus().subscribe((menus: MainMenuItem[]) => {
      this.toolbarMenus = menus;
    });
  }

  ngOnDestroy(): void {
    if (this.toolbarMenuSubscription) {
      this.toolbarMenuSubscription.unsubscribe();
    }
  }
}
