import { Component, Inject } from '@angular/core';
import { CommonCookieService } from 'kscookies';
import { LayoutService } from '../../layout.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'portal-theme-switcher-menu',
  templateUrl: './theme-switcher-menu.component.html',
  styleUrls: ['./theme-switcher-menu.component.scss']
})
export class ThemeSwitcherMenuComponent {
  themes: Theme[] = [
    {
      id: 'classic-light_blue-pink',
      name: 'Classic - Light Blue / Pink',
      url: 'styles.css',
      sidebar: '#424242',
      toolbar: '#FFF',
      content: '#FFF',
      primary: '#03A9F4',
      accent: '#FF4081'
    },
    {
      id: 'classic-green-deep_orange',
      name: 'Classic - Green / Deep Orange',
      url: 'theme-prevent.css',
      sidebar: '#424242',
      toolbar: '#FFF',
      content: '#FFF',
      primary: '#4CAF50',
      accent: '#FF5722'
    },
    {
      id: 'classic-indigo-orange',
      name: 'theme-gd-brush',
      url: 'theme-gd-brush.css',
      sidebar: '#424242',
      toolbar: '#FFF',
      content: '#FFF',
      primary: '#3F51B5',
      accent: '#FF9800'
    },
    {
      id: 'dark-light_blue-pink',
      name: 'Dark - Light Blue / Pink',
      url: 'theme-dark-light_blue-pink.css',
      sidebar: '#424242',
      toolbar: '#424242',
      content: '#FFF',
      primary: '#03A9F4',
      accent: '#FF4081'
    },
    {
      id: 'dark-green-deep_orange',
      name: 'Dark - Green / Deep Orange',
      url: 'theme-mynf.css',
      sidebar: '#424242',
      toolbar: '#424242',
      content: '#FFF',
      primary: '#4CAF50',
      accent: '#FF5722'
    },
    {
      id: 'dark-indigo-orange',
      name: 'Dark - Indigo / Orange',
      url: 'theme-dark-indigo-orange.css',
      sidebar: '#424242',
      toolbar: '#424242',
      content: '#FFF',
      primary: '#3F51B5',
      accent: '#FF9800'
    },
    {
      id: 'night-light_blue-pink',
      name: 'Night - Light Blue / Pink',
      url: 'theme-engage.css',
      sidebar: '#424242',
      toolbar: '#424242',
      content: '#424242',
      primary: '#03A9F4',
      accent: '#FF4081'
    },
    {
      id: 'night-green-deep_orange',
      name: 'Night - Green / Deep Orange',
      url: 'theme-night-green-deep_orange.css',
      sidebar: '#424242',
      toolbar: '#424242',
      content: '#424242',
      primary: '#4CAF50',
      accent: '#FF5722'
    },
    {
      id: 'night-indigo-orange',
      name: 'Night - Indigo / Orange',
      url: 'theme-night-indigo-orange.css',
      sidebar: '#424242',
      toolbar: '#424242',
      content: '#424242',
      primary: '#3F51B5',
      accent: '#FF9800'
    },
    {
      id: 'Procurement-theme',
      name: 'Procurement Theme',
      url: 'theme-procurement.css',
      sidebar: '#424242',
      toolbar: '#424242',
      content: '#424242',
      primary: '#3F51B5',
      accent: '#FF9800'
    },
  ];

  currentTheme: Theme = this.themes[0];

  constructor(
    @Inject(DOCUMENT) private document: any,
    private layoutService: LayoutService,
    private cookieService: CommonCookieService
  ) {
    const themeID = this.cookieService.getCookie('portal-theme');
    if (themeID) {
      const theme = this.themes.find(findTheme => findTheme.id === themeID);
      if (undefined !== theme) {
        // this.switch(theme);
      }
    }
  }

  switch(theme: Theme): void {
    this.layoutService.switchTheme(theme.url);
  }
}

interface Theme {
  id: string;
  name: string;
  url: string;
  sidebar: string;
  toolbar: string;
  content: string;
  primary: string;
  accent: string;
}
