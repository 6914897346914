import { Pipe, PipeTransform } from '@angular/core';
import { Field } from '../types/field.types';
// if there is any field dependent, then it will checked by this pipe.
@Pipe({
  name: 'checkDependent',
  pure: false
})
export class CheckDependentPipe implements PipeTransform {

  transform(value: any, field: Field, requestPayload: any, languageKey: string): any {
    if (field.isDependant && field.dependOn && field.dependOn.bindKey && (requestPayload[field.dependOn.bindKey] || requestPayload[field.dependOn.bindKey] === false)) {
      if (requestPayload[field.dependOn.bindKey] instanceof Array) {
        return field.dependOn.value.some(aValue => requestPayload[field.dependOn.bindKey].includes(aValue));
      } else {
        return field.dependOn.value.indexOf(requestPayload[field.dependOn.bindKey]) !== -1;
      }
    }
    return false;
  }

}
