import {Injectable} from '@angular/core';
import {WebService} from 'web-service';
import {LanguageService} from '../language/language.service';
import {ServerUrls} from '../../server-url-constants';
import {AppConstants} from '../../constants/app-constants';

// import * as $ from 'jquery';

interface FroalaEditorSetting {
  key: string;
  placeholderText?: string;
  imageUploadURL?: string;
  fileUploadURL?: string;
  imageManagerLoadUrl?: any[];
  imageManagerLoadMethod?: string;
  height: number;
  fontFamily?: any;
  toolbarSticky?: boolean;
  imageUploadMethod?: string;
  imageManagerPageSize?: number;
  htmlAllowedTags?: string[];
  toolbarButtons?: string[];
  toolbarButtonsMD?: string[];
  toolbarButtonsSM?: string[];
  toolbarButtonsXS?: string[];
  convertMailAddresses?: boolean;
  plainPaste?: boolean;
  wordPasteKeepFormatting?: boolean;
  wordPasteModal?: boolean;
}

export const DATA = {
  '${firstName}': 'First Name',
  '${lastName}': 'Last Name',
  // '${address}': 'Address',
  '${email}': 'Email',
  // '${priority}': 'Priority',
  // '${lastUpdatedByUserName}': 'Last UpdatedBy UserName',
  // '${verificationLink}': 'Mailing Subscription Verification Link',
  // '${unsubscribeLink}': 'Mailing Subscription Un-Subscribe Link'
};

export const PROCUREMENT_DATA = {
  '${firstName}': 'Applicant First Name',
  '${lastName}': 'Applicant Last Name',
  '${email}': 'Email',
  '${orgEmail}': 'Applicant Organization Email',
  '${procurementName}': 'Opportunity Name',
  '${orgName}': 'Applicant Organization Name',
  '${orgAddress}': 'Applicant Organization Address',
  '${contractAmt}': 'Contract Amount',
  '${serviceName}': 'Name of Service',
  '${contractStartDate}': 'Contract Start Date',
  '${contractEndDate}': 'Contract End Date',
  '${programStaffName}': 'Name of Program Staff',
  '${procurementReleaseDate}': 'Release Date of Procurement',
  '${date}': 'Date',
  '${programStaffContactNumber}': 'Program Staff Contact Number'
};
const froalaKey = 'RA3E3A14B9D9E7kF4A3C3H3C6B7B6A3D3A2C-8LAHYKAJOEh1HQDUH==';

@Injectable()
export class FroalaEditorSettingService {
  private froalaOptions: FroalaEditorSetting = {
    key: froalaKey,
    placeholderText: 'Type Something',
    imageUploadURL: '',
    height: 300,
    toolbarSticky: false,
    imageUploadMethod: 'POST',
    imageManagerPageSize: 1,
    htmlAllowedTags: ['code', 'span', 'label', 'a', 'br', 'p', 'b', 'i', 'del', 'strike', 'u', 'blockquote', 'mark', 'cite',
      'small', 'ul', 'ol', 'li', 'hr', 'dl', 'dt', 'dd', 'sup', 'sub', 'big', 'pre', 'figure', 'figcaption', 'strong', 'em',
      'table', 'tr', 'td', 'th', 'tbody', 'thead', 'tfoot', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'img', 'iframe', 'video'],
    toolbarButtons: ['fullscreen', 'bold', 'italic', 'underline', 'strikeThrough', 'subscript',
      'superscript', 'fontFamily', 'fontSize', '|', 'color', 'emoticons', 'inlineStyle',
      'paragraphStyle', '|', 'paragraphFormat', 'align', 'formatOL', 'formatUL', 'outdent', 'indent',
      'quote', 'insertHR', 'insertLink', 'insertImage', 'insertFile', 'insertTable', 'undo',
      'redo', 'clearFormatting', 'selectAll', 'html', 'dropDown'],
    toolbarButtonsMD: ['fullscreen', 'bold', 'italic', 'underline', 'strikeThrough', 'subscript',
      'superscript', 'fontFamily', 'fontSize', '|', 'color', 'emoticons', 'inlineStyle',
      'paragraphStyle', '|', 'paragraphFormat', 'align', 'formatOL', 'formatUL', 'outdent', 'indent',
      'quote', 'insertHR', 'insertLink', 'insertImage', 'insertFile', 'insertTable', 'undo',
      'redo', 'clearFormatting', 'selectAll', 'html', 'dropDown'],
    toolbarButtonsSM: ['fullscreen', 'bold', 'italic', 'underline', 'strikeThrough', 'subscript',
      'superscript', 'fontFamily', 'fontSize', '|', 'color', 'emoticons', 'inlineStyle',
      'paragraphStyle', '|', 'paragraphFormat', 'align', 'formatOL', 'formatUL', 'outdent', 'indent',
      'quote', 'insertHR', 'insertLink', 'insertImage', 'insertFile', 'insertTable', 'undo',
      'redo', 'clearFormatting', 'selectAll', 'html', 'dropDown'],
    toolbarButtonsXS: ['fullscreen', 'bold', 'italic', 'underline', 'strikeThrough', 'subscript',
      'superscript', 'fontFamily', 'fontSize', '|', 'color', 'emoticons', 'inlineStyle',
      'paragraphStyle', '|', 'paragraphFormat', 'align', 'formatOL', 'formatUL', 'outdent', 'indent',
      'quote', 'insertHR', 'insertLink', 'insertImage', 'insertFile', 'insertTable', 'undo',
      'redo', 'clearFormatting', 'selectAll', 'html', 'dropDown'],
    fontFamily: {
      'Arial,Helvetica,sans-serif': 'Arial',
      'Courier New,Courier,monospace': 'Courier New',
      'Georgia,serif': 'Georgia',
      'Quicksand, sans-serif': 'Quicksand',
      'Impact,Charcoal,sans-serif': 'Impact',
      'Lucida Console,Monaco,monospace': 'Lucida Console',
      'Tahoma,Geneva,sans-serif': 'Tahoma',
      'Times New Roman,Times,serif': 'Times New Roman',
      'Verdana,Geneva,sans-serif': 'Verdana'
    },
    convertMailAddresses: false,
    plainPaste: true,
    wordPasteKeepFormatting: false,
    wordPasteModal: false
  };

  private froalaOptionsForPN: FroalaEditorSetting = {
    key: froalaKey,
    imageManagerLoadUrl: [],
    height: 300,
    imageManagerLoadMethod: 'POST',
    imageManagerPageSize: 1,
    placeholderText: 'Edit Your Content Here!',
    toolbarButtons: ['dropDown', '|'],
    toolbarButtonsSM: ['dropDown', '|'],
    toolbarButtonsMD: ['dropDown', '|'],
    toolbarButtonsXS: ['dropDown', '|'],
    convertMailAddresses: false,
    plainPaste: true,
  };

  constructor(private webService: WebService, private translateService: LanguageService) {
  }

  public getFroalaOptions(imgUrl: string, pText?: string, editable = true): FroalaEditorSetting {
    this.froalaOptions = this.getConfigFroalaOptions(editable);
    this.froalaOptions.imageUploadURL = imgUrl;
    this.froalaOptions.fileUploadURL = imgUrl;
    this.froalaOptions.placeholderText = pText;
    this.froalaOptions['requestHeaders'] = {
      Authorization: 'Bearer ' + this.webService.getTokenDetails(),
      language: this.translateService.getDefaultLang()
    };
    return this.froalaOptions;
  }

  public getFroalaOptionsForPn(imgUrlArray?: any[], pText?: string): FroalaEditorSetting {
    this.froalaOptionsForPN.imageManagerLoadUrl = imgUrlArray ? imgUrlArray : [
      {
        'url': 'http://www.planwallpaper.com/static/images/big-love2.jpg'
      },
      {
        'url': 'http://img.talkandroid.com/uploads/2013/05/google-logo-640-80.jpg'
      }
    ]
    ;
    this.froalaOptions.imageUploadURL = ServerUrls.serverUrl + AppConstants.API.imageUpload;
    this.froalaOptions.fileUploadURL = ServerUrls.serverUrl + AppConstants.API.imageUpload;
    this.froalaOptionsForPN.placeholderText = pText;
    this.froalaOptionsForPN['requestHeaders'] = {
      Authorization: 'Bearer ' + this.webService.getTokenDetails(),
      language: this.translateService.getDefaultLang()
    };
    return this.froalaOptionsForPN;
  }

  public getFroalaOptionsForEmail(imgUrlArray?: any[], pText?: string): FroalaEditorSetting {
    this.froalaOptions.imageManagerLoadUrl = imgUrlArray ? imgUrlArray : [
      {
        'url': 'http://www.planwallpaper.com/static/images/big-love2.jpg'
      },
      {
        'url': 'http://img.talkandroid.com/uploads/2013/05/google-logo-640-80.jpg'
      }
    ]
    ;
    this.froalaOptions.placeholderText = pText;
    this.froalaOptions.imageUploadURL = ServerUrls.serverUrl + AppConstants.API.imageUpload;
    this.froalaOptions.fileUploadURL = ServerUrls.serverUrl + AppConstants.API.imageUpload;
    this.froalaOptions['requestHeaders'] = {
      Authorization: 'Bearer ' + this.webService.getTokenDetails(),
      language: this.translateService.getDefaultLang()
    };
    return this.froalaOptions;
  }


  addDropDown(isNOFA: boolean = true): void {
    (<any>$).FroalaEditor.DefineIcon('dropDown', {NAME: 'dropDown'});
    (<any>$).FroalaEditor.RegisterCommand('dropDown', {
      title: 'Select',
      icon: 'Select',
      type: 'dropdown',
      focus: true,
      undo: false,
      refreshAfterCallback: true,
      options: isNOFA ? DATA : PROCUREMENT_DATA,
      callback: function (cmd: any, val: any) {
        this.html.insert(val);
      },
      refresh: function ($btn: any) {
      },
      refreshOnShow: function ($btn: any, $dropdown: any) {
      }
    });
  }

  getConfigFroalaOptions(editable = true) {
    return {
      events: {
        'froalaEditor.initialized': function (e, editor) {
          editable ? editor.edit.on() : editor.edit.off();
        }
      }, ...this.froalaOptions
    };
  }
}
