import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import {SharedModule} from '../shared/shared.module';
// Layouts
import {LayoutToolbarComponent} from './layout-toolbar/layout-toolbar.component';
import {LayoutCompactComponent} from './layout-compact/layout-compact.component';
// Components
import {UserMenuComponent} from './components/user-menu/user-menu.component';
import {MenuSidenavComponent} from './components/menu-sidenav/menu-sidenav.component';
import {VerticalIconMenuComponent} from './components/vertical-icon-menu/vertical-icon-menu.component';
import {ThemeSwitcherMenuComponent} from './components/theme-switcher-menu/theme-switcher-menu.component';
import {LayoutSwitcherMenuComponent} from './components/layout-switcher-menu/layout-switcher-menu.component';
import {PortalHeaderTitleDirective} from './directives/portal-header-title.directive';
import {HorizontalMenuComponent} from './components/horizontal-menu/horizontal-menu.component';
import {TopHorizontalMenuComponent} from './components/top-horizontal-menu/top-horizontal-menu.component';
import {LayoutLoaderComponent} from './components/layout-loader/layout-loader.component';
import {FooterComponent} from './components/footer/footer.component';
// Directives.
import {PortalScrollTopDirective} from './directives/portal-scroll-top.directive';
import {LoaderModule} from '../core/loader/loader.module';
import {MenuTreeItemComponent} from './components/menu-sidenav/menu-tree-item/menu-tree-item.component';
import {HorizontalMenuTreeComponent} from './components/horizontal-menu/horizontal-menu-tree/horizontal-menu-tree.component';
import {VerticalIconMenuTreeComponent} from './components/vertical-icon-menu/vertical-icon-menu-tree/vertical-icon-menu-tree.component';
import {TranslatePipe} from '@ngx-translate/core';
import {DropDownMatMenuComponent} from './components/drop-down-mat-menu/drop-down-mat-menu.component';
import {LayoutTabbedComponent} from './layout-tabbed/layout-tabbed.component';
import {DefaultTopNavbarComponent} from './components/default-top-navbar/default-top-navbar.component';
import {DefaultLayoutComponent} from './default-layout/default-layout.component';
import {GlobalSearchModule} from './components/global-search/global-search.module';
import {LayoutCitizenComponent} from './layout-citizen/layout-citizen.component';
import {LayoutToolbarTabbedComponent} from './layout-toolbar-tabbed/layout-toolbar-tabbed.component';
import {CustomHorizontalMenuComponent} from './components/custom-horizontal-menu/custom-horizontal-menu.component';
import {CustomMenuSidenavComponent} from './components/custom-menu-sidenav/custom-menu-sidenav.component';
import {CustomMenuTreeItemComponent} from './components/custom-menu-sidenav/custom-menu-tree-item/custom-menu-tree-item.component';
import {LayoutRoutingModule} from './layout-routing.module';
import {ChangePasswordModule} from '../feature-modules/authentication/change-password/change-password.module';
import {LoginLayoutComponent} from './login-layout/login-layout.component';
import {TopToolbarMenusComponent} from './components/top-toolbar-menus/top-toolbar-menus.component';
import {PopoverModule} from 'ngx-popover';
import {AuthenticationModule} from '../feature-modules/authentication/authentication.module';
import {LanguageMenuModule} from './components/language-menu/language-menu.module';
import {TopStaticTabsViewComponent} from './components/top-static-tabs-view/top-static-tabs-view.component';
import {NotificationBarModule} from '../feature-modules/common-components/notification-bar/notification-bar.module';
import {NotificationMenuComponent} from './components/notification-menu/notification-menu.component';
import {NotificationSidenavComponent} from './components/notification-sidenav/notification-sidenav.component';
import {NotificationScrollDirective} from './components/notification-sidenav/notification-scroll.directive';
import {LayoutClassicComponent} from './layout-classic/layout-classic.component';

@NgModule({
  imports: [
    LayoutRoutingModule,
    SharedModule,
    RouterModule,
    LoaderModule,
    LanguageMenuModule,
    PopoverModule,
    GlobalSearchModule,
    ChangePasswordModule,
    AuthenticationModule,
    NotificationBarModule.forRoot()
  ],
  declarations: [
    LayoutClassicComponent,
    LayoutToolbarComponent,
    LayoutCompactComponent,
    LayoutTabbedComponent,
    DefaultLayoutComponent,
    DefaultTopNavbarComponent,
    UserMenuComponent,
    MenuSidenavComponent,
    VerticalIconMenuComponent,
    ThemeSwitcherMenuComponent,
    LayoutSwitcherMenuComponent,
    PortalHeaderTitleDirective,
    HorizontalMenuComponent,
    TopHorizontalMenuComponent,
    PortalScrollTopDirective,
    LayoutLoaderComponent,
    FooterComponent,
    MenuTreeItemComponent,
    HorizontalMenuTreeComponent,
    VerticalIconMenuTreeComponent,
    DropDownMatMenuComponent,
    LayoutCitizenComponent,
    LayoutToolbarTabbedComponent,
    CustomHorizontalMenuComponent,
    CustomMenuSidenavComponent,
    CustomMenuTreeItemComponent,
    LoginLayoutComponent,
    TopToolbarMenusComponent,
    TopStaticTabsViewComponent,
    NotificationMenuComponent,
    NotificationSidenavComponent,
    NotificationScrollDirective
  ],
  providers: [TranslatePipe],
  exports: [
    TopStaticTabsViewComponent,
    PortalScrollTopDirective
  ],
  entryComponents: [FooterComponent]
})
export class LayoutsModule {
  static dynamicComponentsMap​ = {
    FooterComponent
  };
}
