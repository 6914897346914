import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { ErrorDialogComponent } from './error-dialog.component';


@NgModule({
  imports: [SharedModule],
  declarations: [ErrorDialogComponent],
  entryComponents: [ErrorDialogComponent]
})
export class ErrorDialogModule {
}
