import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppSettingDetailsProto, AppSettingService } from '../../../core/app-setting.service';
import { Subscription } from 'rxjs';
import { HomepageConfigParam } from '../../homepage/canned-homepages/canned-homepages-list';
import { CannedHomepageService } from '../../homepage/canned-homepages/pipe/canned-homepage.service';
import { MatDialog } from '@angular/material';
import { AppUtilService } from '../../../core/app-util.service';
import { LoginBaseClass } from '../base-component/loginBaseClass';
import { OAuth2Service } from 'o-auth2';
import { WebService } from 'web-service';
import { UserService } from '../../../core/user.service';
import { LoaderService } from '../../../core/loader/loader.service';
import { LayoutService } from '../../../layouts/layout.service';
import { FacebookAuthService } from '../base-component/social-login/facebook-auth.service';
import { CommonCookieService } from 'kscookies';
import { Location } from '@angular/common';
import { RegisterDialogComponent } from '../register-dialog/register-dialog/register-dialog.component';
import { LoginDialogComponent } from '../login-dialog/login-dialog/login-dialog.component';
import { LogoutService } from "../logout/logout.service";

@Component({
  selector: 'portal-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent extends LoginBaseClass implements OnInit, OnDestroy {

  registerForm: FormGroup;
  public appSetting: AppSettingDetailsProto;
  private subscriber: Subscription;


  constructor(public router: Router,
              public authService: OAuth2Service,
              public webService: WebService,
              public userService: UserService,
              public loader: LoaderService,
              public layoutService: LayoutService,
              public facebookAuth: FacebookAuthService,
              public cookieService: CommonCookieService,
              public logoutService: LogoutService,
              public utilService: AppUtilService,
              public matDialog: MatDialog,
              private appSettingService: AppSettingService,
              public cannedHomepageService: CannedHomepageService,
              public location: Location,
              private formBuilder: FormBuilder,
  ) {
    super(router, authService, webService, userService, loader, layoutService, facebookAuth, cookieService, utilService, matDialog, location, logoutService, cannedHomepageService);

    const emailRegex = '^[a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,15})$';
    const formOptions = {
      'first_name': ['', Validators.required],
      'last_name': ['', Validators.required],
      'email': ['', [Validators.required, Validators.pattern(emailRegex)]],
      'password': ['', Validators.required],
      'passwordConfirm': ['', Validators.required]
    };

    this.registerForm = this.formBuilder.group(formOptions, {
      validator: this.matchingPasswordsValidator('password', 'passwordConfirm')
    });
  }

  ngOnInit(): void {
    this.setHomepageConfig();
    if (this.homepageConfig[this.homepageConfigParam.SHOW_SOCIAL_LOGIN_ON_REGISTER]) {
      this.getSocialTemplateDetails();
    }

    this.subscriber = this.appSettingService.getObserver().subscribe((response) => {
      this.appSetting = response;
    });
  }

  ngOnDestroy(): void {
    if (this.subscriber) {
      this.subscriber.unsubscribe();
    }
  }

  goToRegister(): void {
    if (this.isRegisterDialog) {
      this.matDialog.open(RegisterDialogComponent, this.utilService.getDialogData(null));
    } else {
      this.router.navigate(['/register']);
    }
  }

  goToLogin(): void {
    if (this.isLoginDialog) {
      this.matDialog.open(LoginDialogComponent, this.utilService.getDialogData(null));
    } else {
      this.router.navigate(['/login']);
    }
  }

  matchingPasswordsValidator(passwordKey: string, confirmPasswordKey: string): any {
    return (group: FormGroup) => {
      const password = group.controls[passwordKey];
      const confirmPassword = group.controls[confirmPasswordKey];

      if (!password.value || !confirmPassword.value) {
        return null;
      }
      if (password.value !== confirmPassword.value) {
        return {
          mismatchedPasswords: true
        };
      }
      return null;
    };
  }

}
