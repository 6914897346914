import { Component, HostListener, OnInit } from '@angular/core';
import { WebService } from 'web-service';
import { ToasterService } from '../../../../../../core/toaster/toaster.service';
import { LoaderService } from '../../../../../../core/loader/loader.service';
import { AppUtilService } from '../../../../../../core/app-util.service';
import { MenuService } from '../../../../../../layouts/components/menu.service';
import { MatDialog, MatDialogRef } from '@angular/material';
import {
  ContactListResponse,
  CrmContactConstants,
  excelExport,
  GetByViewObj,
  KendoPaginationProto,
  secretPhrase,
  ViewSaveRequest
} from '../../crm-contact-constants';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { RightMaskHandler } from '../../../../../../shared/classes/right-mask-handler/right-mask-handler';
import { ConfirmDialogComponent } from '../../../../../../core/confirm-dialog/confirm-dialog.component';
import { EditCrmContactDialogComponent } from '../../edit-crm-contact-dialog/edit-crm-contact-dialog.component';
import { ViewCrmContactComponent } from '../../view-crm-contact/view-crm-contact.component';
import { SaveListDialogComponent } from '../save-list-dialog/save-list-dialog.component';
import { SendMailDialogComponent } from '../send-mail-dialog/send-mail.component';
import { saveAs } from 'file-saver';
import { PromtDialogComponent } from '../../../../../../core/promt-dialog/promt-dialog.component';
import { ResponseFieldResponse } from '../types/response-field';

@Component({
  selector: 'app-crm-contact-list',
  templateUrl: './crm-contact-list.component.html',
  styleUrls: ['./crm-contact-list.component.scss', './../crm-contact.component.scss']
})
export class CrmContactListComponent extends RightMaskHandler implements OnInit {

  public viewListItems: any[] = [];
  public listUserData: GridDataResult = {'data': [], 'total': 0};
  public getByViewObj: GetByViewObj = new GetByViewObj();
  public searchListDataPagObj: KendoPaginationProto = new KendoPaginationProto();
  public listResponseData;
  public viewSaveRequest = new ViewSaveRequest();
  public fieldSortList: any[];
  public userListField: any[] = [];
  public staticFields: any[];
  public removeCrmList: any[] = [];
  public listId: string = '';
  public excelObj = new excelExport();
  public innerWidth: number = 280;
  public outerWidth: number;
  private dialogRef: MatDialogRef<SendMailDialogComponent>;

  constructor(private webService: WebService,
              private toasterService: ToasterService,
              private loaderService: LoaderService,
              private appUtilService: AppUtilService,
              private dialog: MatDialog, public menuService: MenuService,
              public toaster: ToasterService) {
    super(menuService);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setGridColumnSize();
  }

  @HostListener('window:keyup.esc') onKeyUp() {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  ngOnInit() {
    this.getRightMask('app-crm-contact');
    this.getDefaultColumns();
    this.getContactList();
  }

  setGridColumnSize() {
    this.innerWidth = window.innerWidth;
    this.innerWidth = this.innerWidth / this.userListField.length;
  }

  sendMail(viewListId) {
    const data = {
      view: viewListId,
      crmList: this.removeCrmList
    };
    const dialogData = this.appUtilService.getDialogData(data);
    dialogData.disableClose = true;
    this.dialogRef = this.dialog.open(SendMailDialogComponent, dialogData);
  }

  public exportToExcel(id: any): void {
    const d = JSON.stringify({id: this.listId});
    const data = {
      moduleName: 'crm',
      additionalInfo: {
        isList: true,
        request: d
      },
      fileType: 'EXCEL'
    };
    this.webService.downloadCertificatePost(CrmContactConstants.API.contact.export,
        data, 'application/json')
        .subscribe((response) => {
          const blob = new Blob([response], {type: 'application/vnd.ms-excel'});
          saveAs(blob, 'crm-list.xlsx');
        });
  }

  onPageChange(event: PageChangeEvent, id: string): void {
    if (this.listId) {
      this.searchListDataPagObj.pageSize = event.take;
      this.searchListDataPagObj.skip = event.skip;
      this.getDataById(id);
    }
  }

  getDefaultColumns() {
    this.webService.get(CrmContactConstants.API.contact.getDefaultColumns)
        .subscribe((response: ResponseFieldResponse) => {
          if (response.status.code === 200) {
            /* response.responseFields = [];
             if (response.crmFields) {
               response.responseFields = response.responseFields.concat(response.crmFields);
             }*/
            this.staticFields = [...response.responseFields];
            this.fieldSortList = [...response.responseFields];
            this.userListField = this.fieldSortList.filter(object => object.default);
            this.setGridColumnSize();
          }
        }, (err) => {
        });
  }

  deleteView(id: string): void {
    const data = this.appUtilService.getDeleteMsg();
    data.message = 'CRM_CONTACT.LIST.DELETE_CONFIRM';
    this.dialog.open(PromtDialogComponent, this.appUtilService.getDialogData(data))
        .afterClosed().subscribe((result) => {
      if (result) {
        if (result.comment === this.listResponseData.list.name) {
          this.loaderService.show();
          this.webService.delete(CrmContactConstants.API.contact.deleteList + id)
              .subscribe((response) => {
                this.loaderService.hide();
                if (response.status.code === 200) {
                  this.toaster.showToast(response.status.message, 'success');
                  this.getContactList();
                  this.listUserData.data = [];
                  this.listUserData.total = 0;
                  this.listId = '';
                } else {
                  this.toaster.showToast(response.status.message, 'error');
                }
              }, (() => {
                this.loaderService.hide();
              }));
        } else {
          this.toaster.showToast('List name not matched', 'error');
        }
      }
    });
  }


  public menuChange(menu: any, event: any, i: number) {
    if (event.checked) {
      menu.default = true;
      this.userListField.push(this.fieldSortList[i]);

    } else {
      // const index1 = this.userListField.indexOf(this.fieldList[i].key);
      const index1 = this.userListField.map(function (e) {
        return e.key;
      }).indexOf(menu.key);
      if (index1 >= 0) {
        this.userListField.splice(index1, 1);
      }
      menu.default = false;
    }
    this.setGridColumnSize();
  }

  onAddContactsToList(isUpdate: boolean): void {
    let dialogData: any = {};
    dialogData = this.listResponseData.list;
    dialogData.viewList = this.viewListItems;
    if (isUpdate) {
      dialogData = this.listResponseData.list;
      dialogData.isUpdate = isUpdate;
    }
    if (this.removeCrmList.length > 0) {
      dialogData.config.crmIds = this.removeCrmList;
    } else {
      dialogData.config.crmIds = [];
    }
    this.dialog.open(SaveListDialogComponent, this.appUtilService.getDialogData(dialogData))
        .afterClosed().subscribe((res) => {
      if (res) {
        if (isUpdate) {
          this.getDataById(dialogData.id);
        }
        this.getContactList();
      }
    });
  }


  getContactList(): void {
    this.loaderService.show();
    this.webService.get(CrmContactConstants.API.contact.getContactList)
        .subscribe((response: ContactListResponse) => {
          this.loaderService.hide();
          if (response.status.code === 200) {
            this.viewListItems = response.lists;
            if (this.viewListItems.length > 0) {
              this.listId = this.viewListItems[0].id;
              this.getDataById(this.listId);
            }
          }
        }, () => this.loaderService.hide());
  }

  removeContactChangeEvent(event: any): void {
    event.selectedRows.forEach((v, i) => {
      this.removeCrmList.push(v.dataItem.crmId);
    });
    event.deselectedRows.forEach((v, i) => {
      this.removeCrmList.forEach((id, k) => {
        if (id === v.dataItem.crmId) {
          this.removeCrmList.splice(k, 1);
        }
      });
    });
  }


  getDataById(id?: any) {
    if (id) {
      this.getByViewObj.id = id;
    }
    this.getByViewObj.pageNumber = this.searchListDataPagObj.skip / this.searchListDataPagObj.pageSize + 1;
    this.getByViewObj.pageSize = this.searchListDataPagObj.pageSize;
    this.webService.post(CrmContactConstants.API.contact.getByIdList, this.getByViewObj)
        .subscribe((response) => {
          this.loaderService.hide();
          if (response.status.code === 200) {
            this.listResponseData = response;
            if (this.listResponseData.list.mailingListConfig) {
              this.viewSaveRequest.mailingListConfig = this.listResponseData.list.mailingListConfig;
            } else {
              delete this.viewSaveRequest.mailingListConfig;
            }
            if (response.userList) {
              if (response.userList.length > 0) {
                this.listUserData.data = [...response.userList];
                this.listUserData.total = response.count;
              }
            } else {
              this.listUserData.data = [];
              this.listUserData.total = 0;
            }
          }
        });
  }


  viewContact(data: any): void {
    this.dialog.open(ViewCrmContactComponent, this.appUtilService.getDialogDataFullScreen(data)).afterClosed()
        .subscribe((result) => {
          if (result) {
          }
        });
  }

  addEditContact(data?: any): void {
    if (data) {
      const crmId = CryptoJS.AES.encrypt(data.crmId, secretPhrase).toString();
      this.dialog.open(EditCrmContactDialogComponent, this.appUtilService.getDialogData(crmId)).afterClosed()
          .subscribe((result) => {
            if (result) {
              this.getDataById(this.listId);
            }
          });
    } else {
      this.dialog.open(EditCrmContactDialogComponent, this.appUtilService.getDialogData(data)).afterClosed()
          .subscribe((result) => {
            if (result) {
              this.getDataById(this.listId);
            }
          });
    }
  }

  removeContactsToList(): void {
    const dialogData = this.appUtilService.getDialogData(this.appUtilService.getDeleteMsg());
    dialogData.data = {
      'title': 'CRM_CONTACT.LIST.REMOVE_USER_CONFIRMATION.TITLE',
      'message': 'CRM_CONTACT.LIST.REMOVE_USER_CONFIRMATION.MESSAGE'
    };
    this.dialog.open(
        ConfirmDialogComponent,
        dialogData).afterClosed().subscribe((result) => {
      if (result) {
        this.loaderService.show();
        this.viewSaveRequest = new ViewSaveRequest();
        this.viewSaveRequest.name = this.listResponseData.list.name;
        this.viewSaveRequest.config = this.listResponseData.list.config;
        if (this.listResponseData.list.mailingListConfig) {
          this.viewSaveRequest.mailingListConfig = this.listResponseData.list.mailingListConfig;
        } else {
          delete this.viewSaveRequest.mailingListConfig;
        }
        delete this.viewSaveRequest.description;
        delete this.viewSaveRequest.fields;
        delete this.viewSaveRequest.removeFromList;
        this.viewSaveRequest.crmList = this.removeCrmList;
        this.viewSaveRequest.id = this.listResponseData.list.id;
        this.viewSaveRequest.removeFromList = true;
        this.webService.put(CrmContactConstants.API.contact.updateList, this.viewSaveRequest)
            .subscribe((response) => {
              this.loaderService.hide();
              if (response.status.code === 200) {
                this.toaster.showToast(response.status.message, 'success');
                this.getDataById(this.listId);
                this.removeCrmList = [];
              } else {
                this.toaster.showToast(response.status.message, 'error');
              }
            }, () => this.loaderService.hide());
      }
    });
  }

  resetListFilter(): void {
    this.listUserData.data = [];
    this.listUserData.total = 0;
    this.listResponseData = {};
    this.listId = '';
    this.userListField = [];
    this.removeCrmList = [];
    this.getDefaultColumns();
    this.searchListDataPagObj = new KendoPaginationProto();
    if (this.viewListItems.length > 0) {
      this.listId = this.viewListItems[0].id;
      this.getDataById(this.listId);
    }
  }

  getDescription() {
    return this.appUtilService.findObject(this.viewListItems, 'id', this.listId).description;
  }

}
