import { Component, OnDestroy, OnInit } from '@angular/core';
import { CannedHomepageService } from '../../feature-modules/homepage/canned-homepages/pipe/canned-homepage.service';
import { Subscription } from 'rxjs';
import { HomepageConfigParam } from '../../feature-modules/homepage/canned-homepages/canned-homepages-list';

@Component({
  selector: 'app-default-layout',
  templateUrl: './login-layout.component.html',
  styleUrls: ['./login-layout.component.scss']
})
export class LoginLayoutComponent implements OnInit, OnDestroy {

  public homepageConfigParam: typeof HomepageConfigParam = HomepageConfigParam;
  public homepageConfig: Map<HomepageConfigParam, any> = new Map<HomepageConfigParam, any>();
  private subscriber: Subscription;

  constructor(private cannedHomepageService: CannedHomepageService) {
  }

  ngOnInit(): void {
    this.subscriber = this.cannedHomepageService.getHomepageConfigObservable().subscribe((result) => {
      this.homepageConfig = result;
    });
  }

  ngOnDestroy(): void {
    if (this.subscriber) {
      this.subscriber.unsubscribe();
    }
  }

}
