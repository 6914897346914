/**
 * Created by dinesh on 18/05/17.
 */
import { Directive, ElementRef, EventEmitter, Output } from '@angular/core';


@Directive({
  selector: '[google-place]'
})

export class GooglePlaceDirective {
  autocomplete: any = '';
  @Output() onPlaceChange = new EventEmitter<any>();

  constructor(el: ElementRef) {
    this.autocomplete = new google.maps.places.Autocomplete(el.nativeElement);
    this.autocomplete.setComponentRestrictions({'country': ['us']});
    this.autocomplete.addListener('place_changed', () => {
      const place = this.autocomplete.getPlace();
      this.onPlaceChange.emit(place);
    });
    google.maps.event.addDomListener(el.nativeElement, 'keydown', function (event: KeyboardEvent) {
      if (event['keyCode'] === 13) {
        event.preventDefault();
      }
    });
  }
}
