import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MainMenuItem } from '../../../layout-constants';
import { MenuService } from '../../menu.service';

@Component({
  selector: 'app-menu-tree-item',
  templateUrl: './menu-tree-item.component.html',
  styleUrls: ['./menu-tree-item.component.scss']
})
export class MenuTreeItemComponent {

  @Input('menuItem') public menuItem: MainMenuItem;
  @Input('isTabbed') public isTabbed: boolean;

  @Output('onClick') public onMenuClick: EventEmitter<MainMenuItem> = new EventEmitter();

  constructor(public menuService: MenuService) {
  }
}
