import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppSettingDetailsProto, AppSettingService } from '../../core/app-setting.service';
import { NavigationEnd, Router } from '@angular/router';
import { ServerUrls } from '../../server-url-constants';
import { Subscription } from 'rxjs';
import { MenuService } from '../components/menu.service';

@Component({
  selector: 'app-layout-citizen',
  templateUrl: './layout-citizen.component.html',
  styleUrls: ['./layout-citizen.component.scss']
})
export class LayoutCitizenComponent implements OnInit, OnDestroy {

  public appSetting: AppSettingDetailsProto;
  hideBannerFor: string[] = ['/citizen/dashboard'];
  hideBanner: boolean = false;
  cep_config: boolean = false;
  subscribeUrl: Subscription;
  public hideGlobalSearch = ServerUrls.hideGlobalSearch;

  constructor(private appSettingService: AppSettingService,
              public router: Router,
              private menuService: MenuService) {
  }

  ngOnInit(): void {
    this.menuService.getMenus().subscribe();
    this.cep_config = ServerUrls.CEP_CONFIG;
    this.appSetting = this.appSettingService.getSetting();
    if (this.hideBannerFor.indexOf(this.router.url) !== -1) {
      this.hideBanner = true;
    }
    this.subscribeUrl = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.hideBannerFor.indexOf(event.url) !== -1) {
          this.hideBanner = true;
        } else {
          this.hideBanner = false;
        }
      }
    });
  }

  ngOnDestroy(): void {
    if (this.subscribeUrl) {
      this.subscribeUrl.unsubscribe();
    }
  }
}
