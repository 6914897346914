/**
 * Created by Ganeshram Kumhar (ganeshram.kumhar@kahunasystems.com)
 */
import { Injectable } from '@angular/core';
import { PageContentModelProto } from 'kscommon-components';
import { CannedHomepagesResponseFooterProto, } from '../canned-homepages-constants';
import { CannedHomepageList, CannedHomepagesEnum, HomepageConfigParam } from '../canned-homepages-list';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CannedHomepageService {

  /***
   * @Author : Ganeshram Kumhar (ganeshram.kumhar@kahunasystems.com)
   * @description : name of the canned layout received from server
   */
  private _layoutName: CannedHomepagesEnum;

  /***
   * @Author : Ganeshram Kumhar (ganeshram.kumhar@kahunasystems.com)
   * @description : map list of translate keys with value from server. map so we can access with key only
   */
  private _keyMap: Map<string, string> = new Map<string, string>();

  /***
   * @Author : Ganeshram Kumhar (ganeshram.kumhar@kahunasystems.com)
   * @description : to store homepage config parameters
   */
  private _homepageConfig: Map<HomepageConfigParam, any> = new Map<HomepageConfigParam, any>();
  private _homepageConfigObservable: BehaviorSubject<Map<HomepageConfigParam, any>> = new BehaviorSubject<Map<HomepageConfigParam, any>>(this.getHomepageConfig());

  /***
   * @Author : Ganeshram Kumhar (ganeshram.kumhar@kahunasystems.com)
   * @description : to store footer config details.
   */
  private _footerConfig: CannedHomepagesResponseFooterProto[] = [];
  /***
   * @Author : Ganeshram Kumhar (ganeshram.kumhar@kahunasystems.com)
   * @description : to store footer config details.
   */
  private _isPreview: boolean = false;

  /***
   * @Author : Ganeshram Kumhar (ganeshram.kumhar@kahunasystems.com)
   * @description : set homepage config param default value true
   * @returns: {void}
   */
  public setDefaultHomepageConfig(): void {
    for (const item in HomepageConfigParam) {
      if (item) {
        this._homepageConfig[item] = true;
      }
    }
    this._homepageConfigObservable.next(this._homepageConfig);
  }

  setTranslateKeyJson(translateObject: Object) {
    for (const translateObjectKey in translateObject) {
      if (translateObject[translateObjectKey]) {
        this.setValue(translateObjectKey, translateObject[translateObjectKey]);
      }
    }
  }

  /***
   * @Author : Ganeshram Kumhar (ganeshram.kumhar@kahunasystems.com)
   * @description : to get value of translate key. will return the value for the key.
   * @param: key {string} function will return value of this key
   * @returns: {string}
   */
  getValue(key: string): string {
    return this._keyMap[key] || '';
  }

  /***
   * @Author : Ganeshram Kumhar (ganeshram.kumhar@kahunasystems.com)
   * @description : to set value for the key in key map
   * @param: key {string} translate key
   * @param: value {string} value of the translate key
   * @returns: {void}
   */
  setValue(key: string, value: string): void {
    this._keyMap[key] = value;
  }

  /***
   * @Author : Ganeshram Kumhar (ganeshram.kumhar@kahunasystems.com)
   * @description : to get current layout name from server.
   * @returns: {cannedHomepagesEnum}
   */
  getLayoutName(): CannedHomepagesEnum {
    return this._layoutName;
  }

  setIsPreview(value: boolean): void {
    this._isPreview = value;
  }

  getIspreview(): boolean {
    return this._isPreview;
  }

  /***
   * @Author : Ganeshram Kumhar (ganeshram.kumhar@kahunasystems.com)
   * @description : to set layout name received from server
   * @param: layoutName {CannedHomepagesEnum} name of the layout
   * @returns: {void}
   */
  setLayoutName(layoutName: CannedHomepagesEnum): void {
    this._layoutName = layoutName;
  }

  /***
   * @Author : Ganeshram Kumhar (ganeshram.kumhar@kahunasystems.com)
   * @description : to get the module data for current layoutName from CannedHomepageList/
   * @returns: {PageContentModelProto[]}
   */
  getLayoutDetails(): PageContentModelProto[] {
    const findObject = CannedHomepageList.find((object) => {
      return object.type === this._layoutName;
    });
    if (findObject) {
      return findObject.moduleData;
    }
    return undefined;
  }

  /***
   * @Author : Ganeshram Kumhar (ganeshram.kumhar@kahunasystems.com)
   * @description : to get the module data for current layoutName from CannedHomepageList/
   * @returns: {PageContentModelProto[]}
   */
  getLayoutDetailsForLayoutName(layoutName: CannedHomepagesEnum): PageContentModelProto[] {
    const findObject = CannedHomepageList.find((object) => {
      return object.type === layoutName;
    });
    if (findObject) {
      return findObject.moduleData;
    }
    return undefined;
  }


  /***
   * @Author : Ganeshram Kumhar (ganeshram.kumhar@kahunasystems.com)
   * @description : to get the homepage config parameter observable
   * @returns: {Map<HomepageConfigParam, any>}
   */
  getHomepageConfigObservable(): Observable<Map<HomepageConfigParam, any>> {
    return this._homepageConfigObservable.asObservable();
  }

  /***
   * @Author : Ganeshram Kumhar (ganeshram.kumhar@kahunasystems.com)
   * @description : to get the homepage config parameter
   * @returns: {Map<HomepageConfigParam, any>}
   */
  getHomepageConfig(): Map<HomepageConfigParam, any> {
    return this._homepageConfig;
  }

  /***
   * @Author : Ganeshram Kumhar (ganeshram.kumhar@kahunasystems.com)
   * @description : to set home page config parameter
   * @param: config {Map<HomepageConfigParam, any>} map of configuration parameters
   * @returns: {void}
   */
  setHomepageConfig(config: Map<HomepageConfigParam, any>): void {
    this._homepageConfig = config;
    this._homepageConfigObservable.next(this._homepageConfig);
  }

  /***
   * @Author : Ganeshram Kumhar (ganeshram.kumhar@kahunasystems.com)
   * @description : to get footer configuration
   * @returns: {CannedHomepagesResponseFooterProto[]}
   */
  getFooterConfig(): CannedHomepagesResponseFooterProto[] {
    return this._footerConfig;
  }

  /***
   * @Author : Ganeshram Kumhar (ganeshram.kumhar@kahunasystems.com)
   * @description : set footer configuration
   * @param: footerConfig {CannedHomepagesResponseFooterProto[]} list of footer config objects
   * @returns: {void}
   */
  setFooterConfig(footerConfig: CannedHomepagesResponseFooterProto[]): void {
    this._footerConfig = footerConfig;
  }

}
