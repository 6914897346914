/**
 * Created by Ganeshram Kumhar (ganeshram.kumhar@kahunasystems.com)
 */

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {AppUtilService} from "../app-util.service";

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  showCount = 0;
  hideCount = 0;
  public loader: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private appUtilService: AppUtilService) {
  }

  show(): void {
   /* if(!this.appUtilService.RoutingLoadingIcon) {
      console.log("here 2");
      this.appUtilService.ApiLoadingIcon = true;
      this.loader.next(true);
    }*/
    // this.showCount++;
    this.loader.next(true);
  }

  hide(): void {
  //  this.appUtilService.ApiLoadingIcon = false;
    // this.hideCount++;
    // if (this.showCount == this.hideCount) {
    //   this.loader.next(false);
    // }
      this.loader.next(false);
  }

}
