import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {MatDrawer} from '@angular/material';
import {NotificationService} from '../notification-sidenav/notification.service';
import {Observable, Subscription} from 'rxjs';
import {APP_SETTING, ServerUrls} from '../../../server-url-constants';
import {LayoutService} from "../../layout.service";
import { DatePipe } from '@angular/common';

@Component({
  selector: 'portal-notification-menu',
  templateUrl: './notification-menu.component.html',
  styleUrls: ['./notification-menu.component.scss']
})
export class NotificationMenuComponent implements OnInit, OnDestroy {

  @Input('rightSidenav') rightSidenav: MatDrawer;
  public enableNotification: boolean = APP_SETTING.enableNotification;
  public isNOFA: boolean = ServerUrls.IS_NOFA;
  private badgeCount: number = 0;
  private subscription: Subscription;
  private notificationSubscription: Subscription;
  public sDate = new Date();
  public eDate = new Date();
  public notificationData

  constructor(public notificationService: NotificationService, public layoutService: LayoutService,
    private datePipe: DatePipe) {
  }

  ngOnInit() {
    this.sDate.setFullYear(this.sDate.getFullYear() - 1);
    const today = new Date();
    this.eDate.setDate(today.getDate() + 1)

    this.notificationData = {
    startDate: this.sDate,
    endDate: this.eDate
    }
    if (this.enableNotification) {
      this.notificationService.getNotifications();
      this.notificationService.getUnreadNotificationsCount(this.notificationData);
      this.fetchNotificationsCountInterval();
    }

    this.subscription = this.notificationService.getUnreadCount().subscribe((response) => {
      if (this.badgeCount !== response) {
        this.badgeCount = Number(response);
        this.notificationService.getNotifications();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.notificationSubscription) {
      this.notificationSubscription.unsubscribe();
    }
  }

  /***
   * @Author : Ganeshram Kumhar (ganeshram.kumhar@kahunasystems.com)
   * @description : used to get un read count of notifications from server
   * @returns: {void}
   */
  fetchNotificationsCountInterval() {
    this.notificationSubscription = Observable.interval(APP_SETTING.notificationInterval)
      .takeWhile(() => this.enableNotification)
      .subscribe(() => this.notificationService.getUnreadNotificationsCount(this.notificationData));
  }
}
