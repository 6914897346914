import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { MainMenuItem, StaticTabs } from "../layout-constants";
import { WebService } from "web-service";
import {
  AppConstants,
  ResponseStatusWrapper,
} from "../../constants/app-constants";
import { CommonCookieService } from "kscookies";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { UserService } from "app/core/user.service";
import * as _ from "lodash";
interface GetMenuForSidebarResponse {
  status: ResponseStatusWrapper;
  menus: MainMenuItem[];
}

@Injectable({
  providedIn: "root",
})
export class MenuService {
  public menus: MainMenuItem[] = [];
  public menuFilterKeyArray: any[] = [
    { rightkey: "manage-form-template", sequence: 1 },
    { rightkey: "app-manage-review-sheets-list", sequence: 2 },
    { rightkey: "email-template-list", sequence: 3 },
    { rightkey: "letter-template-list", sequence: 4 },
    { rightkey: "calendar", sequence: 5 },
    { rightkey: "manage-mailing-list", sequence: 6 },
    { rightkey: "Manage-FAQS-List", sequence: 7 },
    { rightkey: "App-category-list", sequence: 8 },
    { rightkey: "Manage-Sub-Category-List", sequence: 9 },
    { rightkey: "app-faq-list-view", sequence: 10 },
    { rightkey: "nofa-manage-organizations-view", sequence: 11 },
    { rightkey: "app-manage-funds", sequence: 12 },
    { rightkey: "manage-agency-users", sequence: 13 },
  ];
  public filteredMenus: MainMenuItem[] = [];
  public quickMenus$: BehaviorSubject<MainMenuItem[]> = new BehaviorSubject([]);
  public rightMasks$: BehaviorSubject<
    Map<string, string[]>
  > = new BehaviorSubject(new Map<string, string[]>());
  public staticTabClick: BehaviorSubject<string> = new BehaviorSubject<string>(
    ""
  );
  private quickMenus: MainMenuItem[] = [];
  private toolbarMenus: MainMenuItem[] = [];
  private tabbedMenu$: BehaviorSubject<MainMenuItem[]> = new BehaviorSubject<
    MainMenuItem[]
  >([]);
  private toolbarMenus$: BehaviorSubject<MainMenuItem[]> = new BehaviorSubject<
    MainMenuItem[]
  >([]);
  private menuForSearch: BehaviorSubject<MainMenuItem[]> = new BehaviorSubject<
    MainMenuItem[]
  >([]);
  private rightMaskObject: Map<string, string[]> = new Map<string, string[]>();
  /*static tabs*/
  private staticTabbedMenu$: BehaviorSubject<
    StaticTabs[]
  > = new BehaviorSubject<StaticTabs[]>([]);

  constructor(
    private webService: WebService,
    private cookieService: CommonCookieService,
    private router: Router,
    private translateService: TranslateService,
    public userService: UserService
  ) {}

  /***
   * @Author : Ganeshram Kumhar (ganeshram.kumhar@kahunasystems.com)
   * @description : to get menu list as observable
   * @param :
   * @returns: {void}
   */
  getMenus = (): Observable<MainMenuItem[]> => {
    return new Observable<MainMenuItem[]>((resolver) => {
      if (this.menus && this.menus.length > 0) {
        resolver.next(this.menus);
        resolver.complete();
      } else {
        this.menus = [];
        this.quickMenus = [];
        this.filteredMenus = [];
        this.webService
          .post(AppConstants.API.getMenuForSidebar, {
            pageNumber: -1,
            pageSize: -1,
          })
          .subscribe(
            (response: GetMenuForSidebarResponse) => {
              if (response.status.code === 200) {
                if(this.userService.getUser().userRole[0] !== 'SuperAdmin') {
                  this.filterMenuArray(response.menus);
                } else {
                  this.menus = response.menus;
                }
                //this.menus = response.menus;
                this.onMenuReceiveSuccess();
                resolver.next(response.menus);
                resolver.complete();
              } else {
                resolver.error(response);
              }
            },
            (error) => {
              resolver.error(error);
            }
          );
      }
    });
  };

  filterMenuArray(menus: MainMenuItem[]) {
    menus.forEach((item: MainMenuItem) => {
      let temp = this.menuFilterKeyArray.filter(
        (e) => e["rightkey"] === item.rightKey
      );
      if (temp && temp.length > 0) {
        item.sequence = temp[0]["sequence"];
        if (/* item.name["en"] !== "FAQs" && */ item.name["en"] !== "Manage FAQs") {
          this.filteredMenus.push(item);
          this.sortBy();
        }
      } else if (
        item.type === "DROPDOWN" &&
        item.name &&
        item.name["en"] !== "Ask a Question"
      ) {
        if (item.children) {

          item.children.forEach((childMenu) => {
            temp = this.menuFilterKeyArray.filter(
              (e) => e["rightkey"] === childMenu.rightKey
            );

            if (temp && temp.length > 0) {
              childMenu.sequence = temp[0]["sequence"];
            this.filteredMenus.push(childMenu);
            this.sortBy();
            }
          });
        }
      } else if (
        item.rightKey !== "Manage-FAQS-List" &&
        item.rightKey !== "app-manage-user"
      ) {
        this.menus.push(item);
      }
    });
  }
  sortBy(prop?: string) {
    return this.filteredMenus.sort((a, b) =>
      a["sequence"] > b["sequence"]
        ? 1
        : a["sequence"] === b["sequence"]
        ? 0
        : -1
    );
  }

  onMenuReceiveSuccess = (): void => {
    this.generateRightMask(this.menus);
    this.generateRightMask(this.filteredMenus);
    if (this.cookieService.getCookie("tabbed-menu")) {
      const objectMenu = this.menus.find((object) => {
        if (typeof object.name === "string") {
          return object.name === this.cookieService.getCookie("tabbed-menu");
        } else {
          return (
            object.name[this.translateService.getDefaultLang()] ===
            this.cookieService.getCookie("tabbed-menu")
          );
        }
      });
      if (objectMenu) {
        this.setTabbedMenu(objectMenu, false);
      }
    }
  };

  getCustomPage = (): Observable<any> => {
    return new Observable((resolve) => {
      this.webService.get(AppConstants.API.getCustomPageForUser).subscribe(
        (response) => {
          if (response.status.code === 200) {
            resolve.next(response);
          } else {
            resolve.error(response);
          }
        },
        (error) => {
          resolve.error(error);
        }
      );
    });
  };

  generateRightMask = (list: MainMenuItem[]): void => {
    list.forEach((item: MainMenuItem) => {
      this.rightMaskObject[item.rightKey] = item.rightMask || [];
      /*check for previous version and also for menu for page response*/
      if (typeof item.name !== "string") {
        item.menuName = item.name[this.translateService.getDefaultLang()];
      } else {
        item.menuName = item.name;
      }
      if (item.showInMenu) {
        if (
          item.type === "LINK" ||
          item.type === "CUSTOM_PAGE" ||
          item.type === "EXTERNAL"
        ) {
          this.setMenuForSearch(item);
        }
      }
      if (item.isQuick) {
        this.quickMenus.push(item);
        this.setQuickMenus(this.quickMenus);
      }
      if (item.isToolbar) {
        this.toolbarMenus.push(item);
        this.setToolbarMenus(this.toolbarMenus);
      }
      this.setRightMask(this.rightMaskObject);
      if (item.children && item.children.length > 0) {
        this.generateRightMask(item.children);
      }
    });
  };

  setRightMask = (value: Map<string, string[]>) => {
    this.rightMasks$.next(value);
  };

  getRightMask = (): Observable<Map<string, string[]>> => {
    return this.rightMasks$.asObservable();
  };

  getRightMaskForKey = (rightKey: string): string[] => {
    return this.rightMaskObject[rightKey];
  };

  setQuickMenus = (value: MainMenuItem[]) => {
    this.quickMenus$.next(value);
  };

  getQuickMenus = (): Observable<MainMenuItem[]> => {
    return this.quickMenus$.asObservable();
  };

  setToolbarMenus = (value: MainMenuItem[]) => {
    this.toolbarMenus$.next(value);
  };

  getToolbarMenus = (): Observable<MainMenuItem[]> => {
    return this.toolbarMenus$.asObservable();
  };

  setTabbedMenu = (menu: MainMenuItem, navigate: boolean = true): void => {
    if (typeof menu.name !== "string") {
      this.cookieService.setCookie(
        "tabbed-menu",
        menu.name[this.translateService.getDefaultLang()]
      );
    } else {
      this.cookieService.setCookie("tabbed-menu", menu.name);
    }
    if (navigate) {
      if (menu.children && menu.children.length > 0) {
        menu.children = menu.children.filter(
          (object) => object.type === "LINK"
        );
        if (menu.children.length > 0) {
          this.router.navigate([menu.children[0].state]);
        }
      }
    }
    this.tabbedMenu$.next(menu.children);
  };

  getTabbedMenu = (): Observable<MainMenuItem[]> => {
    return this.tabbedMenu$.asObservable();
  };

  removeTabbedMenu = (): void => {
    this.cookieService.removeCookie("tabbed-menu");
    this.tabbedMenu$.next([]);
  };

  getMenuForSearch(): Observable<MainMenuItem[]> {
    return this.menuForSearch.asObservable();
  }

  resetMenus(): void {
    this.menus = [];
    this.quickMenus = [];
    this.toolbarMenus = [];
    this.quickMenus$.next([]);
    this.tabbedMenu$.next([]);
    this.toolbarMenus$.next([]);
    this.menuForSearch.next([]);
    this.staticTabbedMenu$.next([]);
    this.rightMaskObject = new Map<string, string[]>();
    this.rightMasks$.next(new Map<string, string[]>());
  }

  /**
   * static tab functionality
   * */
  onClickStaticTab = (tabId: string): void => {
    this.staticTabClick.next(tabId);
  };

  getStaticTabs = (): Observable<any> => {
    return this.staticTabbedMenu$;
  };

  setStaticTabs = (tabs: StaticTabs[]): void => {
    this.staticTabbedMenu$.next(tabs);
  };

  setStaticTabItem = (id: string, item: StaticTabs): void => {
    const list = this.staticTabbedMenu$.getValue();
    const findIndex = list.findIndex((object) => object.id === id);
    if (findIndex > -1) {
      list[findIndex] = item;
    } else {
      list.push(item);
    }
    this.staticTabbedMenu$.next(list);
  };

  getStaticTabItem = (id: string): StaticTabs => {
    const list = this.staticTabbedMenu$.getValue();
    return list.find((object) => object.id === id);
  };

  private setMenuForSearch(menuItem: MainMenuItem): void {
    const list = this.menuForSearch.getValue();
    list.push(menuItem);
    this.menuForSearch.next(list);
  }
}
