import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AddEditListDialogData, CrmContactConstants, ViewSaveRequest } from '../../crm-contact-constants';
import { AppUtilService } from '../../../../../../core/app-util.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { WebService } from 'web-service';
import { ToasterService } from '../../../../../../core/toaster/toaster.service';
import { LoaderService } from '../../../../../../core/loader/loader.service';

@Component({
  selector: 'app-add-edit-list-dialog',
  templateUrl: './add-edit-list-dialog.component.html',
  styleUrls: ['./add-edit-list-dialog.component.scss']
})
export class AddEditListDialogComponent implements OnInit {

  public isUpdate: boolean = false;
  @ViewChild('addEditListViewForm') addEditListViewForm: NgForm;
  public requestPayload: ViewSaveRequest = new ViewSaveRequest();

  /**
   * local variable for manipulation*/
  public isCreateNew: boolean = true;
  public isMailingList: boolean = false;
  public viewList: any[] = [];
  public userGroupsList: any[] = [];
  public title: string = 'CRM_CONTACT.ADD_VIEW_LIST_DIALOG.UPDATE_LIST';

  constructor(public dialogRef: MatDialogRef<AddEditListDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public dialogData: AddEditListDialogData | any,
              public webService: WebService, private toaster: ToasterService,
              private loaderService: LoaderService, private appUtilService: AppUtilService) {
  }

  ngOnInit() {
    this.getGroups();
    this.title = this.dialogData.title;

    this.requestPayload.config = this.dialogData.config;

    if (this.dialogData.mailingListConfig) {
      this.requestPayload.mailingListConfig = this.dialogData.mailingListConfig;
    }

    if (this.dialogData.id) {
      this.isUpdate = true;
      this.requestPayload.name = this.dialogData.name;
      this.requestPayload.description = this.dialogData.description;
      this.requestPayload.id = this.dialogData.id;
      if (this.dialogData.fields) {
        if (this.dialogData.fields[this.dialogData.fields.length - 1] === 'view-change') {
          this.dialogData.fields.pop();
        }
      }
      this.requestPayload.fields = this.dialogData.fields;
      this.requestPayload.config.crmIds = this.dialogData.config.crmIds;
      this.requestPayload.crmList = this.dialogData.crmList;
      this.requestPayload.config.staffAccess = this.dialogData.config.staffAccess;
      this.requestPayload.config.staffUpdateAccess = this.dialogData.config.staffUpdateAccess;
      this.requestPayload.config.groupUpdateAccess = this.dialogData.config.groupUpdateAccess;
      this.requestPayload.config.groupIds = this.dialogData.config.groupIds;
      this.requestPayload.config.viewAll = this.dialogData.config.viewAll;

      if (this.requestPayload.config.staffUpdateAccess) {
        this.requestPayload.config.publicAllowAccess = 'all';
      }
      if (this.requestPayload.config.groupUpdateAccess) {
        this.requestPayload.config.publicAllowAccess = 'group';
      }

      if (this.requestPayload.config.groupIds.length > 0 || this.requestPayload.config.groupUpdateAccess) {
        this.requestPayload.config.groupAccess = true;
      }
    } else {
      this.requestPayload.fields = this.dialogData.fields;
      this.requestPayload.crmList = this.dialogData.crmList;
      this.requestPayload.config = this.dialogData.config;
    }

    if (this.dialogData.viewList) {
      if (this.dialogData.viewList.length > 0) {
        this.viewList = this.dialogData.viewList.filter((v) => v.updateAccess);
      }
    }
  }

  getGroups(): void {
    const reqObj = {
      pageNumber: -1,
      pageSize: -1
    };
    this.webService.post(CrmContactConstants.API.contact.get_group_mapping, reqObj)
      .subscribe(
        (response: any) => {
          if (response.status.code === 200) {
            this.userGroupsList = response.userGroups;
          }
        }, () => {
        });
  }

  setName() {
    this.requestPayload.name = this.appUtilService.findObject(this.viewList, 'id', this.requestPayload.id).name;
  }


  onSubmit() {
    const requestPayload = new ViewSaveRequest();

    requestPayload.id = this.requestPayload.id;
    requestPayload.name = this.requestPayload.name;
    requestPayload.description = this.requestPayload.description;
    requestPayload.mailingListConfig = this.requestPayload.mailingListConfig;
    requestPayload.responseFields = this.dialogData.responseFields;
    // requestPayload.responseFields = this.requestPayload.responseFields;
    requestPayload.removeFromList = this.requestPayload.removeFromList;
    requestPayload.fields = this.requestPayload.fields;
    requestPayload.crmList = this.requestPayload.crmList;

    /** config assign */
    requestPayload.config.viewAll = this.requestPayload.config.viewAll;

    requestPayload.config.crmIds = this.requestPayload.config.crmIds;
    requestPayload.config.crmUpdateAccess = this.requestPayload.config.crmUpdateAccess;

    requestPayload.config.staffAccess = this.requestPayload.config.staffAccess;
    requestPayload.config.staffUpdateAccess = this.requestPayload.config.staffUpdateAccess;

    requestPayload.config.groupAccess = this.requestPayload.config.groupAccess;
    requestPayload.config.groupUpdateAccess = this.requestPayload.config.groupUpdateAccess;
    requestPayload.config.groupIds = this.requestPayload.config.groupIds;

    if (this.requestPayload.crmList !== undefined) {
      if (this.requestPayload.crmList.length > 0) {
        requestPayload.removeFromList = false;
      }
    } else {
      delete requestPayload.removeFromList;
    }

    if (this.requestPayload.config.viewAll) {
      if (this.requestPayload.config.groupAccess) {
        requestPayload.config.staffUpdateAccess = this.requestPayload.config.publicAllowAccess === 'all';
        requestPayload.config.groupUpdateAccess = this.requestPayload.config.publicAllowAccess === 'group';
        if (!requestPayload.config.groupUpdateAccess) {
          requestPayload.config.groupIds = [];
        }
        requestPayload.config.staffAccess = true;
      } else {
        requestPayload.config.groupIds = [];
        requestPayload.config.staffAccess = true;
        requestPayload.config.staffUpdateAccess = false;
      }
    } else {
      if (!this.requestPayload.config.groupAccess) {
        requestPayload.config.groupIds = [];
      }
    }

    if (this.dialogData.fromAllContacts) {
      requestPayload.fields = this.dialogData.fields;
    } else {
      requestPayload.crmList = this.dialogData.crmList;
    }


    if (!this.isMailingList) {
      delete requestPayload.mailingListConfig;
    }

    requestPayload.name = requestPayload.name.trim();

    delete requestPayload.config.groupAccess;
    if (this.dialogData.for === 'view') {
      this.saveView(requestPayload);
    } else {
      this.saveList(requestPayload);
    }
  }

  saveList(requestPayload: ViewSaveRequest) {
    if (this.dialogData.isUpdate || !this.isCreateNew) {
      if (requestPayload.crmList !== undefined) {
        if (requestPayload.crmList.length > 0) {
          requestPayload.removeFromList = false;
        }
      } else {
        delete requestPayload.removeFromList;
      }
      this.webService.put(CrmContactConstants.API.contact.updateList, requestPayload)
        .subscribe((response) => {
          this.loaderService.hide();
          if (response.status.code === 200) {
            this.toaster.showToast(response.status.message, 'success');
            this.dialogRef.close(true);
          } else {
            this.toaster.showToast(response.status.message, 'error');
          }
        }, () => this.loaderService.hide());
    } else {
      delete requestPayload.id;
      delete requestPayload.removeFromList;
      if (this.dialogData.fromAllContacts) {
        requestPayload.fields = this.dialogData.fields;
      } else {
        requestPayload.crmList = this.dialogData.crmList;
      }
      this.webService.post(CrmContactConstants.API.contact.saveList, requestPayload)
        .subscribe((response) => {
          this.loaderService.hide();
          if (response.status.code === 200) {
            this.dialogRef.close(true);
            this.toaster.showToast(response.status.message, 'success');
          } else {
            this.toaster.showToast(response.status.message, 'error');
          }
        }, () => this.loaderService.hide());
    }
  }

  saveView(requestPayload: ViewSaveRequest): void {
    this.loaderService.show();
    if (this.dialogData.isUpdate || !this.isCreateNew) {
      this.webService.put(CrmContactConstants.API.contact.update, requestPayload)
        .subscribe((response) => {
          this.loaderService.hide();
          if (response.status.code === 200) {
            this.toaster.showToast(response.status.message, 'success');
            this.dialogRef.close(true);
          } else {
            this.toaster.showToast(response.status.message, 'error');
          }
        }, () => this.loaderService.hide());
    } else {
      this.webService.post(CrmContactConstants.API.contact.save, requestPayload)
        .subscribe((response) => {
          this.loaderService.hide();
          if (response.status.code === 200) {
            this.dialogRef.close(true);
            this.toaster.showToast(response.status.message, 'success');
          } else {
            this.toaster.showToast(response.status.message, 'error');
          }
        }, () => this.loaderService.hide());
    }
  }
}
