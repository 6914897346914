import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { GoogleAppGlobals } from './app-globals';

declare const gapi: any;

@Component({
  selector: 'google-signin',
  template: `
    <button type="button" mat-raised-button id="social-google-login-button"
            class="bg-google text-white text-capitalize">
      <mat-icon fontSet="fa" fontIcon="fa-google-plus" class="mat-18"></mat-icon>
      <span *ngIf="showText" class="ml-2">
        {{'AUTHENTICATION.LOGIN.GOOGLE' | translate }}
      </span>
    </button>`
})
export class GoogleLoginComponent implements AfterViewInit {

  private clientId: string = GoogleAppGlobals.GOOGLE_CLIENT_ID;
  @Output('loginSuccess') loginSuccess: EventEmitter<any> = new EventEmitter();
  @Input('showText') showText: boolean = false;
  private scope: string = [
    'profile',
    'email'
  ].join(' ');

  public auth2: any;

  constructor(private element: ElementRef) {
  }

  ngAfterViewInit(): void {
    this.googleInit();
  }

  public googleInit(): void {
    gapi.load('auth2', () => {
      this.auth2 = gapi.auth2.init({
        client_id: this.clientId,
        cookiepolicy: 'single_host_origin',
        scope: this.scope
      });
      this.attachSignin(this.element.nativeElement);
    }, (error) => {
      console.error(error);
    });
  }

  public attachSignin(element: ElementRef): any {
    this.auth2.attachClickHandler(element, {},
      (googleUser) => {
        this.loginSuccess.emit(googleUser);
      }, (error: any) => {
        console.log(JSON.stringify(error, undefined, 2));
      });
  }

}
