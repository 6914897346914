import { Component, OnInit } from '@angular/core';
import * as AOS from 'aos';
import { AnalyticsService } from './core/analytics.service';
import { VersionCheckService } from './version-check.service';
import { ServerUrls } from './server-url-constants';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from "@angular/router";
import { AppUtilService } from "./core/app-util.service";
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { OAuth2Service } from 'o-auth2';
import { MatDialog } from '@angular/material';
import { LogoutService } from './feature-modules/authentication/logout/logout.service';
import { SessionTimeoutDialogComponent } from './core/session-timeout-dialog/session-timeout-dialog.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  direction: string;
  loading: boolean;
  isIdleScreenConfirmationDialogOpen: boolean = false;
  idleInterval: any; // Use this for Testing purpose else comment the code.

  constructor(private analyticsService: AnalyticsService, private router: Router, private versionCheckService: VersionCheckService,
    private appUtilService: AppUtilService,
    public authService: OAuth2Service,
    private dialog: MatDialog, private logoutService: LogoutService,
    private idle: Idle, private keepalive: Keepalive) {
    const sessionDirection = sessionStorage.getItem('portal-direction');
    this.direction = sessionDirection || 'ltr';
    this.router.events.subscribe((event: any) => {
      switch (true) {
        case event instanceof NavigationStart:
          {
            this.loading = true;
            break;
          }
        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.loading = false;
          this.idleScreenTimeoutSession();
          // this.appUtilService.RoutingLoadingIcon = false;
          break;
        }
        default: {
          break;
        }
      }
    });
  }

  ngOnInit(): void {
    AOS.init();
    this.analyticsService.init();

    // this.versionCheckService.initVersionCheck(ServerUrls.UI_URL + '/version.json');
  }

  /*  Function to set idle screen   */
  idleScreenTimeoutSession() {
    this.idle.setIdleName("nofa_screen_idle");
    
    if (this.authService.isAuthenticated()) {
      
      // sets an idle timeout in seconds (currently 18 minutes)
      this.idle.setIdle((60 * 18));

      // sets a timeout period of screen after idle (currently 2 minutes). After 2 minutes, the user will be considered timed.
      this.idle.setTimeout((60 * 2));

      // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
      //this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

      // function when screen come out from idle state
      this.idle.onIdleEnd.subscribe(() => {
        // console.log('No longer idle.');
        this.resetIdleScreen();
      });

      // function when call when idle screen time completed    
      this.idle.onTimeout.subscribe(() => {
        // console.log('Timed out!');
        this.isIdleScreenConfirmationDialogOpen = false;
        this.dialog.closeAll();
        this.logoutService.logout();

        // Use this for Testing purpose else comment the code.
        if (this.idleInterval) { clearInterval(this.idleInterval); }
      });

      // Use this for Testing purpose else comment the code.
      if (this.idleInterval) { clearInterval(this.idleInterval); }


      // Use this interval function for Testing purpose else comment the code.
      // let currentIdleMinute = 1;
      // this.idleInterval = setInterval(() => {
      //   // console.log("Remainng minutes for idle screen : (" + ((this.idle.getIdle() / 60) - currentIdleMinute) + " out of " + (this.idle.getIdle() / 60) + ") \nIs Screen Idle : " + this.idle.isIdling());
      //   //currentIdleMinute = currentIdleMinute + 1;
      // }, (1000 * 60));


      // This function is called when screen goes idle
      // Show the user confirmation pop up to continue the login active
      this.idle.onIdleStart.subscribe(() => {
        this.idle.clearInterrupts();
        this.resetIdleScreenConfirmationDialog(this.idle.getTimeout());
      });

      // Can be used fo showing the counter
      this.idle.onTimeoutWarning.subscribe((countdown) => {        
        //console.log('You will be timed out in ' + countdown + ' seconds!');
      });

      // sets the ping interval to 15 seconds
      this.keepalive.interval(15);

      //this.keepalive.onPing.subscribe(() => {});

      this.resetIdleScreen();

    }
    else {
      this.idle.stop();
    }
  }

  /* Function to show reset idle screen confirmation pop up */
  resetIdleScreenConfirmationDialog = (idleTimeoutCounter) => {
    if (this.isIdleScreenConfirmationDialogOpen) {
      return;
    } else {
      this.isIdleScreenConfirmationDialogOpen = true;
    };

    this.dialog.open(SessionTimeoutDialogComponent, this.appUtilService.getDialogData({
      timeoutCounter: idleTimeoutCounter
    }, true)).afterClosed().subscribe((result) => {
      if (result == undefined || result) {
        this.dialog.closeAll();
        this.resetIdleScreen();
        this.isIdleScreenConfirmationDialogOpen = false;
      }
      else {
        this.isIdleScreenConfirmationDialogOpen = true;
        this.dialog.closeAll();
        this.logoutService.logout();
      }
    });
  }

  /*   Function to reset idle screen    */
  resetIdleScreen() {
    this.idle.clearInterrupts();
    this.idle.stop();

    if (this.authService.isAuthenticated()) {
      this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES)
      this.idle.watch();
      this.isIdleScreenConfirmationDialogOpen = false;
      //console.log("started")
    }
  }

}
