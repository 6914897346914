import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[appNotificationScroll]'
})
export class NotificationScrollDirective {

  public scrollPageMap: Map<number, number> = new Map<number, number>();
  public pageNumber: number = 1;
  @Output('onPageChange') onPageChange: EventEmitter<number> = new EventEmitter();

  @Input('setToTop')
  set setToTop(value) {
    this.el.nativeElement.scrollTop = 0;
  }

  constructor(private el: ElementRef) {
  }

  @HostListener('scroll')
  onScroll() {
    if (this.el.nativeElement.scrollHeight - this.el.nativeElement.scrollTop === this.el.nativeElement.clientHeight) {
      if (!this.scrollPageMap.hasOwnProperty(this.el.nativeElement.scrollTop)) {
        this.pageNumber++;
        this.scrollPageMap[this.el.nativeElement.scrollTop] = this.pageNumber;
        this.onPageChange.emit(this.pageNumber);
      }
    }
  }

}
