/****** Created by shivani gajjar(shivani.gajjar@kahunasystems.com) 9/10/17 4:19 PM*******/
import { AbstractControl, ValidatorFn, NG_VALIDATORS, Validator } from '@angular/forms';
import { Directive, Input, OnInit } from '@angular/core';

export function maxValueValidator(maxValue: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (control.value) {
      if (control.value > maxValue) {
        return {
          maximumValue: {
            valid: false
          }
        };
      } else {
        return null;
      }
    } else {
      return null;
    }

  };
}

@Directive({
  selector: '[maxValue][ngModel]',
  providers: [
    {provide: NG_VALIDATORS, useExisting: MaxValueDirective, multi: true}
  ]
})
export class MaxValueDirective implements Validator, OnInit {
  @Input() maxValue: any;

  validator: ValidatorFn;

  constructor() {
  }

  ngOnInit() {
    this.validator = maxValueValidator(this.maxValue);
  }

  validate(control: AbstractControl): { [key: string]: any } {
    return this.validator(control);
  }


}
