import { AxisShowHideProto, GridColumnProto } from '../dashboard-constant';
import { ParamProto } from '../constants/dashboard-constant';

export class ModuleWidgetProto {
  moduleName: string;
  supportedWidgetType: string[] = [];
  widgetParam: Map<string, WidgetProto>;
  id: string;
}

export class WidgetType {
  id: string;
  widgetType: string;
}

export class WidgetParamConfigProto {
  fieldName: string;
  dependentField: string;
  paramValue: string[] = [];
  isMultiValue: boolean;
  isDefaultValueAvl: boolean;
  isDependentOnField: boolean;
  sequence: number;
  dataType: string;
  defaultValue: any;
  dependeeField: string;
}

export class WidgetProto {
  pivotField: string[];
  dataUrl: string;
  previewUrl: string;
  paramNameKey: string;
  paramValueUrl: string;
  paramConfig: WidgetParamConfigProto[];
  columns: ColumnAttr[];
}

export class ColumnAttr {
  columnName: string = '';
  renameColumn?: string = '';
}


export const DATA_TYPES = {
  'NUMBER': 'Number',
  'TEXT': 'Text',
  'DATE': 'Date',
  'LIST': 'List',
  'BOOLEAN': 'Boolean'
};


export const TIME_INTERVALS = {
  'NUMBER': 'Number',
  'TEXT': 'Text',
  'DATE': 'Date',
  'LIST': 'List'
};


export class WidgetDetailProto {
  appName: Map<string, string>;
  appId: string;
  appHost: string;
  modules: ModuleWidgetProto[];
}


export class AddWidgetProto {
  isLibrary: boolean;
  filterParam: ParamProto[][];
  lineFilterParam: LineFilterParamProto[];
  timeInterval: any;
  isFullWidth: boolean;
  dashboardId: string;
  applicationId: string;
  moduleId: string;
  widgetName: string;
  widgetType: string;
  columns: GridColumnProto[];
  allowAutoRefresh: boolean;
  pivot: string;
  widgetDataUrl: string;
  openWigdetsQuery: WidgetQueryProto = new WidgetQueryProto();
  color: string;
  cpToggle: boolean;
  roleId: string;
  refresh: boolean;
  id?:string;
}

export class WidgetQueryProto {
  axisShowHide: AxisShowHideProto;
  dateFormat: string;
}


export class LineFilterParamProto {
  seriesName?: string;
  filterParam?: ParamProto[][];
}


export const OPERATIONS_LIST = [
  'lt', 'lte', 'gt', 'gte', 'in', 'notin', 'is', 'isnot'
  , 'isblank', 'isnotblank', 'isnull', 'isnotnull', 'contains', 'notcontains', 'startwith', 'notstartwith', 'endwith', 'notendwith', 'pastnday',
  'pastnweek',
  'pastnmonth',
  'pastnyear'

];


export class ParamValueRequestProto {
  dependentField: string;
  paramName: string;
  dependentFieldValue: string[];
}






