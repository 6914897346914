import { Directive, ElementRef, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { LayoutService } from '../layout.service';
import { TranslateService } from '@ngx-translate/core';

@Directive({
  selector: '[portalHeaderTitle]'
})
export class PortalHeaderTitleDirective implements OnDestroy {
  titleSubscription: Subscription;

  constructor(
    private layoutService: LayoutService,
    private el: ElementRef,
    private translateService: TranslateService
  ) {
    this.titleSubscription = this.layoutService.getTitle()
      .subscribe(title => this.el.nativeElement.innerHTML = title ? this.translateService.instant(title) : title);
  }

  ngOnDestroy(): void {
    this.titleSubscription.unsubscribe();
  }
}
