import { Component, Input, ViewChild } from '@angular/core';
import { MainMenuItem } from '../../layout-constants';
import { MatMenu } from '@angular/material';

@Component({
  selector: 'app-drop-down-mat-menu',
  templateUrl: './drop-down-mat-menu.component.html',
  styleUrls: ['./drop-down-mat-menu.component.scss']
})
export class DropDownMatMenuComponent {
  @Input('menuItem') public menuItem: MainMenuItem;
  @ViewChild('childMatMenu') public childMatMenu: MatMenu;
}
