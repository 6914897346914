import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { LoginBaseClass } from '../../base-component/loginBaseClass';
import { Subscription } from 'rxjs';
import { AppSettingDetailsProto, AppSettingService } from '../../../../core/app-setting.service';
import { Router } from '@angular/router';
import { OAuth2Service } from 'o-auth2';
import { WebService } from 'web-service';
import { UserService } from '../../../../core/user.service';
import { LoaderService } from '../../../../core/loader/loader.service';
import { LayoutService } from '../../../../layouts/layout.service';
import { FacebookAuthService } from '../../base-component/social-login/facebook-auth.service';
import { CommonCookieService } from 'kscookies';
import { AppUtilService } from '../../../../core/app-util.service';
import { MatDialog, MatDialogRef } from '@angular/material';
import { Location } from '@angular/common';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgForm } from '@angular/forms';
import { ServerUrls } from '../../../../server-url-constants';
import { RegisterDialogComponent } from '../../register-dialog/register-dialog/register-dialog.component';
import { CannedHomepageService } from "../../../homepage/canned-homepages/pipe/canned-homepage.service";
import { LogoutService } from "../../logout/logout.service";

@Component({
  selector: 'app-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.scss']
})
export class LoginDialogComponent extends LoginBaseClass implements OnInit, OnDestroy {

  private subscription: Subscription;
  public appSetting: AppSettingDetailsProto = new AppSettingDetailsProto();
  @ViewChild('loginForm') loginForm: NgForm;
  private loading: boolean = false;
  public isNOFA: boolean = ServerUrls.IS_NOFA;
  public fieldTextType: boolean;
  constructor (@Inject(MAT_DIALOG_DATA) public dialogData: any,
               public router: Router,
               public authService: OAuth2Service,
               public webService: WebService,
               public userService: UserService,
               public loader: LoaderService,
               public layoutService: LayoutService,
               public facebookAuth: FacebookAuthService,
               public cookieService: CommonCookieService,
               public utilService: AppUtilService,
               public matDialog: MatDialog,
               private appSettingService: AppSettingService,
               public cannedHomepageService: CannedHomepageService,
               public location: Location,
               public logoutService: LogoutService,
               private dialogRef: MatDialogRef<LoginDialogComponent>) {
    super(router, authService, webService, userService, loader, layoutService, facebookAuth, cookieService, utilService, matDialog, location,  logoutService, cannedHomepageService);
  }

  ngOnInit (): void {
    if (this.dialogData) {
      this.redirectPage = this.dialogData.redirectPage;
    }
    this.setHomepageConfig();
    if (this.homepageConfig[this.homepageConfigParam.SHOW_SOCIAL_LOGIN]) {
      this.getSocialTemplateDetails();
    }

    this.matDialogRef = this.dialogRef;
    this.subscription = this.appSettingService.getObserver().subscribe((result) => {
      this.appSetting = result;
    });
  }

  ngOnDestroy (): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  checkLoader () {
    if (this.loginForm.invalid) {
      return;
    }
    this.checkLoading = true;
    this.login();
  }

  onRegisterClick() {
    if (this.isRegisterDialog) {
      this.matDialog.open(RegisterDialogComponent, this.utilService.getDialogData(null));
    } else {
      this.router.navigate(['/register']);
    }
  }
}
