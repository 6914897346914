/**
 * Created by Ganeshram Kumhar (ganeshram.kumhar@kahunasystems.com)
 */
import { Directive, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoaderService } from './loader.service';
import * as $ from 'jquery';

@Directive({
  selector: '[appLoader]'
})
export class LoaderDirective implements OnInit, OnDestroy {

  loaderSubscription: Subscription;

  constructor(private loaderService: LoaderService, private el: ElementRef, private renderer: Renderer2) {
  }

  ngOnInit(): void {
    this.loaderSubscription = this.loaderService.loader.subscribe((value: boolean) => {
      if (value) {
        if ($('mat-dialog-container[role=\'dialog\']')[0]) {
          const dialogElement = $('mat-dialog-container[role=\'dialog\']')[$('mat-dialog-container[role=\'dialog\']').length - 1];
          let firstDiv: HTMLElement;
          if (document.getElementById('innerLoader')) {
            firstDiv = document.getElementById('innerLoader');
          } else {
            firstDiv = document.createElement('div');
            firstDiv.id = 'innerLoader';
            firstDiv.className = 'loader-content inner-loader';
            const secondDiv = document.createElement('div');
            secondDiv.className = 'loader-container animation-4';
            const shape1 = document.createElement('div');
            shape1.className = 'shape shape1';
            const shape2 = document.createElement('div');
            shape2.className = 'shape shape2';
            const shape3 = document.createElement('div');
            shape3.className = 'shape shape3';
            const shape4 = document.createElement('div');
            shape4.className = 'shape shape4';
            secondDiv.appendChild(shape1);
            secondDiv.appendChild(shape2);
            secondDiv.appendChild(shape3);
            secondDiv.appendChild(shape4);
            firstDiv.appendChild(secondDiv);
            dialogElement.appendChild(firstDiv);
          }
        } else {
          let firstDiv: HTMLElement;
          if (document.getElementById('innerLoader')) {
            firstDiv = document.getElementById('innerLoader');
          } else {
            firstDiv = document.createElement('div');
            firstDiv.id = 'innerLoader';
            firstDiv.className = 'loader-content inner-loader';
            const secondDiv = document.createElement('div');
            secondDiv.className = 'loader-container animation-4';
            const shape1 = document.createElement('div');
            shape1.className = 'shape shape1';
            const shape2 = document.createElement('div');
            shape2.className = 'shape shape2';
            const shape3 = document.createElement('div');
            shape3.className = 'shape shape3';
            const shape4 = document.createElement('div');
            shape4.className = 'shape shape4';
            secondDiv.appendChild(shape1);
            secondDiv.appendChild(shape2);
            secondDiv.appendChild(shape3);
            secondDiv.appendChild(shape4);
            firstDiv.appendChild(secondDiv);
            this.addElement(firstDiv);
          }
        }
      } else {
        setTimeout(() => {
          const loader = document.getElementById('innerLoader');
          if (loader) {
            if ($('mat-dialog-container[role=\'dialog\']')[0]) {
              const dialogElement = $('mat-dialog-container[role=\'dialog\']')[$('mat-dialog-container[role=\'dialog\']').length - 1];
              dialogElement.removeChild(loader);
            } else {
              this.removeElement(loader);
            }
          }
        }, 20);
      }
    });
  }

  ngOnDestroy(): void {
    if (this.loaderSubscription) {
      this.loaderSubscription.unsubscribe();
    }
  }

  addClass(className: string): void {
    this.renderer.addClass(this.el.nativeElement, className);
    this.resizeWindow();
  }

  addElement(element: HTMLElement): void {
    this.renderer.appendChild(this.el.nativeElement, element);
    this.resizeWindow();
  }

  resizeWindow(): void {
    // window.dispatchEvent(new Event('resize'));
    const resizeEvent = window.document.createEvent('UIEvents');
    resizeEvent.initUIEvent('resize', true, false, window, 0);
    window.dispatchEvent(resizeEvent);
  }

  removeClass(className: string): void {
    this.renderer.removeClass(this.el.nativeElement, className);
    this.resizeWindow();
  }

  removeElement(element: HTMLElement): void {
    this.renderer.removeChild(this.el.nativeElement, element);
    this.resizeWindow();
  }

}
