import { Injectable } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';
import { Observable, of } from 'rxjs';
import { OAuth2Service } from 'o-auth2';

@Injectable({
  providedIn: 'root',
})
export class PreloadingStrategyService implements PreloadingStrategy {
  preloadedModules: string[] = [];

  constructor(private authService: OAuth2Service) {
  }

  preload(route: Route, load: () => Observable<any>): Observable<any> {
    if (route.data && route.data['preload'] && this.authService.isAuthenticated()) {
      // add the route path to the preloaded module array
      this.preloadedModules.push(route.path);
      return load();
    } else {
      return of(null);
    }
  }
}
