import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-social-login-data-dialog',
  templateUrl: './social-login-data-dialog.component.html',
  styleUrls: ['./social-login-data-dialog.component.scss']
})
export class SocialLoginDataDialogComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: any,
              private dialogRef: MatDialogRef<SocialLoginDataDialogComponent>) {
  }

  onSubmit(): void {
    this.dialogRef.close(this.dialogData);
  }
}
