/**
 * Created by Isha Dhimar (isha.dhimar@kahunasystems.com) on 10/8/18.
 **/

import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomMaterialModule } from './custom-material.module';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { KeysPipe } from './pipes/keysPipe.pipe';
import { CapitalizePipe } from './pipes/capitalize.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { RegisterModelToFormDirective } from './directive/register-model-to-form.directive';
import { FilterArrayPipe } from './pipes/filter-array.pipe';
import { FilterService } from '@progress/kendo-angular-grid';
import { PortalCardComponent } from './component/portal-card/portal-card.component';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { HomePageTranslatePipe } from '../feature-modules/homepage/canned-homepages/pipe/home-page-translate.pipe';
import { FlexLayoutModule } from '@angular/flex-layout';
import { GoBackDirective } from './directive/go-back.directive';
import { GooglePlaceDirective } from './directive/googleplace.directive';
import { RouterModule } from '@angular/router';
import { CheckBoxColorDirective } from './directive/check-box-color.directive';
import { MaxValueDirective } from './validators/max-value.directive';
import { FileIconDirective } from './directive/file-icon.directive';
import {KendoGridDirective} from './directive/kendo-grid.directive';
import { ViewDetailsDialogComponent } from './component/view-entity-details-dialog/view-details-dialog.component';
import { KSCommonComponentsModule } from 'kscommon-components';
import { ClickOutsideDirective } from './directive/click-outside.directive';
import { SafeHtmlPipe } from "./pipes/safeHtml.pipe";
import { HpGridComponent } from "./component/hp-grid/hp-grid.component";
import { CustomKendoModule } from './custom-kendo/custom-kendo.module';
import { CheckDependentPipe } from 'app/feature-modules/form-builder/form-renderer/check-dependent.pipe';
import { ChartsComponent } from './component/charts/charts/charts.component';
import { NumberChartComponent } from './component/charts/number-chart/number-chart.component';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { NumberSuffixPipe } from './pipes/number-suffix.pipe';
import { GooglemapsComponent } from './googlemaps/googlemaps.component';
@NgModule({
  imports: [
    CommonModule, TranslateModule, CustomMaterialModule, FormsModule, ReactiveFormsModule, DropDownsModule,
    FlexLayoutModule, RouterModule, KSCommonComponentsModule, CustomKendoModule, ChartsModule],
  declarations: [
    KeysPipe, GooglemapsComponent,
    CapitalizePipe,
    SafeHtmlPipe,
    FilterPipe, RegisterModelToFormDirective, FilterArrayPipe, KendoGridDirective,
    PortalCardComponent, HomePageTranslatePipe,
    GoBackDirective, GooglePlaceDirective,
    CheckBoxColorDirective, MaxValueDirective, FileIconDirective, ViewDetailsDialogComponent,
    ClickOutsideDirective,
    HpGridComponent, CheckDependentPipe, ChartsComponent, NumberChartComponent, NumberSuffixPipe
  ],
  exports: [
    KeysPipe, GooglemapsComponent,
    CapitalizePipe,
    SafeHtmlPipe,
    FilterPipe, RegisterModelToFormDirective, FilterArrayPipe,
    PortalCardComponent, HomePageTranslatePipe, KendoGridDirective,
    GoBackDirective, GooglePlaceDirective, CheckBoxColorDirective, MaxValueDirective,
    FileIconDirective, ClickOutsideDirective, HpGridComponent, CheckDependentPipe, ChartsComponent,
    NumberChartComponent, NumberSuffixPipe
  ],
  entryComponents: [ViewDetailsDialogComponent, ChartsComponent, NumberChartComponent,
    GooglemapsComponent],
  providers: [FilterService]
})

export class SharedComponentsModule {
}
