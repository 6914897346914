import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';

@Component({
  selector: 'app-hp-grid',
  templateUrl: './hp-grid.component.html',
  styleUrls: ['./hp-grid.component.scss']
})
export class HpGridComponent implements OnInit, OnChanges {



  @Input() hpData: any;
  @Input() dataUpdatedV: boolean;
  @Input() showEdit: boolean;
  // @Input() hpData = [];
  @Output() editRecord = new EventEmitter<string>();
  @Output() deleteRecord = new EventEmitter<string>();

  hspColumnsArray = [

    {
      'name': '',
      'keyValue': '',
      'total': ''
    },
    {
      'name': 'Non-Special Needs',
      'keyValue': '',
      'total': ''
    },
    {
      'name': 'Individuals',
      'keyValue': 'noSpecIndividuals',
      'total': 0
    },
    {
      'name': 'Family',
      'keyValue': 'noSpecFamily',
      'total': 0
    },

    {
      'name': 'Senior',
      'keyValue': 'noSpecSenior',
      'total': 0
    },
    {
      'name': 'Veterans',
      'keyValue': 'noSpecVeterans',
      'total': 0
    },
    {
      'name': 'Market Rate',
      'keyValue': 'marketRate',
      'total': 0
    },
    {
      'name': 'Manager',
      'keyValue': 'manager',
      'total': 0
    },
    {
      'name': 'Non-Special Needs Sub Total',
      'keyValue': 'nonSpecialNeedsTotalSite',
      'total': 0
    },
    {
      'name': 'SN-Non-Homeless',
      'keyValue': '',
      'total': ''
    },
    {
      'name': 'Intellectual/Developmental Disabilities',
      'keyValue': 'noHomeDisabled',
      'total': 0
    },
    {
      'name': 'Transition Age Youth (TAY)',
      'keyValue': 'nhTransitionYouth',
      'total': 0
    },
    {
      'name': 'Chronic Illness (HIV/ AIDS)',
      'keyValue': 'nhChronicIllness',
      'total': 0
    },
    {
      'name': 'SN-Non-Homeless Sub Total',
      'keyValue': 'nonHomelessTotalSite',
      'total': 0
    },
    {
      'name': 'SN-Homeless',
      'keyValue': '',
      'total': ''
    },
    {
      'name': 'Mental Illness',
      'keyValue': 'mentalIllness',
      'total': 0
    },
    {
      'name': 'Chronic Illness (HIV/ AIDS)',
      'keyValue': 'hChronicIllness',
      'total': 0
    },
    {
      'name': 'Transition Age Youth (TAY)',
      'keyValue': 'hTransitionAgeYouth',
      'total': 0
    },
    {
      'name': 'Intellectual/Developmental Disabilities',
      'keyValue': 'hIntellectualDevelopmentalDisabilities',
      'total': 0
    },
    {
      'name': 'Chronically Homeless',
      'keyValue': 'hChronicallyHomeless',
      'total': 0
    },
    {
      'name': 'Veterans',
      'keyValue': 'homelessVeterans',
      'total': 0
    },
    {
      'name': 'Seniors',
      'keyValue': 'hSeniors',
      'total': 0
    },
    {
      'name': 'Survivors of Domestic Violence',
      'keyValue': 'hSurvivorsOfDomesticViolence',
      'total': 0
    },
    {
      'name': 'Survivors of Human Trafficking',
      'keyValue': 'hSurvivorsOfHumanTrafficking',
      'total': 0
    },
    {
      'name': 'Family',
      'keyValue': 'homelessFamily',
      'total': 0
    },
    {
      'name': 'Individuals',
      'keyValue': 'homelessIndividuals',
      'total': 0
    },
    {
      'name': 'SN-Homeless Sub Total',
      'keyValue': 'homelessTotalSite',
      'total': 0
    },
    {
      'name': '',
      'keyValue': '',
      'total': ''
    },
    {
      'name': 'Total',
      'keyValue': 'totalTargetSite',
      'total': ''
    }
  ];

  constructor() { }

  ngOnInit() {

    this.UpdateTotals();
  }

  ngOnChanges(changes: SimpleChanges) {

    // console.log(this.dataUpdatedV)
    if (this.dataUpdatedV) {
      this.UpdateTotals();
    }
  }

  UpdateTotals() {
    if(this.hpData === undefined){
      this.hpData = [];
    }
    this.hpData.forEach((element, index) => {
      for (const key in element) {
        if (key !== '') {
          const totalRef = this.hspColumnsArray.find(x => x.keyValue === key);
          if (totalRef !== undefined) {
            if (index === 0) {
              this.hspColumnsArray.find(x => x.keyValue === key).total = 0;
            }
            this.hspColumnsArray.find(x => x.keyValue === key).total = totalRef.total + element[key];
          }
        }
      }
    });
  }

  editRecordFn(site) {
    this.editRecord.emit(site);
  }

  deleteRecordFn(site) {
    this.deleteRecord.emit(site);
  }



}
