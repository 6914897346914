import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';

@Injectable({
  providedIn: 'root'
})
export class ToasterService {

  constructor(private snackBar: MatSnackBar) {
  }

  /***************************************************************
   * For show success with action
   * this.toasterService.showToast("Hello", "success", "ok")
   * For show Error with action
   * this.toasterService.showToast("Hello", "error", "ok")
   * *************************************************************/
  showToast(message: string, type: string = 'success', action?: string): void {
    let cl = 'toasterSuccessClass';
    let duration = 3000;
    if (type === 'error') {
      cl = 'toasterErrorClass';
      duration = 5000;
    }
    this.snackBar.open(message, action, {
      duration: duration,
      panelClass: [cl],
      horizontalPosition: 'right'
    });
  }
}
