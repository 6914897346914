import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  AdditionalFieldDetails,
  AddressListProto,
  AddressTypes,
  ContactDetailsProto,
  ContactTypes,
  CrmContactConstants,
  CustomFieldsProto,
  DIRECTIONS_LIST,
  EditOwnerProto,
  EmailIdListProto,
  FRACTIONS_LIST,
  LocationAdditionalDetails,
  OwnerInfoProto,
  secretPhrase
} from '../crm-contact-constants';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { WebService } from 'web-service';
import { ToasterService } from '../../../../../core/toaster/toaster.service';
import { LoaderService } from '../../../../../core/loader/loader.service';
import { ServerUrls } from '../../../../../server-url-constants';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { AddAdditionalFieldDialogComponent } from './add-additional-field-dialog/add-additional-field-dialog.component';
import { AppUtilService } from '../../../../../core/app-util.service';
import { InformationObject } from '../../../../authentication/authentication-constants';

@Component({
  selector: 'app-edit-crm-contact-dialog',
  templateUrl: './edit-crm-contact-dialog.component.html',
  styleUrls: ['./edit-crm-contact-dialog.component.scss']
})
export class EditCrmContactDialogComponent implements OnInit {

  public ownerInfoRequest: OwnerInfoProto = new OwnerInfoProto();
  public editOwnerRequest: EditOwnerProto = new EditOwnerProto();
  @ViewChild('editOwnerInfoForm') editOwnerInfoForm: NgForm;
  public isRegistered: boolean;
  public countries: any[] = [];
  public SortCountriesList: any[];
  public fractionsList: any = FRACTIONS_LIST;
  public SortFractionsList: any;
  public directionsList: any = DIRECTIONS_LIST;
  public SortDirectionsList: any;
  public addressTypes: any = AddressTypes;
  public SortAddressTypes: any;
  public contactTypes: any = ContactTypes;
  public SortContactTypes: any;
  public apnNo: string;
  public typeSame: any = {};
  public location: any = {};
  public addressPrimary: number;
  public emailPrimary: number;
  public contactPrimary: number;
  public aPrimary: boolean;
  public cPrimary: boolean;
  public ePrimary: boolean;
  public finalFlag: boolean;
  public fullAddress: string;
  public showAddressError: boolean = false;
  public isUpdate: boolean;
  public currentLoc: string;
  private index: number;


  public additionalDetails: LocationAdditionalDetails = new LocationAdditionalDetails();

  constructor(@Inject(MAT_DIALOG_DATA) private dialogData: any,
              private activatedRoute: ActivatedRoute,
              private loader: LoaderService,
              private webService: WebService,
              private toaster: ToasterService,
              private dialog: MatDialog,
              private appUtilService: AppUtilService,
              private dialogRef: MatDialogRef<EditCrmContactDialogComponent>) {
  }

  ngOnInit(): void {
    this.SortAddressTypes = this.appUtilService.sortList(this.addressTypes , '');
    this.SortFractionsList = this.appUtilService.sortList(this.fractionsList , 'value');
    this.SortDirectionsList = this.appUtilService.sortList(this.directionsList , '');
    this.SortContactTypes = this.appUtilService.sortList(this.contactTypes , '');
    this.isUpdate = !!this.dialogData;
    this.editOwnerRequest.additionalDetails = [];
    this.getCountries();
    this.apnNo = this.dialogData;
    const crmId = this.dialogData;
    if (crmId) {
      this.ownerInfoRequest.crmId = CryptoJS.AES.decrypt(crmId, secretPhrase).toString(CryptoJS.enc.Utf8);
      this.getOwnerDetails();
    } else {
      this.editOwnerRequest.addressList = [];
      this.editOwnerRequest.contactDetails = [];
      this.editOwnerRequest.userRole = [];
      this.editOwnerRequest.emailIdList = [];

    }
  }

  /***
   * @Author : Sonal Afre (sonal.afre@kahunasystems.com)
   * @description :  get countries to add address
   * @return {void}
   */
  getCountries(): void {
    this.webService.get(CrmContactConstants.API.getListOfCountries)
      .subscribe(
        (response) => {
          if (response.status.code === 200) {
            this.countries = response.countryList;
            this.SortCountriesList = this.appUtilService.sortList(response.countryList , 'countryName')
          }
        });
  }

  /***
   * @Author : Sonal Afre (sonal.afre@kahunasystems.com)
   * @description :  get owner details
   * @return {void}
   */
  getOwnerDetails(): void {
    this.loader.show();
    this.webService.post(CrmContactConstants.API.getOwnerDetails, this.ownerInfoRequest)
      .subscribe((response) => {
        this.loader.hide();
        if (response.status.code === 200) {
          if (response.userList.length > 0) {
            if (response.userList[0].firstName) {
              response.userList[0].userFirstName = response.userList[0].firstName;
              delete response.userList[0].firstName;
            }
            if (response.userList[0].lastName) {
              response.userList[0].userLastName = response.userList[0].lastName;
              delete response.userList[0].lastName;
            }
            Object.assign(this.editOwnerRequest, response.userList[0]);
            if (this.editOwnerRequest.addressList) {
              for (let i = 0; i < this.editOwnerRequest.addressList.length; i++) {
                if (this.editOwnerRequest.addressList[i].addressCustomFields) {
                  for (let j = 0; j < this.editOwnerRequest.addressList[i].addressCustomFields.length; j++) {
                    this.editOwnerRequest.addressList[i].addressCustomFields[j] = response.userList[0].addressList[i].addressCustomFields[j];
                  }
                }
              }
            }
            if (this.editOwnerRequest.additionalDetails) {
              const findObject = this.appUtilService.findObject(this.editOwnerRequest.additionalDetails, 'appId', ServerUrls.APPID);
              if (findObject) {
                for (const key in findObject.information) {
                  const temp = new InformationObject();
                  temp.key = key;
                  temp.value = findObject.information[key];
                  this.additionalDetails.information.push(temp);
                }
              } else {
                const tempAdditionalDetails = new AdditionalFieldDetails();
                tempAdditionalDetails.appId = ServerUrls.APPID;
                this.editOwnerRequest.additionalDetails.push(tempAdditionalDetails);
                this.additionalDetails.appId = ServerUrls.APPID;
              }
            }
            if (!this.editOwnerRequest.addressList) {
              this.editOwnerRequest.addressList = [];
            } else {
              this.editOwnerRequest.addressList.forEach((v) => {
                if (!v.addressCustomFields) {
                  v.addressCustomFields = [];
                }
              });
            }
            if (!this.editOwnerRequest.contactDetails) {
              this.editOwnerRequest.contactDetails = [];
            }
            if (!this.editOwnerRequest.emailIdList) {
              this.editOwnerRequest.emailIdList = [];
            }
            if (!this.editOwnerRequest.additionalDetails || this.editOwnerRequest.additionalDetails.length === 0) {
              this.editOwnerRequest.additionalDetails = [];
            }
            //  this.ownerDetails = JSON.parse(JSON.stringify(this.editOwnerRequest));
            this.isRegistered = !(this.editOwnerRequest.userRole.indexOf('Non_Registered_Citizen') > -1);
          }
        } else {
          this.toaster.showToast(response.status.message, 'error');
        }
      }, () => {
        this.loader.hide();
      });
  }

  /***
   * @Author : Sonal Afre (sonal.afre@kahunasystems.com)
   * @description :  place change event
   * @return {void}
   */
  onPlaceChange(place: any, index?: number): void {
    if (this.editOwnerRequest.addressList.length > 0) {
      this.index = index;
    } else {
      this.index = 0;
    }
    this.editOwnerRequest.addressList[this.index].addressLine = place.formatted_address;
    this.editOwnerRequest.addressList[this.index].state = '';
    this.editOwnerRequest.addressList[this.index].city = '';
    this.editOwnerRequest.addressList[this.index].zip = '';
    this.editOwnerRequest.addressList[this.index].country = '';
    this.editOwnerRequest.addressList[this.index].coordinates = [];
    place.address_components.forEach((value) => {
      if (value.types[0] === 'route') {
        this.editOwnerRequest.addressList[this.index].streetName = value.short_name;
      }
      if (value.types[0] === 'locality') {
        this.editOwnerRequest.addressList[this.index].city = value.short_name;
      }
      if (value.types[0] === 'administrative_area_level_1') {
        this.editOwnerRequest.addressList[this.index].state = value.short_name;
      }
      if (value.types[0] === 'postal_code') {
        this.editOwnerRequest.addressList[this.index].zip = value.short_name;
      }
      if (value.types[0] === 'country') {
        this.editOwnerRequest.addressList[this.index].country = value.short_name;
      }
    });
    this.editOwnerRequest.addressList[this.index].coordinates.push(place.geometry.location.lat(), place.geometry.location.lng());
    setTimeout(() => {
      this.loader.hide();
    }, 500);
    this.appUtilService.resizeWindow();
  }

  /***
   * @Author : Sonal Afre (sonal.afre@kahunasystems.com)
   * @description :  add new address in profile
   * @return {void}
   */
  addAddress(): void {
    this.showAddressError = false;
    const temp = new AddressListProto();
    this.editOwnerRequest.addressList.push(temp);
  }

  /***
   * @Author : Sonal Afre (sonal.afre@kahunasystems.com)
   * @description :  delete address from profile
   * @param: index{type: index} index of address to be deleted
   * @return {void}
   */
  deleteAddress(index: number): void {
    this.typeSame[index] = false;
    this.editOwnerRequest.addressList.splice(index, 1);
  }

  /***
   * @Author : Sonal Afre (sonal.afre@kahunasystems.com)
   * @description :  check address type if address type is same or different
   * @param: type{type: string} address in which address type to be checked
   * @param: index{type: number} index of address
   * @return {void}
   */
  checkAddressType(type: string, index: number): void {
    this.editOwnerRequest.addressList.forEach((v, k) => {
      this.typeSame[k] = false;
    });
    this.editOwnerRequest.addressList.forEach((v, k) => {
      if (k !== index && v.type === type) {
        this.typeSame[index] = true;
      }
    });
  }

  /***
   * @Author : Sonal Afre (sonal.afre@kahunasystems.com)
   * @description :  set the primary value for address
   * @param: index {type: number} address index to set its primary to true and others primary false
   * @return {void}
   */
  onAddressPrimary(index: number): void {
    this.aPrimary = false;
    this.editOwnerRequest.addressList.forEach((v, k) => {
      if (k !== index) {
        v.primary = false;
      }
    });
  }

  /***
   * @Author : Sonal Afre (sonal.afre@kahunasystems.com)
   * @description :  add new custom field in profile
   * @return {void}
   */
  addCustomField(addressIndex: number): void {
    this.editOwnerRequest.addressList[addressIndex].addressCustomFields.push(new CustomFieldsProto());
  }

  /***
   * @Author : Sonal Afre (sonal.afre@kahunasystems.com)
   * @description :  delete custom field from profile
   * @param: addressIndex{type: number } address field index to be deleted
   * @param: customIndex{type: number} index of which custom field to be deleted
   * @return {void}
   */
  deleteCustomField(addressIndex: number, customIndex: number): void {
    this.editOwnerRequest.addressList[addressIndex].addressCustomFields.splice(customIndex, 1);
  }

  /***
   * @Author : Sonal Afre (sonal.afre@kahunasystems.com)
   * @description :  add new contact in profile
   * @return {void}
   */
  addContact(): void {
    this.editOwnerRequest.contactDetails.push(new ContactDetailsProto());
  }

  /***
   * @Author : Sonal Afre (sonal.afre@kahunasystems.com)
   * @description :  delete contact from profile
   * @return {void}
   */
  deleteContact(index: number): void {
    this.editOwnerRequest.contactDetails.splice(index, 1);
  }

  /***
   * @Author : Sonal Afre (sonal.afre@kahunasystems.com)
   * @description :  set the primary value for contact
   * @param: contactIndex{type: number} contact index to set its primary to true and others primary false
   * @return {void}
   */
  onContactPrimary(index: number): void {
    this.cPrimary = false;
    this.editOwnerRequest.contactDetails.forEach((v, k) => {
      if (k !== index) {
        v.primary = false;
      }
    });
  }

  /***
   * @Author : Sonal Afre (sonal.afre@kahunasystems.com)
   * @description :  add new email in profile
   * @return {void}
   */
  addPreference(): void {
    this.editOwnerRequest.emailIdList.push(new EmailIdListProto());
  }

  /***
   * @Author : Sonal Afre (sonal.afre@kahunasystems.com)
   * @description :  delete email from profile
   * @return {void}
   */
  deletePreference(index: number): void {
    this.editOwnerRequest.emailIdList.splice(index, 1);
  }

  onPreferencePrimary(index: number): void {
    this.ePrimary = false;
    this.editOwnerRequest.emailIdList.forEach((v, k) => {
      if (k !== index) {
        v.primary = false;
      }
    });
  }

  addAdditionalField(): void {
    this.dialog.open(AddAdditionalFieldDialogComponent).afterClosed()
      .subscribe((result) => {
        if (result) {
          this.additionalDetails.information.push(result);
        }
      });
  }

  deleteAdditionalField(index: number): void {
    this.additionalDetails.information.splice(index, 1);
  }

  /***
   * @Author : Sonal Afre (sonal.afre@kahunasystems.com)
   * @description :  update profile is invoked
   * @return {void}
   */
  editOwner(): void {
    this.finalFlag = false;
    this.editOwnerRequest.appId = ServerUrls.APPID;
    this.addressPrimary = 0;
    this.emailPrimary = 0;
    this.contactPrimary = 0;
    this.editOwnerRequest.addressList.forEach((v) => {
      if (v.primary !== true) {
        this.addressPrimary++;
      }
    });
    if ((this.editOwnerRequest.addressList.length) && (this.editOwnerRequest.addressList.length === this.addressPrimary)) {
      this.aPrimary = true;
    }
    this.editOwnerRequest.emailIdList.forEach((v) => {
      if (v.primary !== true) {
        this.emailPrimary++;
      }
    });
    if ((this.editOwnerRequest.emailIdList.length) && (this.editOwnerRequest.emailIdList.length === this.emailPrimary)) {
      this.ePrimary = true;
    }
    this.editOwnerRequest.contactDetails.forEach((v) => {
      if (v.primary !== true) {
        this.contactPrimary++;
      }
    });
    if ((this.editOwnerRequest.contactDetails.length) && (this.editOwnerRequest.contactDetails.length === this.contactPrimary)) {
      this.cPrimary = true;
    }
    const findIndex = this.appUtilService.getIndex(this.editOwnerRequest.additionalDetails, 'appId', ServerUrls.APPID);
    if (findIndex !== -1) {
      this.editOwnerRequest.additionalDetails[findIndex] = new AdditionalFieldDetails();
      this.editOwnerRequest.additionalDetails[findIndex].appId = ServerUrls.APPID;
      this.additionalDetails.information.forEach((field) => {
        this.editOwnerRequest.additionalDetails[findIndex].information[field.key] = field.value;
      });
    } else {
      this.editOwnerRequest.additionalDetails [0] = new AdditionalFieldDetails();
      this.editOwnerRequest.additionalDetails[0].appId = ServerUrls.APPID;
      this.additionalDetails.information.forEach((field) => {
        this.editOwnerRequest.additionalDetails[0].information[field.key] = field.value;
      });
    }
    if (!((this.editOwnerRequest.addressList.length) && (this.editOwnerRequest.addressList.length === this.addressPrimary)) &&
      !((this.editOwnerRequest.emailIdList.length) && (this.editOwnerRequest.emailIdList.length === this.emailPrimary)) &&
      !((this.editOwnerRequest.contactDetails.length) && (this.editOwnerRequest.contactDetails.length === this.contactPrimary))) {
      for (let i = 0; i < this.editOwnerRequest.addressList.length; i++) {
        if (this.typeSame[i] === true) {
          this.finalFlag = true;
          this.toaster.showToast('Address is of same type', 'error');
        }
      }
      if (this.editOwnerRequest.addressList.length === 0) {
        this.finalFlag = true;
        this.showAddressError = true;
      }
      if (this.finalFlag !== true) {
        this.loader.show();
        this.webService.post(CrmContactConstants.API.updateOwnerDetails, this.editOwnerRequest)
          .subscribe((response) => {
            this.loader.hide();
            if (response.status.code === 200) {
              this.toaster.showToast(response.status.message, 'success');
              this.dialogRef.close(true);
            } else {
              this.toaster.showToast(response.status.message, 'error');
            }
          }, () => {
            this.loader.hide();
          });
      }
    }
  }

  cancel(): void {
    // this.editOwnerRequest = JSON.parse(JSON.stringify(this.ownerDetails));
  }

}
