import { Component, Input, OnInit } from '@angular/core';
import { AppSettingService } from '../../../core/app-setting.service';
import {ServerUrls} from '../../../server-url-constants';


@Component({
  selector: 'portal-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  /**
   * Makes the footer transparent.
   */
  @Input() transparent: Boolean = false;
  public copyRight: string;
  public isNOFA: boolean = ServerUrls.IS_NOFA;
  version: string = require( '../../../../../package.json').version;

  constructor(private appSettingService: AppSettingService) {
    this.copyRight = this.appSettingService.getProperty('COPY_RIGHT');
  }

  ngOnInit(): void {
    if (!ServerUrls.IS_NOFA) {
      this.copyRight = ' © '+ new Date().getFullYear() + '<a target="_blank" href="https://www.3disystems.com/">3Di Systems</a>';
    }
  }

}
