import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss']
})
export class AlertDialogComponent implements OnInit {

  public title: string;
  public message: string;

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: any) {
  }

  ngOnInit() {
    this.title = this.dialogData.title;
    this.message = this.dialogData.message;
  }

}
