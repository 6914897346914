import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationBarService {

  public url: string;
  public refresh: boolean;
  public notificationContent: string;
  public notificationColor: 'primary' | 'accent' | 'warn' | 'success' | 'yellow';
  public showBar: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() {
  }

  show(): void {
    this.showBar.next(true);
  }

  hide(): void {
    this.notificationContent = null;
    this.url = null;
    this.refresh = false;
    this.showBar.next(false);
  }

}
