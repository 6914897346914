/**
 * Created by Lekhika Inani(lekhika.inani@kahunasystems.com)  on 24/02/20.
 */
import {AfterViewInit, Directive, ElementRef} from '@angular/core';

@Directive({
  selector: '[kendoGrid]'
})
export class KendoGridDirective implements AfterViewInit {

  constructor(private element: ElementRef) {
  }

  ngAfterViewInit() {

    const prev1 = this.element.nativeElement.querySelector('.k-i-seek-w');
    if (prev1 !== null) {
      prev1.insertAdjacentHTML('beforeend', '<span style="display: none">prev</span>');
    }
    const next1 = this.element.nativeElement.querySelector('.k-i-seek-e');
    if (next1 !== null) {
      next1.insertAdjacentHTML('beforeend', '<span style="display: none">next</span>');
    }
    const prev2 = this.element.nativeElement.querySelector('.k-i-arrow-w');
    if (prev2 !== null) {
      prev2.insertAdjacentHTML('beforeend', '<span style="display: none">prev</span>');
    }
    const next2 = this.element.nativeElement.querySelector('.k-i-arrow-e');
    if (next2 !== null) {
      next2.insertAdjacentHTML('beforeend', '<span style="display: none">next</span>');
    }

    const checkHeader = this.element.nativeElement.querySelector('.k-grid-draggable-header');
    if (checkHeader !== null) {
      checkHeader.insertAdjacentHTML('beforeend', '<span style="display: none">Empty-header</span>');
    }

   const checkLabel3 = this.element.nativeElement.querySelector('.k-checkbox-label');
    if (checkLabel3 !== null) {
      checkLabel3.insertAdjacentHTML('beforeend', '<span style="display: none">Check-Label</span>');
    }

  }

}
