import { Directive, ElementRef, HostListener, Input, OnInit, Renderer2 } from '@angular/core';
import { MatCheckboxChange } from '@angular/material';

@Directive({
  selector: '[checkBoxColor]',
  exportAs: 'checkBoxColor'
})
export class CheckBoxColorDirective implements OnInit {

  @Input('checkBoxColor') checkBoxColor: any;
  @Input('color') color: string;
  private frameElement: ElementRef;
  private backgroundElement: ElementRef;

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {
  }

  @HostListener('change', ['$event'])
  onChange(event: MatCheckboxChange): void {
    if (event.checked) {
     // this.setBackgroundColor(this.color);
    } else {
      this.setBackgroundColor('');
    }
  }

  ngOnInit(): void {
    this.frameElement = this.elementRef.nativeElement.children[0].children[0].children[2];
    this.backgroundElement = this.elementRef.nativeElement.children[0].children[0].children[3];
    this.setFrameColor();
    if (this.checkBoxColor.checked) {
      this.setBackgroundColor(this.color);
    }
  }

  setFrameColor() {
    this.renderer.setStyle(this.frameElement, 'border-color', this.color);
  }

  setBackgroundColor(color?: string) {
    this.renderer.setStyle(this.backgroundElement, 'background-color', color);
  }

}
