import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatSidenav } from '@angular/material';
import { Observable, Subscription } from 'rxjs';
import { AppSettingDetailsProto, AppSettingService } from '../../../core/app-setting.service';
import { MainMenuItem } from '../../layout-constants';
import { NavigationStart, Router, RouterEvent } from '@angular/router';
import { MenuService } from '../menu.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-custom-menu-sidenav',
  templateUrl: './custom-menu-sidenav.component.html',
  styleUrls: ['./custom-menu-sidenav.component.scss']
})
export class CustomMenuSidenavComponent implements OnInit, OnDestroy {

  @Input() sidenav: MatSidenav;
  @Input() isTabbed: boolean;
  routerSubscription: Subscription;

  public appSetting: AppSettingDetailsProto;
  private menuSubscriber: Subscription;
  private appSettingSubscriber: Subscription;
  public mainMenuList: MainMenuItem[] = [];
  public searchMenuList: Observable<MainMenuItem[]>;
  @Output('showChildren') showTabs = new EventEmitter();

  constructor(private router: Router, private appSettingService: AppSettingService,
              private menuService: MenuService) {
    this.routerSubscription = this.router.events
      .pipe(
        filter(event => event instanceof NavigationStart),
      )
      .subscribe((event: RouterEvent) => {
        if ((this.sidenav && this.sidenav.mode === 'over' && this.sidenav.opened) ||
          (this.sidenav.position === 'end' && this.sidenav.mode === 'over')) {
          this.sidenav.close();
        }
      });
  }

  ngOnInit(): void {
    this.appSettingSubscriber = this.appSettingService.getObserver().subscribe((result) => {
      this.appSetting = result;
    });
    this.menuSubscriber = this.menuService.getMenus().subscribe((response: MainMenuItem[]) => {
      this.mainMenuList = response;
      this.mainMenuList.forEach((v) => {
        if (v.type === 'DROPDOWN') {
          v.children.forEach((child) => {
            if (child.type === 'LINK') {
              if (child['state'] === this.router.routerState.snapshot.url.toString()) {
                this.showChildren(v);
              }
            }
            if (child.type === 'DROPDOWN') {
              child.children.forEach((value) => {
                if (value.type === 'LINK') {
                  if (value['state'] === this.router.routerState.snapshot.url.toString()) {
                    this.showChildren(v);
                  }
                }
              });
            }
          });
        }
      });
    });
    this.searchMenuList = this.menuService.getMenuForSearch();
  }

  ngOnDestroy(): void {
    if (this.menuSubscriber) {
      this.menuSubscriber.unsubscribe();
    }
    if (this.appSettingSubscriber) {
      this.appSettingSubscriber.unsubscribe();
    }
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  showChildren(item: any) {
    this.showTabs.emit(item);
  }

}
