import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-view-mail-notification-dialog',
  templateUrl: './view-mail-notification-dialog.component.html',
  styleUrls: ['./view-mail-notification-dialog.component.scss']
})
export class ViewMailNotificationDialogComponent implements OnInit {
  public viewMailData: any;
  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: any) { }

  ngOnInit() {
    this.viewMailData = this.dialogData;
  }

}
