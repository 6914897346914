import { NgModule } from '@angular/core';
import { AuthenticationRoutingModule } from './authentication-routing.module';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { SharedModule } from '../../shared/shared.module';
import { LoaderModule } from '../../core/loader/loader.module';
import { UpdateUserProfileComponent } from './update-user-profile/update-user-profile.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ChangePasswordModule } from './change-password/change-password.module';
import { AddNewContactComponent } from './update-user-profile/add-new-contact/add-new-contact.component';
import { VerifyOtpDialogComponent } from './verify-otp-dialog/verify-otp-dialog.component';
import { SocialLoginModule } from './base-component/social-login.module';
import { RegisterDialogComponent } from './register-dialog/register-dialog/register-dialog.component';
import { LoginDialogComponent } from './login-dialog/login-dialog/login-dialog.component';
import { AlertDialogModule } from '../../core/alert-dialog/alert-dialog.module';
import { UpdateUserProfileBasicComponent } from './update-user-profile-basic/update-user-profile-basic.component';
import { CreateUserFormFieldModule } from './create-user-form-field/create-user-form-field.module';

@NgModule({
  imports: [
    SharedModule,
    AuthenticationRoutingModule,
    LoaderModule,
    CreateUserFormFieldModule,
    ChangePasswordModule,
    SocialLoginModule,
    AlertDialogModule
  ],
  declarations: [
    LoginComponent,
    RegisterComponent,
    LoginDialogComponent,
    RegisterDialogComponent,
    ForgotPasswordComponent,
    UpdateUserProfileComponent,
    ResetPasswordComponent,
    AddNewContactComponent,
    VerifyOtpDialogComponent,
    UpdateUserProfileBasicComponent
  ],
  exports: [ResetPasswordComponent],
  entryComponents: [ForgotPasswordComponent, AddNewContactComponent, VerifyOtpDialogComponent,
    LoginDialogComponent, RegisterDialogComponent, LoginComponent]
})
export class AuthenticationModule {
  static dynamicComponentsMap​ = {
    LoginComponent
  };
}
