import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-add-additional-field-dialog',
  templateUrl: './add-additional-field-dialog.component.html',
  styleUrls: ['./add-additional-field-dialog.component.scss']
})
export class AddAdditionalFieldDialogComponent {

  public result = {key: '', value: ''};

  constructor(private dialogRef: MatDialogRef<AddAdditionalFieldDialogComponent>) {
  }

  submit() {
    this.dialogRef.close(this.result);
  }

}
