import { NgModule } from '@angular/core';
import { FacebookService } from 'ngx-facebook';
import { FacebookAuthService } from './social-login/facebook-auth.service';
import { SocialLoginDataDialogComponent } from './social-login-data-dialog/social-login-data-dialog.component';
import { GoogleLoginComponent } from './social-login/google.component';
import { SharedModule } from '../../../shared/shared.module';

@NgModule({
  imports: [SharedModule],
  declarations: [GoogleLoginComponent, SocialLoginDataDialogComponent],
  exports: [GoogleLoginComponent, SocialLoginDataDialogComponent],
  entryComponents: [SocialLoginDataDialogComponent],
  providers: [FacebookService, FacebookAuthService]
})
export class SocialLoginModule {
}
