import { Component, Inject, OnInit } from '@angular/core';
import { CrmContactConstants, ViewSaveRequest } from '../../crm-contact-constants';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { LoaderService } from '../../../../../../core/loader/loader.service';
import { WebService } from 'web-service';
import { ToasterService } from '../../../../../../core/toaster/toaster.service';
import { AppUtilService } from '../../../../../../core/app-util.service';

@Component({
  selector: 'app-save-mailing-list-dialog',
  templateUrl: './save-mailing-list-dialog.component.html',
  styleUrls: ['./save-mailing-list-dialog.component.scss']
})
export class SaveMailingListDialogComponent implements OnInit {
  public update = false;
  public listAddRequest = new ViewSaveRequest();
  public queryType: boolean = true;
  public privacyType: string = 'public';
  public staffAccessType: string = 'read';
  public groupAccessType: string = 'read';
  public groupAccess: boolean = false;
  public userGroupsList: any[] = [];
  public viewList: any[] = [];
  public url = '';
  public isMailingList: boolean = false;
  public allowUpdate: boolean = false;
  public accessType: string = 'all';

  constructor(public dialogRef: MatDialogRef<SaveMailingListDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public dialogData: any,
              public webService: WebService, private toaster: ToasterService,
              private loaderService: LoaderService, private appUtilService: AppUtilService) {
  }

  ngOnInit() {
    this.getGroups();
    this.listAddRequest.config.staffAccess = true;
    if (this.dialogData.mailingListConfig) {
      this.listAddRequest.mailingListConfig.verificationRequired = false;
      this.listAddRequest.mailingListConfig.defaultList = false;
    }
    if (this.dialogData.id) {
      this.update = true;
      if (this.dialogData.mailingListConfig) {
        this.isMailingList = true;
        this.listAddRequest.mailingListConfig.verificationRequired = this.dialogData.mailingListConfig.verificationRequired;
        this.listAddRequest.mailingListConfig.defaultList = this.dialogData.mailingListConfig.defaultList;
      }
      this.listAddRequest.name = this.dialogData.name;
      this.listAddRequest.description = this.dialogData.description;
      this.listAddRequest.id = this.dialogData.id;
      if (this.dialogData.fields) {
        if (this.dialogData.fields[this.dialogData.fields.length - 1] === 'view-change') {
          this.dialogData.fields.pop(this.dialogData.fields.length - 1);
        }
      }
      this.listAddRequest.fields = this.dialogData.fields;
      this.listAddRequest.config.crmIds = this.dialogData.config.crmIds;
      this.listAddRequest.crmList = this.dialogData.crmList;
      this.listAddRequest.config.staffAccess = true;
      this.listAddRequest.config.staffUpdateAccess = this.dialogData.config.staffUpdateAccess;
      this.listAddRequest.config.groupUpdateAccess = this.dialogData.config.groupUpdateAccess;
      this.listAddRequest.config.groupIds = this.dialogData.config.groupIds;
      this.listAddRequest.config.viewAll = this.dialogData.config.viewAll;
      if (this.dialogData.config.groupIds.length > 0) {
        this.groupAccess = true;
      }
      if (this.dialogData.config.viewAll) {
        this.privacyType = 'public';
      } else {
        this.privacyType = 'private';
      }
      if (this.dialogData.config.staffUpdateAccess) {
        this.staffAccessType = 'update';
      } else {
        this.staffAccessType = 'read';
      }
      if (this.dialogData.config.groupUpdateAccess) {
        this.groupAccessType = 'update';
      } else {
        this.groupAccessType = 'read';
      }
    } else {
      this.listAddRequest.fields = this.dialogData.fields;
      this.listAddRequest.crmList = this.dialogData.crmList;
      this.listAddRequest.config = this.dialogData.config;
      this.update = this.dialogData.isUpdate;
    }
    if (this.dialogData.config.staffAccess || this.groupAccess) {
      this.allowUpdate = true;
      if (this.groupAccess) {
        this.accessType = 'group';
      }
    }
    /*if (this.listAddRequest.config.groupIds.length > 0) {
      this.groupAccess = true;
      if (this.privacyType === 'public') {
        this.accessType = 'group';
      }
    }*/
    if (this.dialogData.viewList) {
      if (this.dialogData.viewList.length > 0) {
        this.viewList = this.dialogData.viewList.filter((v) => v.updateAccess);
      }
    }
  }


  getGroups(): void {
    const reqObj = {
      pageNumber: -1,
      pageSize: -1
    };
    this.webService.post(CrmContactConstants.API.contact.get_group_mapping, reqObj)
      .subscribe(
        (response: any) => {
          if (response.status.code === 200) {
            this.userGroupsList = response.userGroups;
          }
        }, () => {
        });
  }

  changePrivacy(event: any): void {
    if (event === 'public') {
      this.listAddRequest.config.viewAll = true;
      this.listAddRequest.config.staffAccess = false;
    } else {
      this.listAddRequest.config.staffAccess = true;
      this.listAddRequest.config.viewAll = false;
    }
  }

  changeStaffType(event: any): void {
    if (event === 'read') {
      this.listAddRequest.config.staffUpdateAccess = false;
    } else if (event === 'update') {
      this.listAddRequest.config.staffUpdateAccess = true;
    }
  }

  /*changeQueryType(event: any): void {
    this.dialogData.isUpdate = !event;
  }*/


  changeGroupType(event: any): void {
    if (event === 'read') {
      this.listAddRequest.config.groupUpdateAccess = false;
    } else if (event === 'update') {
      this.listAddRequest.config.groupUpdateAccess = true;
    }
  }


  saveList(): void {
    /*if (!this.listAddRequest.config.staffAccess) {
      this.listAddRequest.config.staffUpdateAccess = false;
    }*/
    if (this.allowUpdate) {
      this.listAddRequest.config.staffUpdateAccess = this.accessType === 'all';
      this.listAddRequest.config.groupUpdateAccess = this.accessType === 'group';
    } else {
      this.listAddRequest.config.staffAccess = false;
      this.listAddRequest.config.staffUpdateAccess = false;
      this.listAddRequest.config.groupUpdateAccess = false;
      this.groupAccess = false;
      this.listAddRequest.config.groupIds = [];
    }
    this.loaderService.show();
    /*if (!this.isMailingList) {
      delete this.listAddRequest.mailingListConfig;
    }*/
    this.listAddRequest.name = this.listAddRequest.name.trim();

    if (this.dialogData.isUpdate) {
      if (this.listAddRequest.crmList !== undefined) {
        if (this.listAddRequest.crmList.length > 0) {
          this.listAddRequest.removeFromList = false;
        }
      } else {
        delete this.listAddRequest.removeFromList;
      }
      this.webService.put(CrmContactConstants.API.contact.updateList, this.listAddRequest)
        .subscribe((response) => {
          this.loaderService.hide();
          if (response.status.code === 200) {
            this.toaster.showToast(response.status.message, 'success');
            this.dialogRef.close(true);
          } else {
            this.toaster.showToast(response.status.message, 'error');
          }
        }, () => this.loaderService.hide());
    } else {
      delete this.listAddRequest.id;
      delete this.listAddRequest.removeFromList;
      if (this.dialogData.fromAllContacts) {
        this.listAddRequest.fields = this.dialogData.fields;
      } else {
        this.listAddRequest.crmList = this.dialogData.crmList;
      }
      this.webService.post(CrmContactConstants.API.contact.saveList, this.listAddRequest)
        .subscribe((response) => {
          this.loaderService.hide();
          if (response.status.code === 200) {
            this.dialogRef.close(true);
            this.toaster.showToast(response.status.message, 'success');
          } else {
            this.toaster.showToast(response.status.message, 'error');
          }
        }, () => this.loaderService.hide());
    }
  }

  changeAccessType() {
    if (this.accessType === 'all') {
      this.listAddRequest.config.staffAccess = true;
      this.groupAccess = false;
    } else {
      this.groupAccess = true;
      this.listAddRequest.config.staffAccess = false;
    }
  }

  setName() {
    this.listAddRequest.name = this.appUtilService.findObject(this.viewList, 'id', this.listAddRequest.id).name;
  }

}

