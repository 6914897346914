import { NgModule } from '@angular/core';
import { SessionTimeoutDialogComponent } from './session-timeout-dialog.component';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
  imports: [
    SharedModule
  ], 
  declarations: [SessionTimeoutDialogComponent],
  entryComponents: [SessionTimeoutDialogComponent]
})
export class SessionTimeoutDialogModule { }
