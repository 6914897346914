import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../../core/user.service';
import { LoaderService } from '../../../core/loader/loader.service';
import { OAuth2Service } from 'o-auth2';
import { MenuService } from '../../../layouts/components/menu.service';
import { NotificationService } from '../../../layouts/components/notification-sidenav/notification.service';
import { AppUtilService } from '../../../core/app-util.service';

@Injectable({
  providedIn: 'root'
})
export class LogoutService {

  constructor(public router: Router, public userService: UserService,
              public loader: LoaderService,
              public menuService: MenuService, public authService: OAuth2Service,
              private notificationService: NotificationService,
              private utilService: AppUtilService) {
  }

  /**
   * @author: Ganeshram Kumhar (ganeshram.kumhar@kahunasystems.com)
   * @description :Logout user
   * @return {void}
   */
  logout() {
    this.loader.show();
    window.localStorage.removeItem('logged_in');
    this.authService.logoutSystem().subscribe(() => {
      this.loader.hide();
      this.utilService.hideNotificationBar();
      this.userService.resetUser();
      this.authService.removeUsersDetailsAndRedirect();
      this.menuService.resetMenus();
      this.notificationService.setList([]);
    }, () => {
      this.loader.hide();
      this.utilService.hideNotificationBar();
      this.userService.resetUser();
      this.authService.removeUsersDetailsAndRedirect();
      this.menuService.resetMenus();
      this.notificationService.setList([]);
    });
    window.localStorage.removeItem('loginTime');

  }

}
