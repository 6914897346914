/**
 * Created by ganeshram on 9/11/17.
 */
import { Injectable } from '@angular/core';
import { ActivationStart, NavigationEnd, Router } from '@angular/router';
import { LazyLoadService } from './lazy-load/lazy-load.service';
import { MatDialog } from '@angular/material';


@Injectable()
export class RouterSubscribeService {
  private resolveFor: any;

  constructor(public router: Router, private lazyLoadService: LazyLoadService,
              private matDialog: MatDialog) {
  }

  initRouterSubscribeService() {
    this.router.events.subscribe((event: any) => {
      if (event instanceof ActivationStart) {
        if (event.snapshot.data.hasOwnProperty('resolveFor')) {
          this.resolveFor = event.snapshot.data.resolveFor;
        }
        this.matDialog.closeAll();
      }
      if (event instanceof NavigationEnd) {
        if (this.resolveFor) {
          this.lazyLoadService.resolveFor(this.resolveFor);
        }
      }
    });

    /*const pair = pairs(this.router.events);
    pair.subscribe((event: any) => {
      if (event[0] instanceof NavigationEnd) {
        if (event[1].url !== '/notFound' && event[1].url !== '/internalError'
          && event[1].url !== '/unauthorized' && event[0].url !== '/notFound'
          && event[0].url !== '/internalError' && event[0].url !== '/unauthorized') {
          localStorage.setItem('preState', event[0].url);
        }
      }
    });*/

  }


}
