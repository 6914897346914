import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PreventableEvent } from '@progress/kendo-angular-dropdowns';
import { BaseFilterCellComponent, FilterService } from '@progress/kendo-angular-grid';
import { Subject } from 'rxjs';

@Component({
  selector: 'kendo-drop-down-filter',
  templateUrl: './kendo-drop-down-filter.component.html',
  styleUrls: ['./kendo-drop-down-filter.component.scss'],
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: KendoDropDownFilterComponent, multi: true}
  ]
})
export class KendoDropDownFilterComponent extends BaseFilterCellComponent implements OnInit, ControlValueAccessor {


  @Input('id') public id: string;
  @Input('name') public name: string;
  @Input('placeholder') public placeholder: string;
  @Input('textField') public textField: string;
  @Input('valueField') public valueField: string;
  @Input() public field: string;
  @Output('open') open: EventEmitter<PreventableEvent> = new EventEmitter<PreventableEvent>();
  @Output('close') close: EventEmitter<PreventableEvent> = new EventEmitter<PreventableEvent>();
  @Output('focus') focus: EventEmitter<any> = new EventEmitter<any>();
  @Output('blur') blur: EventEmitter<any> = new EventEmitter<any>();
  @Output('valueChanged') valueChanged: EventEmitter<any> = new EventEmitter();
  public sourceList: any[] = [];
  innerValue: string;
  @Input() clearInputValue: Subject<any>;

  constructor(filterService: FilterService) {
    super(filterService);
  }

  public _multiple: boolean = false;

  @Input('multiple')
  set multiple(value: boolean) {
    this._multiple = true;
  };

  public _optionList: any[] = [];

  get optionList(): any[] {
    return this._optionList;
  }

  @Input('optionList')
  set optionList(list: any[]) {
    if (list) {
      this._optionList = list;
      this.sourceList = JSON.parse(JSON.stringify(list));
    }
  }

  ngOnInit(): void {
    if (this.clearInputValue) {
      this.clearInputValue.asObservable().subscribe(() => {
        this.innerValue = undefined;
      });
    }
  }

  propagateChange = (_: any) => {
  };

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(): void {
  }

  writeValue(value: any): void {
    if (value && this.innerValue !== value) {
      this.innerValue = value;
      this.propagateChange(value);
    } else {
      this.innerValue = null;
    }
  }

  valueChange = (event: any[]) => {
    this.propagateChange(event);
    this.valueChanged.emit(event);
    if (this.field) {
      this.applyFilter(
        event === null ?
          this.removeFilter(this.field) :
          this.updateFilter({
            field: this.field,
            operator: 'eq',
            value: event
          })
      );
    }
  };

  public searchChange(filter: string): void {
    if (this._multiple && !this.valueField) {
      this.filterList(filter);
    } else if (this._multiple && this.valueField) {
      this.filterList(filter, this.textField);
    } else if (!this._multiple && !this.valueField) {
      this.filterList(filter);
    } else if (!this._multiple && this.valueField) {
      this.filterList(filter, this.textField);
    }
  }

  filterList(filter: string, key?: string): void {
    if (key) {
      this._optionList = this.sourceList.filter((s) => {
        return s[key].toLowerCase().indexOf(filter.toLowerCase()) !== -1;
      });
    } else {
      this._optionList = this.sourceList.filter((s) => {
        return s.toLowerCase().indexOf(filter.toLowerCase()) !== -1;
      });
    }
  }

  onOpen = (event: PreventableEvent): void => {
    this.open.emit(event);
  };
  onClose = (event: PreventableEvent): void => {
    this.close.emit(event);
  };
  onFocus = (event: any): void => {
    this.focus.emit(event);
  };
  onBlur = (event: any): void => {
    this.blur.emit(event);
  };
}
