import { Directive, ElementRef, HostListener, OnDestroy, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';

import { Subscription } from 'rxjs';
import { delay, filter } from 'rxjs/operators';
import { LayoutService } from '../layout.service';
import {AppUtilService} from "../../core/app-util.service";

@Directive({
  selector: '[portalScrollTop]'
})
export class PortalScrollTopDirective implements OnInit, OnDestroy {

  private windowScrolled: boolean;
  private routerSubscription: Subscription;
  private subscription: Subscription;

  constructor(private router: Router, private el: ElementRef, private layoutService: LayoutService,
              private utilService:AppUtilService) {
    this.routerSubscription = this.router.events
      .pipe(
        filter(event => event instanceof NavigationStart),
        delay(0)
      )
      .subscribe(route => {
        this.el.nativeElement.scrollTop = 0;
        this.utilService.resizeWindow();

      });
  }

  @HostListener('scroll', ['$event'])
  onWindowScroll() {
    if (this.el.nativeElement.scrollTop > 100) {
      this.windowScrolled = true;
      this.layoutService.setWindowScroll(true);
    } else if (this.windowScrolled && this.el.nativeElement.scrollTop <= 0) {
      this.layoutService.setWindowScroll(false);
    }
  }

  ngOnInit(): void {
    this.subscription = this.layoutService.getWindowScrollTop().subscribe((scroll) => {
      this.el.nativeElement.scrollTop = 0;
    });
  }

  ngOnDestroy(): void {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
