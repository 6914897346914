import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';
import { CrmContactConstants, CrmNoteResponse } from '../crm-contact-constants';
import { LoaderService } from '../../../../../core/loader/loader.service';
import { ToasterService } from '../../../../../core/toaster/toaster.service';
import { WebService } from 'web-service';
import { AddEditNotesDialogComponent } from './add-edit-notes-dialog/add-edit-notes-dialog.component';
import { AppUtilService } from '../../../../../core/app-util.service';
import { MatDialog } from '@angular/material';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { Subject } from 'rxjs';
import { RightMaskHandler } from '../../../../../shared/classes/right-mask-handler/right-mask-handler';
import { MenuService } from '../../../../../layouts/components/menu.service';

@Component({
  selector: 'app-crm-notes-list',
  templateUrl: './crm-notes-list.component.html',
  styleUrls: ['./crm-notes-list.component.scss']
})
export class CrmNotesListComponent extends RightMaskHandler implements OnChanges, OnInit {
  public notesList: GridDataResult = {
    data: [],
    total: 0
  };
  public noteList: any[] = [];
  clearInputFilter: Subject<any> = new Subject();
  public state: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: []
    },
    sort: []
  };

  public allData(): ExcelExportData {
    const state: State = {
      skip: 0,
      take: this.noteList.length
    };
    const noteList = this.noteList;
    const result: ExcelExportData = {
      data: process(noteList, state).data
    };
    return result;
  }

  @Input('crmId') userCrmId: string;

  constructor(private loader: LoaderService,
              private toasterService: ToasterService,
              private appUtilService: AppUtilService,
              private dialog: MatDialog,
              private webService: WebService,
              public menuService: MenuService) {
    super(menuService);
    this.allData = this.allData.bind(this);
  }

  ngOnInit(): void {
    this.getRightMask('app-crm-contact');
  }

  ngOnChanges(): void {
    if (this.userCrmId) {
      this.getNotes();
    }
  }

  /***
   * @Author : Sonal Afre (sonal.afre@kahunasystems.com)
   * @description :  add note dialog is invoked
   * @return {void}
   */
  onAdd(item?: any): void {
    const dialogData = {
      userCrmId: this.userCrmId,
      isUpdate: false
    };
    this.dialog.open(AddEditNotesDialogComponent, this.appUtilService.getDialogData(dialogData))
      .afterClosed().subscribe((result) => {
      if (result) {
        this.getNotes();
      }
    });
  }

  /***
   * @Author : Sonal Afre (sonal.afre@kahunasystems.com)
   * @description :  gets note list from server
   * @return {void}
   */
  getNotes(): void {
    this.loader.show();
    this.webService.get(CrmContactConstants.API.getNotes + this.userCrmId)
      .subscribe((response: CrmNoteResponse) => {
        this.loader.hide();
        if (response.status.code === 200) {
          this.noteList = response.crmNotes;
          this.setGridData();
        } else {
          this.toasterService.showToast(response.status.message, 'error');
        }
      }, () => {
        this.loader.hide();
      });
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.setGridData();
  }

  setGridData(): void {
    this.notesList = process(this.noteList, this.state);
  }
}
