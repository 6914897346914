import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { ChangePasswordComponent } from './change-password.component';
import { AlertDialogModule } from '../../../core/alert-dialog/alert-dialog.module';

@NgModule({
  imports: [SharedModule, AlertDialogModule],
  declarations: [ChangePasswordComponent],
  entryComponents: [ChangePasswordComponent]
})
export class ChangePasswordModule {
}
