import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { NavigationStart, Router, RouterEvent } from '@angular/router';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatSidenav } from '@angular/material';
import { AppSettingDetailsProto, AppSettingService } from '../../../core/app-setting.service';
import { MenuService } from '../menu.service';
import { MainMenuItem } from '../../layout-constants';
import { ServerUrls } from '../../../server-url-constants';

@Component({
  selector: 'portal-menu-sidenav',
  templateUrl: './menu-sidenav.component.html',
  styleUrls: ['./menu-sidenav.component.scss'],
})
export class MenuSidenavComponent implements OnInit, OnDestroy {
  /**
   * Import material sidenav so we can access open close functions.
   */
  @Input() sidenav: MatSidenav;
  @Input() isTabbed: boolean;
  routerSubscription: Subscription;

  public appSetting: AppSettingDetailsProto;
  private menuSubscriber: Subscription;
  private appSettingSubscriber: Subscription;
  public mainMenuList: MainMenuItem[] = [];
  public searchMenuList: Observable<MainMenuItem[]>;
  public homepageLogoPaddingRemove: boolean = false;
  public isNOFA: boolean = ServerUrls.IS_NOFA;

  constructor(private router: Router, private appSettingService: AppSettingService,
              private menuService: MenuService) {
    this.routerSubscription = this.router.events
      .pipe(
        filter(event => event instanceof NavigationStart),
      )
      .subscribe((event: RouterEvent) => {
        if ((this.sidenav && this.sidenav.mode === 'over' && this.sidenav.opened) ||
          (this.sidenav.position === 'end' && this.sidenav.mode === 'over')) {
          this.sidenav.close();
        }
      });
  }

  ngOnInit(): void {
    this.appSettingSubscriber = this.appSettingService.getObserver().subscribe((result) => {
      this.appSetting = result;
    });
    this.menuSubscriber = this.menuService.getMenus().subscribe((response: MainMenuItem[]) => {
      this.mainMenuList = response;
    });
    this.searchMenuList = this.menuService.getMenuForSearch();
    this.homepageLogoPaddingRemove = ServerUrls.homepageLogoPaddingRemove;
  }

  ngOnDestroy(): void {
    if (this.menuSubscriber) {
      this.menuSubscriber.unsubscribe();
    }
    if (this.appSettingSubscriber) {
      this.appSettingSubscriber.unsubscribe();
    }
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }
}
