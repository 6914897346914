import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { AddEditListDialogData, ConfigObj, CrmContactConstants } from '../crm-contact-constants';
import { AddEditListDialogComponent } from '../crm-contact/add-edit-list-dialog/add-edit-list-dialog.component';
import { AppUtilService } from '../../../../../core/app-util.service';
import { CrmListResponse } from '../crm-contact/types/crm-list-response';
import { LoaderService } from '../../../../../core/loader/loader.service';
import { WebService } from 'web-service';
import { CommonCookieService } from 'kscookies';
import { Router } from '@angular/router';

@Component({
  selector: 'app-view-crm-contact',
  templateUrl: './view-crm-contact.component.html',
  styleUrls: ['./view-crm-contact.component.scss', '../crm-contact/crm-contact.component.scss']
})
export class ViewCrmContactComponent implements OnInit {
  public mobile: any[] = [];
  public telephone: any[] = [];
  public fax: any[] = [];
  public details: any[] = [];
  public contactList: any[] = [];
  private temp: any;
  @Input() data: any;

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public dialogData: any,
              @Optional() public matDialogRef: MatDialogRef<ViewCrmContactComponent>,
              private dialog: MatDialog,
              private appUtilService: AppUtilService, private loaderService: LoaderService,
              private webService: WebService, private cookieService: CommonCookieService,
              private router: Router) {
  }

  ngOnInit() {
    if (this.dialogData) {
      this.data = this.dialogData;
    }
    this.getContactList();
    if (this.data.contactDetails && this.data.contactDetails.length > 0) {
      this.temp = this.data.contactDetails;
      for (let i = 0; i < this.temp.length; i++) {
        if (this.temp[i].type === 'Mobile') {
          this.mobile.push({
            'phoneCountryPrefix': this.temp[i].phoneCountryPrefix,
            'phoneNumberFull': this.temp[i].phoneNumberFull
          });
        } else if (this.temp[i].type === 'Telephone') {
          this.telephone.push({
            'phoneCountryPrefix': this.temp[i].phoneCountryPrefix,
            'phoneNumberFull': this.temp[i].phoneNumberFull,
            'phoneExtension': this.temp[i].phoneExtension
          });
        } else if (this.temp[i].type === 'Fax') {
          this.fax.push({'faxNumber': this.temp[i].faxNumber});
        }
      }
    }
    if (this.data.additionalDetails) {
      for (const key in this.data.additionalDetails[0].information) {
        this.details.push({'key': key, 'value': this.data.additionalDetails[0].information[key]});
      }
    }
  }

  getContactList(): void {
    this.loaderService.show();
    this.webService.get(CrmContactConstants.API.contact.getContactList)
      .subscribe((response: CrmListResponse) => {
        this.loaderService.hide();
        if (response.status.code === 200) {
          this.contactList = response.lists;
        }
      }, () => this.loaderService.hide());
  }

  onAddSelectedContacts() {

    const dialogData: AddEditListDialogData = {
      title: 'CRM_CONTACT.ADD_VIEW_LIST_DIALOG.SAVE_LIST',
      for: 'list',
      config: new ConfigObj(),
      crmList: [this.data.crmId],
      fields: [],
      isUpdate: false,
      viewList: this.contactList
    };
    this.dialog.open(AddEditListDialogComponent, this.appUtilService.getDialogData(dialogData))
      .afterClosed().subscribe((res) => {
      if (res) {

      }
    });
  }

  onCreateCase() {
    this.cookieService.setCookie('cId', this.data.crmId);
    this.router.navigate(['/case-engine/case-management/intake/create']);
  }
}
