import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { RightMaskHandler } from '../../../../../shared/classes/right-mask-handler/right-mask-handler';
import { MenuService } from '../../../../../layouts/components/menu.service';
import { Subscription } from 'rxjs';
import { StaticTabs } from '../../../../../layouts/layout-constants';
import { CommonCookieService } from 'kscookies';

@Component({
  selector: 'app-crm-contact',
  templateUrl: './crm-contact.component.html',
  styleUrls: ['./crm-contact.component.scss', './add-custom-views/add-custom-views.component.scss']
})
export class CrmContactComponent extends RightMaskHandler implements OnInit, OnDestroy {

  public selectedTab: string = 'crm_contact_view';
  private tabsSubscription: Subscription;
  private tabList: StaticTabs[] = [
    {
      id: 'crm_contact_view',
      title: 'CRM_CONTACT.TITLE'
    },
    {
      id: 'crm_contact_list',
      title: 'CRM_CONTACT.CONTACT_LIST_TITLE'
    },
    {
      id: 'crm_mailing_list',
      title: 'CRM_CONTACT.MAILING_LIST.TITLE'
    },
    {
      id: 'app_campaign',
      title: 'CRM_CONTACT.CAMPAIGN'
    }
  ];

  constructor(public menuService: MenuService, private changeDetector: ChangeDetectorRef, private cookieService: CommonCookieService) {
    super(menuService);
  }

  ngOnInit(): void {
    this.menuService.setStaticTabs(this.tabList);
    if (this.cookieService.getCookie('bat-mrc')) {
      this.menuService.onClickStaticTab(this.cookieService.getCookie('bat-mrc'));
      this.cookieService.removeCookie('bat-mrc');
    }
    this.getRightMask('app-crm-contact');
    this.tabsSubscription = this.menuService.staticTabClick.asObservable().subscribe((value) => {
      if (value) {
        this.selectedTab = value;
        this.cookieService.setCookie('bat-mrc', value);
      }
      this.changeDetector.detectChanges();
    });
  }

  ngOnDestroy(): void {
    if (this.tabsSubscription) {
      this.tabsSubscription.unsubscribe();
    }
  }

}
