/****** Created by shivani gajjar(shivani.gajjar@kahunasystems.com) 4/10/17 3:47 PM*******/
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CommonCookieService } from 'kscookies';

@Injectable()
export class LanguageService {

  constructor(public translate: TranslateService, private cookieService: CommonCookieService) {
  }

  getLanguages() {
    return this.translate.getLangs();
  }

  getLangs() {
    return this.translate.getLangs();
  }

  addLanguages(lang: string[]) {
    this.translate.addLangs(lang);
  }

  setDefaultLanguage(lang) {
    this.translate.setDefaultLang(lang);
    this.translate.use(lang);
    this.cookieService.setCookie('lang', lang);
  }

  setDefaultLang(lang) {
    this.translate.setDefaultLang(lang);
    this.translate.use(lang);
    this.cookieService.setCookie('lang', lang);
  }

  getCurrentLanguage() {
    return this.cookieService.getCookie('lang');
  }

  getDefaultLang() {
    return this.cookieService.getCookie('lang');
  }

  changeLanguage(lang) {
    this.translate.use(lang);
  }

  instant(key: string) {
    return this.translate.instant(key);
  }

}
