/**
 * Created by Isha Dhimar (isha.dhimar@kahunasystems.com) on 22/3/18.
 **/
import { Pipe, PipeTransform } from '@angular/core';

export class SearchDataProto {
  text: string;
  filterBy: string;
}

@Pipe({
  name: 'filter'
})

export class FilterPipe implements PipeTransform {
  transform(items: any[], searchData: SearchDataProto): any[] {
    if (!items) {
      return [];
    }
    if (!searchData.text) {
      return items;
    }
    return items.filter((it) => {
      if (Array.isArray(it[searchData.filterBy])) {
        return it[searchData.filterBy].includes(searchData.text);
      }
      searchData.text = searchData.text.toLowerCase();
      return it[searchData.filterBy].toLowerCase().includes(searchData.text);
    });
  }
}
