import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { WebService } from 'web-service';
import { CrmContactConstants, ViewSaveRequest } from '../../crm-contact-constants';
import { ToasterService } from '../../../../../../core/toaster/toaster.service';
import { LoaderService } from '../../../../../../core/loader/loader.service';

@Component({
  selector: 'app-save-view-dialog',
  templateUrl: './save-view-dialog.component.html',
  styleUrls: ['./save-view-dialog.component.scss']
})
export class SaveViewDialogComponent implements OnInit {
  public viewSaveRequest: ViewSaveRequest = new ViewSaveRequest();
  public update: boolean = false;
  public privacyType: string = 'public';
  public staffAccessType: string = 'read';
  public groupAccessType: string = 'read';
  public groupAccess: boolean = false;
  public userGroupsList: any[] = [];
  public viewList: any[] = [];
  public queryType: boolean = true;
  public url = '';

  constructor(public dialogRef: MatDialogRef<SaveViewDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public dialogData: any,
              public webService: WebService, private toaster: ToasterService,
              private loaderService: LoaderService) {
    this.getGroups();
  }

  ngOnInit(): void {
    if (this.dialogData.id) {
      this.update = true;
      this.viewSaveRequest.name = this.dialogData.name;
      this.viewSaveRequest.description = this.dialogData.description;
      this.viewSaveRequest.id = this.dialogData.id;
      if (this.dialogData.fields) {
        if (this.dialogData.fields[this.dialogData.fields.length - 1] === 'view-change') {
          this.dialogData.fields.pop(this.dialogData.fields.length - 1);
        }
      }
      this.viewSaveRequest.fields = this.dialogData.fields;
      this.viewSaveRequest.config.crmIds = this.dialogData.config.crmIds;
      this.viewSaveRequest.crmList = this.dialogData.crmList;
      this.viewSaveRequest.config.staffAccess = this.dialogData.config.staffAccess;
      this.viewSaveRequest.config.staffUpdateAccess = this.dialogData.config.staffUpdateAccess;
      this.viewSaveRequest.config.groupUpdateAccess = this.dialogData.config.groupUpdateAccess;
      this.viewSaveRequest.config.groupIds = this.dialogData.config.groupIds;
      this.viewSaveRequest.config.viewAll = this.dialogData.config.viewAll;
      if (this.dialogData.config.groupIds.length > 0) {
        this.groupAccess = true;
      }
      if (this.dialogData.config.viewAll) {
        this.privacyType = 'public';
      } else {
        this.privacyType = 'private';
      }
      if (this.dialogData.config.staffUpdateAccess) {
        this.staffAccessType = 'update';
      } else {
        this.staffAccessType = 'read';
      }
      if (this.dialogData.config.groupUpdateAccess) {
        this.groupAccessType = 'update';
        this.groupAccess = true;
      } else {
        this.groupAccessType = 'read';
        this.groupAccess = false;
      }
    } else {
      this.viewSaveRequest.fields = this.dialogData.fields;
      if (this.dialogData.viewList) {
        if (this.dialogData.viewList.length > 0) {
          this.viewList = this.dialogData.viewList;
        }
      }
    }
  }

  getGroups(): void {
    const reqObj = {
      pageNumber: -1,
      pageSize: -1
    };
    this.webService.post(CrmContactConstants.API.contact.get_group_mapping, reqObj)
      .subscribe(
        (response: any) => {
          if (response.status.code === 200) {
            this.userGroupsList = response.userGroups;
          }
        }, () => {
        });
  }

  changePrivacy(event: any): void {
    if (event === 'public') {
      this.viewSaveRequest.config.viewAll = true;
    } else {
      this.viewSaveRequest.config.viewAll = false;
    }
  }

  changeStaffType(event: any): void {
    if (event === 'read') {
      this.viewSaveRequest.config.staffUpdateAccess = false;
    } else if (event === 'update') {
      this.viewSaveRequest.config.staffUpdateAccess = true;
    }
  }

  changeStaffAccess(event: any): void {
    this.viewSaveRequest.config.staffAccess = event.checked;
  }

  changeGroupType(event: any): void {
    if (event === 'read') {
      // this.viewSaveRequest.config.groupUpdateAccess = false;
      this.viewSaveRequest.config.groupIds = [];
    } else if (event === 'update') {
      // this.viewSaveRequest.config.groupUpdateAccess = true;
    }
  }

  changeGroupAccess(event: any): void {
    if (event.checked) {
      this.viewSaveRequest.config.groupUpdateAccess = true;
    } else {
      this.viewSaveRequest.config.groupUpdateAccess = false;
    }
  }

  saveView(): void {
    if (!this.viewSaveRequest.config.staffAccess) {
      this.viewSaveRequest.config.staffUpdateAccess = false;
    }
    this.loaderService.show();
    this.viewSaveRequest.name = this.viewSaveRequest.name.trim();

    if (this.dialogData.isUpdate) {

      if (this.viewSaveRequest.crmList !== undefined) {
        if (this.viewSaveRequest.crmList.length > 0) {
          this.viewSaveRequest.removeFromList = false;
        }
      }
      this.viewSaveRequest.responseFields = this.dialogData.responseFields;

      this.webService.put(CrmContactConstants.API.contact.update, this.viewSaveRequest)
        .subscribe((response) => {
          this.loaderService.hide();
          if (response.status.code === 200) {
            this.toaster.showToast(response.status.message, 'success');
            this.dialogRef.close(true);
          } else {
            this.toaster.showToast(response.status.message, 'error');
          }
        }, () => this.loaderService.hide());
    } else {
      delete this.viewSaveRequest.id;
      delete this.viewSaveRequest.removeFromList;
      if (this.dialogData.fromAllContacts) {
        this.viewSaveRequest.fields = this.dialogData.fields;
      } else {
        this.viewSaveRequest.crmList = this.dialogData.crmList;
      }
      this.viewSaveRequest.responseFields = this.dialogData.responseFields;
      delete this.viewSaveRequest.crmList;
      this.webService.post(CrmContactConstants.API.contact.save, this.viewSaveRequest)
        .subscribe((response) => {
          this.loaderService.hide();
          if (response.status.code === 200) {
            this.dialogRef.close(true);
            this.toaster.showToast(response.status.message, 'success');
          } else {
            this.toaster.showToast(response.status.message, 'error');
          }
        }, () => this.loaderService.hide());

    }
  }

}
