import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CrmContactComponent } from './crm-contact/crm-contact.component';
import { IsAuthenticatedGuard } from '../../../../core/guards/is-authenticated.guard';
import {ShowMailHistoryComponent} from './crm-contact/crm-mailing-list/show-mail-history/show-mail-history.component';
import { ViewCrmContactComponent } from './view-crm-contact/view-crm-contact.component';

const routes: Routes = [
  {
    path: '',
    component: CrmContactComponent,
    canActivate: [IsAuthenticatedGuard],
    data: {
      title: 'CRM_CONTACT.TITLE'
    }
  },
  {
    path: 'view',
    component: ViewCrmContactComponent
  },
  {
    path: 'history/:id',
    component: ShowMailHistoryComponent
  },
  {
    path: 'inventory',
    loadChildren: 'app/feature-modules/admin/crm/crm-contact/crm-inventory/crm-inventory.module#CrmInventoryModule'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CrmContactRoutingModule {
}
