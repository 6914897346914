import { MatDialogRef } from '@angular/material';
import { ToasterService } from '../../../core/toaster/toaster.service';
import { LoaderService } from '../../../core/loader/loader.service';
import { WebService } from 'web-service';
import { ServerUrls } from '../../../server-url-constants';
import { AuthenticationUrlConstants } from '../authentication-url.constants';
import { Component } from '@angular/core';

@Component({
  selector: 'app-verify-otp-dialog',
  templateUrl: './verify-otp-dialog.component.html',
  styleUrls: ['./verify-otp-dialog.component.scss']
})
export class VerifyOtpDialogComponent {

  public otp: string;

  constructor(public webService: WebService, public toasterService: ToasterService,
              public dialogRef: MatDialogRef<VerifyOtpDialogComponent>, public loader: LoaderService) {
  }

  /**
   * @author: Ganeshram Kumhar (ganeshram.kumhar@kahunasystems.com)
   * @description : Verify OTP with server
   * @return {void}
   */
  onSubmit() {
    this.loader.show();
    this.webService.post(AuthenticationUrlConstants.API.Authentication.verifyOTP, {
      'otp': this.otp
    }).subscribe((response) => {
      this.loader.hide();
      if (response.status.code === 200) {
        this.dialogRef.close(true);
      } else {
        this.toasterService.showToast(response.status.message, 'error');
      }
    }, () => {
      this.loader.hide();
    });
  }

}
