import { Component, Input, OnChanges } from '@angular/core';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';
import { LoaderService } from '../../../../../core/loader/loader.service';
import { AppUtilService } from '../../../../../core/app-util.service';
import { ToasterService } from '../../../../../core/toaster/toaster.service';
import { WebService } from 'web-service';
import { CrmContactConstants } from '../crm-contact-constants';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-crm-case-list',
  templateUrl: './crm-case-list.component.html',
  styleUrls: ['./crm-case-list.component.scss']
})
export class CrmCaseListComponent implements OnChanges {
  public gridView: GridDataResult = {
    data: [],
    total: 0
  };
  public casesList = [];
  public caseType: any;
  public state: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: []
    },
    sort: []
  };
  public clearInputFilter: Subject<any> = new Subject<any>();
  public showCaseEntity: boolean = false;
  @Input('crmId') userCrmId: string;

  constructor(private loader: LoaderService,
              private appUtilService: AppUtilService,
              private toasterService: ToasterService,
              private webService: WebService,
              private router: Router) {
    // this.allData = this.allData.bind(this);
  }

  /*public allData(): ExcelExportData {
    const state: State = {
      skip: 0,
      take: this.caseList.length
    };
    const crmCaseList = this.caseList;
    const result: ExcelExportData = {
      data: process(crmCaseList, state).data
    };
    return result;
  }*/

  ngOnChanges(): void {
    if (this.userCrmId) {
      this.getCases();
    }
  }

  /***
   * @Author : Sonal Afre (sonal.afre@kahunasystems.com)
   * @description :  gets case list from server
   * @return {void}
   */
  getCases(): void {
    this.loader.show();
    this.webService.getWithParams(CrmContactConstants.API.getCaseList, {ownerId: this.userCrmId})
      .subscribe((response: any) => {
        this.loader.hide();
        if (response.status.code === 200) {
          this.casesList = response.cases;
          this.showCaseEntity = false;
          this.casesList.forEach((v) => {
            v.caseType = v.caseType['name'];
            if (v.assest && v.assest.apnNo) {
              v.assest1 = 'APN: ' + v.assest['apnNo'];
            }

            if (v.assest1) {
              this.showCaseEntity = true;
            }
          });
          this.gridView = process(this.casesList, this.state);
        } else {
          this.toasterService.showToast(response.status.message, 'error');
        }

      }, () => {
        this.loader.hide();
      });
  }

  /***
   * @Author : Sonal Afre (sonal.afre@kahunasystems.com)
   * @description :  view case detail
   * @return {void}
   */
  onViewCase(dataItem: any): void {
    this.loader.show();
    this.router.navigate(['property', dataItem.assest.apnNo, dataItem.caseId]);
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.setGridData();
  }

  setGridData(): void {
    this.gridView = process(this.casesList, this.state);
  }
}
