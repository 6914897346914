import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import {
  AddEditListDialogData,
  AddressTypeList,
  AppIdList,
  CRM_OPERATORS_LIST,
  CrmContactConstants,
  CustomFieldObj,
  FieldKey,
  GetDataReqProto,
  OperatorList,
  ViewSaveRequest
} from '../../crm-contact-constants';
import { WebService } from 'web-service';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { MatCheckboxChange, MatDialog, MatDrawer } from '@angular/material';
import { AppUtilService } from '../../../../../../core/app-util.service';
import { LoaderService } from '../../../../../../core/loader/loader.service';
import { ToasterService } from '../../../../../../core/toaster/toaster.service';
import { CrmContactService } from '../../crm-contact.service';
import { Subscription } from 'rxjs';
import { AddEditListDialogComponent } from '../add-edit-list-dialog/add-edit-list-dialog.component';

@Component({
  selector: 'app-add-custom-views',
  templateUrl: './add-custom-views.component.html',
  styleUrls: ['./add-custom-views.component.scss']
})
export class AddCustomViewsComponent implements OnInit, OnDestroy {

  public viewSaveRequest: ViewSaveRequest = new ViewSaveRequest();
  public fieldKey: string[] = [];
  public sortRoleList: any[];
  public caseCategoryList: any[] = [];
  public caseTypesList: any[] = [];
  public caseStatusList: any[] = [];
  public addressListCountryList: any[] = [];
  public inspectedByList: any[] = [];
  public assignedToList: any[] = [];
  public appIdSortList: any[];
  public addressTypeSortList: any[];
  public operatorsList: any[];
  public customFieldList: CustomFieldObj[] = [];
  public operatorSortList: any[];
  public getDataReqProto: GetDataReqProto = new GetDataReqProto();

  public _id: string;
  public dropDown: any[];
  @Output('pastSelectedSend') pastSelectedSend: any = new EventEmitter();
  @Output('searchResultEvent') searchResultEventEmitter: any = new EventEmitter();
  @Output('saveViewEvent') saveViewEventEmitter: any = new EventEmitter();

  public showError: boolean = true;

  @ViewChild('addCustomViewForm') addCustomViewForm;
  public fields: any[] = [];
  public pastSelected: any[] = [];
  public search: string;

  @Input()
  set id(value: string) {
    if (value) {
      this._id = value;
    } else {
      this._id = '';
      this.viewSaveRequest = new ViewSaveRequest();
    }
  }

  @Input('searchChange')
  set searchChange(text: string) {
    if (text) {
      this.search = text;
    } else {
      this.search = '';
    }
    for (let i = 0; i < this.customFieldList.length; i++) {
      if (this.customFieldList[i].isChecked) {
        if (!this.customFieldList[i].value || !this.customFieldList[i].operation) {
          this.customFieldList[i].isChecked = false;
        }
      }
    }
  }

  @Input('drawer') drawer: MatDrawer;
  fieldList: any[] = [];

  @Input('responseViewFields') responseViewFields: Array<any>;

  drawerSubscription: Subscription;
  selectedFiltersSubscription: Subscription;
  fieldListSubscription: Subscription;
  userRolesSubscription: Subscription;
  eventEmitSubscription: Subscription;
  public apnFilters: any[] = [];
  public caseFilters: any[] = [];
  public crmFilters: any[] = [];

  constructor(private loader: LoaderService, private webService: WebService,
              private router: Router, private appUtilService: AppUtilService,
              public dialog: MatDialog, private toaster: ToasterService,
              private crmContactService: CrmContactService) {
  }

  ngOnDestroy(): void {
    if (this.drawerSubscription) {
      this.drawerSubscription.unsubscribe();
    }
    if (this.selectedFiltersSubscription) {
      this.selectedFiltersSubscription.unsubscribe();
    }
    if (this.fieldListSubscription) {
      this.fieldListSubscription.unsubscribe();
    }
    if (this.userRolesSubscription) {
      this.userRolesSubscription.unsubscribe();
    }
    if (this.eventEmitSubscription) {
      this.eventEmitSubscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.operatorsList = this.appUtilService.sortList(CRM_OPERATORS_LIST, 'name');
    this.drawerSubscription = this.drawer.openedChange.subscribe((event) => {
      if (event === false && this.addCustomViewForm.valid) {
        this.crmContactService.selectedFilters$.next(this.customFieldList);
      }
    });

    this.selectedFiltersSubscription = this.crmContactService.selectedFilters$.asObservable().subscribe((list) => {
      this.customFieldList = list;
    });

    this.fieldListSubscription = this.crmContactService.fieldList$.asObservable().subscribe((list) => {
      this.fieldList = list;
      this.getFields();
    });

    this.userRolesSubscription = this.crmContactService.userRoles$.asObservable().subscribe((list) => {
      this.sortRoleList = list;
    });

    this.operatorSortList = this.appUtilService.sortList(OperatorList, 'name');
    this.appIdSortList = this.appUtilService.sortList(AppIdList, '');
    this.addressTypeSortList = this.appUtilService.sortList(AddressTypeList, '');
    this.fieldKey = [...FieldKey];
    this.eventEmitSubscription = this.crmContactService.eventEmit$.asObservable().subscribe((event) => {
      if (event === 'reset') {
        this.getFields();
      }
      const finalFields: any[] = this.customFieldList.filter((field) => {
        return field.isChecked === true;
      });
      if (finalFields.length === 0) {
        this.showError = true;
      } else {
        this.showError = false;
      }
    });
    this.getCategoryAndType();
    this.getListOfCountries();
  }

  getListOfCountries() {
    this.webService.get(CrmContactConstants.API.getListOfCountries).subscribe((response) => {
      if (response.status.code === 200) {
        this.addressListCountryList = this.appUtilService.sortList(response.countryList, 'countryName');
      }
    });
  }

  getCategoryAndType() {
    this.webService.get(CrmContactConstants.API.contact.category_and_type).subscribe((response) => {
      if (response.status.code === 200) {
        this.caseCategoryList = response.serviceRequestTypes;
      }
    });
  }

  getFields() {
    this.customFieldList = [];
    this.fieldList.forEach((object, index) => {
      const temp = {
        key: object.key,
        title: object.value,
        type: object.type,
        isChecked: false,
        excludeFromSearch: false,
        indexValue: index,
        additionalInfo: object.additionalInfo
      };
      if (object.type === 'LIST') {
        temp['operation'] = 'EQ';
      }
      if (object.type === 'ENUM') {
        temp['operation'] = 'EQ';
        temp['lovList'] = object.lovList;
      }
      if (object.type === 'DATE') {
        temp['value'] = 'dateField';
        temp['operation'] = 'EQ';
        temp['startDate'] = object.startDate;
        temp['endDate'] = object.endDate;
      }
      this.customFieldList.push(temp);
    });
   /* this.crmFilters = this.customFieldList.filter(object => object.additionalInfo.crmFields);
    this.apnFilters = this.customFieldList.filter(object => object.additionalInfo.apnFields);
    this.caseFilters = this.customFieldList.filter(object => object.additionalInfo.caseFields);*/
    this.crmFilters = this.customFieldList.filter(object => object.additionalInfo ? object.additionalInfo.crmFields : '');
    this.apnFilters = this.customFieldList.filter(object => object.additionalInfo ? object.additionalInfo.apnFields : '');
    this.caseFilters = this.customFieldList.filter(object => object.additionalInfo ? object.additionalInfo.caseFields : '');
  }

  /**
   * @author: Rasana Agrawal (rasna.agarwal@kahunasystems.com)
   * @description : search particular fields on search button
   * @param:void
   * @return {void}
   */

  setFields(): any {
    this.customFieldList = this.apnFilters.concat(this.caseFilters).concat(this.crmFilters);
    const fields: any[] = _.cloneDeep(this.customFieldList);
    const finalFields: any[] = fields.map((field, index) => {
      if (field.isChecked === true) {
        if (field.key === 'userType' || field.key === 'userRole' || field.key === 'emailVerified' || field.key === 'contactDetails.phoneCountryPrefix') {
          field.operation = 'EQ';
        }
        if (field.type !== 'BOOLEAN' && (!field.operation || !field.value)) {
          this.customFieldList[index].isChecked = false;
          field.isChecked = false;
        }
      }
      return field;
    }).filter(field => field.isChecked);

    finalFields.forEach((val) => {
      if (val.isChecked) {
        if (typeof val.value === 'string') {
          val.value = val.value.trim();
        }
        delete val.title;
        delete val.type;
        delete val.isChecked;
        delete val.excludeFromSearch;
        delete val.indexValue;
      }
    });
    return finalFields;
  }

  onSearchFields(): void {
    this.search = '';
    const finalFields: any[] = this.setFields();
    if (finalFields.length === 0) {
      return;
    }
    this.getDataReqProto.fields = [...finalFields];
    this.getDataReqProto.sideBarFilterFlag = true;
    if (this.addCustomViewForm.invalid && this.customFieldList.length <= 1) {
      return;
    }
    if (this.addCustomViewForm.valid) {
      this.searchResultEventEmitter.next(this.getDataReqProto);
      this.pastSelectedSend.next(this.customFieldList);
    }
  }

  changeField(event: MatCheckboxChange, customField: CustomFieldObj): void {
    const findObject = this.customFieldList.find(object => object.key === customField.key);
    if (findObject) {
      findObject.isChecked = event.checked;
      if (!event.checked) {
        findObject.value = '';
      }
      if (findObject.key === 'caseTypeCategory' && !event.checked) {
        this.caseTypesList = [];
        this.removeFieldKeyFromList('caseType');

        this.caseStatusList = [];
        this.removeFieldKeyFromList('caseStatus');
      }
      if (findObject.key === 'caseType' && !event.checked) {
        this.caseStatusList = [];
        this.removeFieldKeyFromList('caseStatus');
      }
    }
    const finalFields: any[] = this.customFieldList.filter(object => object.isChecked);
    this.showError = (finalFields.length === 0);
    setTimeout(() => {
      if (this.addCustomViewForm.valid) {
        this.crmContactService.selectedFilters$.next(this.customFieldList);
      }
    }, 500);
  }


  /**
   * @author: Rasana Agrawal (rasna.agarwal@kahunasystems.com)
   * @description : save the searched list
   * @param: void
   * @return {void}
   */
  onSaveSearchList(isUpdate: boolean): void {
    this.viewSaveRequest.fields = this.setFields();
    if (this._id) {
      const reqObj: any = {};
      reqObj.id = this.viewSaveRequest.id;
      reqObj.name = this.viewSaveRequest.name;
      reqObj.fields = this.viewSaveRequest.fields;
      reqObj.config = this.viewSaveRequest.config;
      this.webService.put(CrmContactConstants.API.contact.update, reqObj)
        .subscribe((response) => {
          this.loader.hide();
          if (response.status.code === 200) {
            this.toaster.showToast(response.status.message, 'success');
            this.saveViewEventEmitter.next();
          } else {
            this.toaster.showToast(response.status.message, 'error');
          }
        }, () => this.loader.hide());
    } else {
      const dialogData: AddEditListDialogData = <any>_.cloneDeep(this.viewSaveRequest);
      dialogData.isUpdate = isUpdate;
      dialogData.for = 'view';
      dialogData.responseFields = this.responseViewFields;
      dialogData.title = 'CRM_CONTACT.SAVE_CRITERIA';
      this.dialog.open(AddEditListDialogComponent, this.appUtilService.getDialogData(dialogData))
        .afterClosed().subscribe((res) => {
        if (res) {
          this.saveViewEventEmitter.next();
        }
      });
    }
  }

  onCaseTypeCategoryChange(item: CustomFieldObj) {
    this.caseStatusList = [];
    this.caseTypesList = [];
    if (item.value) {
      if (item.value.length === 0) {
        this.removeFieldKeyFromList('caseType');
      }
      item.value.forEach((id) => {
        const findObject = this.caseCategoryList.find(object => object.id === id);
        if (findObject) {
          this.caseTypesList = this.caseTypesList.concat(findObject.child);
        }
      });
    }
  }

  onCaseTypeChange(item: CustomFieldObj) {
    const params = {
      ids: this.customFieldList.find(object => object.key === 'caseType').value
    };
    this.webService.getWithParams(CrmContactConstants.API.contact.getActiveStates, params)
      .subscribe((response) => {
        if (response.status.code === 200) {
          this.caseStatusList = response.states;
        }
      });
  }


  removeFieldKeyFromList(fieldKey: string) {
    const findObject = this.customFieldList.find(object => object.key === fieldKey);
    const caseFiltersFindObject = this.caseFilters.find(object => object.key === fieldKey);
    const crmFiltersFindObject = this.crmFilters.find(object => object.key === fieldKey);
    const apnFiltersFindObject = this.apnFilters.find(object => object.key === fieldKey);
    if (findObject) {
      findObject.isChecked = false;
      findObject.value = [];
    }
    if (caseFiltersFindObject) {
      caseFiltersFindObject.isChecked = false;
      caseFiltersFindObject.value = [];
    }
    if (crmFiltersFindObject) {
      crmFiltersFindObject.isChecked = false;
      crmFiltersFindObject.value = [];
    }
    if (apnFiltersFindObject) {
      apnFiltersFindObject.isChecked = false;
      apnFiltersFindObject.value = [];
    }
  }
}
