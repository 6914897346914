import { Injectable } from '@angular/core';
import { FacebookService, InitParams, LoginOptions, LoginResponse } from 'ngx-facebook';
import { Observable } from 'rxjs/Observable';
import { FacebookAppGlobals } from './app-globals';

@Injectable()
export class FacebookAuthService {
  constructor(private fb: FacebookService) {
  }

  /**
   * Calling facebook login API and fetching account details.
   * @param callback Callback to function
   */
  public authenticateUser(): Observable<any> {
    return new Observable((resolve) => {
      try {
        const params: InitParams = {
          appId: FacebookAppGlobals.FACEBOOK_CLIENT_ID,
          version: 'v2.8',
          xfbml: true
        };
        this.fb.init(params);
        // login with options
        const options: LoginOptions = {
          scope: 'public_profile, email',
          return_scopes: true,
          enable_profile_selector: true
        };
        this.fb.login(options)
          .then((response: LoginResponse) => resolve.next(response))
          .catch(e => console.log(e));

      } catch (error) {
        resolve.error(error);
      }
    });
  }

  getUserInfo(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.fb.api('/me?fields=name,email', 'get').then((response) => {
        resolve(response);
      }, (response) => {
        reject(response);
      });
    });
  }

  getUserProfileImage(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.fb.api('/me/picture?type=normal', 'get').then((response) => {
        resolve(response.data.url);
      }, (response) => {
        reject(response);
      });
    });
  }
}
