import { MatSidenav } from '@angular/material';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AppSettingDetailsProto, AppSettingService } from '../../../core/app-setting.service';
import { Subscription } from 'rxjs';
import { LayoutService } from '../../layout.service';
import { ServerUrls } from '../../../server-url-constants';
import { WebService } from 'web-service';
declare var fby: any;
@Component({
  selector: 'portal-top-horizontal-menu',
  templateUrl: './top-horizontal-menu.component.html',
  styleUrls: ['./top-horizontal-menu.component.scss']
})
export class TopHorizontalMenuComponent implements OnInit, OnDestroy {
  notificationsChecked: Boolean = false;
  public homepageLogoPaddingRemove: Boolean = false;

  /**
   * Import left sidenav so we can access open close functions.
   */
  @Input() leftSidenav: MatSidenav;

  /**
   * Import right sidenav so we can access open close functions.
   */
  @Input() rightSidenav: MatSidenav;

  /**
   * Makes the toolbar transparent.
   */
  @Input() transparent: Boolean = false;

  /**
   * to show hide app logo in toolbar
   */
  @Input() showAppLogo: Boolean = false;

  /**
   * to show hide global search in toolbar
   */
  @Input() showGlobalSearch: Boolean = false;

  public appSetting: AppSettingDetailsProto;
  public cartItemsLength: number;
  public hideCart: boolean;
  private appSettingSubscriber: Subscription;
  private cartItemsSubscriber: Subscription;
  public isNOFA: boolean = ServerUrls.IS_NOFA;

  constructor(private appSettingService: AppSettingService, public layoutService: LayoutService,
              private webService: WebService) {
  }

  ngOnInit(): void {
    this.appSettingSubscriber = this.appSettingService.getObserver().subscribe((result) => {
      this.appSetting = result;
    });
    this.hideCart = this.appSettingService.getProperty('hideCart');
    if (!this.hideCart) {
      this.cartItemsSubscriber = this.layoutService.cartItemsLength.asObservable()
        .subscribe((result) => {
          if (result) {
            this.getCartItems();
          }
        });
    }
    if (ServerUrls.hideGlobalSearch) {
      this.showGlobalSearch = false;
    }
    this.homepageLogoPaddingRemove = ServerUrls.homepageLogoPaddingRemove;
  }

  ngOnDestroy(): void {
    if (this.appSettingSubscriber) {
      this.appSettingSubscriber.unsubscribe();
    }
    if (this.cartItemsSubscriber) {
      this.cartItemsSubscriber.unsubscribe();
    }
  }

  openRightSidenav(): void {
    this.rightSidenav.toggle();
  }

  openLeftSideNav(): void {
    this.leftSidenav.toggle();
    this.layoutService.leftSideNav.next(this.leftSidenav.opened);
  }

  showMenuIcon(): boolean {
    return this.leftSidenav && !this.leftSidenav.opened;
  }

  getCartItems() {
    this.webService.post(ServerUrls.getCart, {})
      .subscribe((response) => {
        if (response.status.code === 200) {
          this.cartItemsLength = response.items.length;
        }
      });
  }

  onFeedBackFormClick (){
    fby.push(['showForm', '15557']);return false;
  }
}
