import { NgModule } from '@angular/core';
import { GlobalSearchRoutingModule } from './global-search-routing.module';
import { GlobalSearchComponent } from './global-search.component';
import { SharedModule } from '../../../shared/shared.module';
import { SearchResultComponent } from './search-result/search-result.component';
import { CustomKendoModule } from '../../../shared/custom-kendo/custom-kendo.module';
import { CrmContactModule } from '../../../feature-modules/admin/crm/crm-contact/crm-contact.module';
import { OwlModule } from 'ngx-owl-carousel';

@NgModule({
  imports: [SharedModule, GlobalSearchRoutingModule, CustomKendoModule, CrmContactModule, OwlModule],
  declarations: [GlobalSearchComponent, SearchResultComponent],
  exports: [GlobalSearchComponent]
})
export class GlobalSearchModule {
}
