import { NgModule } from '@angular/core';
import { IsAuthenticatedGuard } from './guards/is-authenticated.guard';
import { LoginGuardGuard } from './guards/login-guard.guard';
import { AppUtilService } from './app-util.service';
import { AppSettingService } from './app-setting.service';
import { ToasterService } from './toaster/toaster.service';
import { ConfirmDialogModule } from './confirm-dialog/confirm-dialog.module';
import { LoaderModule } from './loader/loader.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LanguageService } from './language/language.service';
import { RouterSubscribeService } from './router-subscribe.service';
import { LazyLoadService } from './lazy-load/lazy-load.service';
import { DatePipe } from '@angular/common';
import { LayoutService } from '../layouts/layout.service';
import { MenuService } from '../layouts/components/menu.service';
import { LogoutService } from '../feature-modules/authentication/logout/logout.service';
import { CustomSnackBarComponent } from './toaster/custom-snack-bar/custom-snack-bar.component';
import { CustomMaterialModule } from '../shared/custom-material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ErrorDialogModule } from './error-dialog/error-dialog.module';
import { AnalyticsService } from './analytics.service';
import { NotificationService } from '../layouts/components/notification-sidenav/notification.service';
import { SessionTimeoutDialogModule } from './session-timeout-dialog/session-timeout-dialog.module';

@NgModule({
  imports: [
    BrowserModule, CustomMaterialModule, FlexLayoutModule,
    BrowserAnimationsModule, ConfirmDialogModule, ErrorDialogModule, SessionTimeoutDialogModule, LoaderModule.forRoot()],
  providers: [AppUtilService, AppSettingService, ToasterService, IsAuthenticatedGuard, LoginGuardGuard,
    LanguageService, RouterSubscribeService, LazyLoadService, DatePipe,
    LayoutService, MenuService, LogoutService, AnalyticsService, NotificationService],
  declarations: [CustomSnackBarComponent],
  entryComponents: [CustomSnackBarComponent]
})
export class CoreModule {
}
