/**
 * Created by Isha Dhimar (isha.dhimar@kahunasystems.com) on 9/8/18.
 **/

import { NgModule } from '@angular/core';
import {
  MatAutocompleteModule,
  MatBadgeModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatRadioModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatStepperModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule
} from '@angular/material';
import { CdkTreeModule } from '@angular/cdk/tree';

const modules = [
  MatCardModule, MatFormFieldModule, MatSidenavModule, MatIconModule, MatToolbarModule, MatRadioModule,
  MatAutocompleteModule, MatPaginatorModule, MatTabsModule, MatCheckboxModule, MatSelectModule, MatDialogModule,
  MatTooltipModule, MatMenuModule, MatListModule, MatInputModule, MatButtonModule, MatDatepickerModule,
  MatChipsModule, MatSlideToggleModule, MatStepperModule, MatGridListModule, MatSliderModule, MatSnackBarModule,
  MatNativeDateModule, MatExpansionModule, MatTableModule, MatButtonToggleModule, MatBadgeModule, CdkTreeModule
];

@NgModule({
  imports: [modules],
  exports: [modules]
})
export class CustomMaterialModule {
}
