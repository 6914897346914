import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { OAuth2Service } from 'o-auth2';
import { LayoutService } from '../../layouts/layout.service';

@Injectable()
export class IsAuthenticatedGuard implements CanActivate, CanLoad {
  constructor(private authService: OAuth2Service, private router: Router,
              private layoutService: LayoutService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const index = state.url.lastIndexOf(route.pathFromRoot[route.pathFromRoot.length - 1].routeConfig.path.split('/')[0]);
    const url = state.url.substring(0, index) + route.pathFromRoot[route.pathFromRoot.length - 1].routeConfig.path;
    if (this.authService.isAuthenticated()) {
      this.layoutService.setLoader(false);
      return true;
    }
    this.authService.removeUsersDetailsAndRedirect();
    return false;
  }

  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
    if (this.authService.isAuthenticated()) {
      this.layoutService.setLoader(false);
      return true;
    }
    this.authService.removeUsersDetailsAndRedirect();
    return false;
  }
}
