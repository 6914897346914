/**
 * Created by ganeshram on 9/10/17.
 */

import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { CommonCookieService } from 'kscookies';


export class AddressListPojo {
  public type: string;
  public adressLine: string;
  public city: string;
  public state: string;
  public country: string;
  public zip: string;
  public landmark: string;
  public buildingType: string;
  public unit: string;
  public coordinates: number[] = [];
  public primary: boolean = false;
}

export class UserProfilePojo {
  public crmId: string;
  public token: string;
  public userProfilePath: string;
  public firstName: string;
  public lastName: string;
  public displayGender: boolean = false;
  public addressList: AddressListPojo[] = [];
  public userId: string;
  public receiveEmergencyNotifications = false;
  public primaryEmailId: string;
  public pnEnabled = false;
  public userRole: string[] = [];
  public isAdmin = false;
  public isStaff = false;
  public isCitizen = false;
  public mobile: string;
}


@Injectable({
  providedIn: 'root'
})
export class UserService {

  public Observable: ReplaySubject<UserProfilePojo> = new ReplaySubject();
  private userData: UserProfilePojo = new UserProfilePojo();

  constructor(public cookieService: CommonCookieService) {
  }

  setUser(data: UserProfilePojo): void {
    const keys = Object.keys(data);
    keys.forEach((key) => {
      this.userData[key] = data[key];
    });
    this.Observable.next(this.userData);
    this.cookieService.setObjectToCookie('sjgnei', this.getUser());
  }

  getObserver(): Observable<UserProfilePojo> {
    return this.Observable;
  }

  getUser(): UserProfilePojo {
    return this.userData;
  }

  setUserProperty(key: string, value: any): void {
    this.userData[key] = value;
    this.Observable.next(this.userData);
    this.cookieService.setObjectToCookie('sjgnei', this.getUser());
  }

  getUserProperty(key: string): any {
    return this.userData[key];
  }

  resetUser(): void {
    this.userData = new UserProfilePojo();
    this.cookieService.setObjectToCookie('sjgnei', this.getUser());
  }
}
