import { ResponseStatusWrapper } from '../../../../constants/app-constants';
import { ServerUrls } from '../../../../server-url-constants';

export class CrmContactConstants {
  public static API: any = {
    'filterUserList': '/rest/crm/contact/v1/filter_user',
    'getPropertyList': '/rest/propncase/asset/v1/get_site_address_details/',
    'getCaseList': '/rest/property_case/v1/get/by_owner/',
    'getNotes': '/rest/crm/note/v1/by_crm/',
    'getOwnerDetails': '/rest/crm/contact/v1/filter_userinfo',
    'updateOwnerDetails': '/rest/crm/contact/v1/update_role_based_user_data',
    'getListOfCountries': '/rest/crm/contact/v1/get_list_of_countries',
    'add': '/rest/crm/note/v1',
    'contact': {
      'getList': '/rest/crm/view/v1',    // working (get views for contacts)
      'getDefaultColumns': '/rest/crm/view/v1/fields',
      'getContactList': '/rest/crm/list/v1', // working (get list for contacts)
      'getMailinglist': '/rest/crm/list/v1/mailinglist', // working (get list for contacts)
      'getSubscribedMailinglist': '/rest/crm/list/v1/subscribed_mailing_list',
      'getById': '/rest/crm/view/v1/get',
      'getByIdList': '/rest/crm/list/v1/get', // working
      'getData': '/rest/crm/view/v1/get_data',
      'search': '/rest/crm/view/v1/query',
      'advanced_search_crm_case': '/rest/global_search/v1/advanced_search_crm_case',
      'export': '/rest/export/v1/export_mailing_list',
      'save': '/rest/crm/view/v1/add',
      'saveList': '/rest/crm/list/v1/add',   // working(for adding in list)
      'update': '/rest/crm/view/v1/update',
      'updateList': '/rest/crm/list/v1/update',
      'delete': '/rest/crm/view/v1/delete/',
      'deleteList': '/rest/crm/list/v1/delete/',
      'getAllRole': '/rest/idm/user/v1/get_allrole',    // working
      'getRoleList': '/rest/idm/user/v1/get_role_list',
      'get_group_mapping': '/rest/group/v1/group_mapping_list',
      'searchContent': '/rest/crm/view/v1/search',
      'getTemplates': '/rest/email/template/v1/getall',
      'getTemplateByName': '/rest/email/template/v1/get/',
      'updateTemplate': '/rest/email/template/v1/update',
      'sendMail': '/rest/crm/list/v1/send_mail',
      'addMailingUser': '/rest/crm/list/v1/mailinglist/add_user',
      'addMultipleMailingUser': '/rest/crm/list/v1/mailinglist/add_multiple_user',
      'getUserprofileByCrmIdList': '/rest/crm/contact/v1/get_user_profile_by_crmid_list',
      'getStatusById': '/rest/notification/v1/status_by_bunchid/',
      'category_and_type': '/rest/case_type_category/v1/category_and_type',
      'getActiveStates': '/rest/casetypes/v1/get_active_states',
      'list': {
        'query': '/rest/crm/list/v1/query',
        'search': '/rest/crm/list/v1/search'
      }
    }
  };
}

export const secretPhrase: string = 'AXyTUvWZUI897YureW';

export class Id {
  id: string;
}

export class Info {
  isList: boolean = false;
  request: Id;
}

export class excelExport {
  moduleName: string = 'crm';
  additionalInfo: Info;
  fileType: string = 'EXCEL';
}


export class FilterUserRequest {
  name: string;
  email: string;
  mobile: string;
  pageNumber: number = 1;
  pageSize: number = 10;
  appId: string = ServerUrls.APPID;
  roles: any = ['Citizen', 'Non_Registered_Citizen'];
}

export class UserListReponse {
  status: ResponseStatusWrapper;
  userList: UserListProto[];
  totalRecord: number;
}

export class EmailTemplate {
  templateId: string;
  templateName: string;
  content: string;
  decodedContent: string;
  deleted: string;
  subject: string;
}

export class UserListProto {
  email: string;
  firstName: string;
  lastName: string;
  crmId: string;
  contactDetails?: ContactDetailsProto[];
}

export class SendMail {
  listId: string;
  crmList: any[];
  emailTemplateName: string;
  emailBody: string;
  emailSubject: string;
}

export const FRACTIONS_LIST: any[] = [{id: '1/4', value: '1/4'}, {id: '1/2', value: '1/2'},
  {id: '3/4', value: '3/4'}];
export const DIRECTIONS_LIST: string[] = ['DR', 'LN', 'ST', 'AVE', 'RD', 'BLVD', 'WAY', 'PL', 'CT'];

export class AddressListProto {
  type: string;
  addressLine: string;
  primary: boolean;
  houseFracNumber: string;
  preDirCd: string;
  streetName: string;
  streetTypeCd: string;
  postDirCd: string;
  unit: string;
  houseNumber: string;
  coordinates: any[] = [];
  city: string;
  state: string;
  country: string;
  landmark: string;
  buildingType: string;
  zip: string;
  addressCustomFields: CustomFieldsProto[] = [];
}

export class CustomFieldsProto {
  fieldName: string;
  fieldValue: string;
}

export class ContactDetailsProto {
  type: string;
  phoneCountryPrefix: string;
  phoneNumberFull: string;
  primary: boolean;
  phoneExtension: string;
  faxNumber: string;
}

export class OwnerInfoProto {
  pageNumber = 1;
  pageSize = 10;
  crmId: string;
  contactRequired = true;
  addressRequired = true;
  appId: string = ServerUrls.APPID;
}

export class EditOwnerProto {
  appId: string = ServerUrls.APPID;
  userFirstName: string;
  userLastName: string;
  additionalDetails: AdditionalFieldDetails[] = [];
  addressList: AddressListProto[] = [];
  contactDetails: ContactDetailsProto[] = [];
  userRole: string[] = [];
  emailIdList: EmailIdListProto[] = [];
}

export class AdditionalFieldDetails {
  appId: string;
  information: Map<string, string> = new Map<string, string>();
}

export class LocationAdditionalDetails {
  appId: string;
  information: InformationObject[] = [];
}

export class InformationObject {
  key: string;
  value: string;
}

export const AddressTypes = ['Home', 'Work', 'Other'];

export const ContactTypes = ['Mobile', 'Telephone', 'Fax'];

export class EmailIdListProto {
  emailId: string;
  primary: boolean;
}

export class NoteProto {
  id: string;
  note: string;
  userCrmId: string;
  createdByName: string;
  createdOn: any;
}

export class CrmNote {
  id: string;
  userCrmId: string;
  note: string;
  createdBy: string;
  createdByName: string;
  createdOn: Date;
}

export class CrmNoteResponse {
  status: ResponseStatusWrapper;
  crmNotes: CrmNote[];
}

export class GetAllRole {
  id: string;
  roleName: string;
}

export class ViewListResponse {
  status: ResponseStatusWrapper;
  views: any[];
}

export class ContactListResponse {
  status: ResponseStatusWrapper;
  lists: any[];
}

export interface AddEditListDialogData {
  title: string;
  id?: string;
  for: 'view' | 'list' | 'mailing';
  config: ConfigObj;
  isUpdate: boolean;
  fromAllContacts?: boolean;
  crmList?: any[];
  fields?: any[];
  viewList?: any[];
  responseFields?: any[];
  selectedCrmList?: any[]; // if crm is selected
  mailingListConfig?: MailingList;
  view?: any;
}

export class ViewSaveRequest {
  id: string;
  name: string;
  description: string;
  removeFromList: boolean = false;
  fields: CustomFieldObj[] = [];
  crmList: string[] = [];
  config: ConfigObj = new ConfigObj();
  responseFields: any[] = [];
  mailingListConfig: MailingList = new MailingList();
}

export class MailingList {
  defaultList: boolean = false;
  verificationRequired: boolean = true;
}

export class GetByViewObj {
  id: string;
  pageNumber: number;
  pageSize: number;
}

export class ConfigObj {
  viewAll: boolean = true; // public or private
  groupIds: string[] = [];
  publicAllowAccess: 'all' | 'group'; // for local user only
  groupAccess: boolean; // for local user only
  groupUpdateAccess: boolean = false;
  crmIds: string[] = [];
  crmUpdateAccess: boolean = false;
  staffAccess: boolean = false;
  staffUpdateAccess: boolean = false;
}

export class UpdateView {
  config: Config;
  fields: Fields[] = [];
  responseFields: ResponseFields[] = [];
}

export class Config {
  viewAll: boolean;
  staffAccess: boolean;
  staffUpdateAccess: boolean;
  groupIds: any[];
  groupUpdateAccess: boolean;
  crmIds: any[];
  crmUpdateAccess: boolean;
}

export class ResponseFields {
  key: string;
  value: string;
  type: string;
  default: boolean;
}

export class Fields {
  key: string;
  value: string;
  operation: string;
}

export class CustomFieldObj {
  key: string = '';
  title: string = '';
  type: string = '';
  isChecked: boolean = false;
  value?: any;
  searchContent?: boolean; // true if regex search
  excludeFromSearch: boolean; // if want to exclude[negative] from serach eg. name != test
  checkExist?: boolean; // true if exist match search
  isSortField?: boolean; // is sort field
  operation?: string;
  default?: boolean;
  sortOrder?: string;
  indexValue: number;
  additionalInfo?: FieldAdditionalInfo;
  lovList?: any[];
}

interface FieldAdditionalInfo {
  crmFields?: boolean;
  apnFields?: boolean;
  caseFields?: boolean;
}

export const FieldKey = ['firstName', 'lastName', 'phoneNumberFull', 'addressList.addressLine', 'emailIdList.emailId'];

export interface DefaultColumnsFields {
  key: string;
  value: string;
  type?: string;
  default?: boolean;
}

export const ResponseViewFields: DefaultColumnsFields[] = [
  {
    'key': 'actionMenu',
    'value': ''
  },
  {
    'key': 'checkMenu',
    'value': ''
  },
  {
    'key': 'firstName',
    'value': 'First Name',
    'type': 'TEXT',
    'default': true
  },
  {
    'key': 'lastName',
    'value': 'Last Name',
    'type': 'TEXT',
    'default': true
  },
  {
    'key': 'emailAddress',
    'value': 'Email',
    'type': 'TEXT',
    'default': true
  }
];

export const OperatorList = [
  {name: 'Equal To', value: 'EQ'},
  {name: 'Not Equal To', value: 'NE'},
  {name: 'Contains', value: 'CONTAINS'},
  {name: 'Does Not Contain', value: 'NOTCONTAINS'},
  {name: 'Is Empty', value: 'EMPTY'},
  {name: 'Is null', value: 'NULL'},
  {name: 'Is Not Null', value: 'NOTNULL'},
  {name: 'Is Not Empty', value: 'NOTEMPTY'},
  {name: 'Starts With', value: 'STARTSWITH'},
  {name: 'Ends With', value: 'ENDSWITH'}
];

export const AppIdList = ['myFD', 'CEP', 'VAP', 'MyPLAN', 'rso', 'TeamAssist', '3dimail'];
export const AddressTypeList = ['Home', 'Work', 'Other'];

export class ViewRequestProto {
  pageNumber: number;
  pageSize: number;
  id: string;
}

export class GetDataReqProto {
  pageNumber: number = 1;
  pageSize: number = 10;
  fields: Field[] = [];
  sortFields: Sorting[] = [];
  sideBarFilterFlag: boolean;
}

interface Field {
  key: string;
  operation: string;
  value: any;
}

export class Sorting {
  key: string;
  sortOrder: string;
}

export class KendoPaginationProto {
  pageNumber: number = 1;
  pageSize: number = 10;
  skip: number = 0;
}


export class SearchContent {
  id: string;
  searchText: string;
  responseFields: any[] = [];
  pageNumber: number = 1;
  pageSize: number = 10;
}

export class AddUserMailingListProto {
  mailingListId: string;
  multipleUsersMailingList: MailingUserListProto[];
}

export class MailingUserListProto {
  crmId: string;
  emailAddress: string;
  firstName: string;
  lastName: string;
}

export const CRM_OPERATORS_LIST = [
  {name: 'Equal To', value: 'EQ'},
  {name: 'Not Equal To', value: 'NE'},
  {name: 'Contains', value: 'CONTAINS'},
  {name: 'Does Not Contain', value: 'NOTCONTAINS'},
  // {name: 'Is Empty', value: 'EMPTY'},
  // {name: 'Is Null', value: 'NULL'},
  // {name: 'Is Not Null', value: 'NOTNULL'},
  // {name: 'Is Not Empty', value: 'NOTEMPTY'},
  {name: 'Starts With', value: 'STARTSWITH'},
  {name: 'Ends With', value: 'ENDSWITH'}
];

export class TemplateListResponse {
  status: ResponseStatusWrapper;
  templateList: TemplateListProto[];
}

export class TemplateListProto {
  templateId: string;
  templateName: string;
}
