import { NgModule } from '@angular/core';
import { PromtDialogComponent } from './promt-dialog.component';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
  imports: [SharedModule],
  declarations: [PromtDialogComponent],
  entryComponents: [PromtDialogComponent]
})
export class PromtDialogModule {
}
