import {Inject, Injectable} from '@angular/core';
import {ActivatedRoute, NavigationEnd, NavigationStart, Router} from '@angular/router';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {filter, map, mergeMap, startWith, switchMap} from 'rxjs/operators';
import {DOCUMENT} from '@angular/common';
import {CommonCookieService} from 'kscookies';
import {AppSettingService} from '../core/app-setting.service';
import {MenuService} from './components/menu.service';

@Injectable()
export class LayoutService {

  public cartItemsLength: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public componentClicked: string;
  private title$: BehaviorSubject<string> = new BehaviorSubject('');
  private loaderStatus$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private currentTheme$: BehaviorSubject<any> = new BehaviorSubject('styles.css');
  private menuPosition$: BehaviorSubject<any> = new BehaviorSubject('start');
  private pageLayout$: BehaviorSubject<any> = new BehaviorSubject('classic');
  private direction$: BehaviorSubject<string>;
  private windowScroll$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private windowScrollTop$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public leftSideNav: BehaviorSubject<boolean> = new BehaviorSubject(true);

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    @Inject(DOCUMENT) private document: any,
    private cookieService: CommonCookieService,
    private appSettingService: AppSettingService, private menuService: MenuService
  ) {
    this.switchLayout(this.cookieService.getCookie('portal-layout'));
    this.appSettingService.getObserver().subscribe((response) => {
      if (response.theme) {
        this.switchTheme(response.theme);
      }
      if (response.favicon) {
        this.setAppFavicon(response.favicon);
      }
    });
    // Set direction from session if set.
    const sessionDirection = sessionStorage.getItem('portal-direction');
    const direction = sessionDirection && sessionDirection === 'rtl' ? 'rtl' : 'ltr';
    this.direction$ = new BehaviorSubject(direction);

    // Set menu position from session if set.
    const menuDirection = this.cookieService.getCookie('portal-menu-direction');
    const position = menuDirection && menuDirection === 'end' ? 'end' : 'start';
    this.menuPosition$ = new BehaviorSubject(position);

    // Watch for router events to turn on/off the loader.
    this.router.events
      .subscribe((event: any) => {
        if (event instanceof NavigationStart) {
          this.loaderStatus$.next(true);
        } else if (event instanceof NavigationEnd) {
          this.menuService.setStaticTabs([]);
          this.menuService.staticTabClick.next('');
          this.setTitle();
          this.loaderStatus$.next(false);
        }
      });

    // Watch for router events to set title using route data.
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        startWith(this.activatedRoute),
        map(() => this.activatedRoute),
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter(route => route.outlet === 'primary'),
        mergeMap(route => route.data)
      )
      .subscribe(data => {
        if (data['title']) {
          this.setTitle(data['title']);
        }
      });
  }

  setAppFavicon(icon: string): void {
    $('#favicon-icon').attr('href', icon);
  }

  setTitle(title: string = ''): void {
    this.title$.next(title);
  }

  getTitle(): Observable<string> {
    return this.title$.asObservable();
  }

  setLoader(status: boolean): void {
    this.loaderStatus$.next(status);
  }

  getLoader(): Observable<boolean> {
    return this.loaderStatus$.asObservable();
  }

  toggleMenuPosition(): void {
    const direction = this.menuPosition$.getValue() === 'end' ? 'start' : 'end';
    this.cookieService.setCookie('portal-menu-direction', direction);
    this.menuPosition$.next(direction);
  }

  isMenuStart(): Observable<boolean> {
    return this.menuPosition$.asObservable()
      .pipe(
        switchMap(direction => of(direction === 'start'))
      );
  }

  toggleDirection(): void {
    const direction = this.direction$.getValue() === 'rtl' ? 'ltr' : 'rtl';
    sessionStorage.setItem('portal-direction', direction);
    this.direction$.next(direction);
  }

  getDirection(): Observable<string> {
    return this.direction$.asObservable();
  }

  isRTL(): Observable<boolean> {
    return this.direction$.asObservable()
      .pipe(
        switchMap(direction => of(direction === 'rtl'))
      );
  }

  /***
   * @Author : Ganeshram Kumhar (ganeshram.kumhar@kahunasystems.com)
   * @description : used to change the menu position
   * @param: position {string} position of menu
   * @returns: {void}
   */
  setMenuPosition(position: string): void {
    this.cookieService.setCookie('portal-menu-direction', position);
    this.menuPosition$.next(position);
  }

  getMenuPosition(): Observable<string> {
    return this.menuPosition$.asObservable();
  }

  currentTheme(): Observable<any> {
    return this.currentTheme$.asObservable();
  }

  setCurrentTheme(theme: any): void {
    this.currentTheme$.next(theme);
  }

  switchTheme(theme: string): void {
    if (theme !== this.currentTheme$.getValue()) {
      const script = document.createElement('link');
      script.type = 'text/css';
      script.rel = 'stylesheet';
      script.href = theme;
      document.body.appendChild(script);
      script.addEventListener('load', () => {
        script.remove();
        const styleSheets = document.getElementsByTagName('link');

        for (const style in styleSheets) {
          if (styleSheets[style] && styleSheets[style].href && styleSheets[style].href.includes('styles')) {
            styleSheets[style]['href'] = theme;
            this.setCurrentTheme(theme);
            this.cookieService.setCookie('portal-theme', theme);
            break;
          }
        }
        /*const link: any = this.document.querySelector(`link[href*="${this.currentTheme$.getValue()}"]`);
        if (link) {
          // link.parentNode.removeChild(link);
          link['href'] = theme;
          this.setCurrentTheme(theme);
          this.cookieService.setCookie('portal-theme', theme);
        }*/
      }, false);
      script.addEventListener('error', () => {
        script.remove();
      }, false);
    }
  }

  getLayoutObservable(): Observable<string> {
    return this.pageLayout$.asObservable();
  }

  switchLayout(layoutName: string): void {
    this.cookieService.setCookie('portal-layout', layoutName);
    this.pageLayout$.next(layoutName);
  }

  setWindowScroll(isScroll: boolean) {
    this.windowScroll$.next(isScroll);
  }

  getWindowScroll(): Observable<boolean> {
    return this.windowScroll$.asObservable();
  }

  setWindowScrollTop(isScroll: boolean) {
    this.windowScrollTop$.next(isScroll);
  }

  getWindowScrollTop(): Observable<boolean> {
    return this.windowScrollTop$.asObservable();
  }

  setComponentClicked(component: string) {
    this.componentClicked = component;
  }

  getComponentClicked() {
    return this.componentClicked;
  }
}
